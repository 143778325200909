/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Swal from 'sweetalert2';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updateStockPlan } from 'modules/beneficiaries/redux/planActions';
import { getBoards, updateBoard } from 'modules/boards/redux/boardActions';
import {
  addDocument,
  getDocumentsByReference,
  updateDocument,
} from 'modules/documents/redux/documentActions';
import { updateSociety } from 'modules/society/redux/societyActions';
import { store } from 'redux/store';

import boardDocumentSections from 'constants/boardDocumentSections';
import { MAX_FILESIZE_ALLOWED } from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import { dateFormat, dateFormatInverted } from 'constants/formats';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import reportStatusTypes from 'constants/reportStatusTypes';

import documentLinkTypes from 'constants/documentLinkTypes';
import operationTypes from 'constants/operationTypes';
import DocumentModalDelegationSection from 'modules/documents/components/Modals/DocumentModalDelegationSection';
import { countOperationShares, getPartnerActualShares } from 'utils/filters';

const linkSections = [
  { text: 'Investors' },
  { text: 'Partners' },
  { text: 'Beneficiaries' },
  { text: 'Operations' },
  { text: 'Directorship' },
  { text: 'Representatives' },
  { text: 'Auditors' },
];

const selectableOperationTypes = [
  'CONSTITUTION',
  'CAPITAL_INCREASE',
  'CAPITAL_DECREASE',
  'SELL_PARTICIPATION',
  'HERITAGE',
  'DONATION',
  'SPLIT',
  'RENUMERATION',
  'RECLASSIFICATION',
  'DIVIDENDS_DISTRIBUTION',
];

let countOperationTypes = {
  CAPITAL_INCREASE: 0,
  CAPITAL_DECREASE: 0,
  SELL_PARTICIPATION: 0,
  HERITAGE: 0,
  DONATION: 0,
  SPLIT: 0,
  RENUMERATION: 0,
  RECLASSIFICATION: 0,
  DIVIDENDS_DISTRIBUTION: 0,
};

const animatedComponents = makeAnimated();
const sectionsWithVisibilityWarning = ['Partners', 'Beneficiaries'];

function DocumentModal({
  userId,
  societyId,
  partnerId,
  beneficiaryId,
  representativeId,
  directorId,
  showLinkOptions = false,
  updateLink = false,
  document,
  defaultCategory,
  boardId,
  defaultSubcategory,
  investorId,
  boardSection,
  stockPlan,
  defaultRepresented,
  defaultRepresentative,
  isLiteVersion = false,
}) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const boards = useSelector((state) =>
    state.boards.sort((a, b) => new Date(b.date) - new Date(a.date))
  );

  const { hasOldPartnersOn } = actualSociety?.configuration || {};

  const labelSections = {
    General: t('SelectCategory'),
    Partners: t('SelectPartner'),
    Beneficiaries: t('SelectBeneficiary'),
    Operations: t('SelectOperation'),
    Directorship: t('SelectDirectorship'),
    Representatives: t('SelectRepresentative'),
    Auditors: t('SelectAuditor'),
    Investors: t('SelectInvestor'),
    Borradores: 'el Borrador',
  };

  const [selectedBoardSection, setSelectedBoardSection] =
    useState(boardSection);
  const [minuteBook, setMinuteBook] = useState('');
  const [councilMinute, setCouncilMinute] = useState('');
  const [folder, setFolder] = useState('');
  const [boardOptions, setBoardOptions] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState(boardId);
  const [showOptions, setShowOptions] = useState(true);
  const [reportStatus, setReportStatus] = useState(
    reportStatusTypes.var.APPROVED
  );
  const linkedTo = useRef([]);
  const minuteBookOptions = useSelector((state) => {
    let result = [];
    result = state.society?.actualSociety?.minutebookFolders?.map(
      (minuteBook) => ({
        id: minuteBook?.['_id'],
        text: minuteBook.label,
      })
    );
    return result;
  });

  const councilMinutesOptions = useSelector((state) => {
    let result = [];
    result = state.society?.actualSociety?.councilMinutes?.map(
      (councilMinute) => ({
        id: councilMinute?.['_id'],
        text: councilMinute.label,
      })
    );
    return result;
  });

  const foldersOptions = useSelector((state) => {
    let result = [];
    result = state.society?.actualSociety?.myFolders?.map((folder) => ({
      id: folder?.['_id'],
      text: folder.label,
    }));
    return result;
  });

  const delegationRepresentedOptions = useSelector((state) => {
    let result = [];
    const currentBoard = state.boards?.find(
      (board) => board['_id'] === boardId
    );
    result = currentBoard?.participants
      ?.filter(
        (participant) =>
          getPartnerActualShares(participant.member, actualSociety) > 0
      )
      ?.map((participant) => ({
        value: participant.member,
        label: `${participant?.name} | ${participant?.email}`,
        disabled: !!participant?.hasDelegated,
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label))
      ?.sort((a, b) => a.disabled - b.disabled);

    return result;
  });

  const delegationRepresentativeOptions = useSelector((state) => {
    let result = [];
    const currentBoard = state.boards?.find(
      (board) => board['_id'] === boardId
    );
    result = currentBoard?.participants
      ?.filter((participant) =>
        hasOldPartnersOn
          ? true
          : getPartnerActualShares(participant.member, actualSociety) > 0
      )
      ?.map((participant) => ({
        value: participant.member,
        label: `${participant?.name} | ${participant?.email}`,
        disabled: !!(participant?.isAbsent || participant?.hasDelegated),
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label))
      ?.sort((a, b) => a.disabled - b.disabled);
    return result;
  });

  const reportStatusOptions = Object.keys(reportStatusTypes.var).map(
    (status) => ({
      id: status,
      text: `${t(reportStatusTypes.text[status])}`,
    })
  );

  const [documentCategories, setDocumentCategories] = useState([]);
  const [documentCategorySelected, setDocumentCategorySelected] = useState();
  const [linkedOptions, setLinkedOptions] = useState([]);
  const [linkToSelected, setLinkToSelected] = useState([]);
  const [linkedSection, setLinkedSection] = useState('');
  const [linkDocument, setLinkDocument] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [selectedRepresented, setSelectedRepresented] = useState();
  const [selectedRepresentative, setSelectedRepresentative] = useState();
  const [defaultRepresentedOption, setDefaultRepresentedOption] = useState();
  const [defaultRepresentativeOption, setDefaultRepresentativeOption] =
    useState();
  const [forAdminsDocument, setForAdminsDocument] = useState();
  const [date, setDate] = useState('');
  const [file, setFile] = useState();

  const isButtonDisabled = () =>
    (!updateLink &&
      (!documentName ||
        !date ||
        !file ||
        file.size > MAX_FILESIZE_ALLOWED.value)) ||
    (linkDocument && !linkedSection) ||
    ((linkDocument || updateLink) &&
      linkedSection !== 'General' &&
      !linkToSelected.length) ||
    (documentCategorySelected === documentTypes.MINUTEBOOK && !minuteBook) ||
    (documentCategorySelected === documentTypes.ANNUALREPORTS &&
      !reportStatus) ||
    (documentCategorySelected === documentTypes.BOARDS &&
      (!selectedBoard || !selectedBoardSection)) ||
    (selectedBoardSection === boardDocumentSections.DELEGATION &&
      (selectedRepresented === selectedRepresentative ||
        !selectedRepresented ||
        !selectedRepresentative));

  const handleDelegationDocument = async (board, isRepresented, document) => {
    try {
      const partnerParticipant = board.participants.find((participant) =>
        participant.member === isRepresented
          ? selectedRepresented
          : selectedRepresentative
      );
      const docs = partnerParticipant.delegationDocuments || [];

      const updatedData = {
        participantData: {
          ...partnerParticipant,
          delegationDocuments: [...docs, document['_id']],
        },
      };
      if (isRepresented) {
        updatedData.delegationDocs = { add: document['_id'] };
      }
      await store.dispatch(updateBoard(selectedBoard, updatedData, false));
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  const updateLinkedTo = (value, type) => {
    if (value) {
      linkedTo.current.push({
        linkedId: value,
        linkedType: type,
      });
    }
  };

  async function saveDocument(event) {
    event.preventDefault();
    let subcategory;

    if (documentCategorySelected === documentTypes.MINUTEBOOK) {
      subcategory = minuteBook;
    } else if (documentCategorySelected === documentTypes.MYFOLDERS) {
      subcategory = folder;
    } else if (documentCategorySelected === documentTypes.COUNCILMINUTES) {
      subcategory = councilMinute;
    }

    const newDocument = {
      file,
      date,
      size: file?.size,
      name: documentName,
      author: userId,
      society: societyId,
      fileType: fileTypes[file?.type],
      category:
        documentCategorySelected === documentTypes.BOARDS
          ? documentTypes.DOCUMENTS
          : documentCategorySelected,
      subcategory:
        selectedBoardSection === boardDocumentSections.ANNOUNCEMENT
          ? documentTypes.BOARDS
          : subcategory,
      description: t('DocumentLinkedManually'),
      isForAdmins: forAdminsDocument,
      reportStatus,
    };

    let data = {};
    const docs = {};
    let directorsData = {};
    let edit = [];
    let representativesArray = actualSociety?.representatives;
    let auditorsArray = actualSociety?.auditors;

    if (
      (linkDocument || updateLink) &&
      documentCategorySelected !== documentTypes.BOARDS
    ) {
      // First loop to associate each element to the document.
      linkToSelected.forEach((element) => {
        switch (element.text) {
          case 'Investors':
            updateLinkedTo(element.value, documentLinkTypes.INVESTOR);
            break;
          case 'Partners':
            updateLinkedTo(element.value, documentLinkTypes.PARTNER);
            break;
          case 'Beneficiaries':
            updateLinkedTo(element.value, documentLinkTypes.BENEFICIARY);
            break;
          case 'Operations':
            updateLinkedTo(element.value, documentLinkTypes.OPERATION);
            break;
          case 'Directorship': {
            updateLinkedTo(element.value, documentLinkTypes.DIRECTOR);
            break;
          }
          case 'Representatives': {
            updateLinkedTo(element.value, documentLinkTypes.REPRESENTATIVE);
            break;
          }
          case 'Auditors': {
            updateLinkedTo(element.value, documentLinkTypes.AUDITOR);
            break;
          }
          default:
            break;
        }
      });

      // Create or update the document
      newDocument.linkedTo = linkedTo.current;
      const uploadedDocument = updateLink
        ? await store.dispatch(
            updateDocument(
              {
                ...document,
                linkedTo: [...(document?.linkedTo || []), ...linkedTo.current],
              },
              false
            )
          )
        : await store.dispatch(addDocument(newDocument));

      // Second loop to associate the document to each element.
      linkToSelected.forEach((element) => {
        switch (element.text) {
          case 'Investors':
            docs.investors = docs.investors
              ? [...docs.investors, element.value]
              : [element.value];
            break;
          case 'Partners':
            docs.partner = docs.partner
              ? [...docs.partner, element.value]
              : [element.value];
            break;
          case 'Beneficiaries':
            docs.beneficiary = docs.beneficiary
              ? [...docs.beneficiary, element.value]
              : [element.value];
            break;
          case 'Operations':
            docs.operation = docs.operation
              ? [...docs.operation, element.value]
              : [element.value];
            break;

          case 'Directorship': {
            const director = actualSociety?.directors?.filter(
              (currentDirector) => currentDirector['_id'] === element.value
            )[0];
            director.documents = [
              ...director.documents,
              uploadedDocument['_id'],
            ];
            edit = [
              ...edit,
              { directorId: element.value, newDirector: director },
            ];

            directorsData = {
              directors: {
                edit,
              },
            };
            break;
          }
          case 'Representatives': {
            // Get current representative.
            const representative = representativesArray.filter(
              (representative) => representative['_id'] === element.value
            )[0];
            // Update representative documents with new document.
            representative.documents = [
              ...representative.documents,
              uploadedDocument['_id'],
            ];
            // Update representatives array with the rest of representatives and the updated one.
            representativesArray = [
              ...actualSociety.representatives.filter(
                (representative) => representative['_id'] !== element.value
              ),
              representative,
            ];
            break;
          }
          case 'Auditors': {
            // Get current auditor.
            const auditor = auditorsArray.filter(
              (auditor) => auditor['_id'] === element.value
            )[0];
            // Update auditor documents with new document.
            auditor.documents = [...auditor.documents, uploadedDocument['_id']];
            // Update auditors array with the rest of auditors and the updated one.
            auditorsArray = [
              ...actualSociety.auditors.filter(
                (auditor) => auditor['_id'] !== element.value
              ),
              auditor,
            ];
            break;
          }
          default:
            break;
        }
      });
      data = {
        docs: { add: uploadedDocument, ...docs },
        ...directorsData,
        representatives: [...representativesArray],
        auditors: [...auditorsArray],
      };
    } else if (selectedBoardSection === boardDocumentSections.ANNOUNCEMENT) {
      // Create the document.
      const uploadedDocument = await store.dispatch(addDocument(newDocument));
      // Associate the document with the corresponding board section.
      await store.dispatch(
        updateBoard(
          selectedBoard,
          { announcementDocument: uploadedDocument },
          false
        )
      );
    } else if (selectedBoardSection === boardDocumentSections.DELEGATION) {
      // Create the document.
      const uploadedDocument = await store.dispatch(addDocument(newDocument));
      // Get the corresponding board.
      const board = boards.find((board) => board['_id'] === selectedBoard);
      // Associate the document with represented member of delegation.
      await handleDelegationDocument(board, true, uploadedDocument);
      // Associate the document with representative member of delegation.
      await handleDelegationDocument(board, false, uploadedDocument);
      // update the participants who delegated
      const currentParticipant = board.participants.find(
        (participant) => participant.member === selectedRepresented
      );
      const representedParticipant = {
        ...currentParticipant,
        member: selectedRepresented,
        isRepresented: true,
        hasDelegated: true,
        representative: selectedRepresentative,
        delegationDocuments: [
          ...(currentParticipant?.delegationDocuments || []),
          uploadedDocument['_id'],
        ],
      };
      await store.dispatch(
        updateBoard(boardId, { participantData: representedParticipant }, false)
      );
    } else if (selectedBoardSection === boardDocumentSections.PRIVATE) {
      // Create the document.
      const uploadedDocument = await store.dispatch(addDocument(newDocument));

      // Associate the document with board's privated documents.
      const updatedData = {
        privateDocs: { add: uploadedDocument },
      };

      await store.dispatch(updateBoard(selectedBoard, updatedData, false));
    } else if (stockPlan) {
      // Create the document.
      const uploadedDocument = await store.dispatch(addDocument(newDocument));

      const planDocuments = stockPlan?.documents || [];
      const updateStockPlanData = {
        ...stockPlan,
        documents: [...planDocuments, uploadedDocument['_id']],
      };
      // Associate the document with the corresponding plan.
      await store.dispatch(
        updateStockPlan(stockPlan['_id'], updateStockPlanData, false)
      );
    }
    // This case applies for uploaded documents on Investor/Partner/Beneficiary/Representative/Director docs tab.
    else {
      // Update the linkedTo object for each possible element.
      updateLinkedTo(investorId, documentLinkTypes.INVESTOR);
      updateLinkedTo(partnerId, documentLinkTypes.PARTNER);
      updateLinkedTo(beneficiaryId, documentLinkTypes.BENEFICIARY);
      updateLinkedTo(representativeId, documentLinkTypes.REPRESENTATIVE);
      updateLinkedTo(directorId, documentLinkTypes.DIRECTOR);

      // Create the document.
      newDocument.linkedTo = linkedTo.current;
      const uploadedDocument = await store.dispatch(addDocument(newDocument));

      // Associate the document with the corresponding investor/partner/beneficiary/annexes board section.
      data = {
        docs: {
          add: uploadedDocument,
          ...(investorId ? { investors: [investorId] } : {}),
          ...(partnerId ? { partner: [partnerId] } : {}),
          ...(beneficiaryId ? { beneficiary: [beneficiaryId] } : {}),
          ...(selectedBoardSection === boardDocumentSections.ANNEXES
            ? { board: selectedBoard }
            : {}),
        },
      };

      // Associate the document with the corresponding representative.
      if (representativeId) {
        const representative = actualSociety?.representatives?.find(
          (currentRepresentative) =>
            currentRepresentative['_id'] === representativeId
        );
        if (representative) {
          const currentDocs = representative.documents || [];
          representative.documents = [
            ...currentDocs,
            uploadedDocument?.['_id'],
          ];
          data.representatives = actualSociety?.representatives?.map(
            (currentRepresentative) =>
              currentRepresentative['_id'] === representativeId
                ? representative
                : currentRepresentative
          );
        }
      }
      // Associate the document with the corresponding director.
      if (directorId) {
        const director = actualSociety?.directors?.find(
          (currentDirector) => currentDirector['_id'] === directorId
        );
        if (director) {
          const currentDocs = director.documents || [];
          director.documents = [...currentDocs, uploadedDocument?.['_id']];
          data.directors = { edit: [{ directorId, newDirector: director }] };
        }
      }
    }
    store.dispatch(updateSociety(societyId, data, false));
    store.dispatch(getDocumentsByReference({ societyId }));
    store.dispatch(setModal(null));
    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${
        updateLink
          ? t('DocumentLinkedSuccessfully')
          : t('DocumentUploadedSuccessfully')
      }<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  }

  const initializeCountOperationTypes = () => {
    countOperationTypes = {
      CAPITAL_INCREASE: 0,
      CAPITAL_DECREASE: 0,
      SELL_PARTICIPATION: 0,
      HERITAGE: 0,
      DONATION: 0,
      SPLIT: 0,
      RENUMERATION: 0,
      RECLASSIFICATION: 0,
      DIVIDENDS_DISTRIBUTION: 0,
    };
  };

  const getOperationLabel = (operation) => {
    let result;
    const { operationType } = operation;
    const dateString = format(new Date(operation?.date), dateFormat);

    const comments =
      operation?.comments && isAdmin ? ` | ${operation?.comments}` : '';

    switch (operationType) {
      case operationTypes.CONSTITUTION:
        result = `${dateString} | ${operationTypesRealNames[operationType]}`;
        break;
      case operationTypes.CAPITAL_INCREASE:
        result = `${dateString} | ${++countOperationTypes[operationType]}ª ${
          operationTypesRealNames[operationType]
        }${comments}`;
        break;
      case operationTypes.CAPITAL_DECREASE:
        result = `${dateString} | ${++countOperationTypes[operationType]}ª ${
          operationTypesRealNames[operationType]
        }${comments}`;
        break;
      case operationTypes.SELL_PARTICIPATION:
        result = `${dateString} |  ${++countOperationTypes[operationType]}ª ${
          operationTypesRealNames[operationType]
        } | ${t('SharesSold')}: ${countOperationShares(operation)}${comments}`;
        break;
      case operationTypes.HERITAGE:
        result = `${dateString} |  ${++countOperationTypes[operationType]}ª ${
          operationTypesRealNames[operationType]
        } | ${t('TransmittedShares')}: ${countOperationShares(
          operation
        )}${comments}`;
        break;
      case operationTypes.DONATION:
        result = `${dateString} |  ${++countOperationTypes[operationType]}ª ${
          operationTypesRealNames[operationType]
        } | ${t('DonatedShares')}: ${countOperationShares(
          operation
        )}${comments}`;
        break;
      case operationTypes.SPLIT:
        result = `${dateString} |  ${++countOperationTypes[operationType]}ª ${
          operationTypesRealNames[operationType]
        } | ${t('NrPart')}: ${countOperationShares(operation)}${comments}`;
        break;
      case operationTypes.RENUMERATION:
        result = `${dateString} |  ${operationTypesRealNames[operationType]}${comments}`;
        break;
      case operationTypes.RECLASSIFICATION:
        result = `${dateString} |  ${operationTypesRealNames[operationType]}${comments}`;
        break;
      case operationTypes.DIVIDENDS_DISTRIBUTION:
        result = `${dateString} |  ${operationTypesRealNames[operationType]}${comments}`;
        break;

      default:
        break;
    }

    return result;
  };

  const getSectionContent = (section) => {
    let sectionOptions = [];

    switch (section) {
      case 'General':
        sectionOptions = Object.entries(documentTypes)
          .filter(
            ([, documentType]) =>
              documentType !== documentTypes.ALL &&
              documentType !== documentTypes.DELETED
          )
          .map((documentType) => ({
            id: documentType[0],
            text: `${documentType[1]}`,
          }));
        break;

      case 'Investors':
        sectionOptions = actualSociety?.investors?.map((investor) => ({
          value: investor?.['_id'],
          label: `${investor?.name} | ${investor?.email}`,
          text: 'Investors',
        }));
        break;

      case 'Partners':
        sectionOptions = actualSociety?.partners?.map((partner) => ({
          value: partner?.['_id'],
          label: `${partner?.name} | ${partner?.email}`,
          text: 'Partners',
        }));
        break;

      case 'Beneficiaries':
        sectionOptions = actualSociety?.beneficiaries?.map((beneficiary) => ({
          value: beneficiary?.['_id'],
          label: `${beneficiary?.name} | ${beneficiary?.email}`,
          text: 'Beneficiaries',
        }));
        break;

      case 'Operations':
        initializeCountOperationTypes();
        sectionOptions = actualSociety?.operations
          ?.filter((operation) =>
            selectableOperationTypes.includes(operation.operationType)
          )
          ?.map((operation) => ({
            value: operation?.['_id'],
            date: new Date(operation?.date).getTime(),
            label: getOperationLabel(operation),
            text: 'Operations',
          }))
          .sort((a, b) => b.date - a.date)
          .reverse();
        break;

      case 'Directorship':
        sectionOptions = actualSociety?.directors?.map((director) => ({
          value: director?.['_id'],
          label: `${director?.socialDenomination} | ${director?.email}`,
          text: 'Directorship',
        }));
        break;
      case 'Representatives':
        sectionOptions = actualSociety?.representatives?.map(
          (representative) => ({
            value: representative?.['_id'],
            label: `${representative?.socialDenomination} | ${representative?.email}`,
            text: 'Representatives',
          })
        );
        break;
      case 'Auditors':
        sectionOptions = actualSociety?.auditors?.map((auditor) => ({
          value: auditor?.['_id'],
          label: `${auditor?.socialDenomination} | ${auditor?.email}`,
          text: 'Auditors',
        }));
        break;

      case 'Draft':
        break;
      default:
        break;
    }

    if (minuteBookOptions?.length) {
      setMinuteBook(defaultSubcategory || minuteBookOptions[0]?.id);
    }
    if (foldersOptions?.length) {
      setFolder(defaultSubcategory || foldersOptions[0]?.id);
    }
    return sectionOptions;
  };

  const handleSelectChange = (selectedOptions) => {
    setLinkToSelected(selectedOptions);
  };

  const getCurrentBoards = async () => {
    dispatch(getBoards(societyId));
  };
  useEffect(() => {
    setLinkedOptions(getSectionContent(linkedSection));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedSection]);

  useEffect(() => {
    if (linkDocument || updateLink) {
      setLinkedSection(linkSections[0].text);
    }
  }, [linkDocument, updateLink]);

  useEffect(() => {
    if (defaultCategory && defaultCategory !== documentTypes.ALL) {
      setDocumentCategorySelected(defaultCategory);

      if (defaultSubcategory && defaultCategory === documentTypes.MINUTEBOOK) {
        setMinuteBook(defaultSubcategory);
      }
      if (
        defaultSubcategory &&
        defaultCategory === documentTypes.COUNCILMINUTES
      ) {
        setCouncilMinute(defaultSubcategory);
      }
      if (defaultSubcategory && defaultCategory === documentTypes.MYFOLDERS) {
        setFolder(defaultSubcategory);
      }
    }
  }, [defaultCategory, defaultSubcategory]);

  useEffect(() => {
    if (file && file.name) {
      setDocumentName(file.name);
    }
    if (file && file.lastModifiedDate) {
      setDate(format(new Date(file.lastModifiedDate), dateFormatInverted));
    }
  }, [file]);

  useEffect(() => {
    setDocumentCategories(getSectionContent('General'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (actualSociety) {
      getCurrentBoards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (boards) {
      const result = boards.map((board) => ({
        id: board?.['_id'],
        text: board.name,
      }));
      setBoardOptions(result);
    }
  }, [boards]);

  useEffect(() => {
    if (documentCategorySelected) {
      setShowOptions(
        documentCategorySelected !== documentTypes.BOARDS &&
          documentCategorySelected !== documentTypes.MINUTEBOOK // OJOOO
      );
    }
  }, [documentCategorySelected]);

  useEffect(() => {
    if (boardId) {
      setDocumentCategorySelected(documentTypes.BOARDS);
    } else if (representativeId) {
      setDocumentCategorySelected(documentTypes.AUTHORITY);
    } else if (directorId) {
      setDocumentCategorySelected(documentTypes.ASSIGNMENTS);
    } else if (stockPlan) {
      setDocumentCategorySelected(documentTypes.SCRIPTURES);
    } else {
      setDocumentCategorySelected(documentTypes.DOCUMENTS);
    }
  }, [boardId, representativeId, directorId, stockPlan]);

  useEffect(() => {
    if (defaultRepresentative && delegationRepresentativeOptions) {
      const selectedOption = delegationRepresentativeOptions.find(
        (option) => option.value === defaultRepresentative
      );
      if (selectedOption) {
        setDefaultRepresentativeOption(selectedOption);
        setSelectedRepresentative(selectedOption.value);
      }
    }

    if (defaultRepresented && delegationRepresentedOptions) {
      const selectedOption = delegationRepresentedOptions.find(
        (option) => option.value === defaultRepresented
      );
      if (selectedOption) {
        setDefaultRepresentedOption(selectedOption);
        setSelectedRepresented(selectedOption.value);
      }
    }
  }, []); // eslint-disable-line

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {(updateLink && t('LinkDocumentTo')) || t('AddDocument')}
        </h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveDocument}>
          {!updateLink && (
            <div className="form-group">
              <label className="form-label" htmlFor="default-06">
                {t('AddDocument')}
              </label>
              <div className="form-control-wrap">
                <div className="custom-file">
                  <input
                    id="customFile"
                    type="file"
                    accept="application/pdf"
                    className="custom-file-input"
                    onChange={(event) => setFile(event.target.files[0])}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    {file?.name || t('SelectDocument')}
                  </label>
                </div>
              </div>

              <div className="sub-text mt-1">
                {t('MaxSizeAllowed')} = <code>{MAX_FILESIZE_ALLOWED.text}</code>
                {' | '}
                {t('ActualFileSize')} ={' '}
                <code>
                  {(file && (file.size / 1024 / 1024).toFixed(2)) || 0}mb
                </code>
              </div>

              {file && file.size > MAX_FILESIZE_ALLOWED.value && (
                <div
                  className="alert alert-icon alert-danger mt-2"
                  role="alert"
                >
                  <em className="icon ni ni-alert-circle" />
                  <strong>{t('InvalidFile')}</strong>.{' '}
                  {t('ExceedsMaximumSizePermitted')}.
                </div>
              )}
            </div>
          )}

          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {(updateLink && t('DocumentName')) || t('NewDocumentName')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={document?.name || documentName}
                onChange={(event) => setDocumentName(event.target.value)}
                disabled={updateLink}
              />
            </div>
          </div>

          {!isLiteVersion && (
            <>
              {!updateLink && (
                <div className="form-group">
                  <label className="form-label" htmlFor="email-address">
                    {t('DateOfDocument')}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="date"
                      className="form-control"
                      value={date}
                      onChange={(event) => setDate(event.target.value)}
                      max="2100-01-01"
                      min="1000-01-01"
                    />
                  </div>
                </div>
              )}
            </>
          )}

          {!updateLink && (
            <>
              {!isLiteVersion && (
                <div className="form-group">
                  <label className="form-label" htmlFor="pay-amount">
                    {labelSections['General']}
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        disabled={boardId || stockPlan}
                        className="form-control"
                        value={documentCategorySelected}
                        onChange={(event) =>
                          setDocumentCategorySelected(event.target.value)
                        }
                      >
                        {documentCategories.length &&
                          documentCategories.map((option) => (
                            <option
                              value={option.id}
                              key={`class-${option.id}`}
                              label={t(option.text)}
                            >
                              {option.text}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {documentCategorySelected === documentTypes.ANNUALREPORTS && (
                <div className="form-group">
                  <label className="form-label" htmlFor="pay-amount">
                    {t('SelectReportStatus')}
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control"
                        value={reportStatus}
                        onChange={(event) => {
                          setReportStatus(event.target.value);
                        }}
                      >
                        {reportStatusOptions.map((option) => (
                          <option
                            value={option.id}
                            key={`class-${option.id}`}
                            label={t(option.text)}
                          >
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {documentCategorySelected === documentTypes.MINUTEBOOK &&
              minuteBookOptions?.length > 0 ? (
                <div className="form-group">
                  <label className="form-label" htmlFor="pay-amount">
                    {t('SelectMinuteBook')}
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control"
                        value={minuteBook}
                        onChange={(event) => {
                          setMinuteBook(event.target.value);
                        }}
                      >
                        {minuteBookOptions.map((option) => (
                          <option
                            value={option.id}
                            key={`class-${option.id}`}
                            label={t(option.text)}
                          >
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                documentCategorySelected === documentTypes.MINUTEBOOK && (
                  <div
                    className="alert alert-icon alert-danger mt-2"
                    role="alert"
                  >
                    <em className="icon ni ni-alert-circle" />
                    {t('AddAMinuteBook')}
                  </div>
                )
              )}

              {documentCategorySelected === documentTypes.MYFOLDERS &&
              foldersOptions?.length > 0 ? (
                <div className="form-group">
                  <label className="form-label" htmlFor="pay-amount">
                    {t('SelectFolder')}
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control"
                        value={folder}
                        onChange={(event) => {
                          setFolder(event.target.value);
                        }}
                      >
                        {foldersOptions.map((option) => (
                          <option
                            value={option.id}
                            key={`class-${option.id}`}
                            label={t(option.text)}
                          >
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                documentCategorySelected === documentTypes.MYFOLDERS && (
                  <div
                    className="alert alert-icon alert-danger mt-2"
                    role="alert"
                  >
                    <em className="icon ni ni-alert-circle" />
                    {t('AddAFolder')}
                  </div>
                )
              )}

              {documentCategorySelected === documentTypes.COUNCILMINUTES &&
              councilMinutesOptions?.length > 0 ? (
                <div className="form-group">
                  <label className="form-label" htmlFor="pay-amount">
                    {t('SelectCouncilMinute')}
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control"
                        value={councilMinute}
                        onChange={(event) => {
                          setCouncilMinute(event.target.value);
                        }}
                      >
                        {councilMinutesOptions.map((option) => (
                          <option
                            value={option.id}
                            key={`class-${option.id}`}
                            label={t(option.text)}
                          >
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                documentCategorySelected === documentTypes.COUNCILMINUTES && (
                  <div
                    className="alert alert-icon alert-danger mt-2"
                    role="alert"
                  >
                    <em className="icon ni ni-alert-circle" />
                    {t('AddACouncilMinute')}
                  </div>
                )
              )}

              {documentCategorySelected === documentTypes.BOARDS &&
              boardOptions?.length > 0 ? (
                <div className="form-group">
                  {!isLiteVersion && (
                    <>
                      <div className="form-group">
                        <label className="form-label" htmlFor="pay-amount">
                          {t('Board')}
                        </label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <select
                              disabled={boardId}
                              className="form-control"
                              value={selectedBoard}
                              onChange={(event) => {
                                setSelectedBoard(event.target.value);
                              }}
                            >
                              <option value="" disabled selected>
                                {t('SelectBoard')}
                              </option>
                              {boardOptions.map((option) => (
                                <option
                                  value={option.id}
                                  key={`class-${option.id}`}
                                  label={option.text}
                                >
                                  {option.text}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form-label">
                          {t('BoardSection')}
                        </label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <select
                              className="form-control"
                              value={selectedBoardSection}
                              onChange={(event) => {
                                setSelectedBoardSection(event.target.value);
                              }}
                              disabled={boardSection}
                            >
                              <option value="" disabled selected>
                                {t('SelectSection')}
                              </option>
                              {Object.entries(boardDocumentSections).map(
                                (option) => (
                                  <option
                                    value={option[0]}
                                    key={`class-${option[0]}`}
                                    label={t(option[1])}
                                  >
                                    {option.text}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {selectedBoardSection ===
                    boardDocumentSections.DELEGATION && (
                    <DocumentModalDelegationSection
                      delegationRepresentedOptions={
                        delegationRepresentedOptions
                      }
                      delegationRepresentativeOptions={
                        delegationRepresentativeOptions
                      }
                      defaultRepresentedOption={defaultRepresentedOption}
                      defaultRepresentativeOption={defaultRepresentativeOption}
                      selectedRepresented={selectedRepresented}
                      selectedRepresentative={selectedRepresentative}
                      onChangeRepresented={setSelectedRepresented}
                      onChangeRepresentative={setSelectedRepresentative}
                    />
                  )}

                  {selectedBoardSection ===
                    boardDocumentSections.ANNOUNCEMENT && (
                    <div
                      className="alert alert-icon alert-warning mt-2"
                      role="alert"
                    >
                      <em className="icon ni ni-alert-circle" />
                      {t('ReplaceCurrentCall')}
                    </div>
                  )}
                </div>
              ) : (
                documentCategorySelected === documentTypes.BOARDS && (
                  <div
                    className="alert alert-icon alert-danger mt-2"
                    role="alert"
                  >
                    <em className="icon ni ni-alert-circle" />
                    {t('AddABoard')}
                  </div>
                )
              )}
            </>
          )}

          <div className="form-group row">
            {showLinkOptions && showOptions && (
              <div className="form-group col-6">
                <div className="custom-control custom-control-xs custom-checkbox">
                  <input
                    type="checkbox"
                    id="checkbox-notifications"
                    className="custom-control-input"
                    value={linkDocument}
                    checked={updateLink || linkDocument}
                    onChange={(event) => setLinkDocument(event.target.checked)}
                    disabled={updateLink}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox-notifications"
                  >
                    {t('LinkDocument')}
                  </label>
                </div>
              </div>
            )}
            <div className="form-group col-6">
              <div className="custom-control custom-control-xs custom-checkbox">
                <input
                  type="checkbox"
                  id="checkbox-admin-documents"
                  className="custom-control-input"
                  value={forAdminsDocument}
                  checked={forAdminsDocument}
                  onChange={(event) =>
                    setForAdminsDocument(event.target.checked)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="checkbox-admin-documents"
                >
                  {t('ForAdminsOnlyDocument')}
                </label>
              </div>
            </div>
          </div>

          {(linkDocument || updateLink) && showOptions && (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('SelectSectionLink')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-control-select">
                    <select
                      className="form-control"
                      value={linkedSection}
                      onChange={(event) => setLinkedSection(event.target.value)}
                    >
                      {linkSections.map((section) => (
                        <option
                          value={section.text}
                          key={`class-${section.text}`}
                          label={t(section.text)}
                        >
                          {section.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  {linkedSection !== linkSections[0].text &&
                    linkedOptions.length === 0 && (
                      <span className="badge badge-dot ml-1 mt-1 text-danger w-100">
                        Esta sociedad aún no tiene&nbsp;
                        <b>&quot;{linkedSection}&quot;</b>. Necesita agregar
                        {linkedSection === linkSections[3].text
                          ? ' alguna '
                          : ' alguno '}
                        para poder vincularlo.
                      </span>
                    )}
                </div>
              </div>

              {linkedSection && linkedOptions.length > 0 && (
                <>
                  <div className="form-group">
                    <Select
                      closeMenuOnSelect={false}
                      className="react-select"
                      options={linkedOptions}
                      components={animatedComponents}
                      defaultValue={linkToSelected}
                      onChange={handleSelectChange}
                      placeholder={labelSections[linkedSection]}
                      isMulti
                    />
                  </div>

                  {sectionsWithVisibilityWarning.includes(linkedSection) && (
                    <div
                      className="alert alert-icon alert-warning mt-2"
                      role="alert"
                    >
                      <em className="icon ni ni-alert-circle" />
                      {t('DocumenVisibilityWarning')}
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={isButtonDisabled()}
          >
            {(updateLink && t('LinkDocumentTo')) ||
              (boardId && t('UpDocument')) ||
              t('SaveDocument')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default DocumentModal;
