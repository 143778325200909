import { useTranslate } from 'hooks/useTranslate';
import { useDispatch } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import { Partner } from 'types';

import PartnerDetailInviteModal from './PartnerDetailInviteModal';

type Props = {
  partner: Partner;
};

const PartnerDetailInviteButton = ({ partner }: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(setModal(<PartnerDetailInviteModal partner={partner} />));
  };

  return (
    <button type="button" className="btn btn-primary" onClick={handleOpenModal}>
      <em className="icon ni ni-share-fill" />
      <span>{t('InvitePartner')}</span>
    </button>
  );
};

export default PartnerDetailInviteButton;
