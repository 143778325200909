import userTypes from 'constants/userTypes';
import { Partner } from 'types';

type BoardNewParticipant = {
  member: string;
  name: string;
  email: string;
  userType: string;
  assists: boolean;
  isAbsent: boolean;
  isRepresented: boolean;
  hasDelegated: boolean;
  representative: null;
  delegationVote: null;
  delegationDocuments: [];
  votes: [];
};

export const parsePartnersToParticipants = (
  partners: Partner[]
): BoardNewParticipant[] =>
  // filter((partner) => partner?.sharesCount?.actual > 0) // TODO Uncomment this filter after implement "Delegation to 3rd parties feature"

  partners?.map((partner) => ({
    member: partner['_id'],
    name: partner?.name,
    email: partner?.email,
    userType: userTypes.PARTNER,
    assists: false,
    isAbsent: false,
    isRepresented: false,
    hasDelegated: false,
    representative: null,
    delegationVote: null,
    delegationDocuments: [],
    votes: [],
  })) || [];
