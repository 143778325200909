/* eslint-disable import/no-extraneous-dependencies */
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { MixpanelProvider } from 'react-mixpanel-browser';

import Auth0ProviderWithDynamicConfig from 'Auth0ProviderWithDynamicConfig';

import * as Sentry from '@sentry/react';
import setupLogRocketReact from 'logrocket-react';

import { ThemeProvider } from 'modules/_shared/contexts/ThemeContext';

import { store, persistor } from 'redux/store';

import App from './App';

import './i18n/config';

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID, {
    shouldDebugLog: false,
  });
  setupLogRocketReact(LogRocket);
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        /^https:\/\/sttok-api\.es\/api/,
        /^https:\/\/sociolegal\.es\/api/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: process.env.NODE_ENV !== 'development',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithDynamicConfig>
      <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <MixpanelProvider>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </MixpanelProvider>
        </PersistGate>
      </Provider>
    </Auth0ProviderWithDynamicConfig>
  </BrowserRouter>,
  document.getElementById('root')
);
