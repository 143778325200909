import { useEffect, useState } from 'react';
import { store } from 'redux/store';

import { getDocument } from 'modules/documents/redux/documentActions';

import { Partner, Document } from 'types';

type Props = {
  currentPartner: Partner;
};
async function getDocumentById(documentId: string) {
  try {
    const document = await store.dispatch(getDocument(documentId));

    return document;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
}

export default function useGetLegalDocuments({ currentPartner }: Props) {
  const [constitutionDocument, setConstitutionDocument] =
    useState<Document | null>(null);
  const [appointmentDocument, setAppointmentDocument] =
    useState<Document | null>(null);
  const [ownershipDocument, setOwnershipDocument] = useState<Document | null>(
    null
  );

  useEffect(() => {
    const getDocuments = async () => {
      const constitutionDocumentId = currentPartner?.constitutionDocument;
      const appointmentDocumentId = currentPartner?.appointmentDocument;
      const ownershipDocumentId = currentPartner?.ownershipDocument;

      if (constitutionDocumentId) {
        const constitution = await getDocumentById(constitutionDocumentId);
        setConstitutionDocument(constitution);
      }
      if (appointmentDocumentId) {
        const appointment = await getDocumentById(appointmentDocumentId);
        setAppointmentDocument(appointment);
      }
      if (ownershipDocumentId) {
        const ownership = await getDocumentById(ownershipDocumentId);
        setOwnershipDocument(ownership);
      }
    };

    getDocuments();
  }, [currentPartner]);

  return {
    data: { constitutionDocument, appointmentDocument, ownershipDocument },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
