import { getPartnerFromParticipant } from 'modules/boards/utils';
import percentRound from 'percent-round';

import { BoardDelegationRole, Participant, Partner, Society } from 'types';
import { getDecimalScale, getSocietyValue } from 'utils/filters';
import getPartnerNDPercent from 'utils/getPartnerNDPercent';
import { boardDelegationRoles } from 'modules/boards/constants/boardDelegation';
import { mapParticipantForInitialList } from './mapParticipantForInitialList';

const getPartnersRoundPercents = (
  society: Society,
  participants: Participant[]
) => {
  const societyValue = getSocietyValue(society);
  const scale = societyValue
    ? (getDecimalScale(societyValue.value) as number)
    : 2;

  const sharePercents = participants.map((participant) => {
    const partner = getPartnerFromParticipant(participant, society);
    return getPartnerNDPercent(partner, society);
  });

  const roundPercents = percentRound(sharePercents, scale);

  return roundPercents;
};

export const getPartnersOnInitialLoad = (
  society: Society,
  assistants: Participant[]
) => {
  const participantsList: Participant[] = [];
  const roundPercents = getPartnersRoundPercents(society, assistants);

  assistants.forEach((participant, index) => {
    const partnerPercent = roundPercents[index];
    const partner = getPartnerFromParticipant(participant, society) as Partner;

    const currentParticipant = mapParticipantForInitialList(
      participant,
      partner,
      partnerPercent
    );

    participantsList.push(currentParticipant);
  });

  const participantsFiltered = participantsList.filter(
    (participant) => participant.member !== ''
  );

  return participantsFiltered;
};

export const getPartnersOnRepresentativeChange = (
  memberId: string, // the member id of the updated participant
  value: string, // the id of the selected representative
  participants: Participant[],
  role?: BoardDelegationRole
) => {
  debugger;
  const newFilteredParticipants = [...participants];

  const participantIndex = newFilteredParticipants.findIndex(
    (participant: Participant) => participant.member === memberId
  );

  if (participantIndex === -1) {
    return participants;
  }

  const participantRelated = participants.find(
    (participant: Participant) => participant.member === value
  );

  if (participantRelated && role === boardDelegationRoles.PARTNER) {
    if (participantRelated?.isRepresented) {
      newFilteredParticipants[participantIndex].assists = false;
      newFilteredParticipants[participantIndex].isAbsent = true;
      newFilteredParticipants[participantIndex].representative = null;
      newFilteredParticipants[participantIndex].hasPartnerRepresentant = false;
    } else if (participantRelated?.assists) {
      newFilteredParticipants[participantIndex].assists = true;
      newFilteredParticipants[participantIndex].isAbsent = false;
      newFilteredParticipants[participantIndex].representative = value;
      newFilteredParticipants[participantIndex].hasPartnerRepresentant = false;
    } else {
      newFilteredParticipants[participantIndex].assists = false;
      newFilteredParticipants[participantIndex].isAbsent = true;
      newFilteredParticipants[participantIndex].representative = value;
      newFilteredParticipants[participantIndex].hasPartnerRepresentant = false;
    }
  }

  if (
    !participantRelated &&
    role === boardDelegationRoles.PARTNER_REPRESENTANT
  ) {
    newFilteredParticipants[participantIndex].assists = true;
    newFilteredParticipants[participantIndex].isAbsent = false;
    newFilteredParticipants[participantIndex].representative = value;
    newFilteredParticipants[participantIndex].hasPartnerRepresentant = true;
  }

  const newParticipants = participants.map((participant: Participant) => {
    if (
      participant.member === newFilteredParticipants[participantIndex].member
    ) {
      if (participantRelated) {
        if (participantRelated?.isRepresented) {
          return {
            ...participant,
            representative: null,
            assists: false,
            isAbsent: true,
            hasPartnerRepresentant: false,
          };
        }

        if (participantRelated?.assists) {
          return {
            ...participant,
            representative: value,
            assists: true,
            isAbsent: false,
            hasPartnerRepresentant: false,
          };
        }

        return {
          ...participant,
          representative: value,
          assists: false,
          isAbsent: true,
          hasPartnerRepresentant: false,
        };
      }
      return {
        ...participant,
        representative: value,
        assists: true,
        isAbsent: false,
        hasPartnerRepresentant: true,
      };
    }
    return participant;
  });

  return newParticipants;
};

export const getPartnersOnRepresentationChange = (
  memberId: string,
  value: boolean,
  participants: Participant[]
) => {
  const updatedParticipants = participants.map((participant: Participant) => {
    if (participant.member === memberId) {
      return {
        ...participant,
        isRepresented: value,
        hasDelegated: value,
        representative: null,
      };
    }
    return participant;
  });

  return updatedParticipants;
};

export const getPartnersOnAssistsChange = (
  memberId: string,
  value: boolean,
  participants: Participant[]
) => {
  const newFilteredParticipants = [...participants];

  const participantIndex = newFilteredParticipants.findIndex(
    (participant: Participant) => participant.member === memberId
  );

  if (participantIndex === -1) {
    return participants;
  }

  newFilteredParticipants[participantIndex].assists = value;
  newFilteredParticipants[participantIndex].isAbsent = !value;
  let updatedPartnerIndex = -1;

  const updatedParticipants = participants.map(
    (participant: Participant, index: number) => {
      if (
        participant.member === newFilteredParticipants[participantIndex].member
      ) {
        updatedPartnerIndex = index;
        return { ...participant, assists: value, isAbsent: !value };
      }
      return participant;
    }
  );

  for (let i = 0; i < newFilteredParticipants.length; i += 1) {
    const currentParticipant = newFilteredParticipants[i];
    if (
      currentParticipant?.representative ===
        newFilteredParticipants[participantIndex].member ||
      (newFilteredParticipants[participantIndex].isRepresented &&
        currentParticipant?.member ===
          newFilteredParticipants[participantIndex].representative)
    ) {
      newFilteredParticipants[i].assists = value;
      newFilteredParticipants[i].isAbsent = !value;
    }
  }

  for (let i = 0; i < updatedParticipants.length; i += 1) {
    const currentParticipant = updatedParticipants[i];
    if (
      currentParticipant?.representative ===
        updatedParticipants[updatedPartnerIndex].member ||
      (updatedParticipants[updatedPartnerIndex].isRepresented &&
        currentParticipant?.member ===
          updatedParticipants[updatedPartnerIndex].representative)
    ) {
      updatedParticipants[i].assists = value;
      updatedParticipants[i].isAbsent = !value;
    }
  }

  return updatedParticipants;
};
