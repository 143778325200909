import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';

import { decryptResponse } from 'utils/token';
import axios from '../../../interceptors/axios';

import { addAlert } from './alertActions';

export const createGlobalNotification =
  (globalNotification, showAlert = true) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/notifications`,
        globalNotification
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.CREATE_GLOBAL_NOTIFICATION,
        globalNotification: decodedData,
      });
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.GLOBAL_NOTIFICATION_CREATED));
      }
      return decodedData;
    } catch (error) {
      dispatch(addAlert(alertBodyTypes[500]));
    }
  };

export const getGlobalNotifications = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications`
    );

    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_GLOBAL_NOTIFICATIONS,
      data: decodedData,
    });
  } catch (error) {
    return dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const getGlobalNotificationsBySociety =
  (societyId) => async (dispatch) => {
    try {
      const query = societyId ? { params: { societyId } } : { params: {} };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/notifications`,
        query
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.GET_GLOBAL_NOTIFICATIONS,
        data: decodedData,
      });
    } catch (error) {
      return dispatch(addAlert(alertBodyTypes[500]));
    }
  };

export const updateGlobalNotification =
  (globalNotificationId, updateGlobalNotificationData) => async (dispatch) => {
    const body = {
      _id: globalNotificationId,
      ...updateGlobalNotificationData,
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/notifications/${globalNotificationId}`,
        body
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_GLOBAL_NOTIFICATION,
        globalNotification: decodedData,
      });
      dispatch(addAlert(alertBodyTypes.GLOBAL_NOTIFICATION_UPDATED));
      return decodedData;
    } catch (error) {
      dispatch(addAlert(alertBodyTypes[500]));
    }
  };

export const deleteGlobalNotification =
  (globalNotificationId) => async (dispatch) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/notifications/${globalNotificationId}`
      );

      dispatch({
        type: types.DELETE_GLOBAL_NOTIFICATION,
        globalNotificationId,
      });
      dispatch(addAlert(alertBodyTypes.GLOBAL_NOTIFICATION_DELETED));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes[500]));
    }
  };
