import { useMixpanel } from 'react-mixpanel-browser';

import { FC, useEffect, useState } from 'react';
import { Society } from 'types';

import { useTranslate } from 'hooks/useTranslate';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';
import tableColumns from './tableColumns';

type Props = {
  representants: Record<string, any>[];
  actualSociety: Society;
  isDemo: boolean;
  isAdmin: boolean;
};

const parseRepresentantsToTable = (
  representants: Record<string, any>[],
  isAdmin: boolean,
  isDemo: boolean,
  mixpanel: any
) => {
  if (!representants) return [{}];

  return representants.map((representant, index) => ({
    ...representant,
    index: index + 1,
    name: representant?.name,
    representant,
    hasLink: true,
    isDemo,
    isAdmin,
    mixpanel,
  }));
};

const RepresentantsListTable: FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslate();
  const mixpanel = useMixpanel();

  const { representants, actualSociety, isDemo, isAdmin } = props;

  const [tableData, setTableData] = useState([]);

  const columns = tableColumns(i18n);

  useEffect(() => {
    setTableData(
      parseRepresentantsToTable(representants, isAdmin, isDemo, mixpanel) as any
    );
  }, [representants]); // eslint-disable-line

  return (
    <div className="nk-block">
      <div className="card card-bordered card-stretch">
        <div className="card-inner-group">
          <div className="card-inner p-0">
            <CustomDataTable
              data={transformData({
                data: tableData,
                columns,
              })}
              columns={columns}
              searchBy={[t('Name'), t('Email'), t('Cif')]}
              searchByPlaceholder={[t('name'), t('email'), t('CIF')].join(', ')}
              pagination
              rowsPerPage={10}
              className="nk-tb-list"
              actions
              showDense
              showDownload
              fileName={`${t('Representants')} ${actualSociety?.name}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepresentantsListTable;
