import { Board, Guest, HoldingClass, PartnerRepresentant, User } from 'types';

export type State = {
  user: User;
  partnerBook: Record<string, any>;
  menu: Record<string, any>;
  sideNav: boolean;
  alerts: any[];
  documents: Record<string, any>[];
  modal: any;
  society: Record<string, any>;
  employees: Record<string, any>[];
  employeesPlans: Record<string, any>[];
  plan: Record<string, any>[];
  drafts: Record<string, any>[];
  holdingClasses: HoldingClass[];
  tenderOffers: Record<string, any>[];
  tenderOffersShares: Record<string, any>[];
  boards: Board[];
  communication: Record<string, any>[];
  preview: Record<string, any>;
  notification: Record<string, any>;
  fullWidth: boolean;
  partnerCategories: Record<string, any>[];
  partnerRepresentants: PartnerRepresentant[];
  globalNotifications: Record<string, any>[];
  guests: Guest[];
};

const initialState = {
  user: null,
  partnerBook: null,
  menu: { type: 'MAIN' },
  sideNav: false,
  alerts: [],
  documents: [],
  modal: null,
  society: null,
  employees: [],
  employeesPlans: [],
  plan: [],
  drafts: [],
  holdingClasses: [],
  tenderOffers: [],
  tenderOffersShares: [],
  boards: [],
  communication: [],
  preview: null,
  notification: null,
  fullWidth: false,
  partnerCategories: [],
  partnerRepresentants: [],
  globalNotifications: [],
  guests: [],
};

export default initialState;
