/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n/config';

import documentTypes from 'constants/documentTypes';

import MenuDots from 'modules/_shared/components/MenuDots';
import { updateSociety } from 'modules/society/redux/societyActions';

import folders from 'assets/images/icon-sections/folders.svg';
import NoDocumentsAlert from '../List/NoDocumentsAlert';
import menuOptions from './menuOptions';

import './Books.scss';

const Books = ({ setCurrentBook, section }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const documents = useSelector((state) => state.documents);
  const minuteBooks = useSelector(
    (state) => state.society?.actualSociety?.minutebookFolders
  );
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [sortedBooks, setSortedBooks] = useState(minuteBooks);
  const [currentMenuOptions] = useState(menuOptions(i18n));

  const handleClick = (book) => {
    if (setCurrentBook) {
      setCurrentBook(book);
    }
  };

  const updateBooksOnSociety = (newBooksData) => {
    dispatch(
      updateSociety(
        actualSociety['_id'],
        {
          minutebookFolders: newBooksData,
        },
        false
      )
    );
  };

  const getReorderedBooks = (sourceIndex, targetIndex) => {
    const reorderedBooks = [...sortedBooks];
    const [movedItem] = reorderedBooks.splice(sourceIndex, 1);
    reorderedBooks.splice(targetIndex, 0, movedItem);

    return reorderedBooks.map((item, index) => ({
      ...item,
      index,
    }));
  };

  const handleOnDragEnd = (sourceId, sourceIndex, targetIndex, targetId) => {
    const nextState = swap(sortedBooks, sourceIndex, targetIndex);
    setSortedBooks(nextState);

    const reorderedBooks = getReorderedBooks(sourceIndex, targetIndex);
    updateBooksOnSociety(reorderedBooks);
  };

  const getDocumentsCountInBook = (id) => {
    const documentsInBook = documents.filter(
      (doc) =>
        doc?.category === documentTypes.MINUTEBOOK && doc?.subcategory === id
    ).length;

    const suffix = documentsInBook === 1 ? t('document') : t('documents');
    return `${documentsInBook} ${suffix}`;
  };

  useEffect(() => {
    if (minuteBooks && minuteBooks?.length > sortedBooks?.length) {
      const sortedArray = minuteBooks.sort((a, b) => a?.index - b?.index);
      setSortedBooks(sortedArray);
    }
  }, [minuteBooks]);

  const Item = ({ book }) => (
    <div
      key={book['_id']}
      className="nk-file-item nk-file cursor-pointer w-100 h-100 mx-0"
    >
      <div>
        <div
          className="nk-file-info"
          style={{
            cursor: 'grab',
          }}
        >
          <div className="nk-file-link">
            <div className="nk-file-title">
              <div
                onClick={() => handleClick(book)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <div className="nk-file-icon">
                  <span className="nk-file-icon-type">
                    <img src={folders} alt="section" />
                  </span>
                </div>
                <div className="nk-file-name">
                  <div className="nk-file-name-text">
                    <span className="title">{book.label}</span>
                    <span className="sub-text">
                      {getDocumentsCountInBook(book._id)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="nk-file-meta books-menudots">
        <div className="tb-lead">
          <MenuDots
            menuOptions={currentMenuOptions}
            id={book._id}
            params={{
              currentBook: book,
              actualSociety,
              documents,
              isDemo,
            }}
            direction="left"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="nk-fmg-quick-list nk-block">
      <div className="nk-block-head-xs">
        <div className="nk-block-between g-2">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{t('MinuteBooks')}</h3>
          </div>
        </div>
      </div>

      {sortedBooks?.length > 0 ? (
        <GridContextProvider onChange={handleOnDragEnd}>
          <GridDropZone
            id="items"
            boxesPerRow={4}
            rowHeight={200}
            style={{ height: '400px' }}
          >
            {sortedBooks?.map((book) => (
              <GridItem key={book?.index}>
                <div className="nk-files nk-files-view-grid p-1">
                  <Item book={book} />
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      ) : (
        <NoDocumentsAlert section={section} />
      )}
    </div>
  );
};

export default Books;
