/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getUserTransactions } from 'modules/society/redux/societyActions';

import menuTypes from 'constants/menuTypes';
import operationTypes from 'constants/operationTypes';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import { useTranslate } from 'hooks/useTranslate';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import menuOptions from 'modules/partners/pages/PartnerList/menuOptions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import TransactionsRow from 'modules/transactions/components/TransactionsRow';
import { useParams } from 'react-router-dom';
import {
  countOperationShares,
  getActualPartner,
  getActualSociety,
  getOperationAmount,
  getPartnerOperations,
} from 'utils/filters';
import tableColumns from './utils/tableColumns';

const operationList = [
  'ALL',
  operationTypes.SELL_PARTICIPATION,
  operationTypes.HERITAGE,
  operationTypes.DONATION,
  operationTypes.CONSTITUTION,
  operationTypes.CAPITAL_INCREASE,
  operationTypes.CAPITAL_DECREASE,
  operationTypes.SPLIT,
  operationTypes.RENUMERATION,
  operationTypes.RECLASSIFICATION,
  operationTypes.DIVIDENDS_DISTRIBUTION,
];

const TransactionsList = () => {
  const { t, i18n } = useTranslate();
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const { societyId, partnerId } = useParams();

  const user = useSelector((state) => state?.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [rows, setRows] = useState([]);
  const [userOperations, setUserOperations] = useState();
  const [operations, setOperations] = useState();
  const [tableData, setTableData] = useState([]);
  const [filteredMenuOptions, setFilteredMenuOptions] = useState([]);
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const [availableOperations, setAvailableOperations] = useState({});
  const [filterBy, setFilterBy] = useState(operationList[0]); // ALL

  const columns = tableColumns(i18n);

  const getOperationName = (operation) => {
    const operationName = operation?.alias || operation.operationType;
    return t(operationTypesRealNames[operationName]);
  };

  useEffect(() => {
    setRows(
      operations?.map((transaction) => (
        <TransactionsRow
          transaction={transaction.operation}
          society={transaction.society}
          key={transaction.operation['_id']}
        />
      ))
    );

    const operationList = {};
    operations?.forEach(({ operation }) => {
      operationList[operation.operationType] = true;
    });

    setAvailableOperations(operationList);
  }, [operations]);

  useEffect(() => {
    if (societyId && actualSociety?.['_id'] !== societyId)
      getActualSociety(user, societyId);
    else if (!societyId) getUserTransactions(user['_id'], setUserOperations);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (societyId) {
      dispatch(getStockPlans(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (societyId) {
      dispatch(getHoldingClasses(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (user) {
      let transactions;
      if (!societyId) {
        if (userOperations) {
          transactions = userOperations.map((operation) => ({
            operation,
            society: operation?.society,
          }));
        }
      } else if (!partnerId) {
        if (actualSociety) {
          transactions = actualSociety?.operations.map((operation) => ({
            operation,
            society: actualSociety,
          }));
        }
      } else if (actualSociety) {
        const partner = getActualPartner(actualSociety, partnerId);
        const partnerOperations = getPartnerOperations(partner, actualSociety);
        transactions = partnerOperations.map((operation) => ({
          operation,
          society: actualSociety,
        }));
      }
      const excludedOps = [
        operationTypes.ADD_PARTNER,
        operationTypes.ADD_PARTNERS,
        operationTypes.ADD_BENEFICIARY,
        operationTypes.STOCK_OPTION,
        operationTypes.PHANTOM_SHARE,
      ];
      const filteredTransactions = transactions
        ?.filter(
          (transaction) =>
            !excludedOps.includes(transaction.operation.operationType)
        )
        .sort(
          (transactionA, transactionB) =>
            new Date(transactionB.operation.date) -
            new Date(transactionA.operation.date)
        );
      setOperations(filteredTransactions);
    }
    if (actualSociety) {
      // filter operations menu options
      if (!actualSociety?.partners.length) {
        const [addPartnerOption, constitutionOption] = currentMenuOptions;
        setFilteredMenuOptions([addPartnerOption, constitutionOption]);
      } else if (!actualSociety?.shares.length) {
        const isSocietyConstituted = actualSociety?.operations.some(
          (operation) => operation.operationType === operationTypes.CONSTITUTION
        );

        const [addPartnerOption, constitutionOption, capitalIncreaseOption] =
          currentMenuOptions;

        setFilteredMenuOptions([
          addPartnerOption,
          isSocietyConstituted ? capitalIncreaseOption : constitutionOption,
        ]);
      } else {
        const options = currentMenuOptions.filter(
          (option, index) => index !== 1
        );
        setFilteredMenuOptions(options);
      }
    }
  }, [actualSociety, user, userOperations, societyId, partnerId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety]);

  useEffect(() => {
    if (rows) {
      const data = rows.map(({ props }) => ({
        transaction: props.transaction,
        reference: props.transaction['_id'],
        society: props.society,
        societyName: props.society?.name,
        date: props.transaction?.date,
        participations: countOperationShares(props.transaction) || '-',
        amount: getOperationAmount(props.transaction, props.society),
        operationName: getOperationName(props.transaction),
        comments: props.transaction?.comments,
        isDemo,
      }));

      if (filterBy !== 'ALL') {
        setTableData(
          data.filter((row) => row?.transaction?.operationType === filterBy)
        );
      } else {
        setTableData(data);
      }
    }
  }, [rows, filterBy]);

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">{t('Transactions')}</h3>
              <div className="nk-block-des text-soft">
                {rows?.length > 0 ? (
                  <p>
                    {t('DetailOfLatestOperations', {
                      societyName: actualSociety?.name,
                    })}
                  </p>
                ) : (
                  <p>{t('YouHaveNoTransactions')}</p>
                )}
              </div>
            </div>
            <div className="nk-block-head-content">
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content" data-content="pageMenu">
                  <ul className="nk-block-tools g-3">
                    {isAdmin && (
                      <>
                        <li>
                          <div className="form-group cursor-pointer">
                            <div className="form-control-wrap ">
                              <div className="form-control-select">
                                <div className="form-icon form-icon-left">
                                  <em
                                    className={`icon ni ${
                                      filterBy === 'ALL'
                                        ? 'ni-filter'
                                        : 'ni-filter-fill'
                                    }`}
                                  />
                                </div>
                                <select
                                  className="form-control pr-4  cursor-pointer"
                                  id="filter-society"
                                  name="filter-society"
                                  value={filterBy}
                                  onChange={(event) =>
                                    setFilterBy(event.target.value)
                                  }
                                >
                                  {operationList
                                    .filter(
                                      (operation) =>
                                        availableOperations[operation] ||
                                        operation === 'ALL'
                                    )
                                    .map((operation, index) => (
                                      <option
                                        value={operation}
                                        className="cursor-pointer"
                                      >
                                        {index === 0
                                          ? t('All')
                                          : t(
                                              operationTypesRealNames[operation]
                                            )}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <OperationMenuDots
                            menuOptions={filteredMenuOptions}
                            handleModal={(Modal) =>
                              dispatch(
                                setModal(
                                  <Modal
                                    user={user}
                                    societyId={actualSociety['_id']}
                                  />
                                )
                              )
                            }
                            params={{
                              society: actualSociety,
                              user,
                              mixpanel,
                            }}
                            disabled={isDemo}
                          />
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {tableData?.length > 0 && rows?.length && (
          <CustomDataTable
            data={transformData({
              data: tableData,
              columns,
            })}
            columns={columns}
            searchBy={t('Operation')}
            pagination
            className="nk-tb-list"
            // expandableRows
            actions
            showDense
            fileName={`${t('Transactions')} ${actualSociety.name}`}
          />
        )}
      </div>
    </div>
  );
};

export default TransactionsList;
