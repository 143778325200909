/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';
import { getActualSociety } from 'utils/filters';

import { setMenu } from 'modules/_shared/redux/menuActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';

import { getBoards } from 'modules/boards/redux/boardActions';
import BoardCardPartner from 'modules/boards/components/BoardCardPartner';
import {
  isBoardVisibleAtList,
  updateBoardStatusIfOutdated,
} from 'modules/boards/utils';
import { getPartnerRepresentants } from 'modules/partners/redux/partnerRepresentantsActions';

import BoardListBanner from '../../components/BoardListBanner';

const BoardsPartnerList = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId, memberId } = useParams();

  const user = useSelector((state) => state.user);
  const boards = useSelector((state) => state.boards);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const partnerRepresentants = useSelector(
    (state) => state.partnerRepresentants
  );

  const [boardsUpdated, setBoardsUpdated] = useState(false);
  const [boardsFiltered, setBoardsFiltered] = useState([]);

  const setCurrentMenu = () => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
  };

  const getCurrentValues = async () => {
    dispatch(getBoards(societyId));
    dispatch(getPartnerRepresentants({ societyId }));
  };

  const updateBoards = async (boards) => {
    if (!boardsUpdated) return;

    updateBoardStatusIfOutdated(boards);
    setBoardsUpdated(true);
  };

  const filterBoards = (boards) => {
    if (!boards.length) return;

    const visibleBoards =
      boards
        .filter((board) => isBoardVisibleAtList(board, memberId))
        ?.sort((a, b) => new Date(b.date) - new Date(a.date)) || [];

    setBoardsFiltered(visibleBoards);
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentMenu();
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    filterBoards(boards);
    updateBoards(boards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boards]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <PageListHeader title={t('BoardCouncil')} />

          {boardsFiltered.map((board) => (
            <BoardCardPartner
              key={board?.['_id']}
              data={{
                ...board,
                society: actualSociety,
                societyId: actualSociety?.['_id'],
                user,
                partnerRepresentants,
              }}
            />
          ))}

          <BoardListBanner />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default BoardsPartnerList;
