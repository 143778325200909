/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import DocumentModal from 'modules/documents/pages/Documents/documentModal';
import BeneficiaryDocumentsRow from 'modules/partners/components/PartnerDocuments/PartnerDocumentsRow';

const DirectorDocuments = ({ society, director }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { user, isAdmin, documents } = useSelector((state) => ({
    user: state.user,
    isAdmin: state.society?.role?.isAdmin,
    documents: state.documents,
  }));

  const directorName = director.socialDenomination || '';

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (documents?.length) {
      const docRows = documents
        ?.filter((currentDoc) =>
          director?.documents.some(
            (docId) => docId === currentDoc['_id'] && !currentDoc.isForAdmins
          )
        )
        .map((filteredDocument, index) => (
          <BeneficiaryDocumentsRow
            document={filteredDocument}
            index={index + 1}
            key={filteredDocument?.['_id']}
          />
        ));
      setRows(docRows);
    }
  }, [documents, director]);

  return (
    <div className="card-inner card-inner-lg">
      <div className="nk-block-head">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">{t('Documents')}</h4>
            <div className="nk-block-des">
              <p>
                {t('DirectorDocumentationPar', {
                  directorName,
                  societyName: society.name,
                })}
              </p>
            </div>
          </div>

          <div className="nk-block-head-content">
            {isAdmin && (
              <div className="nk-block float-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    dispatch(
                      setModal(
                        <DocumentModal
                          userId={user['_id']}
                          societyId={society['_id']}
                          directorId={director['_id']}
                        />
                      )
                    )
                  }
                >
                  <em className="icon ni ni-upload mr-1" />
                  {t('UpDocument')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {rows.length ? (
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered card-preview">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col" scope="col">
                    {t('No')}
                  </th>
                  <th className="nk-tb-col" scope="col">
                    {t('Name')}
                  </th>
                  <th className="nk-tb-col" scope="col">
                    {t('Date')}
                  </th>
                  <th className="nk-tb-col" scope="col">
                    {t('Doc')}
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DirectorDocuments;
