import instructionTypes from 'constants/instructionTypes';
import voteValues from 'constants/voteValues';
import { BoardOrder, Participant } from 'types';

function getPointByPointInstruction(
  instructions: any[],
  order: BoardOrder
): string {
  const resultInstruction = instructions?.find(
    (instruction: any) => instruction?.order === order?._id
  );
  if (resultInstruction) {
    return voteValues[resultInstruction?.vote as keyof typeof voteValues]
      ?.value;
  }
  return '';
}

export default function getParticipantInstruction(
  participant: Participant,
  order: BoardOrder
): string {
  const { instructions, instructionType } = participant.delegationVote || {};

  if (instructionType === instructionTypes.ALL_YES.value) {
    return voteValues.YES.value;
  }
  if (instructionType === instructionTypes.ALL_NO.value) {
    return voteValues.NO.value;
  }
  if (instructionType === instructionTypes.ALL_ABS.value) {
    return voteValues.ABS.value;
  }
  if (instructionType === instructionTypes.POINT_BY_POINT.value) {
    if (instructions) {
      return getPointByPointInstruction(instructions, order);
    }
  }

  return '';
}
