/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getUsers } from 'modules/profile/redux/userActions';
import { store } from 'redux/store';

import sizes from 'constants/sizes';

import EditPartnerModal from 'modules/partners/modals/EditPartner';
import representativeTypes from 'modules/society/components/SocietyRepresentative/representativeTypes';
import statusTypes from 'modules/society/components/SocietyRepresentative/statusTypes';

function PartnerPersonal({ society, partner, incorporationDate }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const partners = useSelector(
    (state) => state.society?.actualSociety?.partners
  );
  const partnerCategories = useSelector((state) => state.partnerCategories);

  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const isReadOnlyUser = useSelector((state) =>
    partner?.readOnlyUsers?.includes(state.user['_id'])
  );

  const [address, setAddress] = useState();
  const [primaryEmails, setPrimaryEmails] = useState([]);
  const [secondaryEmails, setSecondaryEmails] = useState([]);
  const [categoryName, setCategoryName] = useState();
  const [sindication, setSindication] = useState();

  const getOtherUsers = async (users) => {
    try {
      const otherUsersList = await dispatch(
        getUsers({
          usersList: users,
        })
      );
      setPrimaryEmails(
        otherUsersList?.length ? otherUsersList.map((curr) => curr.email) : ['']
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const getReadOnlyUsers = async (users) => {
    try {
      const readOnlyUsersList = await dispatch(
        getUsers({
          usersList: users,
        })
      );
      setSecondaryEmails(
        readOnlyUsersList?.length
          ? readOnlyUsersList.map((curr) => curr.email)
          : ['']
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    if (partner?.sindication) {
      const sindication = partners.find((p) => p._id === partner.sindication);
      setSindication(sindication?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners]);

  useEffect(() => {
    const { line1, zip, city, country } = partner.address || {};
    setAddress(
      `${line1 || ''} ${zip || ''} ${city || ''} ${
        country ? `(${country})` : ''
      }`
    );
  }, [partner.address]);

  useEffect(() => {
    if (partner?.otherUsers?.length) {
      getOtherUsers(partner.otherUsers);
    } else {
      setPrimaryEmails([]);
    }
    if (partner?.readOnlyUsers?.length) {
      getReadOnlyUsers(partner.readOnlyUsers);
    } else {
      setSecondaryEmails([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  useEffect(() => {
    if (partner?.category) {
      const category = partnerCategories.find(
        (curr) => curr['_id'] === partner.category
      );
      setCategoryName(category?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerCategories, partner?.category]);

  return (
    <div className="card-inner">
      <div
        className="nk-block-head"
        onClick={() => {
          if (!isDemo && !isReadOnlyUser) {
            store.dispatch(
              setModal(
                <EditPartnerModal
                  partner={partner}
                  societyId={society?.['_id']}
                  size={sizes.XL}
                />
              )
            );
          }
        }}
      >
        {!isDemo && !isReadOnlyUser && (
          <div className="nk-block-head-content">
            <div className="nk-block float-right">
              <button
                type="button"
                className="btn btn-icon btn-trigger btn-tooltip"
                onClick={() => {
                  store.dispatch(
                    setModal(
                      <EditPartnerModal
                        partner={partner}
                        societyId={society?.['_id']}
                        size={sizes.XL}
                      />
                    )
                  );
                }}
                disabled={isDemo || isReadOnlyUser}
              >
                <em className="icon ni ni-edit mr-1" />
              </button>
            </div>
          </div>
        )}
        <h4 className="title">{t('PersonalInformation')}</h4>
        <p>{t('BasicPersonalInformation')}</p>
        <div className="nk-block-head nk-block-head-line" />

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('FullName')}</span>
          <span className="profile-ud-value text-left">
            {partner?.name || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nif')}</span>
          <span className="profile-ud-value text-left">
            {partner.cif || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Telephone')}</span>
          <span className="profile-ud-value text-left">
            {partner?.phone || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Address')}</span>
          <span className="profile-ud-value text-left">{address}</span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">
            {primaryEmails.length === 0 &&
              secondaryEmails.length === 0 &&
              t('Email')}
            {(primaryEmails.length > 0 || secondaryEmails.length > 0) &&
              t('Emails')}
          </span>
          <span className="profile-ud-value text-left">
            <div className="d-block">
              {partner.email}
              <span
                id="email-category-main-info"
                className="badge badge-outline badge-pill text-primary ucap mx-1 float-right"
              >
                {t('Main')}
              </span>
              <Tooltip
                anchorId="email-category-main-info"
                place="top"
                style={{
                  zIndex: 9999,
                }}
              >
                {t('EmailCategoryMainInfo')}
              </Tooltip>
            </div>
            {primaryEmails?.map((email) => (
              <div className="d-block mt-1">
                <span className="profile-ud-value text-left">{email}</span>
                <span
                  id="email-category-primary-info"
                  className="badge badge-outline badge-pill text-primary ucap mx-1 float-right"
                >
                  {t('Primary')}
                </span>
                <Tooltip
                  anchorId="email-category-primary-info"
                  place="top"
                  style={{
                    zIndex: 9999,
                  }}
                >
                  {t('EmailCategoryPrimaryInfo')}
                </Tooltip>
              </div>
            ))}
            {secondaryEmails?.map((email) => (
              <div className="d-block mt-1">
                <span className="profile-ud-value text-left">{email}</span>
                <span
                  id="email-category-secondary-info"
                  className="badge badge-outline badge-pill text-primary ucap mx-1 float-right"
                >
                  {t('Secondary')}
                </span>
                <Tooltip
                  anchorId="email-category-secondary-info"
                  place="top"
                  style={{
                    zIndex: 9999,
                  }}
                >
                  {t('EmailCategorySecondaryInfo')}
                </Tooltip>
              </div>
            ))}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">
            {t('IncoporationDate')}
          </span>
          <span className="profile-ud-value text-left">
            {incorporationDate}
          </span>
        </div>

        {isAdmin && (
          <div className="profile-ud wider d-flex flex-column flex-md-row">
            <span className="profile-ud-label w-200px">{t('Category')}</span>
            <span className="profile-ud-value text-left">
              {categoryName || '-'}
            </span>
          </div>
        )}

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nationality')}</span>
          <span className="profile-ud-value text-left">
            {partner?.nationality || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Sindication')}</span>
          <span className="profile-ud-value text-left">
            {sindication || '-'}
          </span>
        </div>

        {partner?.isNaturalPerson && (
          <div className="profile-ud wider d-flex flex-column flex-md-row">
            <span className="profile-ud-label w-200px">{t('CivilStatus')}</span>
            <span className="profile-ud-value text-left">
              {partner?.civilStatus ? t(partner?.civilStatus) : '-'}
            </span>
          </div>
        )}

        {!partner?.isNaturalPerson && (
          <div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('CommercialRegister')}
              </span>
              <span className="profile-ud-value text-left">
                {partner?.legalInfo?.register || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('PartnerSection')}
              </span>
              <span className="profile-ud-value text-left">
                {partner?.legalInfo?.section || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Sheet')}</span>
              <span className="profile-ud-value text-left">
                {partner?.legalInfo?.sheet || '-'}
              </span>
            </div>

            <h6
              style={{ marginTop: '20px', marginBottom: '30px' }}
              className="title"
            >
              {t('RepresentativeInformation')}
            </h6>

            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Name')}</span>
              <span className="profile-ud-value text-left">
                {partner?.representative?.name || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Nif')}</span>
              <span className="profile-ud-value text-left">
                {partner?.representative?.nif || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Email')}</span>
              <span className="profile-ud-value text-left">
                {partner?.representative?.email || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('RepresentativeDate')}
              </span>
              <span className="profile-ud-value text-left">
                {partner?.representative?.initDate
                  ? format(
                      new Date(partner?.representative?.initDate),
                      'yyyy-MM-dd'
                    )
                  : '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('RevocationDate')}
              </span>
              <span className="profile-ud-value text-left">
                {partner?.representative?.endDate
                  ? format(
                      new Date(partner?.representative?.endDate),
                      'yyyy-MM-dd'
                    )
                  : '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('TypeOfAuthority')}
              </span>
              <span className="profile-ud-value text-left">
                {representativeTypes?.name[
                  partner?.representative?.authority
                ] || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Status')}</span>
              <span
                className={
                  partner?.representative?.status &&
                  `profile-ud-value text-left badge badge-dot badge-${
                    statusTypes.badge[partner?.representative?.status]
                  }`
                }
              >
                {statusTypes?.name[partner?.representative?.status] || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('Nationality')}
              </span>
              <span className="profile-ud-value text-left">
                {partner?.representative?.nationality || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Address')}</span>
              <span className="profile-ud-value text-left">
                {partner?.representative?.address || '-'}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PartnerPersonal;
