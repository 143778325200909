/* eslint-disable no-underscore-dangle */
import { dateFormat } from 'constants/formats';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import MenuDots from 'modules/_shared/components/MenuDots';
import AddSocietyRepresentative from 'modules/_shared/components/Modals/AddSocietyRepresentative';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { updateSociety } from 'modules/society/redux/societyActions';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import downloadFile from 'utils/downloadFile';
import representativeTypes from './representativeTypes';
import statusTypes from './statusTypes';

function SocietyRepresentativeRow({
  userId,
  representativeId,
  societyId,
  socialDenomination,
  nif,
  nationality,
  address,
  email,
  tag,
  comments,
  representativeType,
  initDate,
  endDate,
  representativeStatus,
  documents,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [representativeDocuments, setRepresentativeDocuments] = useState([]);

  const status = () => {
    if (!representativeStatus) {
      if (!endDate || new Date(endDate) - new Date() > 0)
        return statusTypes.var.CURRENT;
      return statusTypes.var.REVOKED;
    }
    return representativeStatus;
  };

  const handleEditRepresentative = () => {
    let representativeData = {
      socialDenomination,
      nif,
      nationality,
      address,
      email,
      tag,
      comments,
      representativeType,
      initDate,
      endDate,
      documents,
    };
    if (representativeStatus)
      representativeData = {
        ...representativeData,
        representativeStatus,
      };
    dispatch(
      setModal(
        <AddSocietyRepresentative
          societyId={societyId}
          action="EDIT"
          representativeData={representativeData}
          representativeId={representativeId}
          userId={userId}
        />
      )
    );
  };

  const handleDeleteRepresentative = () => {
    Swal.fire({
      icon: 'warning',
      title: `<h4 className="nk-block-title page-title">${t(
        'AreYouSureAboutDelRepresentative'
      )}  `,
      html: `<h3 class="text-primary fw-normal">${socialDenomination} </h3><br /><div class="caption-text">${t(
        'ThisProcedureCantUndo'
      )}</div>`,
      confirmButtonText: t('OkDelete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const filteredRepresentatives = actualSociety?.representatives.filter(
          (representative) => representative['_id'] !== representativeId
        );
        const newSocietyData = {
          representatives: filteredRepresentatives,
        };
        await dispatch(updateSociety(societyId, newSocietyData, false));
        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t(
            'RepresentativeSuccessfullyRemoved'
          )}<br></h4>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
      }
    });
  };
  const showLoadedDocuments = () => {
    if (documents?.length === 1) {
      return (
        <span
          className="btn btn-icon btn-trigger"
          onClick={() => {
            downloadFile({ documentId: documents[0] });
          }}
        >
          <em className="icon ni ni-file-download" />
        </span>
      );
    }
    if (documents?.length > 1) {
      if (representativeDocuments.length === 0) {
        return (
          <div className="spinner-border spinner-border-sm" role="status" />
        );
      }
      const options = representativeDocuments.map((doc) => ({
        icon: 'ni-file',
        text: doc.name,
        download: true,
        documentId: doc._id,
      }));
      return (
        <MenuDots
          menuOptions={options}
          direction="left"
          customIcon="ni-files"
        />
      );
    }
    return <span>-</span>;
  };

  useEffect(() => {
    if (representativeId && societyId) {
      dispatch(
        getDocuments(
          { representative: representativeId, society: societyId },
          setRepresentativeDocuments
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representativeId, societyId]);

  const SettingsToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-toggle btn-trigger mr-lg-n1"
    >
      {children}
      <em className="icon ni ni-more-h fs-22px" />
    </div>
  ));
  SettingsToggle.displayName = 'SettingsToggle';

  return (
    <tr className="nk-tb-item" key={representativeId}>
      <td className="nk-tb-col user-card w-max-200px">
        {isDemo ? (
          <span className="text-primary font-weight-bold cursor-pointer">
            {socialDenomination || ''}
          </span>
        ) : (
          <Link
            className="cursor-pointer text-2-lines-ellipsis "
            to={`/detalle-apoderado/${societyId}/${representativeId}`}
          >
            <span className="text-primary font-weight-bold cursor-pointer text-2-lines-ellipsis">
              {socialDenomination || ''}
            </span>
            <span className="d-block text-ellipsis w-max-200px user-info">
              {email}
            </span>
          </Link>
        )}
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{nationality || ''}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>
          {initDate && initDate !== ''
            ? format(new Date(initDate), dateFormat)
            : ''}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>
          {endDate && endDate !== ''
            ? format(new Date(endDate), dateFormat)
            : 'Indefinida'}
        </span>
      </td>

      <td className="nk-tb-col">
        <span>
          {representativeType
            ? representativeTypes.name[representativeType]
            : ''}
        </span>
      </td>

      <td className="nk-tb-col">
        {status && (
          <span
            className={`badge badge-dot badge-${statusTypes.badge[status()]}`}
          >
            {statusTypes.name[status()]}
          </span>
        )}
      </td>

      <td className="nk-tb-col">
        <span>{tag || ''}</span>
      </td>

      <td className="nk-tb-col tb-col-xl text-center">
        {documents.length > 0 ? (
          showLoadedDocuments()
        ) : (
          <span className="btn btn-icon disabled">
            <em className="icon ni ni-file-download" />
          </span>
        )}
      </td>

      {!isDemo && (
        <td className="nk-tb-col">
          <Dropdown className="pr-0">
            <Dropdown.Toggle as={SettingsToggle} />
            <Dropdown.Menu
              size="sm"
              title=""
              className="link-list-opt no-bdr dropdown-menu"
            >
              <a
                className="d-flex cursor-pointer"
                onClick={handleEditRepresentative}
              >
                <em className="icon ni ni-edit fs-20px" />
                <span className="ml-1">{t('Edit')}</span>
              </a>
              <a
                className="d-flex cursor-pointer"
                onClick={handleDeleteRepresentative}
              >
                <em className="icon ni ni-trash fs-20px" />
                <span className="ml-1">{t('Delete')}</span>
              </a>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      )}
    </tr>
  );
}

export default SocietyRepresentativeRow;
