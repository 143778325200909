import { format } from 'date-fns';

const BoardBadges = ({ children }) => <>{children}</>;

BoardBadges.Status = ({ t, style, text }) => (
  <span className={`badge badge-pill badge-dim ${style}`}>
    {`${t('Board')} ${text}` || ''}
  </span>
);

BoardBadges.Date = ({ date }) => (
  <span className="badge badge-pill badge-outline-primary">
    <em className="icon ni ni-calendar mr-1" />
    {format(new Date(date), 'dd/MM/yyyy | HH:mm a')}
  </span>
);

BoardBadges.OnlineAccess = ({ url, t }) => (
  <span className="badge badge-pill badge-outline-primary">
    <em className="icon ni ni-external mr-1" />
    <a href={url} target="_blank" rel="noreferrer">
      {t('VideoCall')}
    </a>
  </span>
);

BoardBadges.Delegation = ({ t, name }) => (
  <span className="badge badge-pill badge-outline-info">
    <em className="icon ni ni-users mr-1" />
    {`${t('DelegateVoteIn')} ${name}`}
  </span>
);

BoardBadges.Assistance = ({ t }) => (
  <span className="badge badge-pill badge-outline-danger">
    <em className="icon ni ni-user-cross mr-1" />
    {t('Absent')}
  </span>
);

BoardBadges.Close = ({ t }) => (
  <span className="badge badge-pill badge-outline-dark">
    <em className="icon ni ni-na mr-1" />
    {t('ClosedVote')}
  </span>
);

BoardBadges.displayName = 'BoardBadges';
BoardBadges.Status.displayName = 'BoardBadges.Status';
BoardBadges.Date.displayName = 'BoardBadges.Date';
BoardBadges.OnlineAccess.displayName = 'BoardBadges.OnlineAccess';
BoardBadges.Delegation.displayName = 'BoardBadges.Delegation';
BoardBadges.Assistance.displayName = 'BoardBadges.Assistance';
BoardBadges.Close.displayName = 'BoardBadges.Close';

export default BoardBadges;
