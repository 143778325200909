/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';

import successSwal from 'utils/successSwal';

import userTypes from 'constants/userTypes';
import { updateBoard } from 'modules/boards/redux/boardActions';
import { store } from 'redux/store';

import errorSwal from 'utils/errorSwal';
import SendVoteModalBody from './components/SendVoteModalBody';
import SendVoteModalFooter from './components/SendVoteModalFooter';
import SendVoteModalHeader from './components/SendVoteModalHeader';

import getPartner from './utils/getPartner';

import './SendVote.scss';
import useGetRepresented from './hooks/useGetRepresented';
import CustomLoading from '../../CustomLoading';
import useGetVoteStep from './hooks/useGetVoteStep';

function SendVoteModal({ board, order, partnerId }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));

  const {
    currentRepresented,
    representedWithInstructions,
    representedWithoutInstructions,
  } = useGetRepresented({ board, partnerId, order, actualSociety }).data;

  const initialVoteResult = useGetVoteStep({
    currentRepresented,
    representedWithInstructions,
    representedWithoutInstructions,
  });

  const { voteStep, selectedRepresented } = initialVoteResult.data;
  const { setVoteStep, setSelectedRepresented } = initialVoteResult.actions;

  const [voteValue, setVoteValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  function getVotedParticipantData(participant, currentPartner, currentVote) {
    const resultData = { ...participant };

    resultData.assists = true;
    resultData.isAbsent = false;
    resultData?.votes.push({
      order: order?.['_id'],
      vote: currentVote,
      voterData: {
        voterId: partnerId,
        voterType: userTypes.PARTNER,
        voterName: currentPartner?.name,
      },
    });

    return resultData;
  }

  async function updateParticipantAssistance(participant) {
    try {
      if (!participant?.assists) {
        await store.dispatch(
          updateBoard(
            board['_id'],
            {
              participantData: {
                ...participant,
                assists: true,
                isAbsent: false,
              },
            },
            false
          )
        );
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function updateRepresentedParticipantsAssistance() {
    try {
      const participantsToUpdate = board?.participants?.filter(
        (participant) =>
          participant?.representative &&
          participant?.representative === partnerId
      );
      for (const participant of participantsToUpdate) {
        await updateParticipantAssistance(participant);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const sendSingleVote = async (currentParticipant, currentVote) => {
    try {
      const currentPartner = getPartner(partnerId, actualSociety);

      const participantData = getVotedParticipantData(
        currentParticipant,
        currentPartner,
        currentVote
      );

      // Update votes and assistance of current participant
      await store.dispatch(
        updateBoard(board['_id'], { participantData }, false)
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleVote = async (representedPartners, manualVote) => {
    try {
      for (const represented of representedPartners) {
        const currentParticipant = board?.participants?.find(
          (participant) => participant.member === represented.value
        );
        if (currentParticipant) {
          const currentVote = manualVote ? voteValue : represented.instruction;
          await sendSingleVote(currentParticipant, currentVote);
        }
      }

      // Update assistance of other participants represented by the partner selected
      await updateRepresentedParticipantsAssistance();

      successSwal(t('VoteSent'));
    } catch (error) {
      console.error(error);
      errorSwal(t('ErrorSendingVote'));
    }
  };

  const handleCurrentRepresentedManualVote = async () => {
    try {
      const representedPartners = [currentRepresented];
      await handleVote(representedPartners, true);
    } catch (error) {
      console.error(error);
    } finally {
      handleCloseModal();
    }
  };

  const handleRepresentedManualVote = async () => {
    try {
      const representedPartners = representedWithoutInstructions;
      await handleVote(representedPartners, true);
      setSelectedRepresented([currentRepresented]);
      setVoteStep(2);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRepresentedAutomaticVote = async () => {
    try {
      const representedPartners = representedWithInstructions;
      await handleVote(representedPartners, false);
      setSelectedRepresented(representedWithoutInstructions);
      setVoteStep(1);
    } catch (error) {
      console.error(error);
    }
  };

  const handleExcecuteVote = async () => {
    try {
      setIsLoading(true);
      switch (voteStep) {
        case 0:
          await handleRepresentedAutomaticVote();
          break;
        case 1:
          await handleRepresentedManualVote();
          break;
        case 2:
          await handleCurrentRepresentedManualVote();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeVoteValue = (event) => {
    setVoteValue(event?.target?.value || '');
  };

  const showFooter = voteStep > 0;

  return (
    <>
      <SendVoteModalHeader
        orderSubject={order?.subject || ''}
        handleCloseModal={handleCloseModal}
      />

      <SendVoteModalBody
        order={order}
        board={board}
        voteValue={voteValue}
        voteStep={voteStep}
        selectedRepresented={selectedRepresented}
        handleChangeVoteValue={handleChangeVoteValue}
        handleExcecuteVote={handleExcecuteVote}
      />

      {showFooter && (
        <SendVoteModalFooter
          voteValue={voteValue}
          selectedRepresented={selectedRepresented}
          handleExcecuteVote={handleExcecuteVote}
          handleCloseModal={handleCloseModal}
        />
      )}

      {isLoading && <CustomLoading />}
    </>
  );
}

export default SendVoteModal;
