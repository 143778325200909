import { BoardOrder, Participant } from 'types';

export default function getVoteValueInOrder(
  participant: Participant,
  order: BoardOrder
) {
  const orderVote = participant?.votes?.find(
    (vote: any) => vote.order === order?._id
  );

  if (orderVote) {
    return orderVote.vote;
  }
  return '';
}
