/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { setMenu } from 'modules/_shared/redux/menuActions';
import {
  getEmployeesInfo,
  getSocietyEmployees,
  saveEmployees,
} from 'modules/beneficiaries/redux/employeeActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';

import employeesSource from 'constants/employeesSource';
import menuTypes from 'constants/menuTypes';

import {
  getEmployeeIncentive,
  getEmployeeSalaryAmount,
  getEmployeeTeam,
  getLastImportationDate,
  getTotalEmployeesWithIncentive,
} from 'utils/employees';
import { getActualSociety } from 'utils/filters';

import CardKPI from 'modules/_shared/components/Cards/CardKPI';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';

import { dateTimeFormat } from 'constants/formats';
import sizes from 'constants/sizes';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import AddEmployeesToPlan from 'modules/_shared/components/Modals/AddEmployeesToPlan';
import { setModal } from 'modules/_shared/redux/modalActions';
import tableColumns from './tableColumns';

function EmployeeList() {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const user = useSelector((state) => state.user);
  const plans = useSelector((state) => state.plans);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const employees = useSelector((state) => state.employees);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  const [activeEmployees, setActiveEmployees] = useState([]);
  const [beneficiariesCIFList, setBeneficiariesCIFList] = useState([]);

  const getPageDescription = () => (
    <>
      {`${actualSociety?.name} ${t('HaveEmployees', {
        count: employees?.length,
      })}`}
      {` / `}
      {t('LastImportationDate')}:&nbsp;
      <b>
        {`${
          employees.length
            ? format(
                new Date(getLastImportationDate(employees)),
                dateTimeFormat
              )
            : 'N/A'
        }`}
      </b>
    </>
  );

  const getAllBeneficiariesCIFs = (beneficiaries) => {
    const cifs = beneficiaries?.map((beneficiary) =>
      beneficiary.cif?.toUpperCase()
    );
    setBeneficiariesCIFList(cifs);
  };

  const handleLoadEmployees = async () => {
    const integrations = {};
    Object.entries(actualSociety?.integrations).forEach(([key, value]) => {
      if (value.enabled && value.validated && value.key.length) {
        integrations[key] = value.key;
      }
    });

    const hasValidKeys = Object.keys(integrations).length > 0;

    if (hasValidKeys) {
      let employeesList = [];

      if (integrations.factorial) {
        employeesList = await dispatch(
          getEmployeesInfo(employeesSource.FACTORIAL, employees)
        );
      }

      if (employeesList.newEmployees || employeesList.updatedEmployees) {
        return Swal.fire({
          icon: 'info',
          title: `<h4 class="nk-modal-title">Empleados disponibles</h4>`,
          html: `<p class="text-soft px-4" style="margin-top:24px;margin-bottom:30px">
            Hemos encontrado <b>${employeesList.newEmployees?.length}</b> empleados nuevos y <b>${employeesList.updatedEmployees?.length}</b> que ya existen. Importaremos los nuevos y actualizaremos los existentes.
          </p>`,
          confirmButtonText: 'Sí, confirmar',
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(
              saveEmployees(
                employeesList,
                employeesSource.FACTORIAL,
                actualSociety['_id']
              )
            );
          }
        });
      }
    } else {
      return Swal.fire({
        icon: 'warning',
        title: `<h4 class="nk-modal-title">${t('HasNotValidKeys')}<br></h4>`,
        html: `<p class="text-soft px-4">${t('HasNotValidKeysDescription')}</p>
        <p class="text-primary" style="margin-top:24px;margin-bottom:30px">
          ${t('IntegrationsTabAndPage')}
        </p>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    }
  };

  const handleFilterEmployees = () => {
    if (plans?.length) {
      dispatch(setModal(<AddEmployeesToPlan size={sizes.LG} />));
    } else {
      return Swal.fire({
        icon: 'warning',
        title: `<h4 class="nk-modal-title">${t('HasNoPlans')}</h4>`,
        html: `<p class="text-soft px-4">${t('HasNoPlansDescription')}</p>
        <p class="text-primary" style="margin-top:24px;margin-bottom:30px">
          ${t('PlansListPage')}
        </p>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    }
  };

  useEffect(() => {
    dispatch(getStockPlans(societyId));
    dispatch(getSocietyEmployees(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );

      getAllBeneficiariesCIFs(actualSociety?.beneficiaries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    const newData = employees?.map((employee) => ({
      employee,
      name: [employee.firstName, employee.lastName].join(' '),
      location: [
        employee.address?.city,
        employee.address?.country?.toUpperCase(),
      ].join(', '),
      status: {
        text: !employee.terminationDate ? t('Active') : t('Inactive'),
        color: !employee.terminationDate ? 'success' : 'danger',
      },
      isActive: !employee.terminationDate,
      currency: 'EUR',
      cif: employee.cif,
      cifType: employee.cifType,
      salary: getEmployeeSalaryAmount(employee),
      hasIncentive: {
        text: getEmployeeIncentive(employee, beneficiariesCIFList)
          ? t('Yes')
          : t('Not'),
        color: getEmployeeIncentive(employee, beneficiariesCIFList)
          ? 'success'
          : 'danger',
      },
      department: getEmployeeTeam(employee),
      societyId: actualSociety?.['_id'],
    }));

    if (JSON.parse(localStorage.getItem('showDetails'))) {
      console.log('newData', newData);
    }

    if (newData?.length > 0) {
      setTableData(
        transformData({
          data: newData,
          columns: tableColumns(i18n),
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
  }, [i18n.language]);

  useEffect(() => {
    if (employees?.length) {
      setActiveEmployees(
        employees.filter((employee) => !employee.terminationDate)
      );
    } else {
      setActiveEmployees([]);
    }
  }, [employees]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <PageListHeader
            title={actualSociety?.name}
            subTitle={t('Employees')}
            description={getPageDescription()}
            actions={
              isAdmin
                ? [
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleLoadEmployees}
                      disabled={isDemo}
                    >
                      <em className="icon ni ni-download-cloud" />
                      <span>{t('UpdateEmployees')}</span>
                    </button>,
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={isDemo}
                      onClick={handleFilterEmployees}
                    >
                      <em className="icon ni ni-filter" />
                      <span>{t('FilterEmployees')}</span>
                    </button>,
                  ]
                : []
            }
          />

          <div className="nk-block row mb-5">
            <div className="col-4">
              <CardKPI
                title="Empleados activos"
                value={activeEmployees?.length || 0}
                valueExtra={`de ${employees.length}`}
                comparisonValue="34.3%"
                comparisonText="VS año anterior"
                positiveDelta
              />
            </div>
            <div className="col-4">
              <CardKPI
                title="Ex-empleados"
                value={employees.length - activeEmployees.length || 0}
                valueExtra={`de ${employees.length}`}
                comparisonValue="10.9%"
                comparisonText="VS año anterior"
                positiveDelta={false}
              />
            </div>
            <div className="col-4">
              <CardKPI
                title="Con incentivos"
                value={getTotalEmployeesWithIncentive(
                  employees,
                  beneficiariesCIFList
                )}
                valueExtra={`de ${employees.length}`}
                comparisonValue="5.0%"
                comparisonText="VS año anterior"
                positiveDelta
              />
            </div>
          </div>

          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('Name')}
            searchByPlaceholder={t('name')}
            pagination
            className="nk-tb-list"
            // expandableRows
            actions
            showDense
            fileName={`${t('EmployeesList')} ${actualSociety?.name}`}
            filterByParam="isActive"
            filterText={{
              true: t('SeeInactives'),
              false: t('HideInactives'),
            }}
            filterIcon={{
              true: 'ni ni-eye',
              false: 'ni ni-eye-off',
            }}
          />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default EmployeeList;
