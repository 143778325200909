import { useTranslate } from 'hooks/useTranslate';

import OnlineVoting from 'assets/images/online-voting.svg';

const InstructionsTablePlaceholder = () => {
  const { t } = useTranslate();

  return (
    <tr className="placeholder-row">
      <td colSpan={3} className="text-center vertical-align-middle">
        <img src={OnlineVoting} alt="Online Voting" />
        <p className="mt-2 px-5">{t('VoteInstructionsPlaceholder')}</p>
      </td>
    </tr>
  );
};

export default InstructionsTablePlaceholder;
