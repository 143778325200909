/* eslint-disable no-console */
/* eslint-disable react/jsx-curly-newline */
import sizes from 'constants/sizes';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';
import { SocietyTypes } from 'types/Society';
import { getFullAddress } from 'utils/filters';
import EditModal from './modal';

const ArrowNext = () => (
  <div className="data-col data-col-end">
    <span className="data-more">
      <em className="icon ni ni-forward-ios" />
    </span>
  </div>
);

const BLANK_VALUE = '-';

function SocietyProfileData({ isOpen }) {
  const { t } = useTranslate();

  const user = useSelector((state) => state.user);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const society = useSelector((state) => state.society?.actualSociety);

  const [mainDirector, setMainDirector] = useState();

  const languageLabels = { es: t('Spanish'), en: t('English') };

  const openEditModal = () => {
    store.dispatch(setModal(<EditModal society={society} size={sizes.LG} />));
  };

  const handleOpenMainModal = () => {
    if (isAdmin && !isDemo) {
      openEditModal();
    }
  };

  const handleOpenAdditionalModal = () => {
    if (!isDemo) {
      openEditModal();
    }
  };

  useEffect(() => {
    if (society.mainDirector) {
      const director = society?.directors.find(
        (d) => d?.['_id'] === society.mainDirector
      );
      const directorLabel = director
        ? `${director.socialDenomination} (${director.email})`
        : '';
      setMainDirector(directorLabel);
    }
  }, [society]);

  useEffect(() => {
    if (isOpen && isAdmin) {
      openEditModal();
    }
  }, [isOpen, society, user, isAdmin]); // eslint-disable-line

  return (
    <section className="nk-block society-data-wrapper">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">{t('SocietyInformation')}</h5>
          <div className="nk-block-des">
            <p>{t('BasicInformationOfSociety')}</p>
          </div>
        </div>
      </div>

      <div
        className="card card-bordered cursor-pointer"
        onClick={handleOpenMainModal}
      >
        <div className="nk-data data-list">
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('SocialDenomination')}</span>
              <span className="data-value">{society?.name || BLANK_VALUE}</span>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">CIF</span>
              <span className="data-value">{society?.cif || BLANK_VALUE}</span>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('SocietyType')}</span>
              <span className="data-value">
                {t(`SocietyType${society?.societyType || SocietyTypes.SL}`)}
              </span>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Web</span>
              <span className="data-value">{society?.web || BLANK_VALUE}</span>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('Telephone')}</span>
              <span className="data-value">
                {society?.phone || BLANK_VALUE}
              </span>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('NotifyThePartners')}</span>
              <span className="data-value">
                {society?.sendNotifications ? t('Yes') : 'No'}
              </span>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">
                {t('EnablePartnerAccessToCaptable')}
              </span>
              <span className="data-value">
                {society?.hasAccessCaptable ? t('Yes') : 'No'}
              </span>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('RegisteredOffice')}</span>
              <span className="data-value">
                {getFullAddress(society?.legalAddress) || BLANK_VALUE}
              </span>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('SelectedAdministrator')}</span>
              <span className="data-value">{mainDirector || BLANK_VALUE}</span>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('CommunicationsLanguage')}</span>
              <span className="data-value">
                {languageLabels[society?.societyLanguage]}
              </span>
            </div>
            <ArrowNext />
          </div>
        </div>
      </div>

      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">{t('AdditionalInformation')}</h5>
          <div className="nk-block-des">
            <p>{t('UseThisSectionToUpdateSociety')}</p>
          </div>
        </div>
      </div>

      <div
        className="card card-bordered cursor-pointer"
        onClick={handleOpenAdditionalModal}
      >
        <div className="nk-data data-list">
          <div className="data-item" data-tab-target="#profile-edit">
            <div className="data-col">
              <span className="data-label">Logo</span>
              <div className="user-avatar md bg-primary">
                {society?.additional.logo ? (
                  <img src={society?.additional.logo} alt={society?.name} />
                ) : (
                  <span>{society?.name.slice(0, 2).toUpperCase()}</span>
                )}
              </div>
            </div>
            <ArrowNext />
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('BriefDescription')}</span>
              <span className="data-value">
                {society?.additional?.description || BLANK_VALUE}
              </span>
            </div>
            <ArrowNext />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SocietyProfileData;
