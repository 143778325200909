/* eslint-disable react/react-in-jsx-scope */
import { store } from 'redux/store';
import editBeneficiary from 'utils/editBeneficiary';
import duplicateBeneficiary from 'utils/duplicateBeneficiary';
import { updateSociety } from 'modules/society/redux/societyActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import EditBeneficiaryModal from 'modules/beneficiaries/modals/EditBeneficiary';

function beneficiaryOptions(i18n) {
  return [
    {
      icon: 'ni-eye',
      text: i18n.t('Open'),
      url: '',
    },
    {
      icon: 'ni-edit',
      text: i18n.t('EditPlan'),
      action: editBeneficiary,
    },
    {
      icon: 'ni-edit',
      text: i18n.t('EditBeneficiary'),
      action: (beneficiary) => {
        store.dispatch(
          setModal(
            <EditBeneficiaryModal
              beneficiary={beneficiary.data}
              societyId={beneficiary.societyId}
            />
          )
        );
      },
    },
    {
      icon: 'ni-copy',
      text: i18n.t('Duplicate'),
      action: duplicateBeneficiary,
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: ({ societyId }, beneficiaryId) => {
        store.dispatch(
          updateSociety(societyId, {
            beneficiaryDraft: { remove: beneficiaryId },
          })
        );
      },
    },
  ];
}
export default beneficiaryOptions;
