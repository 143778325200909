import { Participant } from 'types';

/**
 * Checks if the participant member (partner) is currently the representative of someone in participants array.
 * @param memberId - The ID of the member to check.
 * @param participants - An array of participants.
 * @returns A boolean indicating whether the member is a delegate.
 */
export const isDelegate = (memberId: string, participants: any[]): boolean =>
  !!participants?.find((p) => p.representative === memberId);

export const isRepresentingOtherParticipant = (
  memberId: string,
  participants: Participant[]
): boolean =>
  !!participants?.find(
    (p) => p.representative === memberId && p.member !== memberId
  );
