import { ButtonView, createDropdown, Plugin, SplitButtonView } from 'ckeditor5';
import getTags from '../utils/getTags';

function getOptions(editor: any) {
  const tagsValues = getTags({ groupTags: true });

  const resultOptions: any[] = [];

  tagsValues.forEach((tag) => {
    resultOptions.push({
      label: tag.label,
      disabled: tag.disabled,
      action: () => {
        const docFrag = editor.model.change((writer: any) => {
          const span = writer.createElement('span', { class: 'mention' });
          const docFrag = writer.createDocumentFragment();

          writer.append(span, docFrag);
          writer.insertText(tag.text, span);

          return docFrag;
        });

        editor.model.insertContent(docFrag);
      },
    });
  });

  return resultOptions;
}

export class TagsDropdownPlugin extends Plugin {
  init() {
    // @ts-ignore
    const { editor } = this;

    editor.ui.componentFactory.add('tags', (locale) => {
      const dropdownView = createDropdown(locale, SplitButtonView);

      dropdownView.buttonView.set({
        label: 'Insertar Variable',
        withText: true,
      });

      const options = getOptions(editor);

      // eslint-disable-next-line no-restricted-syntax
      for (const option of options) {
        const item = new ButtonView(locale);

        item.set({
          label: option.label,
          withText: true,
          isEnabled: !editor.isReadOnly && !option.disabled,
        });

        item.on('execute', option.action);

        dropdownView.panelView.children.add(item);
        dropdownView.panelView.position = 'sw';
      }

      editor.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {
        if (isReadOnly) {
          dropdownView.isEnabled = false;
        } else {
          dropdownView.isEnabled = true;
        }
      });

      return dropdownView;
    });
  }
}
