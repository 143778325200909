import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import downloadFile from 'utils/downloadFile';

import 'assets/styles/Icons.scss';
import 'assets/styles/Bootstrap.scss';

const defaultIcon = 'ni-more-h';

const CustomToggle = React.forwardRef(
  ({ children, onClick, customIcon }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn btn-sm btn-icon btn-trigger mt-n1 mr-n1"
    >
      {children}
      <span className={`icon ni ${customIcon}`} />
    </div>
  )
);
CustomToggle.displayName = 'CustomToggle';

function MenuDots({
  menuOptions,
  direction,
  id = null,
  params,
  downloadURL,
  disabled,
  customIcon = defaultIcon,
  className = '',
  dropdownClassName = '',
}) {
  const dispatch = useDispatch();

  const menu = menuOptions.map((menuOption) => {
    const idUrl = id ? `/${id}` : '';

    if (menuOption.action) {
      // eslint-disable-next-line no-nested-ternary
      const calssNameText = menuOption.disabled
        ? 'disabled'
        : menuOption.dontShowCurrent && menuOption.dontShowCurrent(params)
        ? 'disabled'
        : 'cursor-pointer';

      return (
        <a
          key={menuOption.text}
          onClick={() => menuOption.action(params, id)}
          className={calssNameText}
        >
          <em className={`icon ni ${menuOption.icon}`} />
          <span>{menuOption.text}</span>
        </a>
      );
    }
    if (menuOption.url)
      return (
        <Link
          to={`${menuOption.url}${idUrl}${
            menuOption.urlSuffix ? `/${menuOption.urlSuffix}` : ''
          }`}
          key={menuOption.text}
          className={menuOption.disabled ? 'disabled' : 'cursor-pointer'}
        >
          <em className={`icon ni ${menuOption.icon}`} />
          <span>{menuOption.text}</span>
        </Link>
      );

    if (menuOption.modal)
      return (
        <a
          key={menuOption.text}
          onClick={() => dispatch(setModal(menuOption.modal))}
          className={menuOption.disabled ? 'disabled' : 'cursor-pointer'}
        >
          <em className={`icon ni ${menuOption.icon}`} />
          <span>{menuOption.text}</span>
        </a>
      );

    if (
      menuOption.download &&
      (downloadURL || menuOption.downloadURL || menuOption.documentId)
    )
      return !menuOption.documentId ? (
        <a
          key={menuOption.text}
          href={downloadURL || menuOption.downloadURL}
          target="_blank"
          rel="noreferrer"
          className={menuOption.disabled ? 'disabled' : 'cursor-pointer'}
        >
          <em className={`icon ni ${menuOption.icon}`} />
          <span>{menuOption.text}</span>
        </a>
      ) : (
        <a
          key={menuOption.text}
          className={`${menuOption.disabled ? 'disabled' : 'cursor-pointer'}`}
          onClick={() => {
            downloadFile({ documentId: menuOption.documentId });
          }}
        >
          <em className="icon ni ni-file-download" />
          <span>{menuOption.text}</span>
        </a>
      );

    return null;
  });

  return (
    <Dropdown drop={direction} className={className}>
      <Dropdown.Toggle customIcon={customIcon} as={CustomToggle} />
      {!disabled && (
        <Dropdown.Menu
          size="sm"
          title=""
          className={`link-list-opt no-bdr ${dropdownClassName}`}
        >
          {menu}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}

export default MenuDots;
