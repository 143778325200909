/* eslint-disable import/order */
/* eslint-disable import/imports-first */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import actionTypes from 'constants/actionTypes';
import { ANADIR_SOCIO, SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import {
  cleanValue,
  formatDateToInput,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import { setModal } from 'modules/_shared/redux/modalActions';
import { tenderOfferSharesAction } from 'modules/_shared/redux/modalsActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import {
  addDocument,
  getDocument,
  getDocumentsByReference,
} from 'modules/documents/redux/documentActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import { store } from 'redux/store';

import trackEvent from 'utils/trackEvent';

import { updateTenderOfferShares } from 'modules/transactions/redux/tenderOffersSharesActions';
import Swal from 'sweetalert2';
import {
  tenderOfferSharesPriceTypes,
  tenderOfferSharesStatusTypes,
  tenderOfferSharesTypes,
} from 'utils/tenderOfferSharesTypes';
import '../Modals.scss';

const AddTenderOfferShares = ({
  data = {},
  action = actionTypes.ADD,
  type,
  size,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state.user);
  const documents = useSelector((state) => state.documents);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const partners = useSelector((state) =>
    state.society?.actualSociety?.partners?.filter(
      (partner) => partner?.sharesCount.actual > 0
    )
  );

  const [name, setName] = useState('');
  const [details, setDetails] = useState('');
  const [isActive] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [calculationDate, setCalculationDate] = useState('');
  const [totalPriceByShare, setTotalPriceByShare] = useState(0);
  const [totalPriceBySociety, setTotalPriceBySociety] = useState(0);
  const [isPriceFD, setIsPriceFD] = useState(false);
  const [seller, setSeller] = useState(null);

  /* NEW FIELDS */
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [selectedPartnerOption, setSelectedPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: t('SelectPartner'),
  });
  const animatedComponents = makeAnimated();
  /* END OF NEW FIELDS */

  const [maxAmount, setMaxAmount] = useState(0);
  const [maxShares, setMaxShares] = useState(0);
  const [maxPercentShares, setMaxPercentShares] = useState(0);
  const [retentionPercent, setRetentionPercent] = useState(0);
  const [hasRetentionPercent, setHasRetentionPercent] = useState(false);
  const [paymentConditions, setPaymentConditions] = useState('');
  const [isPriceByShare, setIsPriceByShare] = useState(null);
  const [isPriceBySociety, setIsPriceBySociety] = useState(null);
  const [sellAll, setSellAll] = useState(null);
  const [file, setFile] = useState();
  const [template, setTemplate] = useState({});

  const [templates, setTemplates] = useState([]);

  const [societyShares, setSocietyShares] = useState(
    actualSociety?.sharesCount?.actual
  );

  const getCurrentTemplate = async (id) => {
    try {
      const response = await store.dispatch(getDocument(id));
      setTemplate(response);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleChangeTemplate = (e) => {
    const currentTemplate = templates?.find(
      (template) => template['_id'] === e.target.value
    );
    setTemplate(currentTemplate);
  };

  const handleChangePriceType = (type) => {
    if (type === tenderOfferSharesPriceTypes.PRICE_BY_SHARE) {
      setIsPriceByShare(true);
      setIsPriceBySociety(false);
    } else {
      setIsPriceByShare(false);
      setIsPriceBySociety(true);
    }
  };

  const handleChangePriceValue = (value) => {
    if (isPriceByShare) {
      setTotalPriceByShare(value);
      setTotalPriceBySociety(cleanValue(value) * societyShares);
    } else {
      setTotalPriceBySociety(value);
      setTotalPriceByShare(cleanValue(value) / societyShares);
    }
  };

  const handleChangePriceDilution = (value) => {
    setIsPriceFD(value);

    if (value === true) {
      setSocietyShares(
        actualSociety?.sharesCount?.actual +
          actualSociety?.sharesCount?.future +
          actualSociety?.sharesCount?.drafts
      );
    } else {
      setSocietyShares(actualSociety?.sharesCount?.actual);
    }
  };

  const handleChangeHasRetentionPercent = (value) => {
    setHasRetentionPercent(value);
  };

  const handleChangeRetentionPercent = (value) => {
    setRetentionPercent(value);
  };

  const handlePartnerOptionChange = (selectedOption) => {
    const selectedPartner = partners?.find(
      (partner) => partner['_id'] === selectedOption?.value
    );
    setSelectedPartnerOption(selectedOption);
    setSeller(selectedPartner);
  };

  const isFormValid = () => {
    const generalData = !!(name && startDate && endDate);
    const sellerData = !!seller;
    const priceData = !!(
      (isPriceByShare || isPriceBySociety) &&
      totalPriceByShare &&
      totalPriceBySociety
    );
    const conditionsData = !!(
      sellAll ||
      (!sellAll && (maxAmount || maxShares || maxPercentShares))
    );

    return generalData && sellerData && priceData && conditionsData;
  };

  const createPartnerOptions = () => {
    const options = partners?.map((partner) => ({
      value: partner?.['_id'],
      label: `${partner?.cif} | ${partner?.name} | ${
        partner?.sharesCount?.actual
      } ${t('shares')}`,
    }));

    options.push({
      value: 'disabled',
      label: '─────────────────',
      isDisabled: true,
    });

    options.push({
      value: ANADIR_SOCIO,
      label: ANADIR_SOCIO,
    });

    setPartnerOptions(options);
  };

  const saveTenderOffer = async (event) => {
    event.preventDefault();

    let uploadedDocument = null;

    if (file) {
      const newDocument = {
        file,
        date: new Date(),
        size: file.size,
        name: `${operationTypes.ADD_TENDER_OFFER_FILENAME}-${file.name}`,
        author: user['_id'],
        society: actualSociety['_id'],
        fileType: fileTypes[file.type],
        category: documentTypes.DOCUMENTS,
        description: t('DocumentLinkedToOperation'),
      };
      uploadedDocument = await dispatch(addDocument(newDocument));
    }

    const newTenderOfferShares = {
      name,
      details,
      isActive,
      startDate,
      endDate,
      calculationDate,
      paymentConditions,
      isPriceFD,
      isPriceByShare,
      isPriceBySociety,
      sellAll,
      type,
      sellerId: seller?._id,
      sellerName: seller?.name,
      sellerNIF: seller?.cif,
      sellerEmail: seller?.email,
      price: cleanValue(totalPriceByShare),
      retentionPercent: cleanValue(retentionPercent),
      maxAmount: cleanValue(maxAmount),
      maxShares: cleanValue(maxShares),
      maxPercentShares: cleanValue(maxPercentShares),
      society: actualSociety?._id,
      status: tenderOfferSharesStatusTypes.DRAFT.value,
      document: uploadedDocument?._id || null,
      template: template?._id || null,
      participants: [],
      totalPriceByShare: cleanValue(totalPriceByShare), // TODO: check if this is needed
      totalPriceBySociety: cleanValue(totalPriceBySociety), // TODO: check if this is needed
    };

    let currentEvent;

    if (action === actionTypes.ADD) {
      await dispatch(tenderOfferSharesAction(newTenderOfferShares, false));
      currentEvent = eventTypes.ADD_TENDER_OFFER;
    } else {
      await dispatch(
        updateTenderOfferShares(data._id, {
          ...newTenderOfferShares,
        })
      );
      currentEvent = eventTypes.EDIT_TENDER_OFFER;
    }

    trackEvent(mixpanel, currentEvent, {
      userId: user?._id,
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?._id,
      societyName: actualSociety?.name,
      operation: currentEvent,
    });

    dispatch(setModal(null));
    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${
        type === tenderOfferSharesTypes.BUY
          ? t('PurchaseOfferCreatedSuccessfully')
          : t('SalesOfferCreatedSuccessfully')
      }<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  useEffect(() => {
    if (action === actionTypes.EDIT && data._id) {
      setName(data?.name);
      setDetails(data?.details);
      setStartDate(formatDateToInput(data?.startDate));
      setEndDate(formatDateToInput(data?.endDate));
      setCalculationDate(formatDateToInput(data?.calculationDate));
      setPaymentConditions(data?.paymentConditions);
      setIsPriceFD(data?.isPriceFD);
      setIsPriceByShare(data?.isPriceByShare);
      setIsPriceBySociety(data?.isPriceBySociety);
      setSellAll(data?.sellAll);
      const sellPartner = partners?.find(
        (partner) => partner['_id'] === data?.sellerId
      );
      if (sellPartner) {
        setSeller(sellPartner);
        setSelectedPartnerOption({
          value: sellPartner?.['_id'],
          label: `${sellPartner?.cif} | ${sellPartner?.name} | ${
            sellPartner?.sharesCount?.actual
          } ${t('shares')}`,
        });
      }
      setHasRetentionPercent(!!data?.retentionPercent);
      setRetentionPercent(data?.retentionPercent);
      setMaxAmount(data?.maxAmount);
      setMaxShares(data?.maxShares);
      setMaxPercentShares(data?.maxPercentShares);

      setTotalPriceByShare(data?.totalPriceByShare || data?.price); // TODO: check if this is needed
      setTotalPriceBySociety(
        data?.totalPriceBySociety || data?.price * societyShares
      ); // TODO: check if this is needed
      if (data?.template) {
        getCurrentTemplate(data?.template);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (societyShares && (isPriceByShare || isPriceBySociety)) {
      const currentPriceValue = isPriceByShare
        ? totalPriceByShare
        : totalPriceBySociety;

      handleChangePriceValue(currentPriceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyShares]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getStockPlans(actualSociety?.['_id']));
      dispatch(getHoldingClasses(actualSociety?.['_id']));
      dispatch(
        getDocumentsByReference({ commonFromUser: { userId: user?.['_id'] } })
      );
      createPartnerOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (documents) {
      setTemplates(documents?.filter((doc) => doc?.category === 'TEMPLATES'));
    }
  }, [documents]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('OfferConfiguration')}</h5>

        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <div className="nk-block">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                <form className="form-contact" onSubmit={saveTenderOffer}>
                  <div className="row g-4 mb-3">
                    {/* NAME */}
                    <>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label">{t('OfferName')}</label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              placeholder={t('IndicatesOfferName')}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </>

                    {/* SELLER */}
                    <>
                      <div className="nk-block col-12 mt-2">
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            {type === tenderOfferSharesTypes.SELL ? (
                              <h5 className="nk-block-title">
                                {t('Seller')}
                                <small className="d-block fs-14px text-soft mt-1">
                                  {t('SelectPortfolioPpartnerSell')}
                                </small>
                              </h5>
                            ) : (
                              <h5 className="nk-block-title">
                                {t('Buyer')}
                                <small className="d-block fs-14px text-soft mt-1">
                                  {t('SelectPortfolioPpartnerBuy')}
                                </small>
                              </h5>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="default-01">
                            {t('PartnerName')}
                          </label>
                          <div className="form-control-wrap">
                            <Select
                              closeMenuOnSelect
                              className="react-select"
                              value={selectedPartnerOption}
                              options={partnerOptions}
                              components={animatedComponents}
                              onChange={handlePartnerOptionChange}
                            />
                          </div>
                        </div>
                      </div>
                    </>

                    {/* PERIOD */}
                    <>
                      <div className="nk-block col-12 mt-2">
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            <h5 className="nk-block-title">
                              {t('OfferPeriod')}
                              <small className="d-block fs-14px text-soft mt-1">
                                {t('DuringTheseDatesOfferWillBeActive')}
                              </small>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="tender-start-date"
                          >
                            {t('StartDate')}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="date"
                              className="form-control date-picker"
                              id="tender-start-date"
                              name="tender-start-date"
                              placeholder="dd/mm/yyyy"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="tender-end-date"
                          >
                            {t('EndDate')}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="date"
                              className="form-control date-picker"
                              id="tender-end-date"
                              name="tender-end-date"
                              placeholder="dd/mm/yyyy"
                              min={startDate}
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="tender-end-date"
                          >
                            {t('EligibleCalculationDate')}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="date"
                              className="form-control date-picker"
                              id="tender-calculation-date"
                              name="tender-calculation-date"
                              placeholder="dd/mm/yyyy"
                              min={startDate}
                              max={endDate}
                              disabled={!startDate && !endDate}
                              value={calculationDate}
                              onChange={(e) =>
                                setCalculationDate(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </>

                    {/* PRICE */}
                    <>
                      <div className="nk-block col-12 mt-2">
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            <h5 className="nk-block-title">
                              {t('Price')}
                              <small className="d-block fs-14px text-soft mt-1">
                                {t(
                                  'EstablishesPricePerShareOrValuationCompany'
                                )}
                              </small>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 mb-1">
                        <div className="form-control-wrap">
                          <ul className="custom-control-group w-100 d-inline-flex">
                            <li className="col-6">
                              <div className="custom-control custom-radio ">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="price-type"
                                  id="price-share"
                                  value={
                                    tenderOfferSharesPriceTypes.PRICE_BY_SHARE
                                  }
                                  checked={isPriceByShare === true}
                                  onChange={(e) =>
                                    handleChangePriceType(e.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="price-share"
                                >
                                  {t('PriceForParticipation')}
                                </label>
                              </div>
                            </li>
                            <li className="col-6">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="price-"
                                  id="price-society"
                                  value={
                                    tenderOfferSharesPriceTypes.PRICE_BY_SOCIETY
                                  }
                                  checked={isPriceBySociety === true}
                                  onChange={(e) =>
                                    handleChangePriceType(e.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="price-society"
                                >
                                  {t('CompanyValuation')}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="cp1-project-deadline"
                          >
                            {t('PricePerShareOrUnit')}
                          </label>
                          <NumberFormat
                            name="price-type"
                            className="form-control"
                            value={totalPriceByShare}
                            disabled={!isPriceByShare}
                            onChange={(e) => {
                              handleChangePriceValue(e.target.value);
                            }}
                            {...numberFormatDecimals}
                            decimalScale={8}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="price-per-society"
                          >
                            {t('CompanyValuation')}
                          </label>
                          <NumberFormat
                            name="price-per-society"
                            className="form-control"
                            value={totalPriceBySociety}
                            disabled={!isPriceBySociety}
                            onChange={(e) => {
                              handleChangePriceValue(e.target.value);
                            }}
                            {...numberFormatDecimals}
                            decimalScale={8}
                          />
                        </div>
                      </div>
                      <div className="col-12 mt-0 pt-0 g-0">
                        <div className="custom-control-sm custom-switch float-right d-flex align-content-center">
                          <span
                            style={{
                              marginRight: '45px',
                              fontSize: '12px',
                              paddingTop: '0',
                            }}
                          >
                            {t('Non Dilluted')}
                          </span>
                          <input
                            type="checkbox"
                            id="price-switch"
                            className="custom-control-input float-right"
                            checked={isPriceFD === true}
                            onChange={(event) =>
                              handleChangePriceDilution(event.target.checked)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="price-switch"
                          >
                            {t('Fully Dilluted')}
                          </label>
                        </div>
                      </div>
                    </>

                    {/* OTHER CONDITIONS */}
                    <>
                      <div className="nk-block">
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            <h5 className="nk-block-title">
                              {t('AnotherConditions')}
                              <small className="d-block fs-14px text-soft mt-1">
                                {t('TenderOfferSellConditionsMessage')}
                              </small>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-6 mb-0">
                        <label className="form-label">
                          <span>{t('CanSellAllShares')}&nbsp;</span>
                          <em className="icon ni ni-info text-gray" title="" />
                        </label>
                      </div>
                      <div className="form-control-wrap col-6">
                        <ul className="custom-control-group w-100 d-inline-flex">
                          <li className="col-9">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="sell-all"
                                id="sell-all-yes"
                                value
                                checked={sellAll === true}
                                onChange={() => {
                                  setSellAll(true);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="sell-all-yes"
                              >
                                {t('YesCanSellEverything')}
                              </label>
                            </div>
                          </li>
                          <li className="col-3">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="sell-all"
                                id="sell-all-no"
                                value={false}
                                checked={sellAll === false}
                                onChange={() => {
                                  setSellAll(false);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="sell-all-no"
                              >
                                {t('Nope')}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </>

                    {sellAll === false && (
                      <div className="form-group d-flex flex-row flex-wrap align-items-end w-100 border-bottom mb-0 py-2">
                        <div className="col-4">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="sell-max-units"
                            >
                              {t('MaximumNumber')}
                            </label>
                            <NumberFormat
                              name="sell-max-units"
                              className="form-control"
                              value={maxShares}
                              onChange={(e) => setMaxShares(e.target.value)}
                              {...numberFormat}
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="sell-percent-units"
                            >
                              {t('MaximumPercent')}
                            </label>
                            <NumberFormat
                              name="sell-percent-units"
                              className="form-control"
                              value={maxPercentShares}
                              onChange={(e) =>
                                setMaxPercentShares(e.target.value)
                              }
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...numberFormatDecimals}
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="sell-max-partners"
                            >
                              {t('MaximumAmount')}
                            </label>

                            <NumberFormat
                              name="sell-max-partners"
                              className="form-control"
                              value={maxAmount}
                              onChange={(e) => setMaxAmount(e.target.value)}
                              {...numberFormat}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {/* APPLY RETENTION CHECKBOX */}
                    <div className="col-12 d-flex align-items-end">
                      <div className="form-group col-6 mt-2 mb-1">
                        <div className="custom-control custom-control-xs custom-checkbox">
                          <input
                            type="checkbox"
                            id="has-retention-percentage"
                            className="custom-control-input float-right"
                            checked={hasRetentionPercent === true}
                            onChange={(event) =>
                              handleChangeHasRetentionPercent(
                                event.target.checked
                              )
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="has-retention-percentage"
                          >
                            {t('ApplyRetention')}
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="cp1-project-deadline"
                          >
                            {t('RetentionPercentage')}
                          </label>
                          <NumberFormat
                            name="retention-percentage"
                            className="form-control"
                            value={retentionPercent}
                            disabled={!hasRetentionPercent}
                            onChange={(e) => {
                              handleChangeRetentionPercent(e.target.value);
                            }}
                            {...numberFormatDecimals}
                            decimalScale={2}
                          />
                        </div>
                      </div>
                    </div>

                    {/* PAYMENT CONDITIONS */}
                    <>
                      <div className="nk-block col-12 mt-2">
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            <h5 className="nk-block-title">
                              {t('PaymentConditions')}
                              <small
                                className="d-block fs-14px text-soft mt-1"
                                style={{ lineHeight: '18px' }}
                              >
                                {t('SellPaymentConditionsMessage')}
                              </small>
                            </h5>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <div className="form-editor-custom">
                                  <textarea
                                    className="form-control form-control-lg no-resize"
                                    placeholder={t('WriteHere')}
                                    value={paymentConditions}
                                    onChange={(e) =>
                                      setPaymentConditions(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>

                    {/* EXTRA */}
                    <>
                      <div className="nk-block col-12 mt-2">
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            <h5 className="nk-block-title">
                              {t('Comments')}
                              <small
                                className="d-block fs-14px text-soft mt-1"
                                style={{ lineHeight: '18px' }}
                              >
                                {t('TenderOfferCommentsMessage')}
                              </small>
                            </h5>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <div className="form-editor-custom">
                                  <textarea
                                    className="form-control form-control-lg no-resize"
                                    placeholder={t('WriteHere')}
                                    value={details}
                                    rows={2}
                                    onChange={(e) => setDetails(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="default-06"
                              >
                                {t('AddContractTemplate')}
                              </label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <select
                                    className="form-control form-control-lg"
                                    id="beneficiary-plan"
                                    name="beneficiary-plan"
                                    value={template?.['_id']}
                                    onChange={handleChangeTemplate}
                                  >
                                    <option value="" default>
                                      {t('Select')}
                                    </option>
                                    {templates?.length > 0 &&
                                      templates.map((template) => (
                                        <option
                                          value={template['_id']}
                                          key={template['_id']}
                                        >
                                          {template.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>

                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isFormValid()}
                      >
                        {t('Save')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AddTenderOfferShares;
