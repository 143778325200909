/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';

import './Buttons.scss';

type EditButtonProps = {
  action?: any;
  disabled?: boolean;
  className?: string;
  props?: any;
};

const EditButton: FC<EditButtonProps> = ({
  action,
  disabled,
  className,
  props,
}) => {
  const buttonClassName = `btn btn-icon btn-trigger btn-tooltip custom-edit-button text-soft ${className}`;

  const handleButtonClick = () => {
    if (action) {
      action();
    }
  };

  return (
    <button
      type="button"
      className={buttonClassName}
      onClick={handleButtonClick}
      disabled={disabled}
      {...props}
    >
      <em className="icon ni ni-edit mr-1" />
    </button>
  );
};

export default EditButton;
