/* eslint-disable no-restricted-syntax */
import DefaultLogo from 'assets/images/logo-placeholder.jpg';

function findTextAndReplace(blocks, entities) {
  const updatedBlock = blocks.map((block) => {
    if (block.text === '@SOCIEDAD_LOGO') {
      return {
        ...block,
        text: '',
        type: 'atomic',
      };
    }
    const { text, entityRanges } = block;
    const currentBlock = block;

    if (entityRanges.length) {
      let newText = text.toString();

      entityRanges.map((entity, index) => {
        const lookingFor = entities[entity.key].data.text;
        const replaceBy = entities[entity.key].data.value;

        if (newText.includes(lookingFor)) {
          const textPosition = newText.indexOf(lookingFor);
          const textLength = replaceBy.length;

          newText = newText.replace(lookingFor, replaceBy);
          currentBlock.entityRanges[index] = {
            offset: textPosition,
            length: textLength,
            key: entity.key,
          };
        }

        return entity;
      });

      return {
        ...currentBlock,
        text: newText,
      };
    }

    return currentBlock;
  });

  return updatedBlock;
}

const addDataToModel = ({ model, data }) => {
  const updatedEntities = {};

  let hasSocietySign = false;
  let hasBeneficiarySign = false;
  let hasConsolidationCalendar = false;
  let hasTenderOfferSummary = false;

  const standardEntity = (value, currentEntity) => ({
    type: value?.type,
    mutability: value?.mutability,
    data: {
      text: currentEntity?.text,
      // value: findEntity(data, currentEntity?.text) || currentEntity?.text,
      value: `{{${currentEntity?.value}}}`,
      url: '',
    },
  });

  const logoEntity = (currentEntity) => ({
    type: 'IMAGE',
    mutability: 'MUTABLE',
    data: {
      src: currentEntity?.url || DefaultLogo,
      width: 100,
      height: 100,
    },
  });

  Object.entries(model.entityMap).forEach(([key, value]) => {
    if (value?.type === 'IMAGE') {
      const currentEntity = value;
      const corsImageModified = new Image();

      corsImageModified.crossOrigin = 'Anonymous';
      corsImageModified.src = `${currentEntity.data.src}?not-from-cache-please`;
      currentEntity.data.src = corsImageModified.src;

      updatedEntities[key] = currentEntity;
      return;
    }
    const currentEntity = value?.data;

    switch (currentEntity?.text) {
      case '@SOCIEDAD_LOGO':
        updatedEntities[key] = logoEntity(currentEntity);
        break;

      case '@FIRMA_SOCIEDAD':
        hasSocietySign = true;
        updatedEntities[key] = standardEntity(value, currentEntity, data);
        break;

      case '@FIRMA_BENEFICIARIO':
        hasBeneficiarySign = true;
        updatedEntities[key] = standardEntity(value, currentEntity, data);
        break;

      case '@PLAN_CONSOLIDACION_PERSONALIZADA':
        hasConsolidationCalendar = true;
        updatedEntities[key] = standardEntity(value, currentEntity, data);
        break;

      case '@CUADRO_RESUMEN_TENDER_OFFER':
        hasTenderOfferSummary = true;
        updatedEntities[key] = standardEntity(value, currentEntity, data);
        break;

      default:
        updatedEntities[key] = standardEntity(value, currentEntity, data);
        break;
    }
  });

  const updatedBlocks = findTextAndReplace(model.blocks, updatedEntities);

  const draft = {
    blocks: updatedBlocks,
    entityMap: updatedEntities,
  };

  return {
    draft,
    hasSocietySign,
    hasBeneficiarySign,
    hasConsolidationCalendar,
    hasTenderOfferSummary,
  };
};

export default addDataToModel;
