/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { sendCommEmail } from 'modules/communications/redux/communicationActions';
import { getUsers } from 'modules/profile/redux/userActions';

import { getSocietyEmails } from 'utils/filters';

import { TYPE_FILES_ALLOWED } from 'constants/defaultConstants';
import presetRecipients from 'constants/presetRecipients';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import '../Modals.scss';

const SendEmail = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society.actualSociety);

  const [recipients, setRecipients] = useState({
    PARTNERS: [],
    BENEFICIARIES: [],
    INVESTORS: [],
    DIRECTORS: [],
  });

  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [files, setFiles] = useState([]);
  const [presetGroup, setPresetGroup] = useState('');
  const [secondaryEmails, setSecondaryEmails] = useState([]);

  // Stores the unique email recipients from fromArray into toArray
  const storeUniqueRecipients = (fromArray, toArray, uniqueEmails) => {
    fromArray.forEach((element) => {
      if (!uniqueEmails.includes(element?.email)) {
        toArray.push(element);
        uniqueEmails.push(element.email);
      }
    });
  };

  const handleSetRecipients = async (event) => {
    const selectedGroup = event.target.value;
    const uniqueRecipients = [];
    const uniqueEmails = [];

    setPresetGroup(selectedGroup);

    switch (selectedGroup) {
      // Custom case
      case presetRecipients.CUSTOM: {
        setTo('');
        break;
      }
      // Partners case
      case presetRecipients.PARTNERS: {
        storeUniqueRecipients(
          recipients.PARTNERS,
          uniqueRecipients,
          uniqueEmails
        );
        storeUniqueRecipients(secondaryEmails, uniqueRecipients, uniqueEmails);
        setTo(uniqueRecipients);
        break;
      }
      // Beneficiaries case
      case presetRecipients.BENEFICIARIES: {
        storeUniqueRecipients(
          recipients.BENEFICIARIES,
          uniqueRecipients,
          uniqueEmails
        );
        setTo(uniqueRecipients);
        break;
      }
      // Investors case
      case presetRecipients.INVESTORS: {
        storeUniqueRecipients(
          recipients.INVESTORS,
          uniqueRecipients,
          uniqueEmails
        );
        setTo(uniqueRecipients);
        break;
      }
      // Directors case
      case presetRecipients.DIRECTORS: {
        storeUniqueRecipients(
          recipients.DIRECTORS,
          uniqueRecipients,
          uniqueEmails
        );
        setTo(uniqueRecipients);
        break;
      }
      // All case
      case presetRecipients.ALL: {
        // Store unique partners
        storeUniqueRecipients(
          recipients.PARTNERS,
          uniqueRecipients,
          uniqueEmails
        );
        // Store unique beneficiaries
        storeUniqueRecipients(
          recipients.BENEFICIARIES,
          uniqueRecipients,
          uniqueEmails
        );
        // Store unique investors
        storeUniqueRecipients(
          recipients.INVESTORS,
          uniqueRecipients,
          uniqueEmails
        );
        // Store unique directors
        storeUniqueRecipients(
          recipients.DIRECTORS,
          uniqueRecipients,
          uniqueEmails
        );
        storeUniqueRecipients(secondaryEmails, uniqueRecipients, uniqueEmails);
        setTo(uniqueRecipients);
        break;
      }
      // Default case
      default: {
        setTo(recipients[selectedGroup]);
        break;
      }
    }
  };

  const getUniqueListBy = (arr, key) => [
    ...new Map(arr.map((item) => [item[key], item])).values(),
  ];

  const validateEmails = (emailsList) => {
    const allEmails = [
      ...recipients.PARTNERS,
      ...recipients.BENEFICIARIES,
      ...recipients.INVESTORS,
      ...recipients.DIRECTORS,
      ...secondaryEmails,
    ]
      .filter((elem) => elem?.email)
      .map(({ email }) => email.trim());

    let hasInvalidEmails = false;
    const invalidEmails = [];

    emailsList.forEach(({ email }) => {
      if (!allEmails.includes(email)) {
        hasInvalidEmails = true;
        invalidEmails.push(email);
      }
    });

    return { hasInvalidEmails, invalidEmails };
  };

  const getRecipientsData = (list) => {
    if (presetGroup === presetRecipients.CUSTOM) {
      const recipientsList = list
        .split(',')
        .map((recipient) => recipient.trim());

      const uniqueList = [...new Set(recipientsList)];

      return uniqueList.map((recipient) => ({
        email: recipient.trim(),
        name: '',
      }));
    }

    const uniqueList = getUniqueListBy(list, 'email');

    return uniqueList;
  };

  async function getOtherUsers(users) {
    if (!users.length) {
      setSecondaryEmails([]);
    } else {
      try {
        const otherUsersList = await dispatch(
          getUsers({
            usersList: users,
          })
        );
        const otherUsersEmails =
          otherUsersList?.map((user) => ({
            email: user.email,
            name: user.name,
          })) || [];

        setSecondaryEmails(otherUsersEmails);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }

  const getSocietySecondaryEmails = () => {
    const userIds = [];
    actualSociety?.partners?.forEach(
      (partner) =>
        partner?.otherUsers?.length > 0 &&
        partner?.otherUsers?.forEach((user) => userIds.push(user))
    );

    getOtherUsers(userIds);
  };

  const handleSendEmail = async () => {
    const filteredRecipients = getRecipientsData(to).map((recipient) => {
      const { email, name } = recipient;
      return {
        email: email.trim(),
        name,
      };
    });
    const { hasInvalidEmails } = validateEmails(filteredRecipients);

    if (!hasInvalidEmails) {
      const emailData = {
        to: filteredRecipients,
        subject,
        template: 'sttok-general',
        body: body.split('\n'),
        files,
        presetGroup,
        user: user['_id'],
        society: actualSociety['_id'],
        date: new Date(),
        status: 'SENT',
      };

      dispatch(sendCommEmail(emailData));
    } else {
      dispatch(
        addAlert(alertBodyTypes['ERROR_COMMUNICATIONS_EMAIL_DUPLICATED'])
      );
    }
  };

  useEffect(() => {
    if (actualSociety) {
      setRecipients({
        PARTNERS: getSocietyEmails(actualSociety, presetRecipients.PARTNERS),
        BENEFICIARIES: getSocietyEmails(
          actualSociety,
          presetRecipients.BENEFICIARIES
        ),
        INVESTORS: getSocietyEmails(actualSociety, presetRecipients.INVESTORS),
        DIRECTORS: getSocietyEmails(actualSociety, presetRecipients.DIRECTORS),
      });
    }
    getSocietySecondaryEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('NewCommunication')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body className="no-padding">
        <div className="nk-block nk-block-lg">
          <div className="card p-4">
            <div className="card-aside-wrap">
              <div className="card-content">
                <div className="card-inner p-0">
                  <h6 className="title mb-3">{t('Recipient(s)')}</h6>
                  <ul className="custom-control-group d-flex flex-row justify-content-between">
                    <li>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="btnRadio"
                          id="btnRadio1"
                          value={presetRecipients.PARTNERS}
                          disabled={!recipients.PARTNERS.length}
                          onChange={handleSetRecipients}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="btnRadio1"
                        >
                          {t('Partners')}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="btnRadio"
                          id="btnRadio2"
                          value={presetRecipients.BENEFICIARIES}
                          disabled={!recipients.BENEFICIARIES.length}
                          onChange={handleSetRecipients}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="btnRadio2"
                        >
                          {t('Beneficiaries')}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="btnRadio"
                          id="btnRadio3"
                          value={presetRecipients.INVESTORS}
                          disabled={!recipients.INVESTORS.length}
                          onChange={handleSetRecipients}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="btnRadio3"
                        >
                          {t('Investors')} {t('ConvertibleNote')}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="btnRadio"
                          id="btnRadio4"
                          value={presetRecipients.DIRECTORS}
                          disabled={!recipients.DIRECTORS.length}
                          onChange={handleSetRecipients}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="btnRadio4"
                        >
                          {t('AdminOrgan')}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro checked">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="btnRadio"
                          id="btnRadio5"
                          value={presetRecipients.ALL}
                          onChange={handleSetRecipients}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="btnRadio5"
                        >
                          {t('All')}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro checked">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="btnRadio"
                          id="btnRadio6"
                          value={presetRecipients.CUSTOM}
                          onChange={handleSetRecipients}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="btnRadio6"
                        >
                          {t('Personalized')}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card-content p-0">
            <div className="nk-reply-form-header">
              <div className="nk-reply-form-group">
                <div className="nk-reply-form-input-group">
                  <div className="nk-reply-form-input nk-reply-form-input-to">
                    <label className="label" style={{ width: '25px' }}>
                      {t('EmailTo')}:
                    </label>
                    {/* <tags
                      className="tagify input-mail tagify--noTags tagify--empty"
                      tabIndex="-1"
                    >
                      <span
                        contentEditable=""
                        data-placeholder="Destinatario individual"
                        aria-placeholder="Destinatario individual"
                        className="tagify__input"
                        role="textbox"
                        aria-autocomplete="both"
                        aria-multiline="false"
                      />
                    </tags> */}
                    <input
                      type="text"
                      className="input-mail tagify"
                      data-whitelist="team@sttok.com, help@sttok.com, contact@sttok.com"
                      value={
                        presetGroup === presetRecipients.CUSTOM
                          ? to
                          : to?.map((item) => item.email).join(', ')
                      }
                      onChange={(event) => setTo(event.target.value)}
                      readOnly={
                        presetGroup === '' ||
                        presetGroup !== presetRecipients.CUSTOM
                      }
                    />
                  </div>
                  {/* <div
                    className="nk-reply-form-input nk-reply-form-input-cc"
                    data-content="mail-cc"
                  >
                    <label className="label">Cc</label>

                    <input type="text" className="input-mail tagify" />
                    <a href="#" className="toggle-opt" data-target="mail-cc">
                      <em className="icon ni ni-cross" />
                    </a>
                  </div>
                  <div
                    className="nk-reply-form-input nk-reply-form-input-bcc"
                    data-content="mail-bcc"
                  >
                    <label className="label">Bcc</label>

                    <input type="text" className="input-mail tagify" />
                    <a href="#" className="toggle-opt" data-target="mail-bcc">
                      <em className="icon ni ni-cross" />
                    </a>
                  </div> */}
                </div>
                {/* <ul className="nk-reply-form-nav">
                  <li>
                    <a
                      tabIndex="-1"
                      className="toggle-opt is-disable"
                      data-target="mail-cc"
                      href="#"
                    >
                      CC
                    </a>
                  </li>
                  <li>
                    <a
                      tabIndex="-1"
                      className="toggle-opt is-disable"
                      data-target="mail-bcc"
                      href="#"
                    >
                      BCC
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="nk-reply-form-editor">
              <div className="nk-reply-form-field">
                <input
                  type="text"
                  className="form-control form-control-simple"
                  placeholder={t('Subject')}
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                />
              </div>
              <div className="nk-reply-form-field">
                <textarea
                  className="form-control form-control-simple no-resize ex-large"
                  placeholder={t('Text')}
                  value={body}
                  onChange={(event) => setBody(event.target.value)}
                />
              </div>
            </div>
            <div className="nk-reply-form-tools">
              <ul className="nk-reply-form-actions g-1">
                <li className="mr-2">
                  <button
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={() => handleSendEmail()}
                  >
                    {t('Send')}
                  </button>
                </li>

                {/* <li className="mr-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#modalBorrador"
                    disabled
                  >
                    {t('SaveDraft')}
                  </button>
                </li> */}
                {/* <li>
                  <div className="dropdown">
                    <a
                      className="btn btn-icon btn-sm btn-tooltip is-disable"
                      data-toggle="dropdown"
                      title=""
                      href="#"
                      data-original-title="Plantillas"
                    >
                      Plantillas:
                      <em className="icon ni ni-file-text" />
                    </a>
                    <div className="dropdown-menu">
                      <ul className="link-list-opt no-bdr link-list-template">
                        <li>
                          <a href="#">
                            <em className="icon ni ni-notes-alt" />
                            <span>Convocatoria Junta General Ordinaria</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <em className="icon ni ni-notes-alt" />
                            <span>
                              Convocatoria Junta General extrardinaria
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <em className="icon ni ni-notes-alt" />
                            <span>Convocatoria Consejo Administración</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <em className="icon ni ni-plus" />
                            <span>Añadir una Plantilla</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li> */}
                <li>
                  <span
                    className={`btn btn-icon btn-sm ${
                      files.length > 0 ? 'text-soft' : 'text-light'
                    }`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    href="#"
                    data-original-title="Adjuntar documento"
                  >
                    {files.length > 0
                      ? `${files.length} ${t('AttachedFiles')}`
                      : t('NoAttachments')}
                    <em className="icon ni ni-clip-v" />
                  </span>
                </li>
                <li>
                  <div className="form-group">
                    <label className="form-label d-none" htmlFor="comms-file" />
                    <input
                      type="file"
                      id="comms-file"
                      className="form-control-file cursor-pointer w-200px comms-file"
                      accept={TYPE_FILES_ALLOWED}
                      onChange={(event) => setFiles(event.target.files)}
                      title="Selecciona un archivo"
                      multiple
                    />
                  </div>
                </li>
              </ul>
              {/* <ul className="nk-reply-form-actions g-1">
                <li>
                  <div className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle btn-trigger btn btn-icon is-disable"
                      data-toggle="dropdown"
                    >
                      <em className="icon ni ni-more-v" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <a href="#">
                            <span>Another Option</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>More Option</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    href="#"
                    className="btn-trigger btn btn-icon mr-n2 is-disable"
                  >
                    <em className="icon ni ni-trash" />
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        {/* <button
          type="button"
          className="btn btn-primary mr-2"
          onClick={() => {}}
        >
          {t('Save')}
        </button>

        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleClose()}
        >
          {t('EndUp')}
        </button> */}
      </Modal.Body>
    </>
  );
};

export default SendEmail;
