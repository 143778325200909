/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { numberFormatDecimals } from 'constants/formats';

import BadgeSocietyHolderStatus from 'modules/_shared/components/Badges/BadgeSocietyHolderStatus';
import MenuDots from 'modules/_shared/components/MenuDots';

import { formatNumber } from 'utils/formats';
import { getTheme, themeColors } from 'utils/theme';

import 'assets/styles/Components.scss';
import 'assets/styles/Ribbons.scss';

import Badge from '../../../_shared/components/Badge';
import UsersArray from '../../../profile/components/UsersArray';
import menuOptions from '../SocietyRow/menuOptions';

import './SocietyCard.scss';

function SocietyCard({
  data,
  userId,
  isAdmin,
  isHolder,
  isExternal = false,
  alternativeMenuOptions,
  holdingStatus,
  holdingStatusColor,
  holdingPartnerCreationDate,
}) {
  const { t } = useTranslate();
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [selectedTheme, setSelectedTheme] = useState(getTheme());
  const [cardWaveBackground, setCardWaveBackground] = useState({});

  const showSharesTotal = () => {
    if (isAdmin) return false;
    if (isHolder && userId) return true;

    return false;
  };

  const showPartnersTotal = () => {
    if (isAdmin) return true;
    if (isHolder) return true;

    return false;
  };

  const showPartnersAvatar = () => {
    if (isAdmin) return true;
    if (isHolder) return true;

    return false;
  };

  const showSocietyCreationBadge = () => {
    if (isAdmin && !isHolder) return true;
    if (isHolder) return false;

    return false;
  };

  const showSocietyHolderStatusBadge = () => {
    if (isAdmin && !isHolder) return false;
    if (isHolder) return true;

    return false;
  };

  useEffect(() => {
    const colors = themeColors[selectedTheme];

    setCardWaveBackground({
      COLOR_100: colors.WAVE_BACKGROUND,
      COLOR_70: colors.WAVE_BACKGROUND_70,
      COLOR_50: colors.WAVE_BACKGROUND_50,
      COLOR_30: colors.WAVE_BACKGROUND_30,
    });
  }, [selectedTheme]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      const theme = getTheme();
      setSelectedTheme(theme);
    });
  }, []);

  return data ? (
    <div className="col-sm-6 col-xl-4">
      <div className="card card-bordered h-100 society-card wave-container">
        {isExternal && (
          <div className="ribbon ribbon-top-right">
            <span>{t('External')}</span>
          </div>
        )}

        <div className="header">
          <div className="project">
            <div className="card-inner">
              <div className="project-head">
                <Link
                  to={`/socios/${data?.society['_id']}`}
                  className="society-card-title"
                >
                  <div className="user-avatar md bg-primary">
                    {data?.society?.additional?.logo ? (
                      <img
                        src={data?.society?.additional?.logo}
                        alt={data?.society?.name}
                      />
                    ) : (
                      <span>
                        {data?.society?.name?.slice(0, 2).toUpperCase()}
                      </span>
                    )}
                  </div>
                  <div className="society-info">
                    <h6 className="title">{data?.society?.name}</h6>
                    <h5 className="sub-text">
                      {isAdmin ? t('Administrator') : t('Shareholder')}
                    </h5>
                  </div>
                </Link>
                <div>
                  {!isDemo && (
                    <MenuDots
                      menuOptions={alternativeMenuOptions || menuOptions}
                      id={data?.society['_id']}
                      direction="left"
                      params={{
                        societyName: data?.society?.name,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <svg
                className="waves"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shapeRendering="auto"
              >
                <defs>
                  <path
                    id="gentle-wave"
                    d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                  />
                </defs>
                <g className="parallax">
                  <use
                    xlinkHref="#gentle-wave"
                    x="48"
                    y="0"
                    fill={cardWaveBackground.COLOR_70}
                  />
                  <use
                    xlinkHref="#gentle-wave"
                    x="48"
                    y="3"
                    fill={cardWaveBackground.COLOR_50}
                  />
                  <use
                    xlinkHref="#gentle-wave"
                    x="48"
                    y="5"
                    fill={cardWaveBackground.COLOR_30}
                  />
                  <use
                    xlinkHref="#gentle-wave"
                    x="48"
                    y="7"
                    fill={cardWaveBackground.COLOR_100}
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div className="card-inner">
          <div className="project-details">
            <p>{data?.society?.additional?.description}</p>
          </div>

          {showSharesTotal() && (
            <div className="project-progress">
              <div className="project-progress-details">
                <div className="project-progress-task">
                  <em className="icon ni ni-layers" />
                  <span>
                    {formatNumber(data?.societyShares?.actual) || '0'}&nbsp;
                    {t('shares')}
                  </span>
                </div>
                <div className="project-progress-percent">
                  <NumberFormat
                    value={data?.userPercent || 0}
                    displayType="text"
                    suffix="%"
                    {...numberFormatDecimals}
                  />
                </div>
              </div>
              <div className="progress progress-pill progress-md bg-light">
                <div
                  className="progress-bar"
                  data-progress={data?.userPercent}
                  style={{ width: `${data?.userPercent}%` }}
                />
              </div>
            </div>
          )}

          {showPartnersTotal() ? (
            <div className="project-progress-percent mb-2">
              <em className="icon ni ni-users mr-1" />
              {t('HasSocietyPartnersLengthP', {
                partners: data?.partnersCount || 0,
              })}
            </div>
          ) : (
            <div className="mb-1" style={{ height: '22px' }} />
          )}

          <div className="project-meta">
            {showPartnersAvatar() ? (
              <UsersArray users={data?.partners} />
            ) : (
              <UsersArray users={data?.userPartner} />
            )}

            {showSocietyCreationBadge() && data?.society?.creationDate && (
              <Badge creationDate={new Date(data?.society?.creationDate)} />
            )}

            {showSocietyHolderStatusBadge() && (
              <div>
                <BadgeSocietyHolderStatus
                  status={holdingStatus}
                  color={holdingStatusColor}
                />
                &nbsp;
                {holdingPartnerCreationDate && (
                  <Badge creationDate={new Date(holdingPartnerCreationDate)} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default SocietyCard;
