import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import types from 'redux/actions/actionTypes';

import { decryptResponse } from 'utils/token';
import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export function createTenderOffer(tenderOffer) {
  return {
    type: types.CREATE_TENDER_OFFER,
    tenderOffer,
  };
}

export const getTenderOffers = (societyId) => async (dispatch) => {
  try {
    const query = societyId ? { params: { societyId } } : { params: {} };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/tenderOffers`,
      query
    );

    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.GET_TENDER_OFFERS,
      data: decodedData,
    });
  } catch (error) {
    return dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const updateTenderOffer =
  (tenderOfferId, updateTenderOfferData) => async (dispatch) => {
    const body = {
      _id: tenderOfferId,
      ...updateTenderOfferData,
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/tenderOffers`,
        body
      );

      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.UPDATE_TENDER_OFFER,
        tenderOffer: decodedData,
      });
    } catch (error) {
      dispatch(addAlert(alertBodyTypes[500]));
    }
  };

export const deleteTenderOffer = (tenderOffer) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/tenderOffers/${tenderOffer}`
    );

    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.DELETE_TENDER_OFFER,
      tenderOffer: decodedData,
    });
    dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
    return decodedData['_id'];
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};
