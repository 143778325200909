export default [
  { name: 'dashboard', label: 'Dashboard', enabled: false, checked: false },
  { name: 'partners', label: 'Shareholders', enabled: false, checked: false },
  { name: 'portfolio', label: 'Portfolio', enabled: false, checked: false },
  { name: 'drafts', label: 'Drafts', enabled: false, checked: false },
  { name: 'plans', label: 'Plans', enabled: true, checked: false },
  {
    name: 'transactions',
    label: 'Transactions',
    enabled: false,
    checked: false,
  },
  {
    name: 'communications',
    label: 'Communications',
    enabled: false,
    checked: false,
  },
  { name: 'boards', label: 'Boards', enabled: false, checked: false },
  { name: 'documents', label: 'Documents', enabled: false, checked: false },
  {
    name: 'societyConfiguration',
    label: 'Society settings',
    enabled: false,
    checked: false,
  },
];
