import { FC } from 'react';

import SendVoteModalStep from './SendVoteModalStep';

type Props = {
  voteStep: number;
};

const SendVoteModalBodySteps: FC<Props> = ({ voteStep }) => (
  <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
    <div className="steps clearfix text-center">
      <ul role="tablist">
        <SendVoteModalStep
          currentStep={0}
          voteStep={voteStep}
          title="RepresentedWithInstructions"
        />

        <SendVoteModalStep
          currentStep={1}
          voteStep={voteStep}
          title="RepresentedWithoutInstructions"
        />

        <SendVoteModalStep
          currentStep={2}
          voteStep={voteStep}
          title="YourVote"
        />
      </ul>
    </div>
  </div>
);

export default SendVoteModalBodySteps;
