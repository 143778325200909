/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import userTypes from 'constants/userTypes';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import EditModal from 'modules/profile/components/UserMyData/EditModal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

function InvestorPersonal({ society, investor }) {
  const { t } = useTranslate();

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [address, setAddress] = useState();

  useEffect(() => {
    const { line1, zip, city, country } = investor.address || {};

    setAddress(
      `${line1 || ''} ${zip || ''} ${city || ''} ${
        country ? `(${country})` : ''
      }`
    );
  }, [investor.address]);

  return (
    <div className="card-inner">
      <div
        className="nk-block-head"
        onClick={() => {
          if (!isDemo) {
            store.dispatch(
              setModal(
                <EditModal
                  user={investor}
                  societyId={society['_id']}
                  userType={userTypes.INVESTOR}
                />
              )
            );
          }
        }}
      >
        <div className="nk-block-head-content">
          <div className="nk-block float-right">
            <button
              type="button"
              className="btn btn-icon btn-trigger btn-tooltip"
              onClick={() => {
                store.dispatch(
                  setModal(
                    <EditModal
                      user={investor}
                      societyId={society?.['_id']}
                      userType={userTypes.INVESTOR}
                    />
                  )
                );
              }}
              disabled={isDemo}
            >
              <em className="icon ni ni-edit mr-1" />
            </button>
          </div>
        </div>
        <h5 className="title">{t('PersonalInformation')}</h5>
        <p>{t('BasicPersonalInformation')}</p>
        <div className="nk-block-head nk-block-head-line" />

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('FullName')}</span>
          <span className="profile-ud-value text-left">
            {investor.name || ''}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Nif')}</span>
          <span className="profile-ud-value text-left">
            {investor?.cif || '-'}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Telephone')}</span>
          <span className="profile-ud-value text-left">
            {investor?.phone || '-'}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Address')}</span>
          <span className="profile-ud-value text-left">{address}</span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Email')}</span>
          <span className="profile-ud-value text-left">
            {investor?.email || '-'}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">
            {t('ContributionDate')}
          </span>
          <span className="profile-ud-value text-left">
            {format(new Date(investor?.contributionDate), 'dd/MM/yyyy')}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Nationality')}</span>
          <span className="profile-ud-value text-left">
            {investor?.nationality || '-'}
          </span>
        </div>
      </div>

      <div className="nk-block" />
      <div className="nk-divider divider md" />
    </div>
  );
}

export default InvestorPersonal;
