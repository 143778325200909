/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { pdf } from '@react-pdf/renderer';
import { useTranslate } from 'hooks/useTranslate';
import html2pdf from 'html3pdf';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { setPreview } from 'modules/_shared/redux/previewActions';
import { updateBoard } from 'modules/boards/redux/boardActions';
import {
  addDocument,
  getDocumentsByReference,
  updateDocument,
} from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';

import { getConsolidationPeriods } from 'utils/consolidationPeriods';
import { getActualSociety } from 'utils/filters';

import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import DocumentsGenerator from 'modules/documents/components/Creators/DocumentsGenerator';

import { updateDocumentWithValues } from '../../EditorWYSIWYG/helpers';
import tags from '../../EditorWYSIWYG/tags';

import '../Modals.scss';

const GenerateDocument = ({ outputPreview = false, currentBoard }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [templates, setTemplates] = useState([]);
  const [societyTemplates, setSocietyTemplates] = useState([]);
  const [toShowTemplates, setToShowTemplates] = useState([]);
  const [entitiesNeeded, setEntitiesNeeded] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const { plan, partner, beneficiary, board } = useSelector((state) => ({
    plan: state?.plans[0] || {},
    partner: state?.society?.actualSociety?.partners[0] || {},
    beneficiary: state?.society?.actualSociety?.beneficiaries[0] || {},
    board: state?.boards?.[0] || {},
  }));

  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([]);

  const user = useSelector((state) => state.user);
  const plans = useSelector((state) => state.plans);
  const society = useSelector((state) => state?.society?.actualSociety);
  const documents = useSelector((state) => state.documents);
  const partners = useSelector((state) =>
    state.society?.actualSociety?.partners.filter(
      (partner) => partner?.sharesCount?.actual >= 0
    )
  );

  async function handleGenerateDocument(event) {
    event.preventDefault();

    try {
      const documentUpdated = updateDocumentWithValues(
        selectedTemplate.editorModel,
        tags({
          plan,
          partner,
          society,
          beneficiary,
          board,
        })
      );
      const { hasConsolidationCalendar } = documentUpdated;
      const documentModel = documentUpdated.html;

      const newTemplate = {
        name,
        date: new Date(),
        lastAccess: new Date(),
        category: 'TEMPLATES',
        subcategory: 'DOCUMENTS',
        author: user['_id'],
        society: society['_id'],
        isGenerated: false,
        isFavorite: false,
        editorModel: documentModel,
        fileType: fileTypes.TEMPLATE,
        description,
      };
      const bdDocument = await store.dispatch(addDocument(newTemplate));
      store.dispatch(
        getDocumentsByReference({ societyId: newTemplate.society })
      );

      const document = (
        <DocumentsGenerator
          invitationModel={documentModel}
          hasConsolidationCalendar={hasConsolidationCalendar}
          consolidationCalendar={
            hasConsolidationCalendar
              ? getConsolidationPeriods(selectedBeneficiary, selectedPlan)
              : {}
          }
        />
      );

      if (outputPreview) {
        await store.dispatch(
          setPreview({
            name,
            bdDocument,
            document,
            documentModel,
            template: selectedTemplate,
          })
        );
        window.location.href = `#/documentos/documentos/${society?._id}?preview=true`;
        return;
      }
      const documentBlob = await pdf(document).toBlob();
      const documentFile = new File([documentBlob], `${name}.pdf`, {
        type: 'application/pdf',
      });

      const fileName = `${name}.pdf`;
      const newDocument = {
        name: fileName,
        file: documentFile,
        size: documentFile.size,
        date: new Date(),
        lastAccess: new Date(),
        category: documentTypes.DOCUMENTS,
        author: user['_id'],
        society: society['_id'],
        isGenerated: true,
        description: `Generado por la plantilla ${selectedTemplate?.name}`,
      };

      await store.dispatch(addDocument(newDocument));

      dispatch(addAlert(alertBodyTypes.DOCUMENT_GENERATED));
      getActualSociety(user, society['_id']);
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_GENERATING_DOCUMENT));
    } finally {
      dispatch(setModal(null));
    }
  }

  async function handleChangeBoardTemplate(event) {
    event.preventDefault();
    try {
      if (currentBoard.announcementTemplate) {
        dispatch(
          updateDocument(
            {
              _id: currentBoard.announcementTemplate,
              category: documentTypes.DELETED,
            },
            false
          )
        );
      }
      if (currentBoard.announcementDocument) {
        dispatch(
          updateDocument(
            {
              _id: currentBoard.announcementDocument,
              category: documentTypes.DELETED,
            },
            false
          )
        );
      }
      const newTemplate = {
        name: `${t('AnnouncementTemplate')}_${currentBoard?.name}`,
        category: 'TEMPLATES',
        date: new Date(),
        lastAccess: new Date(),
        author: user['_id'],
        society: society['_id'],
        isGenerated: false,
        isFavorite: false,
        editorModel: selectedTemplate.editorModel,
        fileType: fileTypes.TEMPLATE,
      };
      const announcementTemplate = await store.dispatch(
        addDocument(newTemplate)
      );
      // generate new announcement document
      const announcementDoc = updateDocumentWithValues(
        selectedTemplate.editorModel,
        tags({
          society,
          board: currentBoard,
        })
      );
      const options = {
        filename: name,
        margin: [10, 10, 10, 10], // top, right, bottom, left
        pagebreak: { mode: 'avoid-all' },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
        },
      };
      const fileBlob = await html2pdf()
        .from(announcementDoc?.html)
        .set(options)
        .output('blob');

      const announcementFile = new File([fileBlob], name, {
        type: 'application/pdf',
      });
      const newAnnouncementDocument = {
        name,
        file: announcementFile,
        fileType: fileTypes.PDF,
        size: announcementFile.size,
        date: new Date(),
        lastAccess: new Date(),
        category: documentTypes.DOCUMENTS,
        author: user['_id'],
        society: society['_id'],
        isGenerated: true,
        description,
      };
      const announcementDocument = await store.dispatch(
        addDocument(newAnnouncementDocument)
      );
      dispatch(
        updateBoard(currentBoard['_id'], {
          announcementTemplate,
          announcementDocument,
        })
      );
      dispatch(getDocumentsByReference({ societyId: society['_id'] }));
      dispatch(addAlert(alertBodyTypes.DOCUMENT_GENERATED));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_GENERATING_DOCUMENT));
    } finally {
      dispatch(setModal(null));
    }
  }

  const handleChangeTemplate = (e) => {
    const currentTemplate = templates.find(
      (template) => template['_id'] === e.target.value
    );
    setSelectedTemplate(currentTemplate);
  };
  const handleChangeType = (e) => {
    if (e.target.value === '0') {
      setToShowTemplates(templates);
    } else {
      setToShowTemplates(societyTemplates);
    }
  };

  const handleSelectPlan = (e) => {
    const currentPlan = plans.find((plan) => plan['_id'] === e.target.value);
    setSelectedPlan(currentPlan);
  };

  const handleSelectBeneficiary = (e) => {
    const currentBeneficiary = society?.beneficiaries.find(
      (beneficiary) => beneficiary['_id'] === e.target.value
    );
    setSelectedBeneficiary(currentBeneficiary);
  };

  const handleSelectPartner = (e) => {
    const currentPartner = society?.partners.find(
      (partner) => partner['_id'] === e.target.value
    );
    setSelectedPartner(currentPartner);
  };

  const getEntitiesNeeded = (model) => {
    if (model?.entityMap) {
      const currentTexts = Object.entries(model?.entityMap)?.map(
        (entity) => entity[1]?.data?.text
      );

      const uniqueEntities = [];

      currentTexts?.forEach((text) => {
        if (uniqueEntities.includes(text)) {
          return;
        }
        uniqueEntities.push(text);
      });

      const currentEntities = [];

      uniqueEntities?.forEach((entity) => {
        const entityName = entity?.match(/@(.+?)_/)[1];
        if (currentEntities.includes(entityName)) {
          return;
        }
        currentEntities.push(entityName);
      });

      setEntitiesNeeded(currentEntities);
    }
  };

  React.useEffect(() => {
    if (selectedTemplate) {
      getEntitiesNeeded(selectedTemplate?.editorModel);
    }
  }, [selectedTemplate]);

  React.useEffect(() => {
    if (selectedPlan) {
      setFilteredBeneficiaries(
        society?.beneficiaries.filter(
          (beneficiary) => beneficiary?.plan === selectedPlan?._id
        ) || []
      );
    }
  }, [selectedPlan, society?.beneficiaries]);

  React.useEffect(() => {
    const allDocs = documents?.filter(
      (doc) => doc?.category === 'TEMPLATES' && doc?.subcategory !== 'DOCUMENTS'
    );
    if (documents) {
      setTemplates(allDocs);
      setSocietyTemplates(
        documents?.filter(
          (doc) =>
            doc?.category === 'TEMPLATES' &&
            doc?.subcategory !== 'DOCUMENTS' &&
            doc.society === society['_id']
        )
      );
      setToShowTemplates(allDocs);
    }
  }, [documents]);

  return (
    <>
      <form
        className="form-validate is-alter"
        onSubmit={
          currentBoard ? handleChangeBoardTemplate : handleGenerateDocument
        }
      >
        <Modal.Header
          style={{ borderBottom: 'none', padding: '36px 40px 0 40px' }}
        >
          <h5 className="modal-title">{t('UseATemplate')}</h5>
          <a
            className="close cursor-pointer"
            onClick={() => dispatch(setModal(null))}
          >
            <em className="icon ni ni-cross" />
          </a>
        </Modal.Header>
        <Modal.Body style={{ margin: '0px 16px 0px 16px' }}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-plan">
                  {t('TemplateType')}
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      id="beneficiary-plan"
                      name="beneficiary-plan"
                      value={selectedTemplate?.['_id']}
                      onChange={handleChangeType}
                    >
                      <option value={0} key={t('AllTemplates')}>
                        {t('AllTemplates')}
                      </option>
                      <option value={1} key={t('CompanyTemplates')}>
                        {t('CompanyTemplates')}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-plan">
                  {t('Template')}
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      id="beneficiary-plan"
                      name="beneficiary-plan"
                      value={selectedTemplate?.['_id']}
                      onChange={handleChangeTemplate}
                    >
                      <option value="" default>
                        {t('Select')}
                      </option>
                      {toShowTemplates?.length > 0 &&
                        toShowTemplates.map((template) => (
                          <option value={template['_id']} key={template['_id']}>
                            {template.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="partner-file">
                  {t('NewDocumentName')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      type="text"
                      id="doc-name"
                      name="doc-name"
                      className="form-control form-control-lg"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="partner-file">
                  {t('Description')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      type="text"
                      id="doc-description"
                      name="doc-description"
                      className="form-control form-control-lg"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {entitiesNeeded?.length > 0 &&
            !(
              entitiesNeeded?.length === 1 && entitiesNeeded[0] === 'SOCIEDAD'
            ) && (
              <>
                <h5 className="pt-4 pb-3">
                  Datos necesarios para el documento
                </h5>

                {entitiesNeeded.includes('PLAN') && (
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="beneficiary-plan"
                        >
                          {t('Plan')}
                        </label>
                        <div className="form-control-wrap ">
                          <div className="form-control-select">
                            <select
                              className="form-control form-control-lg"
                              id="beneficiary-plan"
                              name="beneficiary-plan"
                              value={selectedPlan?.['_id']}
                              onChange={handleSelectPlan}
                            >
                              <option value="" default>
                                {t('Select')}
                              </option>
                              {plans?.length > 0 &&
                                plans.map((plan) => (
                                  <option value={plan['_id']} key={plan['_id']}>
                                    {plan.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {entitiesNeeded.includes('BENEFICIARIO') && (
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="beneficiary-plan"
                        >
                          {t('Beneficiary')}
                        </label>
                        <div className="form-control-wrap ">
                          <div className="form-control-select">
                            <select
                              className="form-control form-control-lg"
                              id="beneficiary-plan"
                              name="beneficiary-plan"
                              value={selectedBeneficiary?.['_id']}
                              onChange={handleSelectBeneficiary}
                              disabled={!selectedPlan}
                            >
                              <option value="" default>
                                {t('Select')}
                              </option>
                              {filteredBeneficiaries?.length > 0 &&
                                filteredBeneficiaries.map((beneficiary) => (
                                  <option
                                    value={beneficiary['_id']}
                                    key={beneficiary['_id']}
                                  >
                                    {beneficiary.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {entitiesNeeded.includes('SOCIO') && (
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-group">
                        <label className="form-label" htmlFor="partner">
                          {t('Partner')}
                        </label>
                        <div className="form-control-wrap ">
                          <div className="form-control-select">
                            <select
                              className="form-control form-control-lg"
                              id="partner"
                              name="partner"
                              value={selectedPartner?.['_id']}
                              onChange={handleSelectPartner}
                            >
                              <option value="" default>
                                {t('Select')}
                              </option>
                              {partners?.length > 0 &&
                                partners.map((partner) => (
                                  <option
                                    value={partner['_id']}
                                    key={partner['_id']}
                                  >
                                    {partner.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
        </Modal.Body>

        <Modal.Footer
          style={{ borderTop: 'none', padding: '0px 40px 36px 40px' }}
        >
          <button
            type="button"
            className="btn btn-lg btn-outline-primary  border-0"
            onClick={() => dispatch(setModal(null))}
          >
            {t('Cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name}
          >
            {t('GenerateDocument')}
          </button>
        </Modal.Footer>
      </form>
    </>
  );
};

export default GenerateDocument;
