/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';

import { currencyFormatDecimals } from 'constants/formats';
import { hasOutdatedSocietyValue } from 'utils/filters';
import MenuDots from 'modules/_shared/components/MenuDots';
import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import AddSocietyValue from 'modules/_shared/components/Modals/AddSocietyValue';
import { updateSocietyConfiguration } from 'modules/society/redux/societyActions';

import menuOptions from './menuOptions';

const showDate = (date) => {
  if (typeof date === 'string') return format(new Date(date), 'dd/MM/yyyy');
  return format(date, 'dd/MM/yyyy');
};

const SocietyValues = ({ society, isAdmin, setPage }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state.user);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const hasIncentiveChart = useSelector(
    (state) => state.society?.actualSociety?.configuration?.hasIncentiveChart
  );

  const [isLoading, setIsLoading] = useState(false);
  const [updatedValues, setUpdatedValues] = useState();
  const [showOutdatedWarning, setShowOutdatedWarning] = useState(false);
  const [currentIncentiveChart, setCurrentIncentiveChart] = useState(true);

  const handleSaveConfiguration = async () => {
    setIsLoading(true);

    const updatedConfiguration = {
      hasIncentiveChart: !currentIncentiveChart,
    };

    await dispatch(
      updateSocietyConfiguration(society._id, updatedConfiguration, false)
    );

    setIsLoading(false);
    setCurrentIncentiveChart(!currentIncentiveChart);
  };

  const handleChangeIncentiveChart = () => {
    handleSaveConfiguration();
  };

  useEffect(() => {
    setCurrentIncentiveChart(hasIncentiveChart !== false);
  }, [hasIncentiveChart]);

  useEffect(() => {
    const sortedValues = society?.societyValues
      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
      ?.map((societyValue) => ({
        ...societyValue,
        totalShares: societyValue?.isPriceByShare
          ? (societyValue?.value || 0) / societyValue?.shareValue
          : societyValue?.value || 0,
      }));

    setUpdatedValues(sortedValues);
    if (society?.societyValues?.length > 0) {
      setShowOutdatedWarning(hasOutdatedSocietyValue(society));
    } else {
      setShowOutdatedWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society?.societyValues]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">{t('SocietyValues')}</h5>
          <div className="nk-block-des">
            <p>{t('SocietyValuesDescription')}</p>
          </div>
        </div>
      </div>

      <div className="nk-block">
        {isAdmin ? (
          <div className="nk-block-head">
            {showOutdatedWarning && (
              <div className="nk-block-head-content">
                <div
                  className="alert alert-fill alert-icon alert-danger mb-4"
                  role="alert"
                >
                  <em className="icon ni ni-alert-circle" />
                  <strong>{t('ValuationOutdated')}</strong>.&nbsp;
                  {t('ValuationOutdatedMessage')}
                </div>
              </div>
            )}

            <div className="nk-block-head-content">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  dispatch(
                    setModal(
                      <AddSocietyValue
                        society={society}
                        setUpdatedValues={setUpdatedValues}
                        updatedValues={updatedValues}
                      />
                    )
                  );
                  setPage(4);
                }}
                disabled={isDemo}
              >
                {t('AddSocietyValue')}
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}

        {updatedValues?.length > 0 && (
          <>
            <div className="card card-bordered card-preview w-100">
              <div className="table-responsive">
                <table className="nk-tb-list nk-tb-ulist">
                  <thead>
                    <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
                      <th className="nk-tb-col">#</th>
                      <th className="nk-tb-col text-center">{t('Date')}</th>
                      <th className="nk-tb-col text-right">
                        {t('SocietyValue')}
                      </th>
                      <th className="nk-tb-col text-right">
                        {t('ShareValue')}
                      </th>

                      {/* TODO: Enable this column after the shares calculation is fixed */}
                      {/* <th className="nk-tb-col text-right">
                      {t('TotalSharesShort')}
                    </th> */}
                      <th className="nk-tb-col text-center">
                        {t('ValueByShare')}
                      </th>
                      <th className="nk-tb-col">
                        <span className="sub-text" />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {updatedValues?.map((societyValue, index) => (
                      <tr className="nk-tb-item">
                        <th className="nk-tb-col text-center" scope="row">
                          {index + 1}
                        </th>

                        <td className="nk-tb-col text-center">
                          {showDate(societyValue?.date)}
                        </td>

                        <td className="nk-tb-col text-right">
                          {societyValue?.isPriceByShare ? (
                            '-'
                          ) : (
                            <NumberFormat
                              value={societyValue?.value}
                              displayType="text"
                              {...currencyFormatDecimals}
                            />
                          )}
                        </td>

                        <td className="nk-tb-col text-right">
                          {societyValue?.isPriceByShare ? (
                            <NumberFormat
                              value={societyValue?.shareValue}
                              displayType="text"
                              {...currencyFormatDecimals}
                              decimalScale={8}
                            />
                          ) : (
                            '-'
                          )}
                        </td>

                        {/* TODO: Enable this column after shares calculation is fixed */}
                        {/* <td className="text-right">
                        <NumberFormat
                          value={societyValue?.totalShares || 0}
                          displayType="text"
                          {...numberFormat}
                        />
                      </td> */}

                        <td className="nk-tb-col text-center">
                          {societyValue?.isPriceByShare ? t('Yes') : t('Not')}
                        </td>

                        {isAdmin && !isDemo && (
                          <td className="nk-tb-col nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1">
                              <li>
                                <div className="dropdown">
                                  <MenuDots
                                    menuOptions={menuOptions}
                                    id={societyValue['_id']}
                                    params={{
                                      user,
                                      societyId: society?.['_id'],
                                      societyName: society?.name,
                                      societyValue: societyValue?.value,
                                      societyValueId: societyValue['_id'],
                                      societyValueDate: societyValue?.date,
                                      mixpanel,
                                    }}
                                    direction="left"
                                  />
                                </div>
                              </li>
                            </ul>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                <div className="form-group">
                  <div className="custom-control custom-control-xs custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox-hide-incentive-chart"
                      className="custom-control-input"
                      value={currentIncentiveChart}
                      checked={!currentIncentiveChart}
                      onChange={handleChangeIncentiveChart}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkbox-hide-incentive-chart"
                    />
                    {t('DoYouWantToHideTheIncentiveChart')}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {isLoading && <CustomLoading />}
    </div>
  );
};

export default SocietyValues;
