/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  numberFormat,
  numberFormatDecimals,
} from '../../../../constants/formats';
import { isMobileScreen } from '../../../../utils/getScreenSize';

const WidgetTableExtended = ({
  title,
  linkText,
  linkUrl,
  columns,
  data,
  maxRows = 10,
}) => (
  <div className="col-12 col-xl-6 mb-4">
    <div className="card card-bordered h-100">
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">{title}</h6>
          </div>
          <div className="card-tools">
            <Link to={linkUrl} className="link">
              {linkText}
            </Link>
          </div>
        </div>
      </div>
      <div className="nk-tb-list is-loose">
        <div className="nk-tb-item nk-tb-head">
          {columns?.map((column, index) => (
            <div
              className={`nk-tb-col ${index > 0 ? 'text-center' : ''}`}
              key={column}
            >
              <span>{column}</span>
            </div>
          ))}
        </div>
        {data?.slice(0, maxRows)?.map((item) => (
          <div className="nk-tb-item" key={uuidv4()}>
            <div className="nk-tb-col text-ellipsis">
              <div className="icon-text">
                <em className={`text-primary mr-1 icon ni ${item?.icon}`} />
                {item.link ? (
                  <Link to={item.link} className="link">
                    <span
                      className="tb-lead text-ellipsis"
                      style={{
                        width: '120px',
                      }}
                    >
                      {item?.name}
                    </span>
                  </Link>
                ) : (
                  <span
                    className="tb-lead text-ellipsis"
                    style={{
                      width: isMobileScreen() ? '120px' : '200px',
                    }}
                  >
                    {item?.name}
                  </span>
                )}
              </div>
            </div>
            <div className="nk-tb-col text-end  text-right">
              <span className="tb-sub tb-amount">
                <span>
                  <NumberFormat
                    value={item?.value1}
                    displayType="text"
                    {...numberFormat}
                  />
                </span>
              </span>
            </div>
            {item?.value2 !== undefined && (
              <div className="nk-tb-col text-right">
                <span className="tb-sub ">
                  <NumberFormat
                    value={item?.value2}
                    displayType="text"
                    {...numberFormatDecimals}
                  />
                </span>
              </div>
            )}
            {item?.value3 !== undefined ? (
              <div className="nk-tb-col text-right">
                <span className="tb-sub ">
                  <NumberFormat
                    value={item?.value3}
                    displayType="text"
                    {...numberFormatDecimals}
                  />
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default WidgetTableExtended;
