import i18n from 'i18n/config';
import { store } from 'redux/store';

import { User } from 'types';
import userTypes from 'constants/userTypes';

import sendEmail from 'modules/communications/redux/mailActions';
import { updateUser } from 'modules/profile/redux/userActions';
import { updateSociety } from 'modules/society/redux/societyActions';
import { getSocietyMainAdmin } from 'modules/society/utils';

import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import { createAdminEmail } from './createAdminEmail';

type DeleteAdminProps = {
  data: User;
  societyId: string;
  userType: keyof typeof userTypes;
};

export const deleteAdmin = async ({ data, societyId }: DeleteAdminProps) => {
  const { language } = i18n;
  const { user } = store.getState();
  const { society } = store.getState();

  const adminId = data._id;
  const deletedAdminName = data.name;

  try {
    await store.dispatch(
      updateSociety(societyId, { admin: { remove: adminId } }, false)
    );

    store.dispatch(
      updateUser(
        null,
        { admin: { remove: adminId }, society: societyId },
        false
      )
    );

    const mainAdmin = await getSocietyMainAdmin();

    if (!mainAdmin) {
      throw new Error();
    }

    const emailData = createAdminEmail({
      t: i18n.t,
      language,
      actualSociety: society.actualSociety,
      user,
      email: mainAdmin.email,
      mainAdminName: mainAdmin.name,
      updatedAdminName: deletedAdminName,
      action: 'DELETE',
    });

    store.dispatch(sendEmail(emailData, false));
    store.dispatch(addAlert(alertBodyTypes.ADMIN_DELETED_SUCCESSFULLY));
  } catch (error) {
    store.dispatch(addAlert(alertBodyTypes.ERROR_DELETING_ADMIN));
  }
};
