import { dateFormatInverted } from 'constants/formats';
import reportStatusTypes from 'constants/reportStatusTypes';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import {
  getDocumentsByReference,
  updateDocument,
} from 'modules/documents/redux/documentActions';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { store } from 'redux/store';

function UpdateDocumentModal({
  renameUpdate = false,
  operationDocument = false,
  statusUpdate = false,
  currentStatus,
  societyId,
  document,
}) {
  const { t } = useTranslate();
  const [documentName, setDocumentName] = useState(document?.name || '');
  const [documentDescription, setDocumentDescription] = useState(
    document?.description || ''
  );
  const [date, setDate] = useState(
    format(new Date(document?.date), dateFormatInverted) || ''
  );
  const [selectedStatus, setSelectedStatus] = useState(
    currentStatus || reportStatusTypes.var.PENDING
  );
  const [selectedStatusOption, setSelectedStatusOption] = useState({
    value: currentStatus || reportStatusTypes.var.PENDING,
    label:
      t(reportStatusTypes.text[currentStatus]) ||
      t(reportStatusTypes.text[reportStatusTypes.var.PENDING]),
  });
  const statusOptions = Object.keys(reportStatusTypes.var).map((status) => ({
    value: status,
    label: `${t(reportStatusTypes.text[status])}`,
  }));

  const animatedComponents = makeAnimated();

  const handleStatusOptionChange = (selectedOption) => {
    setSelectedStatusOption(selectedOption);
    setSelectedStatus(selectedOption.value);
  };

  async function saveDocument(event) {
    event.preventDefault();
    if (renameUpdate) {
      await store.dispatch(
        updateDocument({
          ...document,
          name: documentName,
          description: documentDescription,
          date,
        })
      );
    } else if (statusUpdate) {
      await store.dispatch(
        updateDocument({ ...document, reportStatus: selectedStatus })
      );
    } else {
      await store.dispatch(updateDocument({ ...document, date }));
    }
    store.dispatch(getDocumentsByReference({ societyId }));
    store.dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5>
          {((renameUpdate || statusUpdate) && t('UpdateDocument')) ||
            t('ChangeDate')}
        </h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveDocument}>
          {renameUpdate && (
            <div className="form-group">
              <label className="form-label" htmlFor="full-name">
                {t('Name')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control"
                  value={documentName}
                  onChange={(event) => setDocumentName(event.target.value)}
                />
              </div>
            </div>
          )}
          {renameUpdate && (
            <div className="form-group">
              <label className="form-label" htmlFor="full-name">
                {t('Description')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control"
                  value={documentDescription}
                  onChange={(event) =>
                    setDocumentDescription(event.target.value)
                  }
                />
              </div>
            </div>
          )}
          {statusUpdate ? (
            <div className="form-group">
              <label className="form-label" htmlFor="full-name">
                {t('SelectReportStatus')}
              </label>
              <div className="form-control-wrap">
                <Select
                  closeMenuOnSelect
                  className="react-select"
                  value={selectedStatusOption}
                  options={statusOptions}
                  components={animatedComponents}
                  onChange={handleStatusOptionChange}
                />
              </div>
            </div>
          ) : (
            <div className="form-group">
              <label className="form-label" htmlFor="email-address">
                {t('Date')}
              </label>
              {operationDocument && (
                <div className="sub-text mt-1">
                  {t('DateCorrespondsToOperation')}
                </div>
              )}
              <div className="form-control-wrap">
                <input
                  type="date"
                  className="form-control"
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                  max="2100-01-01"
                  min="1000-01-01"
                  disabled={operationDocument}
                />
              </div>
            </div>
          )}

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={
              !date ||
              (!documentName && renameUpdate) ||
              (!documentDescription && renameUpdate)
            }
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default UpdateDocumentModal;
