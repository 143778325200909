/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useRef } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import {
  tenderParticipantStatusTypes,
  tenderStatusTypes,
} from 'utils/tenderOfferTypes';

import { dateFormat } from 'constants/formats';

import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';

import './TenderOfferRow.scss';

const memberBases = {
  PARTNER: `socio`,
  BENEFICIARY: `beneficiario`,
};

const TenderOfferEligiblesRow = ({ eligiblesData, tenderOfferData }) => {
  const { t } = useTranslate();
  const { societyId, tenderOfferId } = useParams();
  const printRef = useRef();
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const downloadFilename = `${t('TenderOffer')} - ${tenderOfferData.name} - ${t(
    'EligiblesList'
  )}`;

  const unitPrice = tenderOfferData?.totalPriceByShare;

  const generateMemberUrl = (data) => {
    const { id, type } = data;
    const memberUrl = memberBases[type];

    const baseUrl = `/participacion-tender-offer/${societyId}/${tenderOfferId}/${memberUrl}/${id}`;
    return baseUrl;
  };

  const eligibleParticipation = (id) =>
    tenderOfferData?.participants?.find((item) => item.id === id);

  const getAmountByParticipant = (participant) => {
    if (!participant) return 0;

    if (participant.status === tenderParticipantStatusTypes.ACCEPTED.value) {
      return participant?.totalAccepted * tenderOfferData?.totalPriceByShare;
    }

    return 0;
  };

  const getNetByParticipant = (item) => {
    if (!item) return 0;

    const currentStatus = eligibleParticipation(item.id)?.status;

    if (currentStatus === tenderParticipantStatusTypes.ACCEPTED.value) {
      const totalAmount =
        getAmountByParticipant(eligibleParticipation(item.id)) -
        eligibleParticipation(item.id)?.totalAccepted * (item.strikePrice || 0);

      const discountByRetention =
        ((item.retentionPercent || 0) * totalAmount) / 100;

      return totalAmount - discountByRetention;
    }

    return 0;
  };

  const getOperationDateByParticipant = (item) => {
    if (!item) return '-';

    const currentStatus = eligibleParticipation(item.id)?.status;
    const operationDate = eligibleParticipation(item.id)?.operationDate;

    if (
      currentStatus === tenderParticipantStatusTypes.ACCEPTED.value &&
      operationDate
    ) {
      return format(new Date(operationDate), dateFormat);
    }

    return '-';
  };

  const isValidEligible = (eligible) => {
    if (tenderOfferData?.status === tenderStatusTypes.DRAFT.value) {
      return true;
    }

    const isParticipant = !!eligibleParticipation(eligible.id);

    return !!isParticipant;
  };

  return (
    <div className="nk-block">
      <div className="card card-bordered">
        <div className="card-inner card-inner-md">
          <div className="card-title-group">
            <h6 className="card-title">
              {t('EligiblePartnersOrBeneficiaries')}
            </h6>
            <div className="card-action">
              <ul className="nk-block-tools gx-3">
                <li>
                  <ReactToPrint
                    trigger={() => (
                      <span className="btn btn-icon btn-primary screen-only">
                        <em className="icon ni ni-printer" />
                      </span>
                    )}
                    content={() => printRef.current}
                    documentTitle={downloadFilename}
                  />
                </li>
                <li>
                  <ReactHtmlTableToExcel
                    id="table-nostatus-xls-button"
                    className="btn btn-white btn-outline-light mr-2 mb-sx-2 mb-0"
                    table="eligiblesListTable"
                    filename={downloadFilename}
                    sheet={t('EligiblesList')}
                    buttonText={`${t('Download')}`}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="margin-print-only" ref={printRef}>
          <PrintHeader
            title={t('PrintHeaderTenderOffer')}
            subTitle={tenderOfferData?.name}
          />

          <table className="table table-tranx">
            <thead>
              <tr className="tb-tnx-head">
                <th className="tb-tnx-id">
                  <span className="">Nº</span>
                </th>
                <th>
                  <span>{t('Name')}</span>
                </th>
                <th className="text-center">
                  <span>{t('TotalVestShareUnits')}</span>
                </th>
                <th className="text-center d-none">
                  <span>{t('GrantDate')}</span>
                </th>
                <th className="text-center">
                  <span>{t('CanSell')}</span>
                </th>
                <th className="text-center">
                  <span>%ND</span>
                </th>
                <th className="text-center">
                  <span>%FD</span>
                </th>
                <th className="text-center">
                  <span>{t('OfferAmount')}</span>
                </th>
                <th className="text-center">
                  <span>{t('SoldAmount')}</span>
                </th>
                <th className="text-center">
                  <span>{t('StrikePrice')}</span>
                </th>
                <th className="text-center">
                  <span>{t('RetentionPercentage')}</span>
                </th>
                <th className="text-center">
                  <span>{t('NetAmount')}</span>
                </th>
                <th className="text-center">
                  <span>{t('SharesUnitsSold')}</span>
                </th>
                <th className="text-center">
                  <span>{t('SaleDate')}</span>
                </th>
                <th>
                  <span>{t('Status')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {eligiblesData.length > 0 ? (
                eligiblesData
                  ?.filter((item) => isValidEligible(item))
                  ?.map((item, index) => (
                    <tr className="tb-tnx-item" key={item.id}>
                      <td className="tb-tnx-id">
                        <span className="text-primary">{index + 1}</span>
                      </td>
                      <td className="tb-tnx-info">
                        <div className="tb-tnx-info w-150px">
                          {isDemo ? (
                            <span className="title">{item.name}</span>
                          ) : (
                            <Link to={generateMemberUrl(item)}>
                              <span className="title">{item.name}</span>
                            </Link>
                          )}
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatNumber(item.originalUnits)}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount d-none">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {item.planStartDate
                              ? format(new Date(item.planStartDate), dateFormat)
                              : '-'}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatNumber(item.units)}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatNumberDecimals(item.NDPercent)}%
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatNumberDecimals(item.FDPercent)}%
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatCurrencyDecimals(item.units * unitPrice)}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatCurrencyDecimals(
                              getAmountByParticipant(
                                eligibleParticipation(item.id)
                              )
                            )}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-center w-100">
                          <span className="amount">
                            {formatNumberDecimals(item.strikePrice || 0)}€
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatNumberDecimals(item.retentionPercent)}%
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatCurrencyDecimals(getNetByParticipant(item))}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatNumber(
                              eligibleParticipation(item.id)?.status ===
                                tenderParticipantStatusTypes.ACCEPTED.value
                                ? eligibleParticipation(item.id)?.totalAccepted
                                : 0
                            )}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {getOperationDateByParticipant(item)}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tb-tnx-status">
                          <span
                            className={`badge badge-check ${
                              tenderParticipantStatusTypes[
                                eligibleParticipation(item.id)?.status
                              ]?.style
                            }`}
                          >
                            {t(
                              tenderParticipantStatusTypes[
                                eligibleParticipation(item.id)?.status
                              ]?.value
                            ) || ''}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <NoItemsAvailable
                  mainMessage={t('No hay socios / beneficiarios disponibles')}
                  infoMessage={
                    t('Pruebe a modificar los filtros de la Oferta') || ''
                  }
                />
              )}
            </tbody>
          </table>
        </div>

        {/* This table is only for print purposes */}
        <table className="table table-tranx d-none" id="eligiblesListTable">
          <thead>
            <tr className="tb-tnx-head">
              <th className="tb-tnx-id">
                <span className="">Nº</span>
              </th>
              <th>
                <span>Nombre</span>
              </th>
              <th>
                <span>CIF / TAX ID</span>
              </th>
              <th>
                <span>Nro. Empleado</span>
              </th>
              <th>
                <span>Residencia fiscal</span>
              </th>
              <th>
                <span>Nro. Cuenta bancaria</span>
              </th>
              <th className="text-center">
                <span>
                  Total Vest. <br />
                  Part / Unid
                </span>
              </th>
              <th className="text-center">
                <span>Fecha concesión</span>
              </th>
              <th className="text-center">
                <span>Puede vender</span>
              </th>
              <th className="text-center">
                <span>%ND</span>
              </th>
              <th className="text-center">
                <span>%FD</span>
              </th>
              <th className="text-center">
                <span>Importe Oferta</span>
              </th>
              <th className="text-center">
                <span>Importe vendido</span>
              </th>
              <th className="text-center">
                <span>Strike price</span>
              </th>
              <th className="text-center">
                <span>% retención</span>
              </th>
              <th className="text-center">
                <span>Importe neto</span>
              </th>
              <th className="text-center">
                <span>Part / Unid vendidas</span>
              </th>
              <th className="text-center">
                <span>Fecha Venta</span>
              </th>
              <th>
                <span>Estado</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {eligiblesData.length > 0 &&
              eligiblesData?.map((item, index) => (
                <tr className="tb-tnx-item" key={item._id}>
                  <td className="tb-tnx-id">
                    <span className="text-primary">{index + 1}</span>
                  </td>
                  <td className="tb-tnx-info">
                    <div className="tb-tnx-info w-150px">
                      <span className="title">{item.name}</span>
                    </div>
                  </td>
                  <td className="tb-tnx-info">
                    <div className="tb-tnx-info w-150px">
                      <span className="title">{item.cif}</span>
                    </div>
                  </td>
                  <td className="tb-tnx-info">
                    <div className="tb-tnx-info w-150px">
                      <span className="title">{item.employeeId}</span>
                    </div>
                  </td>
                  <td className="tb-tnx-info">
                    <div className="tb-tnx-info w-150px">
                      <span className="title">{item.taxAddress}</span>
                    </div>
                  </td>
                  <td className="tb-tnx-info">
                    <div className="tb-tnx-info w-150px">
                      <span className="title">{item.bankAccountNumber}</span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">{item.originalUnits}</span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">
                        {item.planStartDate
                          ? format(new Date(item.planStartDate), dateFormat)
                          : '-'}
                      </span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">{item.units}</span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">{item.NDPercent}%</span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">{item.FDPercent}%</span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">
                        {(item.units * unitPrice).toFixed(2)}€
                      </span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">
                        {`${getAmountByParticipant(
                          eligibleParticipation(item.id)
                        ).toFixed(2)}€`}
                      </span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-center w-100">
                      <span className="amount">
                        {formatNumberDecimals(item.strikePrice || 0)}€
                      </span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">
                        {formatNumberDecimals(item.retentionPercent)}%
                      </span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">
                        {`${getNetByParticipant(item).toFixed(2)}€`}
                      </span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">
                        {eligibleParticipation(item.id)?.status ===
                        tenderParticipantStatusTypes.ACCEPTED.value
                          ? eligibleParticipation(item.id)?.totalAccepted
                          : 0}
                      </span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount">
                    <div className="tb-tnx-total text-right w-100">
                      <span className="amount">
                        {getOperationDateByParticipant(item)}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="tb-tnx-status">
                      <span
                        className={`badge badge-check ${
                          tenderParticipantStatusTypes[
                            eligibleParticipation(item.id)?.status
                          ]?.style
                        }`}
                      >
                        {t(
                          tenderParticipantStatusTypes[
                            eligibleParticipation(item.id)?.status
                          ]?.value
                        ) || ''}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TenderOfferEligiblesRow;
