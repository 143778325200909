import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from 'i18n/config';

import documentSections from 'constants/documentSections';

import List from 'modules/documents/components/List';
import Preview from 'modules/documents/components/Preview';

const currentSection = 'DOCUMENTS';

const Documents = ({
  documents,
  isLoadingData,
  searchTerm,
  fullScreen,
  handleToggleFullScreen,
}) => {
  const [section] = useState(
    documentSections(i18n).find((s) => s.tag === currentSection)
  );

  const hasEdit = useLocation().search === '?edit=true';
  const hasPreview = useLocation().search === '?preview=true';

  return (
    <>
      {hasPreview || hasEdit ? (
        <Preview
          hasEdit={hasEdit}
          fullScreen={fullScreen}
          handleToggleFullScreen={handleToggleFullScreen}
        />
      ) : (
        <List
          section={section}
          documents={documents}
          isLoadingData={isLoadingData}
          searchTerm={searchTerm}
        />
      )}
    </>
  );
};

export default Documents;
