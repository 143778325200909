import { Participant } from './';

export enum BoardDelegations {
  'ALL_MEMBERS' = 'ALL_MEMBERS',
  'ALL_PARTNERS' = 'ALL_PARTNERS',
  'SELECTED_PARTNERS' = 'SELECTED_PARTNERS',
  'ALL_REPRESENTANTS' = 'ALL_REPRESENTANTS',
  'SELECTED_REPRESENTANTS' = 'SELECTED_REPRESENTANTS',
}

export enum BoardDelegationRoles {
  'PARTNER' = 'PARTNER',
  'PARTNER_REPRESENTANT' = 'PARTNER_REPRESENTANT',
}

export type BoardDelegation = keyof typeof BoardDelegations;
export type BoardDelegationRole = keyof typeof BoardDelegationRoles;

export type BoardAttendanceTypes =
  | 'HYBRID'
  | 'ONLINE'
  | 'FACE_TO_FACE'
  | 'WRITTEN_NO_SESSION';

export type BoardTypes =
  | 'ORDINARY'
  | 'EXTRAORDINARY'
  | 'ORDINARY_EXTRAORDINARY'
  | 'UNIVERSAL';

export type BoardStatus =
  | 'PENDING'
  | 'ACTIVE'
  | 'CELEBRATING'
  | 'CELEBRATED'
  | 'CLOSED'
  | 'CANCELED';

export type BoardOrder = {
  _id: string;
  subject: string;
  description: string;
  voteType: string;
  voteValidation: string;
  holdingClassForValidation?: string;
};

export type BoardOrderVotesData = {
  id: string;
  favor: number;
  against: number;
  abstention: number;
  totalVotesNDPercent: number;
};

export type BoardVoterData = {
  voterId: string;
  voterName: string;
  voterType: string;
};

export type BoardVote = {
  _id?: string;
  order?: string;
  vote?: string;
  voterData?: BoardVoterData;
};

export type Board = {
  _id: string;
  name: string;
  date: Date;
  status: BoardStatus;
  isActive?: boolean;
  details?: string;
  participants: Participant[];
  delegationPartners?: string[];
  delegationRepresentants?: string[];
  president: string;
  secretary: string;
  attendanceType: BoardAttendanceTypes;
  announcement: string;
  place: string;
  onlineAccess: string;
  digitalVoting: boolean;
  boardType: BoardTypes;
  delegationType: BoardDelegation;
  announcementDocument: string;
  votePeriod: string;
  retriedAnnouncements?: string[];
  announcementTemplate?: string;
  actTemplate?: string;
  actCertificateTemplate?: string;
  orders?: BoardOrder[];
};
