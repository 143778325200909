/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import { tenderOfferAction } from 'modules/_shared/redux/modalsActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import {
  addDocument,
  getDocument,
  getDocumentsByReference,
} from 'modules/documents/redux/documentActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import { updateTenderOffer } from 'modules/transactions/redux/tenderOfferActions';
import { store } from 'redux/store';

import actionTypes from 'constants/actionTypes';
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import {
  cleanValue,
  formatDateToInput,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import operationTypes from 'constants/operationTypes';

import {
  eligiblesSubgroupTypes,
  tenderEligibleTypes,
  tenderPriceTypes,
  tenderStatusTypes,
} from 'utils/tenderOfferTypes';
import trackEvent from 'utils/trackEvent';

import ReactDualList from 'modules/_shared/components/DualList';

import '../Modals.scss';

const AddTenderOffer = ({ data = {}, action = actionTypes.ADD }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const {
    user,
    plans,
    partners,
    documents,
    phantomPlans,
    actualSociety,
    beneficiaries,
    holdingClasses,
    holdingsAndPlans,
    stockOptionsPlans,
    partnersAndBeneficiaries,
  } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
    holdingClasses: state.holdingClasses,
    documents: state.documents,
    plans: state.plans,
    partners: state.society?.actualSociety?.partners?.filter(
      (partner) => partner?.sharesCount.actual > 0
    ),
    phantomPlans: state.plans?.filter(
      (plan) => plan.planType === 'PHANTOM_SHARE'
    ),
    stockOptionsPlans: state.plans?.filter(
      (plan) => plan.planType === 'STOCK_OPTION'
    ),
    beneficiaries: state.society?.actualSociety?.beneficiaries,
    holdingsAndPlans: state.holdingClasses?.concat(state.plans),
    partnersAndBeneficiaries: state.society?.actualSociety?.partners?.concat(
      state.society?.actualSociety?.beneficiaries
    ),
  }));

  const [name, setName] = useState('');
  const [details, setDetails] = useState('');
  const [isActive] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [calculationDate, setCalculationDate] = useState('');
  const [eligibles, setEligibles] = useState([]);
  const [totalPriceByShare, setTotalPriceByShare] = useState(0);
  const [totalPriceBySociety, setTotalPriceBySociety] = useState(0);
  const [isPriceFD, setIsPriceFD] = useState(false);
  const [buyerName, setBuyerName] = useState('');
  const [buyerNIF, setBuyerNIF] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [buyerRepresentativeName, setBuyerRepresentativeName] = useState('');
  const [buyerRepresentativeNIF, setBuyerRepresentativeNIF] = useState('');
  const [buyerRepresentativeEmail, setBuyerRepresentativeEmail] = useState('');

  const [maxUnits, setMaxUnits] = useState(0);
  const [maxPercentUnits, setMaxPercentUnits] = useState(0);
  const [maxPhantoms, setMaxPhantoms] = useState(0);
  const [maxPercentPhantoms, setMaxPercentPhantoms] = useState(0);
  const [maxStocks, setMaxStocks] = useState(0);
  const [maxPercentStocks, setMaxPercentStocks] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [maxPercentUnvested, setMaxPercentUnvested] = useState(100);

  const [retentionPercent, setRetentionPercent] = useState(0);
  const [hasRetentionPercent, setHasRetentionPercent] = useState(false);

  const [paymentConditions, setPaymentConditions] = useState('');
  const [eligibleOptions, setEligibleOptions] = useState([]);
  const [isPriceByShare, setIsPriceByShare] = useState(null);
  const [isPriceBySociety, setIsPriceBySociety] = useState(null);
  const [eligiblesType, setEligiblesType] = useState('');
  const [eligiblesSubgroup, setEligiblesSubgroup] = useState('');
  const [sellAll, setSellAll] = useState(null);
  const [file, setFile] = useState();
  const [template, setTemplate] = useState({});
  const [targetPlan, setTargetPlan] = useState({});

  const [templates, setTemplates] = useState([]);

  const [societyShares, setSocietyShares] = useState(
    actualSociety?.sharesCount?.actual
  );

  const availableOptions = {
    PARTNERS: {
      SOME_GROUPS: holdingClasses,
      SOME_MEMBERS: partners,
    },
    BENEFICIARIES_PHANTOM_SHARES: {
      SOME_GROUPS: phantomPlans,
      SOME_MEMBERS: beneficiaries,
    },
    BENEFICIARIES_STOCK_OPTIONS: {
      SOME_GROUPS: stockOptionsPlans,
      SOME_MEMBERS: beneficiaries,
    },
    ALL_MEMBERS: {
      SOME_GROUPS: holdingsAndPlans,
      SOME_MEMBERS: partnersAndBeneficiaries,
    },
  };

  const parseOptionType = (option) => {
    if ('plan' in option) {
      return 'BENEFICIARY';
    }
    if ('partnerNumber' in option) {
      return 'PARTNER';
    }
    if ('poolType' in option) {
      return 'PLAN';
    }
    if ('votes' in option) {
      return 'HOLDING_CLASS';
    }
    return 'UNKNOWN';
  };

  const getCurrentTemplate = async (id) => {
    try {
      const response = await store.dispatch(getDocument(id));
      setTemplate(response);
    } catch (error) {
      console.log({ error });
    }
  };

  const getCurrentTargetPlan = async (id) => {
    const currentPlan = plans?.find((plan) => plan?._id === id);
    setTargetPlan(currentPlan);
  };

  const handleChangeTemplate = (e) => {
    const currentTemplate = templates?.find(
      (template) => template['_id'] === e.target.value
    );
    setTemplate(currentTemplate);
  };

  const handleChangeTargetPlan = (e) => {
    const currentPlan = plans?.find((plan) => plan['_id'] === e.target.value);
    setTargetPlan(currentPlan);
  };

  const handleChangeEligiblesSubgroup = (group, subgroup) => {
    setEligiblesSubgroup(subgroup);

    const selectedOptions = availableOptions[group][subgroup];

    const options =
      selectedOptions?.map((option) => ({
        value: option?._id,
        label: option?.name,
        type: parseOptionType(option),
      })) || [];

    setEligibleOptions(options);
  };

  const handleChangePriceType = (type) => {
    if (type === tenderPriceTypes.PRICE_BY_SHARE) {
      setIsPriceByShare(true);
      setIsPriceBySociety(false);
    } else {
      setIsPriceByShare(false);
      setIsPriceBySociety(true);
    }
  };

  const handleChangePriceValue = (value) => {
    if (isPriceByShare) {
      setTotalPriceByShare(value);
      setTotalPriceBySociety(cleanValue(value) * societyShares);
    } else {
      setTotalPriceBySociety(value);
      setTotalPriceByShare(cleanValue(value) / societyShares);
    }
  };

  const handleChangePriceDilution = (value) => {
    setIsPriceFD(value);

    if (value === true) {
      setSocietyShares(
        actualSociety?.sharesCount?.actual +
          actualSociety?.sharesCount?.future +
          actualSociety?.sharesCount?.drafts
      );
    } else {
      setSocietyShares(actualSociety?.sharesCount?.actual);
    }
  };

  const handleChangeHasRetentionPercent = (value) => {
    setHasRetentionPercent(value);
  };

  const handleChangeRetentionPercent = (value) => {
    setRetentionPercent(value);
  };

  const isFormValid = () => {
    const generalData = !!(name && startDate && endDate);
    const buyerData = !!(buyerName && buyerNIF && buyerEmail);
    const priceData = !!(
      (isPriceByShare || isPriceBySociety) &&
      totalPriceByShare &&
      totalPriceBySociety
    );
    const eligiblesData = !!(eligiblesType && eligiblesSubgroup);
    const conditionsData = !!(
      sellAll ||
      (!sellAll &&
        (maxAmount || maxUnits || maxPercentUnits || maxPercentUnvested))
    );
    const paymentData = !!paymentConditions;

    return (
      generalData &&
      buyerData &&
      priceData &&
      eligiblesData &&
      conditionsData &&
      paymentData
    );
  };

  const saveTenderOffer = async (event) => {
    event.preventDefault();

    let uploadedDocument = null;

    if (file) {
      const newDocument = {
        file,
        date: new Date(),
        size: file.size,
        name: `${operationTypes.ADD_TENDER_OFFER_FILENAME}-${file.name}`,
        author: user['_id'],
        society: actualSociety['_id'],
        fileType: fileTypes[file.type],
        category: documentTypes.DOCUMENTS, // @Marti - que categoria le ponemos?
        description: t('DocumentLinkedToOperation'),
      };
      uploadedDocument = await dispatch(addDocument(newDocument));
    }

    const newTenderOffer = {
      name,
      details,
      isActive,
      startDate,
      endDate,
      calculationDate,
      eligibles,
      buyerName,
      buyerNIF,
      buyerEmail,
      buyerRepresentativeName,
      buyerRepresentativeNIF,
      buyerRepresentativeEmail,
      paymentConditions,
      isPriceFD,
      isPriceByShare,
      isPriceBySociety,
      eligiblesType,
      eligiblesSubgroup,
      sellAll,
      totalPriceByShare: cleanValue(totalPriceByShare),
      totalPriceBySociety: cleanValue(totalPriceBySociety),
      retentionPercent: cleanValue(retentionPercent),
      maxUnits: cleanValue(maxUnits),
      maxPercentUnits: cleanValue(maxPercentUnits),
      maxPhantoms: cleanValue(maxPhantoms),
      maxPercentPhantoms: cleanValue(maxPercentPhantoms),
      maxStocks: cleanValue(maxStocks),
      maxPercentStocks: cleanValue(maxPercentStocks),
      maxAmount: cleanValue(maxAmount),
      maxPercentUnvested: cleanValue(maxPercentUnvested),
      society: actualSociety?._id,
      status: tenderStatusTypes.DRAFT.value,
      document: uploadedDocument?._id || null,
      template: template?._id || null,
      targetPlan: targetPlan?._id || null,
    };

    let currentEvent;

    if (action === actionTypes.ADD) {
      await dispatch(tenderOfferAction(newTenderOffer));
      currentEvent = eventTypes.ADD_TENDER_OFFER;
    } else {
      await dispatch(
        updateTenderOffer(data._id, {
          ...newTenderOffer,
        })
      );
      currentEvent = eventTypes.EDIT_TENDER_OFFER;
    }

    trackEvent(mixpanel, currentEvent, {
      userId: user?._id,
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?._id,
      societyName: actualSociety?.name,
      operation: currentEvent,
    });

    dispatch(setModal(null));
  };

  useEffect(() => {
    if (action === actionTypes.EDIT && data._id) {
      setName(data?.name);
      setDetails(data?.details);
      setStartDate(formatDateToInput(data?.startDate));
      setEndDate(formatDateToInput(data?.endDate));
      setCalculationDate(formatDateToInput(data?.calculationDate));
      setEligibles(data?.eligibles);
      setTotalPriceByShare(data?.totalPriceByShare);
      setTotalPriceBySociety(data?.totalPriceBySociety);
      setHasRetentionPercent(!!data?.retentionPercent);
      setRetentionPercent(data?.retentionPercent);
      setBuyerName(data?.buyerName);
      setBuyerNIF(data?.buyerNIF);
      setBuyerEmail(data?.buyerEmail);
      setBuyerRepresentativeName(data?.buyerRepresentativeName);
      setBuyerRepresentativeNIF(data?.buyerRepresentativeNIF);
      setBuyerRepresentativeEmail(data?.buyerRepresentativeEmail);
      setMaxUnits(data?.maxUnits);
      setMaxPercentUnits(data?.maxPercentUnits);
      setMaxPhantoms(data?.maxPhantoms);
      setMaxPercentPhantoms(data?.maxPercentPhantoms);
      setMaxStocks(data?.maxStocks);
      setMaxPercentStocks(data?.maxPercentStocks);
      setMaxAmount(data?.maxAmount);
      setMaxPercentUnvested(data?.maxPercentUnvested);
      setPaymentConditions(data?.paymentConditions);
      setIsPriceFD(data?.isPriceFD);
      setIsPriceByShare(data?.isPriceByShare);
      setIsPriceBySociety(data?.isPriceBySociety);
      setEligiblesType(data?.eligiblesType);
      setEligiblesSubgroup(data?.eligiblesSubgroup);
      setSellAll(data?.sellAll);
      if (data?.template) {
        getCurrentTemplate(data?.template);
      }
      if (data?.targetPlan) {
        getCurrentTargetPlan(data?.targetPlan);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (
      eligiblesType === tenderEligibleTypes.BENEFICIARIES_PHANTOM_SHARES ||
      eligiblesType === tenderEligibleTypes.BENEFICIARIES_STOCK_OPTIONS
    ) {
      setMaxUnits(0);
      setMaxPercentUnits(0);
    }
  }, [eligiblesType]);

  useEffect(() => {
    if (societyShares && (isPriceByShare || isPriceBySociety)) {
      const currentPriceValue = isPriceByShare
        ? totalPriceByShare
        : totalPriceBySociety;

      handleChangePriceValue(currentPriceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyShares]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getStockPlans(actualSociety?.['_id']));
      dispatch(getHoldingClasses(actualSociety?.['_id']));
      dispatch(
        getDocumentsByReference({ commonFromUser: { userId: user?.['_id'] } })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (documents) {
      setTemplates(documents?.filter((doc) => doc?.category === 'TEMPLATES'));
    }
  }, [documents]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {t('OfferConfiguration')}
          <small className="d-block fs-14px text-soft">
            {t('TenderOfferSubtitle')}
          </small>
        </h5>

        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="nk-block">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                <form className="form-contact" onSubmit={saveTenderOffer}>
                  <div className="row g-4 mb-3">
                    {/* NAME */}
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">{t('OfferName')}</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder={t('IndicatesOfferName')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {/* BUYER */}
                    <div className="nk-block">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">{t('Buyer')}</h5>
                          <p>{t('SetOfferPrice')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label">{t('Name')}</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder={t('IndicatesPersonWhoPurchases')}
                            value={buyerName}
                            onChange={(e) => setBuyerName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label">{t('Email')}</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder=""
                            value={buyerEmail}
                            onChange={(e) => setBuyerEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label">
                          {t('FiscalIdentification')}
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder={t('NifNiPassport')}
                            value={buyerNIF}
                            onChange={(e) => setBuyerNIF(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="nk-block col-12">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            {t('RepresentativeBoard')}
                          </h5>
                          <p>{t('IfBuyerIsCompanyIndicateData')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label">{t('Name')}</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder={t('RepresentativeName')}
                            value={buyerRepresentativeName}
                            onChange={(e) =>
                              setBuyerRepresentativeName(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label">{t('Email')}</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder=" "
                            value={buyerRepresentativeEmail}
                            onChange={(e) =>
                              setBuyerRepresentativeEmail(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label">
                          {t('FiscalIdentification')}
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder={t('NifNiPassport')}
                            value={buyerRepresentativeNIF}
                            onChange={(e) =>
                              setBuyerRepresentativeNIF(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* PERIOD */}
                    <div className="nk-block col-12">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            {t('OfferPeriod')}
                            <small className="d-block fs-14px text-soft mt-1">
                              {t('DuringTheseDatesOfferWillBeActive')}
                            </small>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="tender-start-date"
                        >
                          {t('StartDate')}
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control date-picker"
                            id="tender-start-date"
                            name="tender-start-date"
                            placeholder="dd/mm/yyyy"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="tender-end-date">
                          {t('EndDate')}
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control date-picker"
                            id="tender-end-date"
                            name="tender-end-date"
                            placeholder="dd/mm/yyyy"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="tender-end-date">
                          {t('EligibleCalculationDate')}
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control date-picker"
                            id="tender-calculation-date"
                            name="tender-calculation-date"
                            placeholder="dd/mm/yyyy"
                            value={calculationDate}
                            onChange={(e) => setCalculationDate(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {/* PRICE */}
                    <div className="nk-block">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            {t('Price')}
                            <small className="d-block fs-14px text-soft mt-1">
                              {t(
                                'EstablishesPricePerShareUnitOrValuationCompany'
                              )}
                            </small>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12">
                      <div className="form-control-wrap">
                        <ul className="custom-control-group w-100 d-inline-flex">
                          <li className="col-6">
                            <div className="custom-control custom-radio ">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="price-type"
                                id="price-share"
                                value={tenderPriceTypes.PRICE_BY_SHARE}
                                checked={isPriceByShare === true}
                                onChange={(e) =>
                                  handleChangePriceType(e.target.value)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="price-share"
                              >
                                {t('PriceForParticipation')}
                              </label>
                            </div>
                          </li>
                          <li className="col-6">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="price-"
                                id="price-society"
                                value={tenderPriceTypes.PRICE_BY_SOCIETY}
                                checked={isPriceBySociety === true}
                                onChange={(e) =>
                                  handleChangePriceType(e.target.value)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="price-society"
                              >
                                {t('CompanyValuation')}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="cp1-project-deadline"
                        >
                          {t('PricePerShareOrUnit')}
                        </label>
                        <NumberFormat
                          name="price-type"
                          className="form-control"
                          value={totalPriceByShare}
                          disabled={!isPriceByShare}
                          onChange={(e) => {
                            handleChangePriceValue(e.target.value);
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...numberFormatDecimals}
                          decimalScale={8}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="price-per-society"
                        >
                          {t('CompanyValuation')}
                        </label>
                        <NumberFormat
                          name="price-per-society"
                          className="form-control"
                          value={totalPriceBySociety}
                          disabled={!isPriceBySociety}
                          onChange={(e) => {
                            handleChangePriceValue(e.target.value);
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...numberFormatDecimals}
                          decimalScale={8}
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-0 pt-0 g-0">
                      <div className="custom-control-sm custom-switch float-right d-flex align-content-center">
                        <span
                          style={{
                            marginRight: '45px',
                            fontSize: '12px',
                            paddingTop: '0',
                          }}
                        >
                          {t('Non Dilluted')}
                        </span>
                        <input
                          type="checkbox"
                          id="price-switch"
                          className="custom-control-input float-right"
                          checked={isPriceFD === true}
                          onChange={(event) =>
                            handleChangePriceDilution(event.target.checked)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="price-switch"
                        >
                          {t('Fully Dilluted')}
                        </label>
                      </div>
                    </div>

                    {/* TARGET */}
                    <div className="nk-block">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            {t('WhoIsTheOfferAddressedTo')}
                            <small className="d-block fs-14px text-soft mt-1">
                              {t('IndicatesWhoReceivePurchaseOffer')}
                            </small>
                          </h5>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          {t('ThePurchaseOfferForEveryone')}
                        </label>
                        <div className="form-control-wrap">
                          <ul className="custom-control-group w-100 d-inline-flex">
                            <li className="col-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="offer-target"
                                  id="partners"
                                  value={tenderEligibleTypes.PARTNERS}
                                  checked={
                                    eligiblesType ===
                                    tenderEligibleTypes.PARTNERS
                                  }
                                  onChange={(e) => {
                                    setEligiblesType(e.target.value);
                                    handleChangeEligiblesSubgroup(
                                      e.target.value,
                                      eligiblesSubgroup
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="partners"
                                >
                                  {t('Partners')}
                                </label>
                              </div>
                            </li>
                            <li className="col-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="offer-target"
                                  id="benficiary-phantoms"
                                  value={
                                    tenderEligibleTypes.BENEFICIARIES_PHANTOM_SHARES
                                  }
                                  checked={
                                    eligiblesType ===
                                    tenderEligibleTypes.BENEFICIARIES_PHANTOM_SHARES
                                  }
                                  onChange={(e) => {
                                    setEligiblesType(e.target.value);
                                    handleChangeEligiblesSubgroup(
                                      e.target.value,
                                      eligiblesSubgroup
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="benficiary-phantoms"
                                >
                                  {t('Beneficiaries')} {t('Phantoms')}
                                </label>
                              </div>
                            </li>
                            <li className="col-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="offer-target"
                                  id="beneficiary-stock-options"
                                  value={
                                    tenderEligibleTypes.BENEFICIARIES_STOCK_OPTIONS
                                  }
                                  checked={
                                    eligiblesType ===
                                    tenderEligibleTypes.BENEFICIARIES_STOCK_OPTIONS
                                  }
                                  onChange={(e) => {
                                    setEligiblesType(e.target.value);
                                    handleChangeEligiblesSubgroup(
                                      e.target.value,
                                      eligiblesSubgroup
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="beneficiary-stock-options"
                                >
                                  {t('Beneficiaries')} {t('StockOptions')}
                                </label>
                              </div>
                            </li>
                            <li className="col-3">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="offer-target"
                                  id="all-members"
                                  value={tenderEligibleTypes.ALL_MEMBERS}
                                  checked={
                                    eligiblesType ===
                                    tenderEligibleTypes.ALL_MEMBERS
                                  }
                                  onChange={(e) => {
                                    setEligiblesType(e.target.value);
                                    handleChangeEligiblesSubgroup(
                                      e.target.value,
                                      eligiblesSubgroup
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="all-members"
                                >
                                  {t('All')}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>

                        {eligiblesType && (
                          <>
                            <div className="nk-block-head-content">
                              <br />
                              <label className="form-label">
                                {t('ForAll')}
                              </label>
                              <p>{t('TenderOfferForAllMessage')}</p>
                            </div>
                            <div className="form-control-wrap">
                              <ul className="custom-control-group  w-100 d-inline-flex">
                                <li className="col-4">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      name="eligibles-subgroup"
                                      id="eligibles-subgroup-all"
                                      value={eligiblesSubgroupTypes.ALL}
                                      checked={
                                        eligiblesSubgroup ===
                                        eligiblesSubgroupTypes.ALL
                                      }
                                      onChange={(e) =>
                                        handleChangeEligiblesSubgroup(
                                          eligiblesType,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="eligibles-subgroup-all"
                                    >
                                      {t('YesAll')}
                                    </label>
                                  </div>
                                </li>
                                <li className="col-4">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      name="eligibles-subgroup"
                                      id="eligibles-subgroup-some-groups"
                                      value={eligiblesSubgroupTypes.SOME_GROUPS}
                                      checked={
                                        eligiblesSubgroup ===
                                        eligiblesSubgroupTypes.SOME_GROUPS
                                      }
                                      onChange={(e) =>
                                        handleChangeEligiblesSubgroup(
                                          eligiblesType,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="eligibles-subgroup-some-groups"
                                    >
                                      {t('NoJustSomeGroups')}
                                    </label>
                                  </div>
                                </li>
                                <li className="col-4">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      name="eligibles-subgroup"
                                      id="eligibles-subgroup-some-members"
                                      value={
                                        eligiblesSubgroupTypes.SOME_MEMBERS
                                      }
                                      checked={
                                        eligiblesSubgroup ===
                                        eligiblesSubgroupTypes.SOME_MEMBERS
                                      }
                                      onChange={(e) =>
                                        handleChangeEligiblesSubgroup(
                                          eligiblesType,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="eligibles-subgroup-some-members"
                                    >
                                      {t('NoOnlySomePartnersOrBeneficiaries')}
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </div>

                      {[
                        eligiblesSubgroupTypes.SOME_GROUPS,
                        eligiblesSubgroupTypes.SOME_MEMBERS,
                      ].includes(eligiblesSubgroup) &&
                        eligibleOptions.length && (
                          <div className="card">
                            {/* @Marti - Añadir prefijo a todos los elementos del List Box */}
                            <ReactDualList
                              options={eligibleOptions}
                              icon
                              canFilter
                              setValue={setEligibles}
                            />
                          </div>
                        )}
                    </div>

                    {/* OTHER CONDITIONS */}
                    <div className="nk-block">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            {t('AnotherConditions')}
                          </h5>
                          <small className="d-block fs-14px text-soft mt-1">
                            {t('TenderOfferConditionsMessage')}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        <span>{t('CanSellAllSharesOrUnits')} </span>
                        <em className="icon ni ni-info text-gray" title="" />
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <ul className="custom-control-group w-100 d-inline-flex">
                        <li className="col-6">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              name="sell-all"
                              id="sell-all-yes"
                              value
                              checked={sellAll === true}
                              onChange={() => {
                                setSellAll(true);
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="sell-all-yes"
                            >
                              {t('YesCanSellEverything')}
                            </label>
                          </div>
                        </li>
                        <li className="col-6">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              name="sell-all"
                              id="sell-all-no"
                              value={false}
                              checked={sellAll === false}
                              onChange={() => {
                                setSellAll(false);
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="sell-all-no"
                            >
                              {t('No')}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>

                    {sellAll === false && (
                      <>
                        <div className="d-flex flex-column w-100">
                          {eligiblesType !==
                            tenderEligibleTypes.BENEFICIARIES_PHANTOM_SHARES &&
                            eligiblesType !==
                              tenderEligibleTypes.BENEFICIARIES_STOCK_OPTIONS && (
                              <div className="form-group d-flex flex-row flex-wrap align-items-end w-100 border-bottom mb-0 py-2">
                                <div className="col-4 pb-1">
                                  <span>{t('Shares')}</span>
                                </div>

                                <div className="col-4">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="sell-max-units"
                                    >
                                      {t('MaximumNumber')}
                                    </label>
                                    <NumberFormat
                                      name="sell-max-units"
                                      className="form-control"
                                      value={maxUnits}
                                      onChange={(e) =>
                                        setMaxUnits(e.target.value)
                                      }
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...numberFormat}
                                    />
                                  </div>
                                </div>

                                <div className="col-4">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="sell-percent-units"
                                    >
                                      {t('MaximumPercent')}
                                    </label>
                                    {/* <div className="form-control-wrap">
                                    <input
                                      id="sell-percent-units"
                                      type="number"
                                      className="form-control form-control-lg"
                                      placeholder="Valoración de la sociedad"
                                      value={maxPercentUnits}
                                      onChange={(e) =>
                                        setMaxPercentUnits(e.target.value)
                                      }
                                    />
                                  </div> */}
                                    <NumberFormat
                                      name="sell-percent-units"
                                      className="form-control"
                                      value={maxPercentUnits}
                                      onChange={(e) =>
                                        setMaxPercentUnits(e.target.value)
                                      }
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...numberFormatDecimals}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                          <div className="form-group d-flex flex-row flex-wrap align-items-end w-100 border-bottom mb-0 py-2">
                            <div className="col-4 pb-1">
                              <span>{t('PhantomShares')}</span>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="sell-max-phantoms"
                                >
                                  {t('MaximumNumber')}
                                </label>
                                {/* <div className="form-control-wrap">
                                  <input
                                    id="sell-max-phantoms"
                                    type="number"
                                    className="form-control form-control-lg"
                                    placeholder=""
                                    value={maxPhantoms}
                                    onChange={(e) =>
                                      setMaxPhantoms(e.target.value)
                                    }
                                  />
                                </div> */}
                                <NumberFormat
                                  name="sell-max-phantoms"
                                  className="form-control"
                                  value={maxPhantoms}
                                  onChange={(e) =>
                                    setMaxPhantoms(e.target.value)
                                  }
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...numberFormat}
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="sell-percent-phantoms"
                                >
                                  {t('MaximumPercent')}
                                </label>
                                {/* <div className="form-control-wrap">
                                  <input
                                    id="sell-percent-phantoms"
                                    type="number"
                                    className="form-control form-control-lg"
                                    placeholder=""
                                    value={maxPercentPhantoms}
                                    onChange={(e) =>
                                      setMaxPercentPhantoms(e.target.value)
                                    }
                                  />
                                </div> */}
                                <NumberFormat
                                  name="sell-percent-phantoms"
                                  className="form-control"
                                  value={maxPercentPhantoms}
                                  onChange={(e) =>
                                    setMaxPercentPhantoms(e.target.value)
                                  }
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...numberFormatDecimals}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group d-flex flex-row flex-wrap align-items-end w-100 border-bottom mb-0 py-2">
                            <div className="col-4 pb-1">
                              <span>{t('StockOptions')}</span>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="sell-max-stocks"
                                >
                                  {t('MaximumNumber')}
                                </label>
                                {/* <div className="form-control-wrap">
                                  <input
                                    id="sell-max-stocks"
                                    type="number"
                                    className="form-control form-control-lg"
                                    placeholder=""
                                    value={maxStocks}
                                    onChange={(e) =>
                                      setMaxStocks(e.target.value)
                                    }
                                  />
                                </div> */}
                                <NumberFormat
                                  name="sell-max-stocks"
                                  className="form-control"
                                  value={maxStocks}
                                  onChange={(e) => setMaxStocks(e.target.value)}
                                  {...numberFormat}
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="sell-percent-stocks"
                                >
                                  {t('MaximumPercent')}
                                </label>
                                <NumberFormat
                                  name="sell-max-stocks"
                                  className="form-control"
                                  value={maxPercentStocks}
                                  onChange={(e) =>
                                    setMaxPercentStocks(e.target.value)
                                  }
                                  {...numberFormatDecimals}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group d-flex flex-row flex-wrap align-items-end w-100 border-bottom mb-2 py-2">
                            <div className="col-4 pb-1">
                              <span>{t('OtherConditions')}</span>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="sell-max-partners"
                                >
                                  {t('MaximumAmountPerPartnerBeneficiary')}
                                </label>
                                {/* <div className="form-control-wrap">
                                  <input
                                    id="sell-max-partners"
                                    type="number"
                                    className="form-control form-control-lg"
                                    placeholder="Valoración de la sociedad"
                                    value={maxAmount}
                                    onChange={(e) =>
                                      setMaxAmount(e.target.value)
                                    }
                                  />
                                </div> */}
                                <NumberFormat
                                  name="sell-max-partners"
                                  className="form-control"
                                  value={maxAmount}
                                  onChange={(e) => setMaxAmount(e.target.value)}
                                  {...numberFormat}
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="sell-percent-unvested"
                                >
                                  {t('MaximumPercentageVestedUnitsCanSold')}
                                </label>
                                {/* <div className="form-control-wrap">
                                  <input
                                    id="sell-percent-unvested"
                                    type="number"
                                    className="form-control form-control-lg"
                                    placeholder="Valoración de la sociedad "
                                    value={maxPercentUnvested}
                                    onChange={(e) =>
                                      setMaxPercentUnvested(e.target.value)
                                    }
                                  />
                                </div> */}
                                <NumberFormat
                                  name="sell-max-partners"
                                  className="form-control"
                                  value={maxPercentUnvested}
                                  onChange={(e) =>
                                    setMaxPercentUnvested(e.target.value)
                                  }
                                  {...numberFormatDecimals}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* APPLY RETENTION CHECKBOX */}
                    <div className="col-12 d-flex align-items-end">
                      <div className="form-group col-6 mt-2 mb-1">
                        <div className="custom-control custom-control-xs custom-checkbox">
                          <input
                            type="checkbox"
                            id="has-retention-percentage"
                            className="custom-control-input float-right"
                            checked={hasRetentionPercent === true}
                            onChange={(event) =>
                              handleChangeHasRetentionPercent(
                                event.target.checked
                              )
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="has-retention-percentage"
                          >
                            {t('ApplyRetention')}
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="cp1-project-deadline"
                          >
                            {t('RetentionPercentage')}
                          </label>
                          <NumberFormat
                            name="retention-percentage"
                            className="form-control"
                            value={retentionPercent}
                            disabled={!hasRetentionPercent}
                            onChange={(e) => {
                              handleChangeRetentionPercent(e.target.value);
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...numberFormatDecimals}
                            decimalScale={2}
                          />
                        </div>
                      </div>
                    </div>

                    {/* TARGET PLAN FOR UNITS */}
                    <div className="nk-block">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            {t('WhereWillThePurchasedUnitsGo')}
                            <small className="d-block fs-14px text-soft mt-1">
                              {t('WhereWillThePurchasedUnitsGoMessage')}
                            </small>
                          </h5>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form-label" htmlFor="default-06">
                          {t('SelectThePlan')}
                        </label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <select
                              className="form-control form-control-lg"
                              id="beneficiary-plan"
                              name="beneficiary-plan"
                              value={targetPlan?._id}
                              onChange={handleChangeTargetPlan}
                            >
                              <option value="" default>
                                {t('Select')}
                              </option>
                              {plans?.length > 0 &&
                                plans
                                  ?.filter((plan) => !plan?.isDraft)
                                  ?.map((plan) => (
                                    <option
                                      value={plan['_id']}
                                      key={plan['_id']}
                                    >
                                      {plan.name}
                                    </option>
                                  ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* PAYMENT CONDITIONS */}
                    <div className="nk-block">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            {t('PaymentConditions')}
                            <small
                              className="d-block fs-14px text-soft mt-1"
                              style={{ lineHeight: '18px' }}
                            >
                              {t('TenderOfferPaymentMessage')}
                            </small>
                          </h5>
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <div className="form-editor-custom">
                                <textarea
                                  className="form-control form-control-lg no-resize"
                                  placeholder={t('WriteHere')}
                                  value={paymentConditions}
                                  onChange={(e) =>
                                    setPaymentConditions(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* EXTRA */}
                    <div className="nk-block col-12">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            {t('Comments')}
                            <small
                              className="d-block fs-14px text-soft mt-1"
                              style={{ lineHeight: '18px' }}
                            >
                              {t('TenderOfferCommentsMessage')}
                            </small>
                          </h5>
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <div className="form-editor-custom">
                                <textarea
                                  className="form-control form-control-lg no-resize"
                                  placeholder={t('WriteHere')}
                                  value={details}
                                  onChange={(e) => setDetails(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-label" htmlFor="default-06">
                              {t('AddDetailsOfThePlanThisDo')}
                            </label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <select
                                  className="form-control form-control-lg"
                                  id="beneficiary-plan"
                                  name="beneficiary-plan"
                                  value={template?.['_id']}
                                  onChange={handleChangeTemplate}
                                >
                                  <option value="" default>
                                    {t('Select')}
                                  </option>
                                  {templates?.length > 0 &&
                                    templates.map((template) => (
                                      <option
                                        value={template['_id']}
                                        key={template['_id']}
                                      >
                                        {template.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isFormValid()}
                      >
                        {t('Save')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AddTenderOffer;
