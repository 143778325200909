/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import NumberFormat from 'react-number-format';

import hasShowDetails from 'utils/showDetails';

import { numberFormat, numberFormatDecimals } from 'constants/formats';

import PendingInvitationLetters from 'modules/beneficiaries/components/PendingInvitationLetters';

type SummaryRow = {
  sharesTotal: number;
  assignedShares: number;
};

type BeneficiaryPlanMetricsCardProps = {
  data: SummaryRow;
  percent: number;
  invitations: any;
};

const BeneficiaryPlanMetricsCard: FC<BeneficiaryPlanMetricsCardProps> = ({
  data,
  percent,
  invitations,
}) => {
  const { t } = useTranslate();
  const { sharesTotal, assignedShares } = data;

  return (
    <div className="card card-bordered card-stretch col-12 col-lg-8 mr-0 mr-lg-2 mb-4 mb-lg-0">
      <div className="card-inner">
        <h6 className="overline-title-alt mb-2">
          <em className="icon ni ni-layers mr-2  fs-20px" />
          {t('UnitsSummary')}
        </h6>
        <div className="row text-center">
          <div className="col-3">
            <div className="analytic-ov">
              <div className="analytic-data-group analytic-ov-group w-100">
                <div className="analytic-data analytic-ov-data w-100 text-left">
                  <div className="title">{t('UnitsTotal')}</div>
                  <div className="amount">
                    <NumberFormat
                      value={sharesTotal || 0}
                      displayType="text"
                      {...numberFormat}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="analytic-ov">
              <div className="analytic-data-group analytic-ov-group w-100">
                <div className="analytic-data analytic-ov-data w-100 text-left">
                  <div className="title">{t('UnitsAssigned')}</div>
                  <div className="amount">
                    <NumberFormat
                      value={assignedShares || 0}
                      displayType="text"
                      {...numberFormat}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="analytic-ov">
              <div className="analytic-data-group analytic-ov-group w-100">
                <div className="analytic-data analytic-ov-data w-100 text-left">
                  <div className="title">{t('UnitsPending')}</div>
                  <div className="amount">
                    <NumberFormat
                      value={sharesTotal - assignedShares || 0}
                      displayType="text"
                      {...numberFormat}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="analytic-ov">
              <div className="analytic-data-group analytic-ov-group w-100">
                <div className="analytic-data analytic-ov-data w-100 text-left">
                  <div className="title">{t('PercentagePool')}</div>
                  <div className="amount">
                    <NumberFormat
                      value={percent || 0}
                      displayType="text"
                      {...numberFormatDecimals}
                    />
                    %
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* TEMPORAL POSITION OF PENDING SIGNATURES WARNING */}
          {hasShowDetails() && (
            <PendingInvitationLetters pendingInvitations={invitations} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryPlanMetricsCard;
