import { translate } from 'hooks/useTranslate';
import { boardDelegationRoles } from 'modules/boards/constants/boardDelegation';
import { getBoardRepresentants } from 'modules/boards/utils';
import { Board, Participant, PartnerRepresentant } from 'types';

type Option = {
  role: string;
  value: string;
  label: string;
  disabled: boolean;
};

type SelectedPartnerOptions = {
  label: string;
  options: Option[];
};

type GetRepresentativeSelectorOptions = {
  currentParticipant: Participant;
  representatives: Participant[];
  partnerRepresentants: PartnerRepresentant[];
  board: Board | undefined;
};

export const getRepresentativeSelectorOptions = ({
  currentParticipant,
  representatives,
  partnerRepresentants,
  board,
}: GetRepresentativeSelectorOptions): SelectedPartnerOptions[] => {
  const partnerOptions = representatives
    .filter(
      (partner) =>
        partner.member !== currentParticipant?.member && !partner?.isRepresented
    )
    .map((partner) => ({
      role: boardDelegationRoles.PARTNER,
      value: partner?.member,
      label: `${partner?.cif} | ${partner?.name}`,
      disabled: false,
    }));

  const representants = getBoardRepresentants(board, partnerRepresentants);

  const representantOptions = representants.map((representant) => ({
    role: boardDelegationRoles.PARTNER_REPRESENTANT,
    value: representant['_id'],
    label: `${representant?.cif} | ${representant?.name}`,
    disabled: false,
  }));

  return [
    { label: translate('PartnerRepresentants'), options: representantOptions },
    { label: translate('Partners'), options: partnerOptions },
  ];
};
