import { Participant } from 'types';

/**
 * Checks if the representative as participant has voted.
 * @param participant - The participant to check.
 * @param participants - An array of all participants.
 * @returns A boolean indicating whether the participant is an assistant.
 */
export const isAssistant = (
  participant: Participant,
  participants: Participant[] = []
): boolean => {
  if (participant?.representative) {
    const representativeAsParticipant = participants.find(
      (p) => p.member === participant?.representative
    );

    if (representativeAsParticipant) {
      return representativeAsParticipant?.votes?.length > 0;
    }
  }

  return false;
};
