import { useTranslate } from 'hooks/useTranslate';
import GenerateDocument from 'modules/_shared/components/Modals/GenerateDocument';
import { setModal } from 'modules/_shared/redux/modalActions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import templateNew from 'assets/images/template-new.svg';
import templateUse from 'assets/images/template-use.svg';

const TemplateHeader = ({
  openTemplateCreator,
  setOpenTemplateCreator,
  setDocumentSelected,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const [optionSelected, setOptionSelected] = React.useState('');

  return (
    optionSelected !== 'NEW' &&
    !openTemplateCreator && (
      <div className="nk-files nk-files-view-grid mb-4">
        <div className="nk-files-list">
          <div
            className="nk-file-item nk-file cursor-pointer"
            onClick={() => {
              if (!isDemo) {
                setOpenTemplateCreator(false);
                setDocumentSelected(null);
                setOptionSelected('GENERATE');
                dispatch(setModal(<GenerateDocument outputPreview />));
              }
            }}
          >
            <div className="nk-file-info">
              <div href="#" className="nk-file-link">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <span className="nk-file-icon-type">
                      <img src={templateUse} alt="Use a template" />
                    </span>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <span className="title">{t('UseATemplate')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="nk-file-item nk-file cursor-pointer"
            onClick={() => {
              if (!isDemo) {
                setOpenTemplateCreator(true);
                setDocumentSelected(null);
                setOptionSelected('NEW');
              }
            }}
          >
            <div className="nk-file-info">
              <div className="nk-file-link">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <span className="nk-file-icon-type">
                      <img src={templateNew} alt="New template" />
                    </span>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <span className="title">{t('NewTemplate')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="nk-file-item nk-file">
            <div className="nk-file-info">
              <div href="#" className="nk-file-link">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <span className="nk-file-icon-type">
                      <img src={templateDup} alt="Duplicate a template" />
                    </span>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <span className="title">
                        Nueva plantilla duplicando otra
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="nk-file-item nk-file">
            <div className="nk-file-info">
              <div href="#" className="nk-file-link">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <span className="nk-file-icon-type">
                      <img src={templateFil} alt="Fill a template" />
                    </span>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <span className="title">
                        Rellenar una plantilla con datos
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    )
  );
};

export default TemplateHeader;
