import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';

type Props = {
  documentName: string;
  fullScreen: boolean;
  handleToggleFullScreen: () => void;
  handleUpdate: () => void;
  handleSave: () => void;
  editorModel?: string;
};

const TemplateCreatorHeader: FC<Props> = ({
  documentName,
  fullScreen,
  handleToggleFullScreen,
  handleUpdate,
  handleSave,
  editorModel,
}) => {
  const { t } = useTranslate();

  const isDemo = useSelector((state: any) => state.society?.role?.isDemo);

  const fullScreenIcon = fullScreen ? 'ni ni-minimize' : 'ni ni-maximize';

  return (
    <div className="nk-block-head nk-block-head-sm">
      <div className="nk-block-between position-relative">
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">
            {editorModel ? t('EditTemplate') : t('NewTemplate')}
          </h3>
          {editorModel && (
            <div className="nk-block-des text-soft">
              <p>{documentName}</p>
            </div>
          )}
        </div>

        <div className="nk-block-head-content">
          <ul className="nk-block-tools g-3">
            <li>
              <button
                type="button"
                className="btn btn-outline-primary bg-transparent px-1"
                onClick={handleToggleFullScreen}
              >
                <em className={`icon ni ${fullScreenIcon}`} />
              </button>
            </li>
            {editorModel ? (
              <li>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpdate}
                  disabled={isDemo}
                >
                  <em className="icon ni ni-save mr-2" />
                  {t('ToUpdate')}
                </button>
              </li>
            ) : (
              <li>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                  disabled={isDemo}
                >
                  <em className="icon ni ni-save mr-2" />
                  {t('ToSave')}
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TemplateCreatorHeader;
