import { useTranslate } from 'hooks/useTranslate';

const SocietyConfigurationHeader = () => {
  const { t } = useTranslate();

  return (
    <div className="nk-block-head-content">
      <h5 className="nk-block-title">
        {t('Customization')}
        <span className="badge badge-outline badge-pill text-primary ucap ml-2">
          {t('BETA')}
        </span>
      </h5>

      <div className="nk-block-des">
        <p>{t('CustomizationSubheader')}</p>
      </div>
    </div>
  );
};

export default SocietyConfigurationHeader;
