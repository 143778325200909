/* eslint-disable react/jsx-curly-newline */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';

import { getActualSociety } from 'utils/filters';
import { formatNumber } from 'utils/formats';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import HoldingClassRow from 'modules/partners/components/HoldingClassRow';

import menuOptions from './menuOptions';

function HoldingClassList() {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const user = useSelector((state) => state?.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const holdingClasses = useSelector((state) => state?.holdingClasses);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId && user)
      getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  const sharesNumber = (shares) => {
    if (!shares) return;

    const sharesNumberValue = shares.reduce((acc, share) => {
      if (share.isActive) {
        const holdingClass = share?.shareClass;
        const shareValue = share?.to - share?.from + 1;
        if (acc[holdingClass?.['_id']]) {
          acc[holdingClass?.['_id']] += shareValue;
        } else {
          acc[holdingClass?.['_id']] = shareValue;
        }

        return acc;
      }
      return acc;
    }, {});
    return sharesNumberValue;
  };

  const numerationValues = (shares) => {
    if (!shares) return;

    const sortedShares = shares.sort((a, b) => a.from - b.from);
    const numerationValues = sortedShares.reduce((acc, share) => {
      if (share.isActive) {
        const holdingClass = share?.shareClass;
        const currentValues = acc[holdingClass?.['_id']];
        if (currentValues) {
          if (share?.from - currentValues[currentValues.length - 1] === 1) {
            currentValues[currentValues.length - 1] = share?.to;
          } else {
            currentValues.push(share?.from);
            currentValues.push(share?.to);
          }
        } else {
          acc[holdingClass?.['_id']] = [share?.from, share?.to];
        }
        return acc;
      }
      return acc;
    }, {});
    return numerationValues;
  };

  const numerationIntervals = (values) => {
    if (!values) return;

    const result = [];
    for (let i = 0; i < values?.length - 1; i += 2) {
      result.push(
        `${formatNumber(values[i])} - ${formatNumber(values[i + 1])}`
      );
    }
    return result;
  };

  useEffect(() => {
    if (actualSociety) {
      setRows(
        holdingClasses?.map((holdingClass, index) => (
          <HoldingClassRow
            key={`class-${holdingClass?.['_id']}`}
            index={index + 1}
            societyId={actualSociety?.['_id']}
            nominalValue={
              holdingClass?.nominalValue || actualSociety?.nominalValue
            }
            sharesNumber={formatNumber(
              sharesNumber(actualSociety?.shares)[holdingClass?.['_id']] || 0
            )}
            numerationValues={
              numerationIntervals(
                numerationValues(actualSociety?.shares)[holdingClass?.['_id']]
              ) || ''
            }
            holdingClass={holdingClass}
            isAdmin={isAdmin}
          />
        ))
      );
    }
  }, [holdingClasses, actualSociety]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getHoldingClasses(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
            <p />
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('ParticipationClasses')}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`${actualSociety?.name} ${t('HaveHoldingClasses', {
                      count: rows?.length,
                    })}`}
                  </p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <li>
                          <OperationMenuDots
                            title="Añadir Clase"
                            menuOptions={menuOptions}
                            handleModal={(Modal) =>
                              dispatch(
                                setModal(
                                  <Modal
                                    user={user}
                                    societyId={actualSociety?.['_id']}
                                  />
                                )
                              )
                            }
                            disabled={isDemo}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {rows.length ? (
            <div className="nk-block">
              <div className="card card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner position-relative card-tools-toggle">
                    <div className="card-title-group">
                      <div className="card-tools" />
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar gx-1">
                          <li>
                            <a
                              className="btn btn-icon search-toggle toggle-search"
                              data-target="search"
                              aria-label="link"
                            >
                              <em className="icon ni ni-search" />
                            </a>
                          </li>
                          <li className="btn-toolbar-sep" />
                          <li>
                            <div className="toggle-wrap">
                              <a
                                className="btn btn-icon btn-trigger toggle"
                                data-target="cardTools"
                                aria-label="link"
                              >
                                <em className="icon ni ni-menu-right" />
                              </a>
                              <div
                                className="toggle-content"
                                data-content="cardTools"
                              >
                                <ul className="btn-toolbar gx-1">
                                  <li className="toggle-close">
                                    <a
                                      className="btn btn-icon btn-trigger toggle"
                                      data-target="cardTools"
                                      aria-label="link"
                                    >
                                      <em className="icon ni ni-arrow-left" />
                                    </a>
                                  </li>
                                  <li />
                                  <li>
                                    <div className="dropdown">
                                      <a
                                        className="btn btn-trigger btn-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-label="link"
                                      >
                                        <em className="icon ni ni-setting" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                        <ul className="link-check">
                                          <li>
                                            <span>{t('Show')}</span>
                                          </li>
                                          <li className="active">
                                            <a aria-label="link">10</a>
                                          </li>
                                          <li>
                                            <a aria-label="link">20</a>
                                          </li>
                                          <li>
                                            <a aria-label="link">50</a>
                                          </li>
                                        </ul>
                                        <ul className="link-check">
                                          <li>
                                            <span>{t('Sort')}</span>
                                          </li>
                                          <li className="active">
                                            <a aria-label="link">
                                              {t('Descendant')}
                                            </a>
                                          </li>
                                          <li>
                                            <a aria-label="link">
                                              {t('Ascendant')}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="card-search search-wrap"
                      data-search="search"
                    >
                      <div className="card-body">
                        <div className="search-content">
                          <a
                            className="search-back btn btn-icon toggle-search"
                            data-target="search"
                            aria-label="link"
                          >
                            <em className="icon ni ni-arrow-left" />
                          </a>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder={t('SearchByNameOrEmail')}
                          />
                          <button
                            type="button"
                            className="search-submit btn btn-icon"
                            aria-label="link"
                          >
                            <em className="icon ni ni-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <table
                      className="datatable-init nk-tb-list nk-tb-ulist"
                      data-auto-responsive="false"
                    >
                      <thead>
                        <tr className="nk-tb-item nk-tb-head text-center">
                          <th className="nk-tb-col">
                            <span className="sub-text">{t('Class')}</span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">
                              {t('NominalValue')}
                            </span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">
                              {t('SharesNumber')}
                            </span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">
                              {t('NumerationOfShares')}
                            </span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">
                              {t('VotesForParticipation')}
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">{t('Numeration')}</span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">
                              {t('PreferredLiquidation')}
                            </span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">
                              {t('Antidilution')}
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">
                              {t('LockupPeriod')}
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">
                              {t('ConversionToOrdinary')}
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">
                              {t('ConversionRate')}
                            </span>
                          </th>
                          {!isDemo && (
                            <th className="nk-tb-col">
                              <span className="sub-text" />
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default HoldingClassList;
