import { translate } from 'hooks/useTranslate';
import { Participant } from 'types';

export const getParticipantAttendance = (participant: Participant): string => {
  if (participant.isRepresented) {
    return translate('Represented');
  }

  if (participant.assists) {
    return translate('Yes');
  }

  if (participant.isAbsent) {
    return translate('Nope');
  }

  return '';
};
