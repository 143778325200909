/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';

import beneficiaryStatus from 'constants/beneficiaryStatus';
import { numberFormat, numberFormatDecimals } from 'constants/formats';
import invitationStatus from 'constants/invitationStatus';
import menuTypes from 'constants/menuTypes';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import BeneficiaryRow from 'modules/beneficiaries/components/BeneficiaryRow';

import {
  getActualSociety,
  getBeneficiaryAmount,
  getBeneficiaryFDPercent,
  getDecimalScale,
  getSocietyValue,
  getVestedUnits,
} from 'utils/filters';
import isSuperAdmin from 'utils/isSuperAdmin';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';
import PendingInvitationLetters from 'modules/beneficiaries/components/PendingInvitationLetters';
import DeletePlanBeneficiaries from 'modules/beneficiaries/modals/DeletePlanBeneficiaries';
import DownloadBeneficiary from 'modules/beneficiaries/modals/DownloadBeneficiary';
import UploadBeneficiary from 'modules/beneficiaries/modals/UploadBeneficiary';

import { useTranslate } from 'hooks/useTranslate';
import { calculateConsolidationPercent } from 'utils/consolidationPeriods';
import hasPlanCustomDilution from 'utils/customDilution';
import useGetBeneficiaryOptions from './hooks/useGetBeneficiaryOptions';
import useGetPlan from './hooks/useGetPlan';
import menuOptions from './menuOptions';
import tableColumns from './tableColumns';

const showDetails = JSON.parse(localStorage.getItem('showDetails'));

function BeneficiaryList() {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();
  const { societyId, planId } = useParams();

  const user = useSelector((state) => state.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const plans = useSelector((state) => state.plans);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [societyShares, setSocietyShares] = useState();
  const [rows, setRows] = useState([]);
  const [summaryRow, setSummaryRow] = useState([]);
  const [percentVestedUnits, setPercentVestedUnits] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const [pendingInvitations, setPendingInvitations] = useState([]);

  const [societyValue] = useState(getSocietyValue(actualSociety));

  const { currentBeneficiaryOptions } = useGetBeneficiaryOptions(user, i18n);

  const { currentPlan } = useGetPlan(planId, plans);

  const getPlanName = (planId) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    return plan ? plan.name : '-';
  };

  const getIncentive = (planId) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    return plan ? operationTypesRealNames[plan.planType] : '-';
  };

  const getUnits = (beneficiary) => {
    const units = beneficiary?.isCanceled
      ? beneficiary?.finalConsolidatedUnits
      : beneficiary?.sharesCount?.future;
    return units;
  };

  const getMonthsDiff = (d1, d2) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  const getConsolidationDate = (beneficiary, planId) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    const endDate = new Date(beneficiary?.planStartDate);

    if (plan?.vestingPeriod) {
      endDate.setMonth(endDate.getMonth() + Number(plan?.vestingPeriod));
    }
    return endDate;
  };

  const getConsolidatedPercent = (beneficiary, planId, date = Date.now()) => {
    let percent;
    const plan = plans?.find((plan) => plan['_id'] === planId);

    if (beneficiary?.isCanceled)
      percent = Number(
        (
          (beneficiary.finalConsolidatedUnits * 100) /
          beneficiary.sharesCount.future
        ).toFixed(2)
      );
    else percent = calculateConsolidationPercent(date, beneficiary, plan);

    return percent;
  };

  const getToVest = (beneficiary, plan, date = Date.now()) => {
    let toVest;
    if (beneficiary?.isCanceled) toVest = 0;
    else {
      const vested = getVestedUnits(beneficiary, plan, date);
      toVest = beneficiary?.sharesCount?.future - vested;
    }
    return toVest;
  };

  const getMonthsToVest = (beneficiary, planId, date = Date.now()) => {
    const finalDate = getConsolidationDate(beneficiary, planId);
    const current = date;
    if (beneficiary?.isCanceled || new Date(finalDate) < new Date(current))
      return 0;
    const months = getMonthsDiff(new Date(current), new Date(finalDate));
    return months;
  };

  const getStrikePrice = (beneficiary, planId) => {
    const conditions = beneficiary?.customConditions;
    if (conditions && conditions?.sharePrice) {
      return conditions?.isFixedPrice
        ? `${conditions?.sharePrice} (fijo)`
        : conditions?.sharePrice;
    }
    const plan = plans?.find((plan) => plan['_id'] === planId);
    return plan?.isFixedPrice ? `${plan?.sharePrice} (fijo)` : plan?.sharePrice;
  };

  const getCertificateData = (beneficiary, planId) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);

    const beneficiaryData = {
      ...beneficiary,
      sharesCount: beneficiary.sharesCount,
      amount: getBeneficiaryAmount(beneficiary, plan),
      cif: beneficiary.cif,
    };
    const planData = {
      sharePrice: plan?.sharePrice,
      startDate: plan?.startDate,
      planId,
    };
    const societyData = {
      name: actualSociety?.name,
      cif: actualSociety?.cif,
      administrator:
        actualSociety?.mainAdmin || actualSociety?.administrators?.[0],
      societyId,
    };

    return { societyData, beneficiaryData, planData };
  };

  const getStatus = (beneficiary, planId, date = Date.now()) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    let percent;
    if (!beneficiary?.isCanceled) {
      percent = calculateConsolidationPercent(date, beneficiary, plan);
    }
    let currentStatus;
    if (beneficiary?.isCanceled) {
      if (beneficiary?.cancelationOption === 'CONSOLIDATED') {
        currentStatus = beneficiaryStatus.var.CONSOLIDATED;
      } else {
        currentStatus = beneficiaryStatus.var.CANCELED;
      }
    } else if (percent >= 0 && percent < 100)
      currentStatus = beneficiaryStatus.var.ACTIVE;
    else currentStatus = beneficiaryStatus.var.CONSOLIDATED;
    return currentStatus;
  };

  const getStatusName = (beneficiary, planId, date = Date.now()) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    let percent;
    if (!beneficiary?.isCanceled) {
      percent = calculateConsolidationPercent(date, beneficiary, plan);
    }
    let currentStatus;
    if (beneficiary?.isCanceled)
      currentStatus = beneficiaryStatus.name.CANCELED;
    else if (percent >= 0 && percent < 100)
      currentStatus = beneficiaryStatus.name.ACTIVE;
    else currentStatus = beneficiaryStatus.name.CONSOLIDATED;
    return currentStatus;
  };

  const filterOptions = (beneficiary) => {
    const options = currentBeneficiaryOptions.map((option) => ({ ...option }));
    options[0].url = `/detalle-beneficiario/${societyId}/${beneficiary?.plan}`;
    if (beneficiary?.isCanceled) options[6].text = t('ChangeCancelation');
    if (!isAdmin) {
      if (user['_id'] !== beneficiary.user) options[1].disabled = true;
      options[2].disabled = true;
      options[3].disabled = true;
      options[5].disabled = true;
    }
    return options;
  };

  const getIncentiveValue = (FD) =>
    societyValue ? (+FD * societyValue.value) / 100 : 0;

  const handleDeleteBeneficiaries = () => {
    dispatch(
      setModal(
        <DeletePlanBeneficiaries societyId={societyId} plan={currentPlan} />
      )
    );
  };

  useEffect(() => {
    dispatch(getStockPlans(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety && plans?.length) {
      const totalShares =
        actualSociety.sharesCount?.actual +
        actualSociety.sharesCount?.future +
        actualSociety?.sharesCount?.drafts;

      setSocietyShares(totalShares);
      const societyValue = getSocietyValue(actualSociety);
      const decimalScale = societyValue
        ? getDecimalScale(societyValue.value)
        : 2;

      const filteredBeneficiaries = planId
        ? actualSociety?.beneficiaries?.filter((beneficiary) =>
            !isAdmin
              ? user['_id'] === beneficiary?.user
              : (beneficiary?.isDraft ?? false) ===
                (currentPlan?.isDraft ?? false)
          )
        : actualSociety?.beneficiaries?.filter((beneficiary) =>
            !isAdmin ? user['_id'] === beneficiary?.user : !beneficiary?.isDraft
          );

      const filteredBeneficiariesByPlan = filteredBeneficiaries?.filter(
        (beneficiary) => beneficiary?.plan === planId
      );

      const beneficiariesTable = planId
        ? filteredBeneficiariesByPlan
        : filteredBeneficiaries;

      const beneficiariesPending = beneficiariesTable
        ?.filter(
          (beneficiary) =>
            beneficiary.isDraft === false &&
            beneficiary?.invitationStatus === 'PENDING'
        )
        .map((beneficiary) => ({
          ...beneficiary,
          planName: plans.find((plan) => plan['_id'] === beneficiary.plan)
            ?.name,
        }));

      setPendingInvitations(beneficiariesPending);
      // TODO Remove filter when beneficiary book is ok
      setRows(
        beneficiariesTable?.map((beneficiary, index) => {
          const plan = plans?.find((plan) => plan['_id'] === beneficiary?.plan);
          return (
            <BeneficiaryRow
              beneficiary={beneficiary}
              email={beneficiary?.email}
              sharesCount={beneficiary?.sharesCount}
              society={actualSociety}
              societyId={actualSociety['_id']}
              societyShares={totalShares}
              decimals={decimalScale}
              amount={getBeneficiaryAmount(beneficiary, plan)}
              index={index + 1}
              key={beneficiary['_id']}
              planId={beneficiary?.plan}
              userId={user['_id']}
              documentURL={beneficiary?.documentURL}
              invitationStatus={beneficiary?.invitationStatus}
              user={user}
              isSuperAdmin={isSuperAdmin(user)}
            />
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualSociety,
    societyShares,
    user,
    societyId,
    plans,
    planId,
    i18n.language,
  ]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    const newData = rows.map(({ props }) => {
      const plan = plans?.find(
        (plan) => plan['_id'] === props.beneficiary?.plan
      );
      return {
        beneficiary: props.beneficiary,
        name: props.beneficiary?.name,
        email: props.beneficiary?.email,
        cif: props.beneficiary?.cif || '-',
        status: getStatus(props.beneficiary, props.beneficiary?.plan),
        statusName: getStatusName(props.beneficiary, props.beneficiary?.plan),
        planId: props.beneficiary?.plan,
        societyId: actualSociety['_id'],
        units: getUnits(props.beneficiary),
        decimals: props.decimals,
        amount: props.amount,
        FDpercent: getBeneficiaryFDPercent(props.beneficiary, props.society),
        jobTitle: props.beneficiary?.jobTitle || '-',
        incentive: getIncentive(props.beneficiary?.plan),
        planName: getPlanName(props.beneficiary?.plan),
        consolidatedPercent: getConsolidatedPercent(
          props.beneficiary,
          props.beneficiary?.plan
        ),
        consolidationDate: getConsolidationDate(
          props.beneficiary,
          props.beneficiary?.plan
        ),
        planStartDate: new Date(props.beneficiary?.planStartDate),
        vestedUnits: getVestedUnits(props.beneficiary, plan),
        toVestUnits: getToVest(props.beneficiary, plan),
        months: getMonthsToVest(props.beneficiary, props.beneficiary?.plan),
        salary: props.beneficiary?.salary || '-',
        laboralCategory: props.beneficiary?.laboralCategory?.name || '-',
        strikePrice: getStrikePrice(props.beneficiary, props.beneficiary?.plan),
        certificateData: getCertificateData(
          props.beneficiary,
          props.beneficiary?.plan
        ),
        documentURL: props.beneficiary?.documentURL,
        beneficiaryOptions: filterOptions(props.beneficiary),
        invitationStatus: invitationStatus[props.beneficiary?.invitationStatus],
        user,
        isSuperAdmin: isSuperAdmin(user),
        isDemo,
      };
    });

    if (newData.length > 0) {
      setTableData(
        transformData({
          data: newData,
          columns: tableColumns(i18n),
        })
      );
      setRawData(newData);
    }

    setSummaryRow(
      newData.reduce(
        (acc, curr) => ({
          units: acc.units + curr.units,
          vestedUnits: acc.vestedUnits + curr.vestedUnits,
          incentiveValue:
            acc.incentiveValue + getIncentiveValue(curr.FDpercent),
        }),
        {
          units: 0,
          vestedUnits: 0,
          incentiveValue: 0,
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    setPercentVestedUnits(
      ((summaryRow.vestedUnits / summaryRow.units) * 100).toFixed(2)
    );
  }, [summaryRow]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
    setCurrentMenuOptions(
      menuOptions(i18n).map((option, index) => ({
        ...option,
        disabled: index === 1 && hasPlanCustomDilution(plans),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, plans]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
            <p />
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('Beneficiaries')}
                  {planId && (
                    <>
                      &nbsp;/&nbsp;
                      <strong className="text-primary small">
                        {getPlanName(planId) || ''}
                      </strong>
                    </>
                  )}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`${planId ? getPlanName(planId) : actualSociety?.name} ${t(
                      'HaveBeneficiaries',
                      {
                        count: rows.length,
                      }
                    )}`}
                  </p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isSuperAdmin(user) && currentPlan && (
                        <li>
                          <button
                            type="button"
                            className="btn btn-danger "
                            onClick={handleDeleteBeneficiaries}
                          >
                            <span>{t('DeleteBeneficiaries')}</span>
                          </button>
                        </li>
                      )}
                      {isAdmin && (
                        <>
                          <li>
                            <button
                              type="button"
                              className="btn btn-white btn-outline-light"
                              onClick={() =>
                                dispatch(
                                  setModal(
                                    <UploadBeneficiary
                                      user={user}
                                      societyId={actualSociety['_id']}
                                    />
                                  )
                                )
                              }
                              disabled={isDemo}
                            >
                              <em className="icon ni ni-upload-cloud" />
                              <span>{t('UploadListing')}</span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="btn btn-white btn-outline-light"
                              onClick={() =>
                                dispatch(
                                  setModal(
                                    <DownloadBeneficiary
                                      user={user}
                                      societyId={actualSociety['_id']}
                                      actualSociety={actualSociety}
                                      plans={plans}
                                      tableData={rawData}
                                      getStatus={getStatus}
                                      getStatusName={getStatusName}
                                      getUnits={getUnits}
                                      getBeneficiaryFDPercent={
                                        getBeneficiaryFDPercent
                                      }
                                      getIncentive={getIncentive}
                                      getPlanName={getPlanName}
                                      getToVest={getToVest}
                                      getMonthsToVest={getMonthsToVest}
                                      getStrikePrice={getStrikePrice}
                                      getCertificateData={getCertificateData}
                                      getConsolidatedPercent={
                                        getConsolidatedPercent
                                      }
                                      getConsolidationDate={
                                        getConsolidationDate
                                      }
                                    />
                                  )
                                )
                              }
                            >
                              <em className="icon ni ni-download-cloud" />
                              <span>{t('DownloadListing')}</span>
                            </button>
                          </li>
                        </>
                      )}
                      {isAdmin && (
                        <li>
                          <OperationMenuDots
                            menuOptions={currentMenuOptions}
                            handleModal={(Modal) =>
                              dispatch(
                                setModal(
                                  <Modal
                                    user={user}
                                    societyId={actualSociety['_id']}
                                  />
                                )
                              )
                            }
                            disabled={isDemo}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {tableData.length > 0 && tableData.length === rows.length && (
            <>
              <div className="nk-block d-flex flex-column flex-lg-row justify-content-around mx-0 mx-lg-2 mb-4">
                <div className="card card-bordered card-stretch col-12 col-lg-8 mr-0 mr-lg-2 mb-4 mb-lg-0">
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">
                      <em className="icon ni ni-layers mr-2  fs-20px" />
                      {t('UnitsSummary')}
                    </h6>
                    <div className="row text-center">
                      <div className="col-4">
                        <div className="analytic-ov">
                          <div className="analytic-data-group analytic-ov-group w-100">
                            <div className="analytic-data analytic-ov-data w-100 text-left">
                              <div className="title">{t('UnitsAssigned')}</div>
                              <div className="amount">
                                <NumberFormat
                                  value={summaryRow?.units || 0}
                                  displayType="text"
                                  {...numberFormat}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="analytic-ov">
                          <div className="analytic-data-group analytic-ov-group w-100">
                            <div className="analytic-data analytic-ov-data w-100 text-left">
                              <div className="title">{t('UnitsVested')}</div>
                              <div className="amount">
                                <NumberFormat
                                  value={summaryRow?.vestedUnits || 0}
                                  displayType="text"
                                  {...numberFormat}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="analytic-ov">
                          <div className="analytic-data-group analytic-ov-group w-100">
                            <div className="analytic-data analytic-ov-data w-100 text-left">
                              <div className="title">{t('IncentiveValue')}</div>
                              <div className="amount">
                                <NumberFormat
                                  value={summaryRow?.incentiveValue || 0}
                                  displayType="text"
                                  {...numberFormatDecimals}
                                />
                                €
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* TEMPORAL POSITION OF PENDING SIGNATURES WARNING */}
                      {showDetails && (
                        <PendingInvitationLetters
                          pendingInvitations={pendingInvitations}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="card card-bordered card-stretch col-12 col-lg-4 ml-0 ml-lg-2 mt-0">
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">
                      <em className="icon ni ni-meter mr-2  fs-20px" />
                      <span>
                        {percentVestedUnits || 0}%&nbsp;
                        {t('UnitsVested')}
                      </span>
                    </h6>
                    <div className="progress progress-md bg-light">
                      <div
                        className="progress-bar"
                        data-progress={percentVestedUnits}
                        style={{
                          width: `${percentVestedUnits}%`,
                        }}
                      />
                    </div>
                    <div className="nk-fmg-status-info">
                      <div className="float-md-left">0</div>
                      <div className="float-md-right">100%</div>
                    </div>
                  </div>
                </div>
              </div>
              <CustomDataTable
                data={tableData}
                columns={currentTableColumns}
                searchBy={[t('Beneficiary'), t('Email'), t('CIF')]}
                searchByPlaceholder={[t('name'), t('email'), t('CIF')]}
                pagination
                className="nk-tb-list"
                // expandableRows
                actions
                showDense
                fileName={`${t('BeneficiariesList')} ${actualSociety?.name}`}
              />
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default BeneficiaryList;
