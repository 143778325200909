/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */

import screens from 'constants/screens';
import MenuDots from 'modules/_shared/components/MenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';

import representantOptions from './representantOptions';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Nr'),
      field: 'index',
      hide: screens.SM,
      selector: (row, index) => index + 1,
      cell: (row, index) => index + 1,
      disableSortBy: true,
      disableFilters: true,
      center: true,
      grow: 0.5,
      compact: true,
      minWidth: 20,
      export: true,
    },
    {
      name: i18n.t('Name'),
      field: 'name',
      selector: (row) => row[i18n.t('Name')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Name')],
        }),
      export: false,
      omit: true,
    },
    {
      name: i18n.t('Email'),
      field: 'email',
      selector: (row) => row[i18n.t('Email')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Email')],
        }),
      export: false,
      omit: true,
    },
    {
      // mandatory props
      name: i18n.t('Representant'),
      field: 'name',
      selector: (row) => row[i18n.t('Representant')],
      sortable: true,
      // optional props
      grow: 4,
      compact: false,
      cell: (row) =>
        CellData.User({
          partner: row?.representant,
          hasLink: row?.hasLink,
        }),
      export: true,
    },
    {
      name: i18n.t('Cif'),
      field: 'cif',
      selector: (row) => row[i18n.t('Cif')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Cif')],
        }),
      export: true,
    },
    {
      name: i18n.t('isPrivate'),
      field: 'isPrivate',
      selector: (row) => row[i18n.t('isPrivate')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.BooleanText({
          value: row[i18n.t('isPrivate')],
        }),
      export: true,
    },
    {
      name: i18n.t('createdAt'),
      field: 'createdAt',
      selector: (row) => row[i18n.t('createdAt')],
      sortable: true,
      width: '120px',
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('createdAt')],
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) => {
        if (!row?.isDemo) {
          return (
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="dropdown">
                  <MenuDots
                    disabled={!row?.isAdmin}
                    menuOptions={representantOptions}
                    direction="left"
                    id={row?.categoryId}
                    params={{
                      representant: row?.representant,
                      actualSociety: row?.society,
                      user: row?.user,
                      mixpanel: row?.mixpanel,
                    }}
                  />
                </div>
              </li>
            </ul>
          );
        }
      },
      export: false,
      right: true,
      width: '50px',
      hide: screens.SM,
      allowOverflow: true,
    },
  ];
}

export default tableColumns;
