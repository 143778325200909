import { Board, Participant, PartnerRepresentant, Society } from 'types';
import { getPartnerActualShares } from 'utils/filters';
import { formatNumber } from 'utils/formats';
import getPartnerNDPercent from 'utils/getPartnerNDPercent';
import { getRepresentativeFromParticipant } from './getRepresentativeFromParticipant';

type ParticipantRow = {
  name: string;
  representant?: string;
  shares?: string;
  percent?: string;
};

const addValueToFooter = (totalShares = 0, totalPercent = '0.0') =>
  `<tr style="height: 37pt;">
    <td style="border-width: 1pt; border-style: solid;"><p style="margin-top: 0pt; margin-bottom: 0pt;"><b><span style="font-size: 11pt; font-family: arial; padding: 0 5px;">TOTAL CAPITAL PRESENTE</span></b></p></td>
    <td style="border-width: 1pt; border-style: solid;"><p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><b><span style="font-size: 11pt; font-family: arial;  padding: 0 5px;">&nbsp;</span></b></p></td>
    <td style="border-width: 1pt; border-style: solid;"><p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><b><span style="font-size: 11pt; font-family: arial;  padding: 0 5px;">${totalShares}</span></b><b></b></p></td>
    <td style="border-width: 1pt; border-style: solid;"><p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><b><span style="font-size: 11pt; font-family: arial;  padding: 0 5px;">${totalPercent}</span></b><b><span style="font-size: 11pt; font-family: arial;">%</span></b></p></td>
  </tr>
  </tbody></table>`;

const addValuesToRow = ({
  name = '',
  representant = '',
  shares = '',
  percent = '',
}) =>
  `<tr style="height: 24pt;">
    <td style="border-width: 1pt; border-style: solid;"><p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: arial; padding: 0 5px;">${name}</span></p></td>
    <td style="border-width: 1pt; border-style: solid;"><p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: arial; padding: 0 5px;">${representant}</span></p></td>
    <td style="border-width: 1pt; border-style: solid;"><p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: arial;  padding: 0 5px;">${shares}</span></p></td>
    <td style="border-width: 1pt; border-style: solid;"><p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: arial;  padding: 0 5px;">${percent}</span><span style="font-size: 11pt; font-family: arial;">%</span></p></td>
  </tr>`;

const getPartnerData = (society: Society, participant: Participant) => {
  const currentPartner = society.partners.find(
    (p) => p._id === participant.member
  );

  const percent = getPartnerNDPercent(currentPartner, society);
  const shares = currentPartner?.sharesCount?.actual || 0;

  return { percent, shares };
};

export function getBoardAssistantsTable(
  board: Board,
  society: Society,
  partnerRepresentants: PartnerRepresentant[]
) {
  const header = `
    <table style="border: none;"><colgroup><col style="width: 220px;"><col style="width: 248px;"><col style="width: 146px;"></colgroup><tbody>
      <tr style="height: 24pt;">
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;"><p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><b><span style="font-size: 11pt; font-family: arial; padding: 0 5px;">NOMBRE DEL SOCIO</span></b></p></td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;"><p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><b><span style="font-size: 11pt; font-family: arial; padding: 0 5px;">REPRESENTANTE</span></b></p></td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;"><p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><b><span style="font-size: 11pt; font-family: arial; padding: 0 5px;">PARTICIPACIONES</span></b></p></td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;"><p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><b><span style="font-size: 11pt; font-family: arial; padding: 0 5px;">% PARTICIPACIÓN</span></b></p></td>
      </tr>`;

  const rows: string[] = [];
  let totalShares = 0;
  let totalPercent = 0;

  board.participants
    ?.filter(
      (participant) => getPartnerActualShares(participant.member, society) > 0
    )
    ?.forEach((participant) => {
      if (participant.assists) {
        const participantToRow: ParticipantRow = {
          name: participant.name,
        };

        if (participant.isRepresented) {
          const representant = getRepresentativeFromParticipant(
            participant,
            society,
            partnerRepresentants
          );
          const representantName = representant?.name || '-';
          participantToRow.representant = representantName;
        }

        const { percent, shares } = getPartnerData(society, participant);

        totalPercent += percent;
        totalShares += shares;

        participantToRow.percent = percent?.toFixed(2) || '-';
        participantToRow.shares = formatNumber(shares) || '-';

        rows.push(addValuesToRow(participantToRow));
      }
    });

  const footer = addValueToFooter(
    formatNumber(totalShares),
    totalPercent?.toFixed(2)
  );

  const assistantsTable = header + rows.join('') + footer;

  return assistantsTable;
}
