/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useTranslate } from 'hooks/useTranslate';

import { currencyFormatDecimals, numberFormat } from 'constants/formats';
import sizes from 'constants/sizes';

import activePartner from 'utils/activePartner';
import {
  getDecimalScale,
  getPartnerAmount,
  getPartnerFDPercent,
  getPartnerNDPercent,
  getPartnerPotentialFD,
  groupBy,
} from 'utils/filters';
import { formatNumber, formatNumberDecimalsLong } from 'utils/formats';
import { tenderOfferSharesStatus } from 'utils/tenderOfferSharesTypes';

import { setModal } from 'modules/_shared/redux/modalActions';

import TenderOfferBuyShares from 'modules/_shared/components/Modals/TenderOfferBuyShares';
import TenderOfferSellShares from 'modules/_shared/components/Modals/TenderOfferSellShares';
import PartnerDividends from 'modules/partners/components/PartnerDividends';
import PartnerSharesRow from 'modules/partners/components/PartnerSharesRow';
import { getPartnerCategories } from 'modules/partners/redux/partnerCategoryActions';

function PartnerSociety({ partner, society, setPage }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const plans = useSelector((state) => state?.plans);
  const partnerCategories = useSelector((state) => state?.partnerCategories);
  const tenderOffersShares = useSelector((state) => state?.tenderOffersShares);

  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const isReadOnlyUser = useSelector((state) =>
    partner?.readOnlyUsers?.includes(state.user['_id'])
  );

  const [fullName] = useState(partner?.name || '');

  const { societyValue } = partner;
  const [decimalScale] = useState(
    societyValue ? getDecimalScale(societyValue.value) : 2
  );
  const [NDpercent] = useState(
    getPartnerNDPercent(partner, society).toFixed(decimalScale)
  );
  const [FDpercent] = useState(
    getPartnerFDPercent(partner, society).toFixed(decimalScale)
  );
  const [PFDPercent] = useState(
    getPartnerPotentialFD(partner, society, plans).toFixed(decimalScale)
  );

  const [hasSellSharesEnabled, setHasSellSharesEnabled] = useState(false);
  const [hasBuySharesEnabled, setHasBuySharesEnabled] = useState(false);

  const [partnerCategory, setPartnerCategory] = useState({});
  const [shares, setShares] = useState([]);
  const [partnerShares, setPartnerShares] = useState([]);
  const [totalPartnerShares, setTotalPartnerShares] = useState(0);
  const [rows, setRows] = useState([]);

  const handleOpenBuyShares = () => {
    dispatch(
      setModal(<TenderOfferBuyShares partner={partner} size={sizes.LG} />)
    );
  };

  const handleOpenSellShares = () => {
    dispatch(
      setModal(<TenderOfferSellShares partner={partner} size={sizes.LG} />)
    );
  };

  const navigateToDividendsDetails = () => {
    setPage(2);
  };

  useEffect(() => {
    setShares(
      society?.shares
        .filter((share) => share.isActive && share.partner === partner['_id'])
        .map((filteredShare) => ({
          _id: filteredShare._id,
          from: filteredShare.from,
          to: filteredShare.to,
          nominalValue:
            filteredShare?.shareClass?.nominalValue || society?.nominalValue,
          sharePremium: filteredShare.sharePremium,
          shareClass: filteredShare.shareClass?.name || '-',
          tag: filteredShare?.tag || '',
          encumbrances: filteredShare?.encumbrances,
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    const groupByClass = groupBy(shares, 'shareClass');
    const currentPartnerShares = groupByClass.flat().reverse();

    setPartnerShares(currentPartnerShares);

    setRows(
      currentPartnerShares?.map((share, index) => (
        <PartnerSharesRow
          share={share}
          index={currentPartnerShares.length - index}
          key={`share-${index}`}
        />
      ))
    );
  }, [shares]);

  useEffect(() => {
    if (partnerShares?.length) {
      const total = partnerShares?.reduce(
        (acc, curr) => acc + curr?.to - curr?.from + 1,
        0
      );
      setTotalPartnerShares(total);
    }
  }, [partnerShares]);

  useEffect(() => {
    if (!tenderOffersShares?.length) return;

    const hasTenderOffersSharesForSell = tenderOffersShares?.some(
      (tenderOfferShare) =>
        tenderOfferShare?.type === 'BUY' &&
        tenderOfferShare?.status === tenderOfferSharesStatus.ACTIVE
    );

    const hasTenderOffersSharesForBuy = tenderOffersShares?.some(
      (tenderOfferShare) =>
        tenderOfferShare?.type === 'SELL' &&
        tenderOfferShare?.status === tenderOfferSharesStatus.ACTIVE
    );

    setHasSellSharesEnabled(hasTenderOffersSharesForSell);
    setHasBuySharesEnabled(hasTenderOffersSharesForBuy);
  }, [tenderOffersShares]);

  useEffect(() => {
    if (partner?.category && partnerCategories?.length) {
      const category = partnerCategories.find(
        (partnerCategory) => partnerCategory['_id'] === partner.category
      );
      setPartnerCategory(category);
    }

    if (partner?.category && !partnerCategories?.length && society?._id) {
      dispatch(getPartnerCategories(society?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerCategories]);

  return (
    <div className="card-inner">
      <div className="card-inner-group">
        <div className="card-inner">
          <div className="user-card user-card-s2">
            <div className="user-avatar lg bg-primary">
              {society.additional.logo ? (
                <img src={society.additional.logo} alt="Logo" />
              ) : (
                <span>{society.name.slice(0, 2).toUpperCase()}</span>
              )}
            </div>
            <div className="user-info">
              <h5>{fullName}</h5>

              <div className="d-flex flex-wrap justify-content-center align-center">
                {activePartner(partner) ? (
                  <div className="badge badge-outline badge-pill text-primary ucap">
                    {t('Partner')}
                  </div>
                ) : (
                  <div className="badge badge-outline-danger badge-pill text-danger ucap">
                    {t('ExPartner')}
                  </div>
                )}
                {partnerCategory?.name && isAdmin && (
                  <div className="badge badge-outline-info badge-pill text-info ucap ml-1">
                    {partnerCategory?.name}
                  </div>
                )}
              </div>

              <span className="sub-text">
                {t('ShareholderOfSocietyName', {
                  societyName: society.name,
                })}
              </span>
            </div>
          </div>
        </div>

        <div className="card-inner card-inner-sm px-0">
          <ul className="btn-toolbar justify-center gx-1">
            {hasBuySharesEnabled && (
              <li>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleOpenBuyShares}
                  disabled={isDemo || isReadOnlyUser}
                >
                  <em className="icon ni ni-coins mr-1" />
                  {t('ToBuy')}
                </button>
              </li>
            )}

            {hasSellSharesEnabled && (
              <li>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleOpenSellShares}
                  disabled={isDemo || isReadOnlyUser}
                >
                  <em className="icon ni ni-coins mr-1" />
                  {t('ToSell')}
                </button>
              </li>
            )}
          </ul>
        </div>

        <div className="card-inner">
          <h6 className="overline-title-alt mb-2">
            {t('InvestmentOfFullnameInSoc', {
              fullName,
              societyName: society.name,
            })}
          </h6>
          <div className="d-flex justify-content-around flex-wrap text-center">
            <div className="my-2">
              <div className="profile-balance-amount">
                <div className="number">
                  <NumberFormat
                    value={partner.sharesCount?.actual || 0}
                    displayType="text"
                    {...numberFormat}
                  />
                </div>
              </div>
              <div className="profile-stats">
                <span className="text-primary">{t('Shares')}</span>
              </div>
            </div>
            <div className="my-2">
              <div className="profile-balance-amount">
                <div className="number">
                  {`${
                    NDpercent > 0 ? formatNumberDecimalsLong(+NDpercent) : 0
                  }%`}
                </div>
              </div>
              <div className="profile-stats">
                <span className="text-primary">{t('Percentage')}</span>
              </div>
            </div>
            <div className="my-2">
              <div className="profile-balance-amount">
                <div className="number">
                  <NumberFormat
                    value={getPartnerAmount(partner.shares, society)}
                    displayType="text"
                    {...currencyFormatDecimals}
                  />
                </div>
              </div>
              <div className="profile-stats">
                <span className="text-primary">{t('Invested')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="nk-fmg-status">
          <h6 className="nk-fmg-status-title">
            <em className="icon ni ni-meter" />
            <span>
              {`${t('Percentage')}: ${
                NDpercent > 0 ? formatNumberDecimalsLong(+NDpercent) : 0
              }%`}
            </span>
          </h6>
          <div className="progress progress-md bg-light">
            <div
              className="progress-bar"
              data-progress={NDpercent || 0}
              style={{
                width: `${NDpercent > 0 ? +NDpercent : 0}%`,
              }}
            />
          </div>
          <div className="nk-fmg-status-info">
            {`${
              NDpercent > 0 ? formatNumberDecimalsLong(+NDpercent) : 0
            }% ND - ${
              FDpercent > 0 ? formatNumberDecimalsLong(+FDpercent) : 0
            }% FD - ${
              PFDPercent > 0 ? formatNumberDecimalsLong(+PFDPercent) : 0
            }% ${t('PotentialFD')}`}
          </div>
          {isAdmin && (
            <div className="nk-fmg-status-action">
              <Link
                to={`/socios-libro/${society['_id']}`}
                className="link link-primary link-sm"
              >
                {t('SeeBookOfPartners')}
              </Link>
            </div>
          )}
        </div>

        <div className="card-inner px-0">
          <h6 className="overline-title-alt mb-2">
            {t('ParticipationDetail')}
          </h6>
          <div className="card card-bordered card-preview">
            <table className="table table-striped table-font-sm">
              <thead>
                <tr>
                  <th scope="col" className="d-none d-md-table-cell w-20px">
                    {t('Nr')}
                  </th>
                  <th scope="col" className="text-right">
                    {t('NrPart.')}
                  </th>
                  <th scope="col" className="text-right d-none d-md-table-cell">
                    {t('Nominal')}
                  </th>
                  <th scope="col" className="text-center">
                    {t('Class')}
                  </th>

                  <th scope="col" className="text-right d-table-cell d-md-none">
                    {t('From/to')}
                  </th>
                  <th scope="col" className="text-right d-none d-md-table-cell">
                    {t('From')}
                  </th>
                  <th scope="col" className="text-right d-none d-md-table-cell">
                    {t('To')}
                  </th>
                  {isAdmin && (
                    <th
                      scope="col"
                      className="text-right d-none d-md-table-cell"
                    >
                      {t('Encumbrance')}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
              <tfoot>
                <tr>
                  <td className="fw-bold">{t('Total')}</td>
                  <td className="fw-bold text-right">
                    {formatNumber(totalPartnerShares)}
                  </td>
                  <td className="" />
                  <td className=" d-none d-md-table-cell" />
                  <td className=" d-none d-md-table-cell" />
                  <td className=" d-none d-md-table-cell" />
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div className="card-inner px-0">
          <div className="d-flex flex-row">
            <h6 className="overline-title-alt mb-2">
              {t('DividendsDistribution')}
            </h6>
            <span
              className="link link-primary link-sm cursor-pointer mb-2 ml-2"
              onClick={navigateToDividendsDetails}
            >
              {t('SeeDetail')}
            </span>
          </div>

          <PartnerDividends
            partner={partner}
            society={society}
            showTitle={false}
            summaryView
          />
        </div>
      </div>
    </div>
  );
}

export default PartnerSociety;
