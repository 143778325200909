import { Participant, Partner, PartnerRepresentant, Society } from 'types';

type Representative = Partner | PartnerRepresentant | undefined;

export const getRepresentativeFromParticipant = (
  participant: Participant,
  actualSociety: Society,
  partnerRepresentants: PartnerRepresentant[]
): Representative => {
  if (!participant.representative) return undefined;

  const representativesList = participant?.hasPartnerRepresentant
    ? partnerRepresentants
    : actualSociety?.partners;

  return representativesList?.find(
    (p: any) => p['_id'] === participant?.representative
  );
};
