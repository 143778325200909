import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';

import { Board } from 'types';

import BoardCardActions from './BoardCardActions';
import BoardCardDetails from './BoardCardDetails';

type BoardCardProps = {
  board: Board;
};

const BoardCard: FC<BoardCardProps> = ({ board }) => {
  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society.actualSociety
  );
  const isDemo = useSelector((state: State) => state.society.role.isDemo);

  const boardLink = `/detalle-junta/${actualSociety?._id}/${board?._id}`;

  return (
    <div className="card card-bordered sp-plan hover-shadow">
      <div className="row no-gutters">
        <BoardCardDetails board={board} boardLink={boardLink} isDemo={isDemo} />

        <BoardCardActions
          board={board}
          boardLink={boardLink}
          actualSociety={actualSociety}
          isDemo={isDemo}
          user={user}
        />
      </div>
    </div>
  );
};

export default memo(BoardCard);
