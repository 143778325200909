/* eslint-disable no-underscore-dangle */
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { State } from 'redux/initialState';
import pages from '../constants/pages';

type SidenavProps = {
  activePage: string;
  onChangePage: any;
};

const Sidenav: FC<SidenavProps> = ({ activePage, onChangePage }) => {
  const { t } = useTranslate();
  const history = useHistory();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const [totalComms, setTotalComms] = useState(0);

  const handleOpenInboxPage = () => {
    history.push(`/comunicaciones/${actualSociety?._id}`);
    onChangePage(pages.INBOX);
  };

  const handleOpenSettingsPage = () => {
    history.push(`/comunicaciones/${actualSociety?._id}`);
    onChangePage(pages.SETTINGS);
  };

  useEffect(() => {
    setTotalComms(actualSociety?.communications?.length || 0);
  }, [actualSociety]);

  return (
    <div
      className="nk-ibx-aside toggle-screen-lg"
      data-content="inbox-aside"
      data-toggle-overlay="true"
      data-toggle-screen="lg"
    >
      <div className="nk-ibx-nav overflow-hidden" data-simplebar="init">
        <div className="simplebar-wrapper m-0">
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div
              className="simplebar-offset"
              style={{ right: '0px', bottom: '0px' }}
            >
              <div
                className="simplebar-content-wrapper h-100"
                role="region"
                aria-label="scrollable content"
                style={{ height: '100%', overflow: 'hidden scroll' }}
              >
                <div className="simplebar-content p-0">
                  <ul className="nk-ibx-menu">
                    <li
                      className={activePage === pages.INBOX ? 'active' : ''}
                      onClick={handleOpenInboxPage}
                    >
                      <button
                        type="button"
                        className="btn btn-block nk-ibx-menu-item p-2 pl-1"
                      >
                        <em className="icon ni ni-send" />
                        <span className="nk-ibx-menu-text">{t('Sent')}</span>
                        <span className="badge badge-pill badge-primary">
                          {totalComms}
                        </span>
                      </button>
                    </li>

                    <hr className="divider my-3" />

                    <li
                      className={activePage === pages.SETTINGS ? 'active' : ''}
                      onClick={handleOpenSettingsPage}
                    >
                      <button
                        type="button"
                        className="btn btn-block nk-ibx-menu-item p-2 pl-1 justify-content-start"
                      >
                        <em className="icon ni ni-setting" />
                        <span className="nk-ibx-menu-text">
                          {t('Settings')}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidenav;
