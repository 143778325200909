/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { memo, useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  validateIsAbsent,
  validateOpenVoting,
} from 'modules/_shared/api/validationActions';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { getBoards } from 'modules/boards/redux/boardActions';
import { getDocumentsByReference } from 'modules/documents/redux/documentActions';

import { isOpenVoting } from 'utils/boards';
import { getActualSociety } from 'utils/filters';
import trackEvent from 'utils/trackEvent';
import getUserMetadata from 'utils/userMetadata';

import boardStatus from 'constants/boardStatus';
import eventTypes from 'constants/eventTypes';

import CustomLoading from 'modules/_shared/components/CustomLoading';

import BoardPartnerDelegations from 'modules/boards/BoardPartnerDelegations';
import BoardDocuments from 'modules/boards/components/BoardDocuments';
import BoardVotes from 'modules/boards/components/BoardVotes';

import { getPartnerRepresentants } from 'modules/partners/redux/partnerRepresentantsActions';
import BoardPartnerHeader from './components/BoardPartnerHeader';
import BoardPartnerActionsRow from './components/BoardPartnerActionsRow';

const BoardPartner = () => {
  const { t } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { societyId, boardId, partnerId } = useParams();

  const user = useSelector((state) => state.user);
  const boards = useSelector((state) => state.boards);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const partnerRepresentants = useSelector(
    (state) => state.partnerRepresentants
  );
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const isRepresentant = useSelector(
    (state) => state.society?.role?.isRepresentant
  );

  const [currentBoard, setCurrentBoard] = useState();
  const [currentPartner, setCurrentPartner] = useState(null);
  const [currentRepresentant, setCurrentRepresentant] = useState(null);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [isDelegated, setIsDelegated] = useState(false);
  const [isAbsent, setIsAbsent] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [representativeName, setRepresentativeName] = useState('');
  const [isParticipant, setIsParticipant] = useState();
  const [isRepresentative, setIsRepresentative] = useState();
  const [isPartnerRepresentant, setIsPartnerRepresentant] = useState();
  const [hasDelegated, setHasDelegated] = useState();
  const [haveVote, setHaveVote] = useState(false);
  const [userMetadata, setUserMetadata] = useState();

  const BoardPartnerTabs = [
    { id: 0, name: t('AttendanceAndVotes') },
    { id: 1, name: `${t('Representation')} / ${t('Delegations')}` },
    { id: 2, name: t('Documents') },
  ];

  const setCurrentMenu = () => {
    dispatch(
      setMenu({
        type: null,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
  };

  const getCurrentValues = async () => {
    dispatch(getBoards(societyId));
    dispatch(getPartnerRepresentants({ societyId }));
  };

  const getCurrentPartner = () => {
    const partnerFound = actualSociety?.partners?.find(
      (partner) => partner?.['_id'] === partnerId
    );
    setCurrentPartner(partnerFound);
  };

  const saveEventOnMixpanel = async () => {
    const currentEvent = eventTypes.ASSIST_BOARD;
    await trackEvent(mixpanel, currentEvent, {
      userId: user?.['_id'],
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?.['_id'],
      societyName: actualSociety?.name,
      operation: currentEvent,
      boardId: currentBoard?.['_id'],
      userIpAddress: userMetadata?.ipAddress,
      userBrowser: userMetadata?.browser,
      userBrowserVersion: userMetadata?.browserVersion,
      userDevice: userMetadata?.device,
    });
  };

  useEffect(() => {
    if (currentBoard) {
      const resultParticipant = currentBoard.participants.find(
        (participant) => participant.member === partnerId
      );
      const representative = currentBoard.participants.find(
        (participant) =>
          participant.member === resultParticipant?.representative
      );
      setHaveVote(resultParticipant?.votes?.length > 0);
      setIsDelegated(resultParticipant?.isRepresented);
      setRepresentativeName(representative?.name);
      setIsAbsent(resultParticipant?.isAbsent);
      const openVoting = isOpenVoting(currentBoard);
      if (!openVoting) {
        dispatch(validateOpenVoting(openVoting, currentBoard));
      }
    }
  }, [partnerId, currentBoard]);

  useEffect(() => {
    dispatch(validateIsAbsent(isAbsent && !hasDelegated));
  }, [isAbsent, i18n.language]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety && partnerId) {
      getCurrentPartner();

      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, partnerId]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentMenu();
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (boards.length) {
      const actualBoard = boards.find((board) => board['_id'] === boardId);
      setIsActive(actualBoard?.isActive);
      setCurrentBoard(actualBoard);
    }
  }, [boards, boardId]);

  useEffect(() => {
    if (currentBoard && partnerId) {
      if (
        [boardStatus.PENDING.value, boardStatus.CANCELED.value].includes(
          currentBoard?.status
        )
      ) {
        history.push(`/juntas/${societyId}/${partnerId}`);
      }
      setIsPartnerRepresentant(
        currentBoard?.delegationRepresentants.some((r) => r === partnerId) &&
          isRepresentant
      );
      setIsParticipant(
        currentBoard?.participants.some((p) => p?.member === partnerId)
      );
      setIsRepresentative(
        currentBoard?.participants.some((p) => p?.representative === partnerId)
      );
      setHasDelegated(
        currentBoard?.participants.some(
          (p) => p?.member === partnerId && p?.representative
        )
      );

      setPages([
        <BoardVotes key="board-votes" board={currentBoard} />,
        <BoardPartnerDelegations board={currentBoard} partnerId={partnerId} />,
        <BoardDocuments
          key="board-documents"
          board={currentBoard}
          partnerId={partnerId}
        />,
      ]);
    }
  }, [currentBoard, partnerId]);

  useEffect(() => {
    if (currentBoard && isPartnerRepresentant) {
      const userRepresentants = user.partnerRepresentants || [];
      const boardRepresentants = currentBoard.delegationRepresentants || [];

      const isUserRepresentant = userRepresentants.some((r) =>
        boardRepresentants.includes(r)
      );

      if (!isUserRepresentant) {
        setCurrentRepresentant(null);
      } else {
        const firstRepresentant = userRepresentants.find((r) =>
          boardRepresentants.includes(r)
        );
        const partnerRepresentant = partnerRepresentants.find(
          (r) => r['_id'] === firstRepresentant
        );
        setCurrentRepresentant(partnerRepresentant);
      }
    }
  }, [currentBoard, isPartnerRepresentant]);

  useEffect(() => {
    if (currentBoard && user && actualSociety && userMetadata) {
      saveEventOnMixpanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, user, currentBoard, userMetadata]);

  useEffect(() => {
    getUserMetadata().then((data) => {
      setUserMetadata(data);
    });
  }, []);

  useEffect(() => {
    const route = window.location.hash.replace('#', '');
    const socket = new WebSocket(
      `${process.env.REACT_APP_API_WSS}?origin=${encodeURIComponent(route)}`
    );

    socket.onopen = () => {
      socket.send(JSON.stringify({ type: 'trackRoute', route }));
    };

    return () => {
      socket.close();
    };
  }, []);

  return (isParticipant || isPartnerRepresentant) && currentBoard ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <BoardPartnerHeader
          societyId={societyId}
          currentBoard={currentBoard}
          representativeName={representativeName}
          statusStyle={boardStatus?.[currentBoard?.status]?.style}
          statusText={boardStatus?.[currentBoard?.status]?.text}
          isDelegated={isDelegated}
          isAbsent={isAbsent}
          isActive={isActive}
          isAdmin={isAdmin}
        />

        <BoardPartnerActionsRow
          currentRepresentant={currentRepresentant}
          currentPartner={currentPartner}
          currentBoard={currentBoard}
          actualSociety={actualSociety}
          isParticipant={isParticipant}
          isPartnerRepresentant={isPartnerRepresentant}
          isRepresentative={hasDelegated}
          hasDelegated={isRepresentative}
          haveVote={haveVote}
          partnerId={partnerId}
        />

        <div className="nk-block">
          <ul className="nk-nav nav nav-tabs nav-tabs__no-wrap">
            {BoardPartnerTabs.map((tab) => (
              <li className="nav-item" key={tab.id}>
                <p
                  className={`nav-link ${page === tab.id ? 'active' : ''}`}
                  onClick={() => setPage(tab.id)}
                >
                  <span>{tab.name}</span>
                </p>
              </li>
            ))}
          </ul>

          {pages[page]}
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default memo(BoardPartner);
