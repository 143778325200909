import { Board, Participant, PartnerRepresentant } from 'types';

export const getPartnerDelegate = (
  board: Board,
  actualPartner: Participant,
  partnerRepresentants: PartnerRepresentant[]
) => {
  const delegateParticipant = board?.participants.find(
    (p) => p?.member === actualPartner?.representative
  );
  const delegateRepresentant = partnerRepresentants?.find(
    (p) => p['_id'] === actualPartner?.representative
  );

  return delegateParticipant || delegateRepresentant;
};
