/* eslint-disable no-nested-ternary */
import { format } from 'date-fns';
import html2pdf from 'html3pdf';
import { FC, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import { State } from 'redux/initialState';

// @ts-ignore
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { requestBoardAssistantsSignature } from 'utils/boards';
import { getPartnerNDPercent } from 'utils/filters';
import { formatNumberDecimals } from 'utils/formats';

import { formatDateToTime } from 'constants/formats';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import Dialog from 'modules/_shared/components/Dialog';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';

import { useTranslate } from 'hooks/useTranslate';
import {
  getPartnerFromParticipant,
  getRepresentativeFromParticipant,
} from 'modules/boards/utils';
import { Board, Participant } from 'types';
import AssistantRow from './AssistantRow';
import downloadOptions from './utils/downloadOptions';
import tableColumns from './utils/tableColumns';
import { downloadCertificate } from './utils/downloadCertificate';
import { getParticipantAttendance } from './utils/getParticipantAttendance';

type tableProps = {
  board: Board;
  attendanceSummaryByCapital: Record<string, number>;
};

const BoardAssistantsTable: FC<tableProps> = ({
  board,
  attendanceSummaryByCapital,
}) => {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );
  const isDemo = useSelector((state: State) => state.society?.role?.isDemo);

  const [currentDownloadOptions, setCurrentDownloadOptions] = useState<
    Record<string, any>[]
  >(downloadOptions(i18n));
  const [rows, setRows] = useState<any[]>([]);
  const [rowsPrint, setRowsPrint] = useState<any[]>([]);

  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const [certificateData, setCertificateData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const columns = tableColumns(i18n);

  const handleDownloadCertificate = (secretaryName: string) => {
    debugger;
    downloadCertificate({
      board,
      secretaryName,
      actualSociety,
      rowsPrint,
      rows,
      attendanceSummaryByCapital,
      t,
    });
  };

  function getTableRows() {
    const result: any[] = [];

    let index = 1;

    board.participants.forEach((participant: Participant) => {
      const partner = getPartnerFromParticipant(participant, actualSociety);
      const representative = getRepresentativeFromParticipant(
        participant,
        actualSociety,
        partnerRepresentants
      );

      if (partner && partner?.sharesCount?.actual > 0) {
        result.push({
          participant,
          participantNumber: index,
          participantMemberId: participant?.member,
          participantName: participant?.name,
          representative,
          representativeName: representative?.name || '-',
          percentage: getPartnerNDPercent(partner, actualSociety),
          shares: partner?.sharesCount?.actual || 0,
          societyId: actualSociety['_id'],
          decimals: 3,
        });
        index += 1;
      }
    });
    return result;
  }

  function getAssistants() {
    const result: any[] = [];

    let index = 1;

    board.participants.forEach((participant: Participant) => {
      const partner = getPartnerFromParticipant(participant, actualSociety);
      const representative = getRepresentativeFromParticipant(
        participant,
        actualSociety,
        partnerRepresentants
      );

      if (partner && partner?.sharesCount?.actual > 0) {
        result.push({
          ...participant,
          representative,
          index,
          percentage: getPartnerNDPercent(partner, actualSociety),
          sharesCount: partner?.sharesCount?.actual || 0,
          societyId: actualSociety['_id'],
          attend: getParticipantAttendance(participant),
        });
        index += 1;
      }
    });
    return result;
  }

  const handleDownload = (type: any) => {
    if (type === 0) {
      const btn = document.getElementById('custom-table-xls-button');
      if (btn) {
        btn.click();
      }
    } else {
      // TODO Pass secretary name as param
      handleDownloadCertificate('');
    }
  };

  const createAndSendCertificate = async () => {
    setIsLoading(true);
    try {
      const certificate = {
        header: t('AttendanceCertificateTitle', {
          societyName: actualSociety?.name,
        }),
        subHeader: `${t('AttendanceCertificateBodyPart1', {
          boardPlace:
            board?.place || `[${t('PlaceOfCelebration').toUpperCase()}]`,
        })} ${format(new Date(board.date), 'dd/MM/yyyy')} ${t(
          'AttendanceCertificateBodyPart2',
          {
            boardTime: formatDateToTime(board.date),
            societyName: actualSociety?.name,
          }
        )}`,
        tableColumns: [
          'Nº',
          t('Name'),
          `Nº ${t('Share.')}`,
          '% ND',
          t('RepresentativeBoard'),
          t('Attend'),
          t('Signature'),
        ],
        tableData: certificateData,
        summaryTableColumns: [
          t('Attend'),
          t('AttendsRepresented'),
          t('Missing'),
          t('Quorum'),
        ],
        summaryTableData: [
          `${formatNumberDecimals(attendanceSummaryByCapital.assistants)}% (${t(
            'OfCapital'
          )})`,
          `${formatNumberDecimals(
            attendanceSummaryByCapital.represented
          )}% (${t('OfCapital')})`,
          `${formatNumberDecimals(attendanceSummaryByCapital.absents)}% (${t(
            'OfCapital'
          )})`,
          `${formatNumberDecimals(attendanceSummaryByCapital.quorum)}% (${t(
            'OfCapital'
          )})`,
        ],
        paragraph: t('AttendanceCertificatePercentages'),
        footerText: t('AttendanceCertificateFooter'),
        signerName: board?.secretary
          ? actualSociety?.directors.find(
              (director: any) => director['_id'] === board?.secretary
            )?.socialDenomination
          : `[${t('SecretaryName').toUpperCase()}]`,
        signerJob: t('Secretary'),
      };

      await requestBoardAssistantsSignature({
        actualSociety,
        board,
        userId: user?._id,
        certificate,
        translate: t,
      });

      dispatch(addAlert(alertBodyTypes.BOARD_ASSISTANTS_SIGNATURE_REQUESTED));
    } catch (error) {
      console.log(error);
      dispatch(addAlert(alertBodyTypes.BOARD_ASSISTANTS_SIGNATURE_ERROR));
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestSignature = async () => {
    Dialog({
      icon: 'ni-send',
      title: t('¿Estás seguro?'),
      body: t(
        'Se enviará un correo a todos los asistentes. Una vez firmado, el documento se guardará en los Documentos Privados de la Junta'
      ),
      buttonText: t('Sí, enviar'),
      onConfirm: () => createAndSendCertificate(),
      onError: () => {},
    });
  };

  useEffect(() => {
    setCurrentDownloadOptions(downloadOptions(i18n));
  }, [i18n.language]);

  useEffect(() => {
    if (board && actualSociety) {
      if (board?.participants?.length) {
        const rowsForTable = getTableRows();
        setTableData(rowsForTable);

        const assistants = getAssistants();

        setCertificateData(assistants);

        setRows(
          assistants.map((assistant: Record<string, any>) => (
            <AssistantRow
              key={`assistant-${assistant.index}`}
              assistant={assistant}
            />
          ))
        );

        setRowsPrint(
          assistants.map((assistant: Record<string, any>) => (
            <AssistantRow
              key={`assistant-${assistant.index}`}
              assistant={assistant}
              print
            />
          ))
        );
      }
    }
  }, [board, actualSociety]);

  return (
    <>
      <div className="nk-block">
        <div className="card card-bordered">
          <div className="card-inner card-inner-md border-bottom">
            <div className="card-title-group">
              <h6 className="card-title">{t('AssistantsList')}</h6>
              <div className="card-action">
                <ReactHtmlTableToExcel
                  id="custom-table-xls-button"
                  className="d-none"
                  table="assistants-table"
                  filename={`${t('BoardAssistants')} - ${board?.name}`}
                  sheet={t('BoardAssistants')}
                  buttonText={t('DownloadList')}
                />

                <button
                  type="button"
                  className="btn btn-outline-light mr-3"
                  onClick={handleRequestSignature}
                  disabled={isDemo}
                >
                  <em className="icon ni ni-edit-alt" />
                  <span>{t('RequestAssistantsSignature')}</span>
                </button>

                {/* @ts-ignore */}
                <OperationMenuDots
                  title={t('Download')}
                  menuOptions={currentDownloadOptions}
                  variant="light"
                  params={{
                    handleDownload,
                    outlined: true,
                  }}
                />
              </div>
            </div>
          </div>

          {tableData?.length && (
            <>
              {/* @ts-ignore */}
              <CustomDataTable
                data={transformData({
                  data: tableData,
                  columns,
                })}
                columns={columns}
                searchBy={t('Name')}
                pagination
                rowsPerPage={30}
                className="nk-tb-list"
                cardClassName="border-0"
                actions
                showDense
                showDownload={false}
                fileName={`${t('AssistantsList')} ${board.name}`}
              />
            </>
          )}
        </div>

        <table className="d-none table table-tranx" id="assistants-table">
          <thead>
            <tr>
              <th>
                <span>Nº</span>
              </th>
              <th>
                <span>{t('Name')}</span>
              </th>
              <th>
                <span>Nº {t('Share.')}</span>
              </th>
              <th>
                <span>% ND</span>
              </th>
              <th>
                <span>{t('RepresentativeBoard')}</span>
              </th>
              <th>
                <span>{t('Attend')}</span>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>

      {isLoading && <CustomLoading />}
    </>
  );
};

export default BoardAssistantsTable;
