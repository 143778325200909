/* eslint-disable no-unused-vars */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';

import Swal from 'sweetalert2';

import { Encumbrance } from 'types';
import { FixedShare } from './types/Share';

import removeRangeFromRanges from './utils/removeRangeFromRanges';
import editEncumbrance from './utils/editEncumbrance';
import addEncumbrance from './utils/addEncumbrance';

import useEncumbranceForm from './hooks/useEncumbranceForm';

import EncumbrancesTable from './EncumbrancesTable';
import ShareEncumbranceViewForm from './components/ShareEncumbranceViewForm';
import getValidRanges from './utils/getValidRanges';

type Props = {
  share: FixedShare;
  encumbrances: Encumbrance[];
  setEncumbrances: (value: Encumbrance[]) => void;
  setEncumbrancesUpdated: (value: boolean) => void;
};

const ShareEncumbranceView: FC<Props> = ({
  share,
  encumbrances,
  setEncumbrances,
  setEncumbrancesUpdated,
}) => {
  const { t } = useTranslate();

  const encumbranceFormResult = useEncumbranceForm({ share });

  const {
    encumbranceName,
    encumbranceDate,
    encumbranceFrom,
    encumbranceTo,
    encumbranceScriptureData,
    encumbranceFile,
    isEditing,
    editingIndex,
    isValidRange,
    validRanges,
    affectedShares,
    coOwners,
    inputLabel,
  } = encumbranceFormResult.data;

  const {
    handleChangeEncumbranceName,
    handleChangeEncumbranceDate,
    handleChangeEncumbranceFrom,
    handleChangeEncumbranceTo,
    handleChangeNotaryName,
    handleChangeProtocolNr,
    handleChangeNotaryCity,
    handleAddCoOwner,
    handleRemoveCoOwner,
    handleChangeEncumbranceFile,
    handleClickRange,
    handleCleanForm,
    handleCancelEdition,
    setCoOwners,
    setIsEditing,
    setValidRanges,
    setValidRangeOnInput,
    setEditValues,
  } = encumbranceFormResult.actions;

  const [showForm, setShowForm] = useState<boolean>(false);

  const handleAddEncumbrance = () => {
    if (isEditing) {
      const newEncumbrances = editEncumbrance({
        encumbrances,
        editingIndex,
        encumbranceName,
        encumbranceDate,
        encumbranceFrom,
        encumbranceTo,
        encumbranceScriptureData,
        encumbranceFile,
        coOwners,
      });
      setIsEditing(false);
      setEncumbrances(newEncumbrances);
    } else {
      const newEncumbrances = addEncumbrance({
        encumbrances,
        encumbranceName,
        encumbranceDate,
        encumbranceFrom,
        encumbranceTo,
        encumbranceScriptureData,
        encumbranceFile,
        coOwners,
      });
      setEncumbrances(newEncumbrances);
    }

    setCoOwners([]);
    const ranges = removeRangeFromRanges(
      encumbranceFrom,
      encumbranceTo,
      validRanges
    );
    setValidRanges(ranges);
    setValidRangeOnInput(ranges);
    setEncumbrancesUpdated(true);
    handleCleanForm();
  };

  const handleEditEncumbrance = async (index: number) => {
    setEditValues(index, encumbrances);
  };

  const handleCancelEdit = () => {
    handleCancelEdition(encumbrances);
  };

  const removeEncumbrance = (index: number) => {
    const encumbrance = encumbrances[index];
    const ranges = getValidRanges(encumbrance, validRanges);
    setValidRanges(ranges);
    setValidRangeOnInput(ranges);
    const filteredEncumbrances = encumbrances.filter((_, i) => i !== index);
    setEncumbrances(filteredEncumbrances);
    setEncumbrancesUpdated(true);
  };

  const handleRemoveEncumbrance = (index: number) => {
    const encumbrance = encumbrances[index];
    Swal.fire({
      icon: 'warning',
      title: `<h4 class="nk-modal-title">${t(
        'AreYouSureAboutDelEncumbrance'
      )}</h4>`,
      html: `<h5 class="text-primary">${
        encumbrance?.name
      }</h5><br /><div class="caption-text">${t(
        'ThisProcedureCantUndo'
      )}</div>`,
      confirmButtonText: t('OkDelete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        removeEncumbrance(index);
      }
    });
  };

  const handleShowForm = () => {
    setShowForm(true);
  };

  return (
    <>
      {showForm && (
        <ShareEncumbranceViewForm
          share={share}
          encumbranceName={encumbranceName}
          encumbranceDate={encumbranceDate}
          encumbranceFrom={encumbranceFrom}
          encumbranceTo={encumbranceTo}
          affectedShares={affectedShares}
          encumbranceScriptureData={encumbranceScriptureData}
          encumbranceFile={encumbranceFile}
          coOwners={coOwners}
          validRanges={validRanges}
          inputLabel={inputLabel}
          isValidRange={isValidRange}
          isEditing={isEditing}
          handleChangeEncumbranceName={handleChangeEncumbranceName}
          handleChangeEncumbranceDate={handleChangeEncumbranceDate}
          handleChangeEncumbranceFrom={handleChangeEncumbranceFrom}
          handleChangeEncumbranceTo={handleChangeEncumbranceTo}
          handleAddCoOwner={handleAddCoOwner}
          handleRemoveCoOwner={handleRemoveCoOwner}
          handleAddEncumbrance={handleAddEncumbrance}
          handleCancelEdit={handleCancelEdit}
          handleClickRange={handleClickRange}
          handleChangeNotaryName={handleChangeNotaryName}
          handleChangeProtocolNr={handleChangeProtocolNr}
          handleChangeNotaryCity={handleChangeNotaryCity}
          handleChangeEncumbranceFile={handleChangeEncumbranceFile}
        />
      )}

      <EncumbrancesTable
        encumbrances={encumbrances}
        isEditing={isEditing}
        handleEditEncumbrance={handleEditEncumbrance}
        handleRemoveEncumbrance={handleRemoveEncumbrance}
      />

      {!showForm && (
        <div className="form-group d-flex justify-content-end">
          <div>
            <button
              type="button"
              className="btn btn-outline-primary btn-dim border-radius-0"
              onClick={handleShowForm}
            >
              {t('AddEncumbrance')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareEncumbranceView;
