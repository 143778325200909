/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditorWYSIWYG from 'modules/_shared/components/EditorWYSIWYG';
import SaveTemplates from 'modules/_shared/components/Modals/SaveTemplates';
import { setModal } from 'modules/_shared/redux/modalActions';
import {
  getDocumentsByReference,
  updateDocument,
} from 'modules/documents/redux/documentActions';

import CKEditor from 'modules/_shared/components/CKEditor';

import hasShowDetails from 'utils/showDetails';

import './TemplateCreator.scss';
import TemplateCreatorHeader from './components/TemplateCreatorHeader';

const TemplateCreator = ({ document, fullScreen, handleToggleFullScreen }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const plan = useSelector((state) => state?.plans[0] || {});
  const partner = useSelector(
    (state) => state?.society?.actualSociety?.partners[0] || {}
  );
  const beneficiary = useSelector(
    (state) => state?.society?.actualSociety?.beneficiaries[0] || {}
  );
  const board = useSelector((state) => state?.boards?.[0] || {});

  const [htmlContent, setHtmlContent] = useState(document?.editorModel || '');
  const [editorInstance, setEditorInstance] = useState(null);

  const signatures = {};
  const tenderOfferShares = {};

  const handleSave = () => {
    const content = hasShowDetails() ? editorInstance?.getData() : htmlContent;

    const newTemplate = {
      date: new Date(),
      lastAccess: new Date(),
      category: 'TEMPLATES',
      author: user['_id'],
      society: actualSociety['_id'],
      isGenerated: false,
      isFavorite: false,
      editorModel: content,
    };

    dispatch(setModal(<SaveTemplates data={newTemplate} />));
  };

  const handleUpdate = () => {
    const content = hasShowDetails() ? editorInstance?.getData() : htmlContent;

    const updatedTemplate = {
      ...document,
      lastAccess: new Date(),
      editorModel: content,
    };

    dispatch(updateDocument(updatedTemplate));
    dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
  };

  return (
    <>
      <div className="nk-fmg-body-content">
        <TemplateCreatorHeader
          documentName={document?.name}
          fullScreen={fullScreen}
          handleToggleFullScreen={handleToggleFullScreen}
          handleSave={handleSave}
          handleUpdate={handleUpdate}
          editorModel={document?.editorModel}
        />

        {!hasShowDetails() ? (
          <div className="w-100">
            <EditorWYSIWYG
              values={{
                plan,
                partner,
                society: actualSociety,
                beneficiary,
                board,
                signatures,
                tenderOfferShares,
                operation: {},
              }}
              setEditorContent={setHtmlContent}
              editorContent={htmlContent}
            />
          </div>
        ) : (
          <div className="avoid-default-font">
            <CKEditor
              editorInstance={editorInstance}
              setEditorInstance={setEditorInstance}
              documentName={document?.name || 'export'}
              documentModel={document?.editorModel || ''}
              enableMentions
              fullScreen={fullScreen}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TemplateCreator;
