/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { store } from 'redux/store';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { updateStockPlan } from 'modules/beneficiaries/redux/planActions';
import {
  addDocument,
  getDocumentsByReference,
} from 'modules/documents/redux/documentActions';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import EditorWYSIWYG from 'modules/_shared/components/EditorWYSIWYG';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';

import Swal from 'sweetalert2';

import '../Modals.scss';

function PlanInvitation({ plan, society, invitationModel }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { editorPlan, editorPartner, editorSociety, editorBeneficiary } =
    useSelector((state) => ({
      editorPlan: state?.plans[0] || {},
      editorPartner: state?.society?.actualSociety?.partners[0] || {},
      editorSociety: state?.society?.actualSociety || {},
      editorBeneficiary: state?.society?.actualSociety?.beneficiaries[0] || {},
    }));
  const documents = useSelector((state) => state.documents);
  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [htmlContent, setHtmlContent] = useState(invitationModel?.html || '');
  const EXTRA_SIGNER_TAG = '{{FIRMA_SOCIEDAD}}';
  const [hasExtraSigner, setHasExtraSigner] = useState(false);
  const [extraSignerName, setExtraSignerName] = useState('');
  const [extraSignerEmail, setExtraSignerEmail] = useState('');
  const [attachmentFile, setAttachmentFile] = useState();
  const [attachmentFileName, setAttachmentFileName] = useState();
  const [isAttachmentSaved, setIsAttachmentSaved] = useState(false);

  const [isSaved, setIsSaved] = useState(true);

  const extraSignerHasData = !!(extraSignerName && extraSignerEmail);

  const handleChangeHtmlContent = (content) => {
    setHtmlContent(content);
    setIsSaved(false);
  };

  const handleChangeExtraSignerName = (event) => {
    setExtraSignerName(event.target.value);
    setIsSaved(false);
  };

  const handleChangeExtraSignerEmail = (event) => {
    setExtraSignerEmail(event.target.value);
    setIsSaved(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = [...event.dataTransfer.files];
    if (files.length > 1) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('MustUploadOneDocument')} </h4>`,
        confirmButtonText: t('Ok'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
      });
    } else if (files[0].type !== 'application/pdf') {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('OnlyPdfDocuments')} </h4>`,
        confirmButtonText: t('Ok'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
      });
    }
    // const pdfFiles = files.filter((file) => file.type === 'application/pdf');
    else {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t(
          'WantUploadThisDocument'
        )} </h4>`,
        confirmButtonText: t('Upload'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setAttachmentFile(files[0]);
          setAttachmentFileName(files[0].name);
          setIsAttachmentSaved(false);
        }
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChooseDocument = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    Swal.fire({
      icon: 'info',
      title: `<h4 class="nk-modal-title">${t('WantUploadThisDocument')} </h4>`,
      confirmButtonText: t('Upload'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setAttachmentFile(file);
        setAttachmentFileName(file.name);
        setIsAttachmentSaved(false);
        setIsSaved(false);
      }
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      let attachmentDocument;
      if (attachmentFile && !isAttachmentSaved) {
        const newDocument = {
          name: attachmentFile?.name,
          file: attachmentFile,
          size: attachmentFile?.size || 0,
          date: new Date(),
          fileType: fileTypes.PDF,
          lastAccess: new Date(),
          isGenerated: true,
          author: user['_id'],
          society: actualSociety['_id'],
          category: documentTypes.LETTERS,
          description: t('DocumentGenerated'),
        };
        attachmentDocument = await store.dispatch(addDocument(newDocument));
        setIsAttachmentSaved(true);
      }
      if (hasExtraSigner) {
        if (extraSignerHasData) {
          let planData = {
            invitationModel: { html: htmlContent },
            invitationExtraSigner: {
              name: extraSignerName,
              email: extraSignerEmail,
            },
          };
          if (attachmentFile && !isAttachmentSaved) {
            planData = {
              ...planData,
              invitationAttachedDocument: attachmentDocument?.['_id'],
            };
          }

          dispatch(updateStockPlan(plan?.['_id'], planData));
        } else {
          dispatch(addAlert(alertBodyTypes.INVITATION_NO_EXTRA_SIGNER_DATA));
          return;
        }
      } else {
        let planData = {
          invitationModel: { html: htmlContent },
          invitationExtraSigner: {},
        };
        if (attachmentFile && !isAttachmentSaved) {
          planData = {
            ...planData,
            invitationAttachedDocument: attachmentDocument?.['_id'],
          };
        }
        dispatch(updateStockPlan(plan?.['_id'], planData));
      }
      setIsSaved(true);
    } catch (error) {
      setIsSaved(false);
    }
  };

  const handleClose = (event) => {
    event.preventDefault();
    dispatch(setModal(null));
  };

  useEffect(() => {
    const validateExtraSigner = htmlContent.indexOf(EXTRA_SIGNER_TAG) !== -1;
    setHasExtraSigner(validateExtraSigner);
  }, [htmlContent, setHasExtraSigner]);
  useEffect(() => {
    setExtraSignerName(plan?.invitationExtraSigner?.name || '');
    setExtraSignerEmail(plan?.invitationExtraSigner?.email || '');
  }, []);

  useEffect(() => {
    if (society) {
      dispatch(getDocumentsByReference({ societyId: society['_id'] }));
    }
  }, [society]);

  useEffect(() => {
    if (documents) {
      setAttachmentFileName(
        documents.find(
          (document) => document?.['_id'] === plan?.invitationAttachedDocument
        )?.name
      );
    }
  }, [documents]);

  return (
    <>
      <Modal.Header className="border-0 pb-0">
        <h5 className="modal-title">
          {t('LetterAndConditions')}{' '}
          {!htmlContent && (
            <span className="badge rounded-pill text-danger ml-3">
              {t('EmptyInvitationLetter')}
            </span>
          )}
          {!isSaved && (
            <span className="badge rounded-pill text-warning ml-3">
              {t('UnsavedChanges')}
            </span>
          )}
        </h5>

        <a className="close cursor-pointer" onClick={handleClose}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <div className="nk-block">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                <div className="row mx-1 mt-0 mb-4">
                  <div className="col-8 pl-0 mb-3">
                    <h6>{t('LetterAndConditions')}</h6>

                    <p className="text-justify w-100">
                      {t('EachBeneficiaryWillHaveInvLetter')} <b>{'{{'}</b>
                      .&nbsp;
                      {t('AlsoIncludesVestingCalendar')}
                    </p>

                    <p className="text-justify w-100">
                      <b>{t('SeeDocument')}:</b> {t('InTheTab')} &quot;
                      {t('SeeDocument')}
                      &quot; {t('YouCanSee')}
                    </p>

                    <p className="text-justify w-100">
                      <b>{t('LetterAndConditionsOfTheP')}:</b> {plan?.name}
                    </p>
                  </div>

                  <div className="col-4 pr-0 mb-3 border-left is-disabled">
                    <label className="form-label d-flex">
                      <h6>{t('SignatoryCompany')}</h6>
                      <em
                        className="icon ni ni-info text-gray ml-2"
                        id="assistanceDelegation"
                      />
                      <Tooltip anchorId="assistanceDelegation" place="top">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t('SignatorySocietyInfoMessage'),
                          }}
                        />
                      </Tooltip>
                    </label>

                    <div className="row gy-2">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label
                            className="form-label w-100"
                            htmlFor="full-name"
                          >
                            {t('NameDenomination')}
                          </label>
                          <div className="form-control-wrap">
                            <div className="form-icon form-icon-left">
                              <em className="icon ni ni-user" />
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              value={extraSignerName}
                              disabled={!hasExtraSigner}
                              placeholder={t('IntroduceFullName')}
                              onChange={handleChangeExtraSignerName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label
                            className="form-label w-100"
                            htmlFor="birth-day"
                          >
                            {t('Email')}
                          </label>
                          <div className="form-control-wrap">
                            <div className="form-icon form-icon-left">
                              <em className="icon ni ni-mail" />
                            </div>
                            <input
                              type="email"
                              className="form-control form-control-lg"
                              value={extraSignerEmail}
                              disabled={!hasExtraSigner}
                              placeholder={t('IntroduceNewEmail')}
                              onChange={handleChangeExtraSignerEmail}
                              pattern={EMAIL_PATTERN}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card card-bordered w-100"
                    style={{
                      height: '400px',
                    }}
                  >
                    <EditorWYSIWYG
                      values={{
                        plan: editorPlan,
                        partner: editorPartner,
                        society: editorSociety,
                        beneficiary: editorBeneficiary,
                      }}
                      editorContent={htmlContent}
                      setEditorContent={handleChangeHtmlContent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div
            className="nk-block-head-content form-row"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div
              className="col-11"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <div className="card card-bordered sp-plan">
                <div
                  className="sp-plan-action card-inner"
                  style={{
                    height: '10px',
                  }}
                >
                  <div>
                    <span className="text-center w-300px d-block text-ellipsis">
                      {attachmentFileName || t('AttachDocument')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1 text-right">
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleChooseDocument}
                accept=".pdf"
              />
              <label
                htmlFor="fileInput"
                className="btn btn-light"
                style={{ marginBottom: '0px', height: '50px' }}
              >
                {t('Choose')}
              </label>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-primary mr-2"
          onClick={handleSave}
          disabled={!htmlContent || isSaved}
        >
          {t('Save')}
        </button>

        <button type="button" className="btn btn-primary" onClick={handleClose}>
          {t('EndUp')}
        </button>
      </Modal.Body>
    </>
  );
}

export default PlanInvitation;
