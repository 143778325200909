import { FC, memo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslate } from 'hooks/useTranslate';
import { useMixpanel } from 'react-mixpanel-browser';

import { Board, Society, User } from 'types';

import MenuDots from 'modules/_shared/components/MenuDots';

import menuOptions from './utils/menuOptions';

type BoardCardActionsProps = {
  board: Board;
  boardLink: string;
  actualSociety: Society;
  isDemo: boolean;
  user: User;
};

const buttonBasicStyle = 'btn btn-dim btn-white btn-outline-primary';

const BoardCardActions: FC<BoardCardActionsProps> = ({
  board,
  boardLink,
  actualSociety,
  isDemo,
  user,
}) => {
  const { t, i18n } = useTranslate();
  const mixpanel = useMixpanel();
  const history = useHistory();

  const [currentMenuOptions] = useState(menuOptions(i18n));

  const firstPartnerId = board?.participants?.[0]?.member;
  const firstPartnerRepresentantId = board?.delegationRepresentants?.[0];

  const boardLinks = {
    ADMIN: boardLink,
    PARTNER: `/voto-junta/${actualSociety?._id}/${board?._id}/${firstPartnerId}`,
    PARTNER_REPRESENTANT: `/voto-junta/${actualSociety?._id}/${board?._id}/${firstPartnerRepresentantId}`,
  };

  const buttonLabels = {
    ADMIN: t('SeeAsAdmin'),
    PARTNER: t('SeeAsPartner'),
    PARTNER_REPRESENTANT: t('SeeAsPartnerRepresentant'),
  };

  const buttonStyles = {
    ADMIN: buttonBasicStyle,
    PARTNER: `${buttonBasicStyle} ${!firstPartnerId && 'disabled'}`,
    PARTNER_REPRESENTANT: `${buttonBasicStyle} ${
      !firstPartnerRepresentantId && 'disabled'
    }`,
  };

  return (
    <div className="col-md-4">
      <div className="sp-plan-action card-inner justify-content-center">
        <div className="sp-plan-btn d-flex flex-column">
          <Link to={boardLinks.ADMIN} className={buttonStyles.ADMIN}>
            <span>{buttonLabels.ADMIN}</span>
          </Link>

          <hr className="w-100 border" />

          <Link to={boardLinks.PARTNER} className={buttonStyles.PARTNER}>
            <span>{buttonLabels.PARTNER}</span>
          </Link>

          {firstPartnerRepresentantId && (
            <>
              <hr className="w-100 border" />

              <Link
                to={boardLinks.PARTNER_REPRESENTANT}
                className={buttonStyles.PARTNER_REPRESENTANT}
              >
                <span>{buttonLabels.PARTNER_REPRESENTANT}</span>
              </Link>
            </>
          )}
        </div>

        <div className="position-absolute top-0 right-0 mt-3 mr-3">
          <MenuDots
            menuOptions={currentMenuOptions}
            // @ts-ignore
            id={board._id}
            params={{
              actualSociety,
              board,
              isDemo,
              user,
              mixpanel,
              history,
            }}
            direction="left"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(BoardCardActions);
