/* eslint-disable no-useless-escape */
import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import addDataToModel from 'utils/addDataToModel';
import draftToHtml from 'draftjs-to-html';
import hasVariablePlaceholder from 'utils/hasVariablePlaceholder';
import oldTags from './oldTags';

export const generateDocument = async (
  editorRef,
  fileName = 'Documento sin nombre.pdf'
) => {
  if (editorRef) {
    try {
      const doc = await editorRef.plugins.export.convert('clientpdf', {});

      const documentFile = new File([doc], fileName, {
        type: 'application/pdf',
      });

      const newDocument = {
        name: fileName,
        file: documentFile,
        size: documentFile.size,
        date: new Date(),
        fileType: fileTypes.PDF,
        category: documentTypes.DOCUMENTS,
        lastAccess: new Date(),
        isGenerated: true,
        description: `Generado por plantilla`,
      };

      return { data: newDocument };
    } catch (error) {
      return { error };
    }
  }
};

export const downloadDocument = async (editorContent) => {
  editorContent.plugins.export.download('clientpdf', {});
};

export const updateDocumentWithValues = (editorContent, values) => {
  const valuesAndUrls = {};

  values.forEach((tagList) => {
    tagList.menu.forEach((tag) => {
      valuesAndUrls[tag.value] = tag.url || '';
    });
  });

  let docWithValues = '';
  const docInitial = editorContent.toString();

  Object.entries(valuesAndUrls).forEach(([key, value]) => {
    const regex = new RegExp(`\\{+${key}\\}+`, 'g');

    if (value) {
      docWithValues = !docWithValues
        ? docInitial.replace(regex, value)
        : docWithValues.replace(regex, value);
    }
  });

  const hasSocietySign = hasVariablePlaceholder(docInitial, 'FIRMA_SOCIEDAD');
  const hasBeneficiarySign = hasVariablePlaceholder(
    docInitial,
    'FIRMA_BENEFICIARIO'
  );
  const hasConsolidationCalendar = hasVariablePlaceholder(
    docInitial,
    'PLAN_CONSOLIDACION_PERSONALIZADA'
  );
  const hasConsolidationCalendarNoStatus = hasVariablePlaceholder(
    docInitial,
    'PLAN_CONSOLIDACION_PERSONALIZADA_SIN_ESTADO'
  );
  const hasConsolidationCalendarNoStatusENES = hasVariablePlaceholder(
    docInitial,
    'PLAN_CONSOLIDACION_PERSONALIZADA_SIN_ESTADO_EN_ES'
  );
  const hasTenderOfferSummary = hasVariablePlaceholder(
    docInitial,
    'CUADRO_RESUMEN_TENDER_OFFER'
  );
  const hasTenderOfferSharesSummary = hasVariablePlaceholder(
    docInitial,
    'TENDER_ANEXO_RESUMEN'
  );

  const cleanedHtml = docWithValues.replace(/class="mention"/g, '');

  return {
    html: cleanedHtml,
    hasSocietySign,
    hasBeneficiarySign,
    hasConsolidationCalendar,
    hasConsolidationCalendarNoStatus,
    hasConsolidationCalendarNoStatusENES,
    hasTenderOfferSummary,
    hasTenderOfferSharesSummary,
  };
};

export const formatOldTemplates = (templates) => {
  const templateData = oldTags({
    plan: {},
    society: {},
    partner: {},
    beneficiary: {},
  });

  const templateUpdated = addDataToModel({
    model: templates,
    data: templateData,
  });

  const templateHtml = draftToHtml(templateUpdated.draft);

  return {
    ...templateUpdated,
    html: templateHtml,
  };
};
