/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import actionTypes from 'constants/actionTypes';
import { dateFormat } from 'constants/formats';
import sizes from 'constants/sizes';
import tenderOfferStatus from 'constants/tenderOfferStatus';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getDocument } from 'modules/documents/redux/documentActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import { getTenderOffersShares } from 'modules/transactions/redux/tenderOffersSharesActions';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { store } from 'redux/store';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import AddTenderOfferShares from 'modules/_shared/components/Modals/AddTenderOfferShares';
import SendTenderOfferShares from 'modules/_shared/components/Modals/SendTenderOfferShares';
import WidgetChartDoughnut, {
  legendModes,
} from 'modules/dashboard/components/WidgetChartDoughnut';
import SharesOfferParticipantRow from 'modules/transactions/components/SharesOfferParticipantRow';
import { getPercentCategory, tenderOfferChartOptions } from 'utils/charts';
import downloadFile from 'utils/downloadFile';
import { getActualSociety } from 'utils/filters';
import {
  formatCurrency,
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import {
  tenderOfferSharesStatusTypes,
  tenderOfferSharesTypes,
} from 'utils/tenderOfferSharesTypes';
import './TenderOfferSharesDetails.scss';
import parsePartnersToEligibles from './utils/parsePartnersToEligibles';

const TenderOfferSharesDetails = () => {
  const { t } = useTranslate();
  const { societyId, tenderOfferSharesId } = useParams();
  const dispatch = useDispatch();
  const printRef = useRef();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const actualTenderOffer = useSelector((state) =>
    state.tenderOffersShares.find(
      (tenderOffer) => tenderOffer._id === tenderOfferSharesId
    )
  );
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const chartOptions = tenderOfferChartOptions;
  const [currentDocument, setCurrentDocument] = useState(null);

  const [chartLegend, setChartLegend] = React.useState([]);
  const [chartData, setChartData] = React.useState(null);

  const [eligibles, setEligibles] = useState([]);

  const isTenderOfferInactive = () => {
    if (actualTenderOffer?.status === tenderOfferSharesStatusTypes.ACTIVE.value)
      return true;

    if (
      actualTenderOffer?.status === tenderOfferSharesStatusTypes.FINISHED.value
    )
      return true;

    return false;
  };

  const isEditOptionDisabled = () => {
    if (actualTenderOffer?.status === tenderOfferSharesStatusTypes.ACTIVE.value)
      return true;

    if (
      actualTenderOffer?.status === tenderOfferSharesStatusTypes.FINISHED.value
    )
      return true;

    return false;
  };

  useEffect(() => {
    if (actualSociety) {
      dispatch(getHoldingClasses(actualSociety?.['_id']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (!actualTenderOffer) return;

    let participantsShares = 0;
    actualTenderOffer?.participants?.forEach((participant) => {
      participantsShares += participant.totalShares;
    });
    const sellerPartner = actualSociety?.partners?.find(
      (partner) => partner['_id'] === actualTenderOffer?.sellerId
    );
    const sellerShares = sellerPartner?.sharesCount?.actual;

    const totalOffer = sellerShares + participantsShares;

    const chartValues = [participantsShares, sellerShares];
    const labels = [
      actualTenderOffer?.type === tenderOfferSharesTypes.BUY
        ? t('Bought')
        : t('Sold'),
      t('Available'),
    ];
    const colors = ['#798bff', '#7de1f8'];

    const legend = [
      {
        title:
          actualTenderOffer?.type === tenderOfferSharesTypes.BUY
            ? t('Bought')
            : t('Sold'),
        icon: 'ni-user',
        color: '#798bff',
        value: participantsShares,
        percent: getPercentCategory(totalOffer, participantsShares),
      },
      {
        title: t('Available'),
        icon: 'ni-user',
        color: '#7de1f8',
        value: sellerShares,
        percent: getPercentCategory(totalOffer, sellerShares),
      },
    ];
    setChartLegend(legend);
    setChartData({
      labels,
      datasets: [
        {
          fill: true,
          label: 'Porciento',
          data: chartValues,
          borderWidth: 2,
          borderColor: '#ffffff',
          backgroundColor: colors,
        },
      ],
    });
  }, [actualSociety?.partners, actualTenderOffer, t]);

  const handleOpenModalSendOffer = () => {
    dispatch(setModal(<SendTenderOfferShares data={actualTenderOffer} />));
  };

  const handleOpenModalEditOffer = () => {
    dispatch(
      setModal(
        <AddTenderOfferShares
          data={actualTenderOffer}
          action={actionTypes.EDIT}
          type={tenderOfferSharesTypes.SELL}
          size={sizes.LG}
        />
      )
    );
  };

  const getCurrentDocument = async (documentId) => {
    const doc = await store.dispatch(getDocument(documentId));
    setCurrentDocument(doc);
  };

  useEffect(() => {
    if (actualTenderOffer?.document && !currentDocument) {
      getCurrentDocument(actualTenderOffer.document);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualTenderOffer]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (societyId) {
      dispatch(getTenderOffersShares(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    const currentParticipantsId = actualTenderOffer?.participants.map(
      (participant) => participant.id
    );
    const currentPartners = actualSociety?.partners.filter(
      (partner) => !currentParticipantsId.includes(partner['_id'])
    );

    const parsedPartners = parsePartnersToEligibles(
      currentPartners,
      actualSociety
    ).filter((partner) => partner.totalShares > 0);

    setEligibles(parsedPartners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualTenderOffer?.participants]);

  return actualTenderOffer ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <div className="nk-block-head-sub">
                <Link
                  className="back-to"
                  to={
                    actualTenderOffer?.type === 'BUY'
                      ? `/tender-offers/${actualSociety?._id}`
                      : `/tender-offers-shares/${actualSociety?._id}`
                  }
                >
                  <em className="icon ni ni-arrow-left" />
                  <span>{t('OffersList')}</span>
                </Link>
              </div>
              <div className="nk-block-between-md g-4">
                <div className="nk-block-head-content">
                  <h2 className="nk-block-title fw-normal">
                    {actualTenderOffer?.name}
                  </h2>
                  <div className="nk-block-des">
                    <p>
                      {t('StartDate')}{' '}
                      {actualTenderOffer?.startDate
                        ? format(
                            new Date(actualTenderOffer?.startDate),
                            dateFormat
                          )
                        : '-'}
                      &nbsp;
                      <span
                        className={`badge badge-pill ml-1 ${
                          tenderOfferStatus[actualTenderOffer?.status]?.style
                        }`}
                      >
                        {t(tenderOfferStatus[actualTenderOffer?.status]?.label)}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="nk-block-head-content">
                  <ul className="nk-block-tools gx-3">
                    <li className="">
                      <ReactToPrint
                        trigger={() => (
                          <span className="btn btn-icon btn-primary screen-only">
                            <em className="icon ni ni-printer" />
                          </span>
                        )}
                        content={() => printRef.current}
                        documentTitle={`${t('TenderOffer')} - ${
                          actualTenderOffer?.name
                        } - ${format(new Date(), 'dd-MM-yyyy')}`}
                      />
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn btn-success screen-only"
                        onClick={() => handleOpenModalEditOffer()}
                        disabled={isEditOptionDisabled() || isDemo}
                      >
                        <em className="icon ni ni-edit mr-2" />
                        {t('EditConditions')}
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn btn-warning screen-only"
                        onClick={() => handleOpenModalSendOffer()}
                        disabled={isTenderOfferInactive() || isDemo}
                      >
                        <em className="icon ni ni-send mr-2" />
                        {t('SendOffer')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div ref={printRef} id="margin-print-only">
            <PrintHeader
              title={t('PrintHeaderTenderOffer')}
              subTitle={actualTenderOffer?.name}
            />

            <div className="nk-block">
              <div className="card card-bordered sp-plan">
                <div className="row no-gutters">
                  <div className="col-xl-8">
                    <div className="card card-bordered">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="sp-plan-head">
                            <h6 className="title">{t('OfferPeriod')}</h6>
                          </div>
                          <div className="sp-plan-desc sp-plan-desc-mb">
                            <ul className="row gx-1">
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('StartDate')}
                                  </span>
                                  {actualTenderOffer?.startDate
                                    ? format(
                                        new Date(actualTenderOffer?.startDate),
                                        dateFormat
                                      )
                                    : '-'}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('EndDate')}
                                  </span>
                                  {actualTenderOffer?.endDate
                                    ? format(
                                        new Date(actualTenderOffer?.endDate),
                                        dateFormat
                                      )
                                    : '-'}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head">
                            <h6 className="title">
                              {actualTenderOffer?.type === 'BUY'
                                ? t('Buyer')
                                : t('Seller')}
                            </h6>
                          </div>
                          <div className="sp-plan-desc sp-plan-desc-mb">
                            <ul className="row gx-1">
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">{t('Name')}</span>{' '}
                                  {actualTenderOffer?.sellerName}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('Email')}
                                  </span>{' '}
                                  {actualTenderOffer?.sellerEmail}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">{t('NIF')}</span>{' '}
                                  {actualTenderOffer?.sellerNIF}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head">
                            <h6 className="title">{t('Price')}</h6>
                          </div>
                          <div className="sp-plan-desc sp-plan-desc-mb">
                            <ul className="row gx-1">
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('PerShare')}
                                  </span>{' '}
                                  {actualTenderOffer?.isPriceByShare
                                    ? t('Yes')
                                    : t('Nope')}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('SocietyValuation')}
                                  </span>{' '}
                                  {actualTenderOffer?.isPriceBySociety
                                    ? t('Tes')
                                    : t('Nope')}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">{t('PricePerShare')}</h6>
                            </div>
                            <div className="sp-plan-amount">
                              <span className="amount">
                                {formatCurrencyDecimals(
                                  actualTenderOffer?.price
                                )}
                              </span>
                            </div>
                          </div>
                          <br />
                        </div>

                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">{t('MaximumAmount')}</h6>
                            </div>
                            <div className="sp-plan-amount">
                              <span className="amount">
                                {actualTenderOffer?.maxAmount > 0
                                  ? `${formatCurrency(
                                      actualTenderOffer?.maxAmount
                                    )}`
                                  : '-'}
                              </span>
                            </div>
                          </div>
                          <br />
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">{t('MaximumUnits')}</h6>
                            </div>
                            <div className="sp-plan-amount">
                              <span className="amount">
                                {actualTenderOffer?.maxShares > 0
                                  ? `${formatNumber(
                                      actualTenderOffer?.maxShares
                                    )}`
                                  : '-'}
                              </span>
                            </div>
                          </div>
                          <br />
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">{t('MaximumPercent')}</h6>
                            </div>
                            <div className="sp-plan-amount">
                              <span className="amount">
                                {actualTenderOffer?.maxPercentShares > 0
                                  ? `${formatNumber(
                                      actualTenderOffer?.maxPercentShares
                                    )}`
                                  : '-'}
                              </span>
                            </div>
                          </div>
                          <br />
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">
                                {t('PaymentConditions')}
                              </h6>
                              <span className="ff-italic text-soft">
                                {actualTenderOffer?.paymentConditions}
                              </span>
                            </div>
                          </div>
                          <ul className="row gx-1 mt-3">
                            <li className="col-sm-4">
                              <p>
                                <span className="text-soft d-block">
                                  % {t('Retention')}
                                </span>
                                {formatNumberDecimals(
                                  actualTenderOffer?.retentionPercent || 0
                                )}
                                %
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">{t('Comments')}</h6>
                              <span className="ff-italic text-soft">
                                {actualTenderOffer?.details}
                              </span>
                            </div>
                          </div>
                        </div>
                        {currentDocument && (
                          <div className="card-inner">
                            <div
                              className="sp-plan-link text-primary cursor-pointer"
                              onClick={() =>
                                downloadFile({
                                  documentId: currentDocument._id,
                                })
                              }
                            >
                              <span>
                                <em className="icon ni ni-file mr-1" />
                                <b>{t('OfferContract')}&nbsp;</b>
                                {currentDocument?.name}
                              </span>
                              <em className="icon ni ni-link ml-1" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4">
                    <div className="sp-plan-action card-inner p-1 tender-chart justify-content-start">
                      <h6 className="text-center pt-3">
                        {t('OfferEvolution')}
                      </h6>
                      {chartData && (
                        <WidgetChartDoughnut
                          title={t('OfferEvolution')}
                          data={{ data: chartData, options: chartOptions }}
                          legendMode={legendModes.VERTICAL}
                          legend={chartLegend}
                          isEmbedded
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SharesOfferParticipantRow
              participantsData={[
                ...actualTenderOffer.participants,
                ...eligibles,
              ]}
              offerData={actualTenderOffer}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default TenderOfferSharesDetails;
