const internalFF = 'showDetails';
const newPDF = 'newPDF';
const beneficiariesTest = 'btest';
const blockAI = 'blockAI';

const hasShowDetails = () => JSON.parse(localStorage.getItem(internalFF));

export const hasNewPDF = () => JSON.parse(localStorage.getItem(newPDF));

export const hasBeneficiariesTest = () =>
  JSON.parse(localStorage.getItem(beneficiariesTest));

export const hasInternalView = () =>
  JSON.parse(localStorage.getItem('show-dev'));

export const hasBlockAI = () => JSON.parse(localStorage.getItem(blockAI));

export default hasShowDetails;
