import i18n from 'i18n/config';
import { editUser } from 'utils/modals';
import { deleteAdmin } from './deleteAdmin';

export const menuOptions = [
  {
    id: 'EDIT_ADMIN',
    icon: 'ni-edit',
    text: i18n.t('Edit'),
    action: editUser,
  },
  {
    id: 'DELETE_ADMIN',
    icon: 'ni-trash',
    text: i18n.t('Delete'),
    action: deleteAdmin,
  },
];
