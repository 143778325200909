/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-newline */
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import Nouislider from 'nouislider-react';
import percentRound from 'percent-round';
import { useEffect, useRef, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { addInvestor } from 'modules/_shared/redux/modalsActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { updateDraft } from 'modules/drafts/redux/draftActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import { updateUser } from 'modules/profile/redux/userActions';
import { updateSociety } from 'modules/society/redux/societyActions';

import { MAX_PARTNERS_CAPTABLE } from 'constants/defaultConstants';
import draftTypes from 'constants/draftTypes';
import {
  cleanValue,
  currencyFormat,
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import menuTypes from 'constants/menuTypes';
import safeStatus from 'constants/safeStatus';

import {
  getActualSociety,
  getInvestorShares,
  getPartnerActualShares,
  getPartnerFutureShares,
  getSocietyActualShares,
  getSocietyTotalShares,
} from 'utils/filters';

import PrintButton from 'modules/_shared/components/Buttons/PrintButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import AddPartnerDraftModal from 'modules/_shared/components/Modals/AddPartnerDraft';
import ChangeNcStatus from 'modules/_shared/components/Modals/ChangeNcStatus';
import SaveDrafts from 'modules/_shared/components/Modals/SaveDrafts';
import DraftBanner from 'modules/drafts/components/DraftBanner';
import DraftSafeRow from 'modules/drafts/components/DraftSafeRow';
import PartnerBook from 'modules/partners/pages/PartnerBook';

import { useTranslate } from 'hooks/useTranslate';
import finalProcesses from './finalProcesses';

import downloadOptions from './downloadOptions';
import './styles.css';
import TransferInvestorsModal from './transferInvestorsModal';

const draftType = draftTypes.DRAFT_SAFE;

const DraftSafe = () => {
  const printRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslate();
  const { societyId, draftId } = useParams();

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const drafts = useSelector((state) => state?.drafts);
  const plans = useSelector((state) => state?.plans);
  const holdingClasses = useSelector((state) => state?.holdingClasses);
  const operations = useSelector(
    (state) => state?.society?.actualSociety.operations
  );
  const isAdmin = useSelector((state) => state?.society?.role?.isAdmin);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [rows, setRows] = useState([]);
  const [draft, setDraft] = useState({});
  const [draftName, setDraftName] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentDraft, setCurrentDraft] = useState({});
  const [currentDraftShares, setCurrentDraftShares] = useState(0);
  const [partners, setPartners] = useState([]);
  const [updatedPartners, setUpdatedPartners] = useState([]);
  const [allUpdatedPartners, setAllUpdatedPartners] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [societyShares, setSocietyShares] = useState();

  const [valuation, setValuation] = useState(0); // Valoración seleccionada
  const [valuationMin, setValuationMin] = useState(0); // Valoración Pre Money Mínima
  const [valuationMax, setValuationMax] = useState(0); // Valoración Pre Money Máxima
  const [sliderMin, setSliderMin] = useState(0); // Valoración Pre Money Máxima
  const [sliderMax, setSliderMax] = useState(1); // Valoración Pre Money Máxima
  const [sliderStep, setSliderStep] = useState(0); // Valoración Pre Money Máxima

  const [totalContribution, setTotalContribution] = useState(0); // Ampliación
  const [valuationPostMoney, setValuationPostMoney] = useState(0); // Valoración Post Money
  const [nominalValue, setNominalValue] = useState(0); // Valor nominal
  const [premium, setPremium] = useState(0); // Prima
  const [newSharesPrice, setnewSharesPrice] = useState(0); // Precio nuevas participaciones
  const [newShares, setNewShares] = useState(0); // Nuevas participaciones
  const [dilution, setDilution] = useState(0); // Dilución

  // Conditions of the Convertible Note
  const [interestPercent, setInterestPercent] = useState(0); // Interés porcentual
  const [interestPeriod, setInterestPeriod] = useState(''); // Cálculo del interés
  const [totalInterest, setTotalInterest] = useState(0); // Total de todos los intereses de los inversores
  const [dueDate, setDueDate] = useState(''); // Fecha de vencimiento
  const [discountPercent, setDiscountPercent] = useState(0); // Descuento porcentual
  const [interestFinalProcess, setInterestFinalProcess] = useState(''); // Qué hacer con el interés
  const [status, setStatus] = useState(''); // Estado de la NC

  const [touched, setTouched] = useState(false);
  const [showAllPartners, setShowAllPartners] = useState(false);
  const [currentDownloadOptions, setCurrentDownloadOptions] = useState(
    downloadOptions(i18n)
  );
  const [showPartnerBook, setShowPartnerBook] = useState(false);
  const [planUnits, setPlanUnits] = useState(0);

  // Sumary row values
  const [summaryRow, setSummaryRow] = useState({
    totalContribution: 0,
    totalInterest: 0,
    totalSharesNew: 0,
    totalSharesTotal: 0,
    totalNDTotal: 0,
    totalFDTotal: 0,
    totalFDPotential: 0,
  });
  const [allSummaryRow, setAllSummaryRow] = useState({
    totalContribution: 0,
    totalSharesNew: 0,
    totalSharesTotal: 0,
    totalNDTotal: 0,
    totalFDTotal: 0,
    totalFDPotential: 0,
  });

  const buttons = {
    equity: 'equityBtn',
    summary: 'summaryBtn',
    original: 'tableBtn',
  };

  const handleStartDraftIncrease = () => {
    const investors = updatedPartners?.filter(
      (partner) => partner?.contribution > 0
    );
    if (investors?.length) {
      dispatch(
        setModal(
          <TransferInvestorsModal investors={investors} draftId={draftId} />
        )
      );
    }
  };

  const handleExportTable = (button) => {
    const btn = document.getElementById(button);
    if (operations.length > 0) {
      if (button === buttons.equity && updatedPartners.length > 0) {
        btn.click();
      } else if (
        button === buttons.summary &&
        partners.length > 0 &&
        plans &&
        holdingClasses.length > 0
      ) {
        btn.click();
      } else if (button === buttons.original) {
        btn.click();
      }
    }
  };

  const handleSaveDraft = async () => {
    const contributors = updatedPartners?.filter(
      (partner) => partner.contribution > 0
    );
    if (draftId) {
      if (status === safeStatus.var.SAFE_ACTIVE) {
        // eslint-disable-next-line no-restricted-syntax
        for (const partner of contributors) {
          const investorData = {
            name: partner?.name,
            cif: partner?.cif || null,
            email: partner?.email || null,
            societyPartnerId: partner?.societyPartnerId || null,
            sharesCount: { drafts: partner?.shares?.new },
            contributionDate: new Date(partner?.contributionDate),
            contribution: partner?.contribution,
            realContribution: partner?.realContribution,
            totalContribution: partner?.totalPartnerContribution,
            discountPercent: partner?.discountPercent,
          };
          if (partner?.investorId) {
            const currentInvestor = investors?.find(
              (investor) => investor['_id'] === partner.investorId
            );
            await dispatch(
              updateUser(
                currentInvestor?.user,
                {
                  investor: currentInvestor['_id'],
                  society: actualSociety?.['_id'],
                  investorData: { ...investorData },
                },
                user['_id'],
                false
              )
            );
          } else {
            const newInvestor = {
              ...investorData,
              society: actualSociety['_id'],
              address: partner?.address,
              userId: user?.['_id'],
              userEmail: user?.email,
              draft: draftId,
            };
            dispatch(addInvestor(newInvestor));
          }
        }
      } else {
        const investorsToDelete = investors?.filter(
          (investor) => investor.draft === draftId
        );
        for (const investor of investorsToDelete) {
          dispatch(
            updateSociety(
              actualSociety['_id'],
              { investor: { remove: investor['_id'], user: investor?.user } },
              false
            )
          );
        }
      }
      dispatch(updateDraft(draftId, draft));
      setTouched(false);
    } else {
      dispatch(
        setModal(
          <SaveDrafts
            draftData={draft}
            draftPartners={contributors}
            draftStatus={status}
            setTouched={setTouched}
          />
        )
      );
    }
  };

  const removePartnerFromDraft = (generatedKey) => {
    const filteredPartners = partners.filter(
      (partner) => partner.generatedKey !== generatedKey
    );
    setPartners([...filteredPartners]);
  };

  const addPartnerToDraft = (partner) => {
    setPartners([...partners, partner]);
  };

  const updatePartnerToDraft = (partner) => {
    const initialPartners = [...updatedPartners];
    const newPartners = initialPartners?.map((p) =>
      p?.generatedKey === partner?.generatedKey ? partner : p
    );
    setPartners([...newPartners]);
  };

  const getCurrentDraftShares = (partnersData) =>
    partnersData.reduce((acc, partner) => {
      const partnerDraftShares =
        partner?.sharesCount?.drafts || partner?.newShares;
      return acc + partnerDraftShares || 0;
    }, 0);

  const getInitialPartnerData = (partnersData, draft) =>
    partnersData?.map((partner) => {
      const partnerDraftShares =
        draft?.status === safeStatus.var.SAFE_ACTIVE &&
        (partner?.sharesCount?.drafts || partner?.newShares)
          ? partner?.sharesCount?.drafts || partner?.newShares
          : 0;
      const investorShares = getInvestorShares(partner?.cif, actualSociety);
      const futureInvestorShares = investorShares
        ? investorShares - partnerDraftShares
        : 0;
      const generatedKey = uuidv4();
      return {
        ...partner,
        generatedKey,
        shares: {
          initial: partner?.societyPartnerId
            ? getPartnerActualShares(partner?.societyPartnerId, actualSociety)
            : 0,
          future: partner?.societyPartnerId
            ? getPartnerFutureShares(partner?.societyPartnerId, actualSociety) -
              partnerDraftShares
            : futureInvestorShares,
        },
      };
    });

  // eslint-disable-next-line no-unused-vars
  const getTotalPartnerShares = (partnersData) =>
    partnersData.map((item) => item.shares.total).reduce((a, b) => a + b, 0);

  const getTotalNewShares = (partnersData) =>
    partnersData.map((item) => item.shares?.new).reduce((a, b) => a + b, 0);

  const getTotalContribution = (partnersData) =>
    partnersData.map((item) => item.contribution).reduce((a, b) => a + b, 0);

  const getActualDraft = async (draftId) =>
    drafts.find((draft) => draft['_id'] === draftId);

  const calculatePartnersData = (partnersList) => {
    // eslint-disable-next-line prefer-const
    const updatedPartners = [];
    let totalNewShares = 0;
    partnersList.forEach((partner) => {
      const contribution = partner?.realContribution || partner.contribution;
      const cleanValueValuation = cleanValue(valuation);
      const cleanValueSocietyInterestPercent = cleanValue(interestPercent);
      const cleanPartnerDiscountPercent = cleanValue(partner.discountPercent);
      const societyActualShares = getSocietyActualShares(actualSociety);

      const daysBetween = contribution
        ? differenceInCalendarDays(
            parseISO(dueDate),
            parseISO(partner.contributionDate)
          )
        : 0;

      const interestDaily =
        (contribution * (cleanValueSocietyInterestPercent / 100)) / 365;
      const interestTotal = +(interestDaily * daysBetween).toFixed(2);

      let totalPartnerContribution = contribution;
      if (interestFinalProcess === finalProcesses.CONSOLIDATE)
        totalPartnerContribution += interestTotal;

      const discountTotal =
        cleanValueValuation * (cleanPartnerDiscountPercent / 100);
      const partnerNewShares = contribution
        ? Math.floor(
            totalPartnerContribution /
              +(
                (cleanValueValuation - discountTotal) /
                (societyShares || societyActualShares)
              )
          )
        : 0;
      const partnerTotalShares = partnerNewShares + partner?.shares?.initial;

      const partnerTotalFutureShares =
        partnerNewShares + partner?.shares?.future;

      const currentPremium =
        cleanValueValuation / (societyShares || societyActualShares) -
        nominalValue;
      const NDInitial = (partner?.shares?.initial * 100) / societyActualShares;

      const partnerContribution =
        partnerNewShares *
        ((cleanValueValuation - discountTotal) /
          (societyShares || societyActualShares));

      updatedPartners.push({
        ...partner,
        contribution: partnerContribution || 0,
        totalPartnerContribution,
        discountPercent: partner.discountPercent,
        discountTotal,
        interest: interestTotal,
        NDInitial,
        shares: {
          ...partner.shares,
          new: partnerNewShares,
          total: partnerTotalShares,
          totalFuture: partnerTotalFutureShares,
        },
      });

      setPremium(currentPremium);
      setSocietyShares(societyShares || societyActualShares);
      totalNewShares = getTotalNewShares(updatedPartners);
      setNewShares(totalNewShares);
    });

    const accumulatedTotalShares =
      getSocietyTotalShares(actualSociety) -
      currentDraftShares +
      totalNewShares;

    const accumulatedPotentialShares =
      actualSociety?.sharesCount?.actual +
      actualSociety?.sharesCount?.drafts +
      totalNewShares +
      planUnits -
      currentDraftShares;

    const accumulatedActualShares =
      getSocietyActualShares(actualSociety) + totalNewShares;

    const totalNewContribution = getTotalContribution(updatedPartners);
    setTotalContribution(totalNewContribution);

    const totalNewInterest = updatedPartners.reduce(
      (acc, partner) => acc + (partner?.interest || 0),
      0
    );
    setTotalInterest(totalNewInterest);

    const updatedNDPartners = updatedPartners.map((partner) => ({
      ...partner,
      NDTotal: (partner.shares.total * 100) / accumulatedActualShares,
      NDPercent:
        (partner.shares.total * 100) / accumulatedActualShares -
        partner.NDInitial,
      FDPotential:
        (partner.shares.totalFuture * 100) / accumulatedPotentialShares,
      FDTotal: (partner.shares.totalFuture * 100) / accumulatedTotalShares,
    }));

    if (showAllPartners) {
      const NDTotals = updatedNDPartners.map((partner) => partner.NDTotal || 0);
      const roundTotals = percentRound(NDTotals, 2);
      for (let i = 0; i < updatedNDPartners.length; i += 1) {
        updatedNDPartners[i].NDTotal = roundTotals[i] || 0;
      }
    }

    let newUpdatedPartners = updatedNDPartners;
    if (plans?.length === 1) {
      // Add dilution values for updatedPartners
      const updatedDilutionPartners = updatedNDPartners.map((partner) => {
        const societyPartner = actualSociety?.partners?.find(
          (p) => p?.['_id'] === partner?.societyPartnerId
        );
        if (societyPartner) {
          const hasDilution = societyPartner?.sharesCount?.hasDilution;
          const newSharesCount = {
            ...partner?.shares,
            dilutedFuture: hasDilution
              ? partner?.shares?.totalFuture
              : partner?.shares?.totalFuture -
                societyPartner?.sharesCount?.future,
          };
          return {
            ...partner,
            hasDilution,
            shares: newSharesCount,
          };
        }
        const newSharesCount = {
          ...partner?.shares,
          dilutedFuture: partner?.shares?.totalFuture,
        };
        return { ...partner, hasDilution: true, shares: newSharesCount };
      });
      // Add partners from society who are not in the draft
      const allPartners = [...updatedDilutionPartners];
      actualSociety?.partners?.forEach((partner) => {
        const investor = allPartners.find(
          (investorPartner) =>
            investorPartner?.societyPartnerId === partner['_id']
        );
        if (!investor) {
          const partnerFutureShares =
            partner?.sharesCount?.actual +
            partner?.sharesCount?.future +
            partner?.sharesCount?.drafts;
          const hasDilution = partner?.sharesCount?.hasDilution;
          allPartners.push({
            ...partner,
            hasDilution,
            shares: {
              initial: partner?.sharesCount?.actual,
              future: partnerFutureShares,
              dilutedFuture: hasDilution
                ? partnerFutureShares
                : partnerFutureShares - partner?.sharesCount?.future,
              total: partner?.sharesCount?.actual,
              totalFuture: partnerFutureShares,
            },
            FDTotal: (partnerFutureShares * 100) / accumulatedTotalShares,
            FDPotential:
              (partnerFutureShares * 100) / accumulatedPotentialShares,
          });
        }
      });
      // Calculate totalFD and totalPotentialFD assuming that all partners dilutes
      const totals = allPartners.reduce(
        (acc, curr) => {
          acc.totalFD += curr?.FDTotal;
          acc.totalPotential += curr?.FDPotential;
          return acc;
        },
        { totalFD: 0, totalPotential: 0 }
      );

      const totalDilutionPercents = allPartners.reduce(
        (acc, curr) => {
          const currSocietySharesCount = curr?.hasDilution
            ? accumulatedTotalShares
            : accumulatedTotalShares - actualSociety?.sharesCount?.future;
          const currSocietyPotentialSharesCount = curr?.hasDilution
            ? accumulatedPotentialShares
            : accumulatedPotentialShares - planUnits;
          acc.totalDilutionFD +=
            (curr?.shares?.dilutedFuture * 100) / currSocietySharesCount;
          acc.totalDilutionPotential +=
            (curr?.shares?.dilutedFuture * 100) /
            currSocietyPotentialSharesCount;
          return acc;
        },
        {
          totalDilutionFD: 0,
          totalDilutionPotential: 0,
        }
      );
      const { totalFD, totalPotential } = totals;
      const { totalDilutionFD, totalDilutionPotential } = totalDilutionPercents;

      const biasFD = Math.abs(totalDilutionFD - totalFD);
      const biasPotential = Math.abs(totalDilutionPotential - totalPotential);
      const dilutedPartnerCount = allPartners.reduce(
        (acc, curr) => (curr?.hasDilution ? acc + 1 : acc),
        0
      );
      const dilutionFDFactor = biasFD / dilutedPartnerCount;
      const dilutionPotentialFactor = biasPotential / dilutedPartnerCount;

      newUpdatedPartners = updatedDilutionPartners.map((partner) => {
        const currSocietySharesCount = partner?.hasDilution
          ? accumulatedTotalShares
          : accumulatedTotalShares - actualSociety?.sharesCount?.future;
        const currSocietyPotentialSharesCount = partner?.hasDilution
          ? accumulatedPotentialShares
          : accumulatedPotentialShares - planUnits;

        return {
          ...partner,
          FDTotal:
            (partner?.shares?.dilutedFuture * 100) / currSocietySharesCount,
          FDPotential:
            (partner?.shares?.dilutedFuture * 100) /
            currSocietyPotentialSharesCount,
        };
      }, 0);

      // Update computedFD for each partner that dilutes
      for (let i = 0; i < newUpdatedPartners.length; i += 1) {
        const currPartner = newUpdatedPartners[i];
        if (
          currPartner?.hasDilution &&
          currPartner?.FDTotal &&
          currPartner?.FDPotential
        ) {
          newUpdatedPartners[i].FDTotal -= dilutionFDFactor;
          newUpdatedPartners[i].FDPotential -= dilutionPotentialFactor;
        }
      }
    }

    // TODO Round percents only when all society partners are in updatedPartners
    // const NDTotals = updatedNDPartners.map((partner) => partner.NDTotal || 0);
    // const roundTotals = percentRound(NDTotals, 2);
    // for (let i = 0; i < updatedNDPartners.length; i += 1) {
    //   updatedNDPartners[i].NDTotal = roundTotals[i] || 0;
    // }

    // MARTI VERSION
    const totalDilution = (totalNewShares / accumulatedActualShares) * 100;

    // const totalDilution =
    //   (totalNewContribution * 100) / (valuation + totalNewContribution);

    // const totalDilution = updatedNDPartners
    //   .filter((partner) => partner?.contribution > 0)
    //   .reduce((a, b) => a + b.NDPercent, 0);
    setDilution(totalDilution);

    return newUpdatedPartners;
  };

  const loadInitialValuesWithPartners = async (draftId) => {
    try {
      const tempDraft = await getActualDraft(draftId);
      if (tempDraft) {
        let currentPartners;
        if (tempDraft.status === safeStatus.var.SAFE_ACTIVE) {
          const draftInvestors = actualSociety?.investors
            ?.filter((investor) => investor.draft === draftId)
            .map((investor) => ({
              ...investor,
              investorId: investor?.['_id'],
            }));
          currentPartners = draftInvestors;
        } else {
          currentPartners = tempDraft?.draftPartners;
        }
        const initialPartnerShares = getInitialPartnerData(
          currentPartners,
          tempDraft
        );
        setCurrentDraft(tempDraft);
        setCurrentDraftShares(
          tempDraft?.status === safeStatus.var.SAFE_ACTIVE
            ? getCurrentDraftShares(currentPartners)
            : 0
        );
        setSocietyShares(
          tempDraft?.status === safeStatus.var.SAFE_ACTIVE
            ? tempDraft?.societyShares
            : actualSociety?.sharesCount?.actual
        );
        setDraftName(tempDraft.name);
        setValuation(tempDraft.valuation);
        setValuationMin(tempDraft.valuationMin);
        setValuationMax(tempDraft.valuationMax);
        setInterestPercent(tempDraft.interestPercent);
        setInterestPeriod(tempDraft.interestPeriod);
        setInterestFinalProcess(tempDraft.interestFinalProcess);
        setDiscountPercent(tempDraft.discountPercent);
        setDueDate(
          tempDraft.dueDate
            ? format(new Date(tempDraft.dueDate), 'yyyy-MM-dd')
            : ''
        );
        setStatus(tempDraft.status);
        setPartners(initialPartnerShares);
        setIsLoaded(true);
      } else {
        // navigate to previous page
        history.push(`/borradores/${societyId}`);
      }
    } catch (error) {
      // TODO* create an alert to show the error
      // eslint-disable-next-line no-console
      console.log('Error loading draft data', error);
    }
  };

  const setInitialValues = () => {
    setRows([]);
    setPartners([]);
    setUpdatedPartners([]);
    setValuation(0);
    setTotalContribution(0);
    setTotalInterest(0);
    setValuationPostMoney(0);
    setNominalValue(actualSociety?.nominalValue);
    setPremium(0);
    setNewShares(0);
    setDilution(0);
    setStatus(safeStatus.var.SAFE_DRAFT);
  };

  const addAllPartnersToList = (partnerList, getAll) => {
    const newPartners = [];

    partnerList.forEach((partner) => {
      const foundPartner = updatedPartners.find(
        (p) => p.societyPartnerId === partner['_id']
      );
      if (
        !foundPartner &&
        ((partner?.sharesCount?.actual > 0 && !getAll) || getAll)
      ) {
        newPartners.push({
          name: partner?.name,
          society: actualSociety['_id'],
          contribution: 0,
          discountPercent: 0,
          shares: {
            initial: partner?.sharesCount?.actual,
            future: getPartnerFutureShares(partner['_id'], actualSociety),
          },
          isPartnerAlready: true,
          societyPartnerId: partner['_id'],
          cif: partner?.cif || '',
          email: partner?.email || '',
          address: partner?.address || {},
          generatedKey: uuidv4(),
          isAddedForcefully: true,
        });
      }
    });

    const newPartnersCalculation = calculatePartnersData([
      ...newPartners,
      ...updatedPartners,
    ]);

    if (!getAll) {
      setUpdatedPartners(newPartnersCalculation);
    } else {
      setAllUpdatedPartners(newPartnersCalculation);
    }
  };

  const removePartnersFromList = (partnerList) => {
    const newPartners = partnerList.filter(
      (partner) => partner.contribution !== 0
    );

    const newPartnersCalculation = calculatePartnersData(newPartners);

    setUpdatedPartners(newPartnersCalculation);
  };

  const downloadFileName = `${draftName || t('S.A.F.E.')} - ${
    actualSociety?.name
  }`;

  const changeNcStatus = (statusCheckboxValue) => {
    setStatus(
      statusCheckboxValue
        ? safeStatus.var.SAFE_ACTIVE
        : safeStatus.var.SAFE_DRAFT
    );
    setTouched(
      statusCheckboxValue
        ? currentDraft?.status !== safeStatus.var.SAFE_ACTIVE
        : currentDraft?.status === safeStatus.var.SAFE_ACTIVE
    );
  };
  useEffect(() => {
    setCurrentDownloadOptions(downloadOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (societyId) {
      dispatch(getHoldingClasses(societyId));
      dispatch(getStockPlans(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    setNominalValue(actualSociety?.nominalValue);
    setInvestors(actualSociety?.investors);
    if (
      actualSociety?.partners?.filter(
        (partner) => partner?.sharesCount?.actual > 0
      )?.length < MAX_PARTNERS_CAPTABLE
    ) {
      setShowPartnerBook(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (draftId) {
      loadInitialValuesWithPartners(draftId);
    } else {
      // TODO Locked temporary because of development
      setInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId]);

  useEffect(() => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (valuation && actualSociety) {
      setUpdatedPartners(calculatePartnersData(partners));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    partners,
    valuation,
    interestPercent,
    interestPeriod,
    dueDate,
    discountPercent,
    interestFinalProcess,
    planUnits,
  ]);

  useEffect(
    () => {
      if (updatedPartners.length > 0) {
        addAllPartnersToList(actualSociety?.partners, true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedPartners]
  );
  useEffect(
    () => {
      if (allUpdatedPartners) {
        setAllSummaryRow(
          allUpdatedPartners.reduce(
            (acc, partner) => {
              acc.totalContribution +=
                partner?.realContribution || partner?.contribution;
              acc.totalSharesNew += partner?.shares?.new;
              acc.totalSharesTotal += partner?.shares?.total;
              acc.totalNDTotal += partner?.NDTotal;
              acc.totalFDTotal += partner?.FDTotal;
              acc.totalFDPotential += partner?.FDPotential;
              return acc;
            },
            {
              totalContribution: 0,
              totalSharesNew: 0,
              totalSharesTotal: 0,
              totalNDTotal: 0,
              totalFDTotal: 0,
              totalFDPotential: 0,
            }
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allUpdatedPartners]
  );

  useEffect(() => {
    if (interestFinalProcess === finalProcesses.CONSOLIDATE) {
      setValuationPostMoney(
        cleanValue(valuation) + totalInterest + totalContribution
      );
    } else {
      setValuationPostMoney(cleanValue(valuation) + totalContribution);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuation, totalContribution, totalInterest]);

  useEffect(() => {
    if (valuationMin && valuationMax) {
      const minRange = cleanValue(valuationMin);
      const maxRange = cleanValue(valuationMax);

      if (maxRange > minRange) {
        setSliderMin(minRange);
        setSliderMax(maxRange);
        setSliderStep(+((maxRange - minRange) / 100).toFixed(2));
        if (!draftName) setValuation(cleanValue(valuationMin));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuationMin, valuationMax]);

  useEffect(() => {
    const units = plans
      ?.filter((plan) => !plan?.isDraft)
      ?.reduce((acc, plan) => acc + plan?.sharesTotal, 0);
    setPlanUnits(units);
  }, [plans]);

  useEffect(() => {
    if (actualSociety) {
      const newDraft = {
        percent: +dilution,
        draftType,
        society: actualSociety['_id'],
        totalContribution,
        nominalValue,
        societyShares,
        valuation: cleanValue(valuation),
        valuationMin: cleanValue(valuationMin),
        valuationMax: cleanValue(valuationMax),
        interestPercent: cleanValue(interestPercent),
        interestPeriod,
        dueDate: dueDate ? new Date(dueDate) : '',
        discountPercent: cleanValue(discountPercent),
        interestFinalProcess,
        status,
        draftPartners: JSON.stringify(
          updatedPartners
            .filter((partner) => partner.contribution > 0)
            .map((partner) => ({
              name: partner.name,
              cif: partner?.cif,
              email: partner?.email,
              society: partner.society,
              contribution: partner.contribution,
              realContribution: partner?.realContribution,
              totalPartnerContribution: partner.totalPartnerContribution,
              newShares: partner.shares.new,
              societyPartnerId: partner.societyPartnerId,
              isPartnerAlready: partner.isPartnerAlready,
              discountPercent: partner.discountPercent,
              discountTotal: partner.discountTotal,
              contributionDate: partner.contributionDate,
            }))
        ),
      };
      setDraft(newDraft);
      setRows(
        updatedPartners.map((partner, index) => (
          <DraftSafeRow
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}${partner?.name}`}
            index={index + 1}
            societyId={actualSociety['_id']}
            partner={partner}
            draft={newDraft}
            premium={premium}
            valuation={valuation}
            dueDate={dueDate}
            societyDiscountPercent={discountPercent}
            addPartnerToDraft={addPartnerToDraft}
            updatePartnerToDraft={updatePartnerToDraft}
            setTouched={setTouched}
            removePartnerFromDraft={removePartnerFromDraft}
          />
        ))
      );
      setSummaryRow(
        updatedPartners.reduce(
          (acc, partner) => {
            acc.totalContribution +=
              partner?.realContribution || partner?.contribution;
            acc.totalInterest += partner?.interest;
            acc.totalSharesNew += partner?.shares?.new;
            acc.totalSharesTotal += partner?.shares?.total;
            acc.totalNDTotal += partner?.NDTotal;
            acc.totalFDTotal += partner?.FDTotal;
            acc.totalFDPotential += partner?.FDPotential;
            return acc;
          },
          {
            totalContribution: 0,
            totalInterest: 0,
            totalSharesNew: 0,
            totalSharesTotal: 0,
            totalNDTotal: 0,
            totalFDTotal: 0,
            totalFDPotential: 0,
          }
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updatedPartners,
    valuation,
    interestPercent,
    interestPeriod,
    dueDate,
    discountPercent,
    interestFinalProcess,
    actualSociety,
    status,
  ]);

  useEffect(() => {
    if (nominalValue && premium) setnewSharesPrice(nominalValue + premium);
  }, [nominalValue, premium]);

  useEffect(() => {
    if (showAllPartners && actualSociety) {
      addAllPartnersToList(actualSociety?.partners);
    } else if (!showAllPartners && updatedPartners?.length > 0) {
      removePartnersFromList(updatedPartners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllPartners]);

  return actualSociety ? (
    <>
      <Prompt
        message={`${t('Hello')}\n ${t('DraftDontSaved')}`}
        when={!!draftName && touched && !isDemo}
      />
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('CalculateSAFE')}
                </h3>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {draftId && actualSociety && (
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => handleStartDraftIncrease()}
                          disabled={isDemo}
                        >
                          {t('StartCapitalIncreaseDraft')}
                        </button>
                      )}
                      {isAdmin && (
                        <>
                          <li className="preview-item">
                            <OperationMenuDots
                              title={t('Download')}
                              menuOptions={currentDownloadOptions}
                              params={{
                                handleExportTable,
                                buttons,
                                outlined: true,
                              }}
                            />
                          </li>
                          <li>
                            <PrintButton
                              fileName={downloadFileName}
                              printRef={printRef}
                              hideLabel
                            />
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* HEADER */}
          <div ref={printRef} id="margin-print-only">
            <PrintHeader title={t('PrintHeaderDraftSAFE')} />

            <div className="card card-bordered sp-plan">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="sp-plan-info card-inner">
                    <div className="row gx-0 gy-3">
                      <div className="col-xl-9 col-sm-8">
                        <div className="sp-plan-name">
                          <h6 className="title d-flex align-items-center">
                            {t('DataSAFE')}:{' '}
                            <span
                              className={`badge badge-pill ${safeStatus.style[status]}`}
                            >
                              {safeStatus.name[status]}
                            </span>
                            {draftId && (
                              <span
                                className={`cursor-pointer ${
                                  isDemo ? 'disabled' : ''
                                }`}
                                onClick={() => {
                                  if (!isDemo) {
                                    dispatch(
                                      setModal(
                                        <ChangeNcStatus
                                          draft={currentDraft}
                                          setStatus={setStatus}
                                        />
                                      )
                                    );
                                  }
                                }}
                              >
                                <em className="icon ni ni-edit text-primary fs-20px ml-1" />
                              </span>
                            )}
                          </h6>
                          <h5 className="sub-title text-primary">
                            {draftId && draftName}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="invest-field form-group">
                      <div className="form-label-group" />
                    </div>
                    <div className="row gy-4">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="default-01">
                            {t('ValuationPreMoneyMinimum')}
                          </label>
                          <div className="form-control-wrap">
                            <NumberFormat
                              id="outlined-normal"
                              className="form-control form-control-xl form-control-outlined"
                              value={valuationMin}
                              onChange={(e) => {
                                setValuationMin(e.target.value);
                                setTouched(true);
                              }}
                              {...numberFormatDecimals}
                            />
                            <div className="form-info">€</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="default-01">
                            {t('ValuationPreMoneyMaximum')}
                          </label>
                          <div className="form-control-wrap">
                            <NumberFormat
                              id="outlined-normal"
                              className="form-control form-control-xl form-control-outlined"
                              value={valuationMax}
                              onChange={(e) => {
                                setValuationMax(e.target.value);
                                setTouched(true);
                              }}
                              {...numberFormatDecimals}
                            />
                            <div className="form-info">€</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-4">
                        <div className="form-control-wrap">
                          <Nouislider
                            start={isLoaded ? valuation : sliderMin}
                            range={{
                              min: sliderMin,
                              max: sliderMax,
                            }}
                            step={sliderStep}
                            animate
                            tooltips
                            connect
                            disabled={sliderMin === 0 && sliderMax === 1}
                            onChange={(value) => {
                              setValuation(+value[0]);
                              setTouched(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="default-01">
                            {t('ConditionsSAFE')}
                          </label>
                          <div className="row">
                            <div className="form-control-wrap col-4">
                              <label
                                className="fs-12px mb-1"
                                htmlFor="discount-percentage"
                              >
                                {t('DiscountPercent')}
                              </label>
                              <NumberFormat
                                name="discount-percentage"
                                id="outlined-discount"
                                className="form-control form-control-outlined"
                                value={discountPercent}
                                onChange={(e) => {
                                  setDiscountPercent(e.target.value);
                                  setTouched(true);
                                }}
                                {...numberFormatDecimals}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sp-plan-desc card-inner">
                    <ul className="row gx-1 text-center align-items-end">
                      <li className="col">
                        <p>
                          <span className="text-soft">{t('VPreMoney')}</span>
                          <NumberFormat
                            value={valuation}
                            displayType="text"
                            {...currencyFormatDecimals}
                          />
                        </p>
                      </li>
                      <li className="col">
                        <p>
                          <span className="text-soft">{t('Increase')}</span>
                          <NumberFormat
                            value={totalContribution}
                            displayType="text"
                            {...currencyFormatDecimals}
                          />
                        </p>
                      </li>
                      <li className="col">
                        <p>
                          <span className="text-soft">{t('VPostMoney')}</span>
                          <NumberFormat
                            value={valuationPostMoney}
                            displayType="text"
                            {...currencyFormatDecimals}
                          />
                        </p>
                      </li>
                      <li className="col">
                        <p>
                          <span className="text-soft">{t('PrimeNewSh')}</span>
                          <NumberFormat
                            value={+newSharesPrice.toFixed(7)}
                            displayType="text"
                            {...currencyFormat}
                          />
                        </p>
                      </li>
                      <li className="col">
                        <p>
                          <span className="text-soft">{t('PrimeBySh')}</span>
                          <NumberFormat
                            value={+premium.toFixed(7)}
                            displayType="text"
                            {...currencyFormat}
                          />
                        </p>
                      </li>
                      <li className="col">
                        <p>
                          <span className="text-soft">{t('VNom')}</span>
                          <NumberFormat
                            value={+nominalValue?.toFixed(7)}
                            displayType="text"
                            {...currencyFormat}
                          />
                        </p>
                      </li>
                      <li className="col">
                        <p>
                          <span className="text-soft">{t('NewSh')}</span>
                          <NumberFormat
                            value={newShares}
                            displayType="text"
                            {...numberFormat}
                          />
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="sp-plan-action card-inner">
                    <div className="pricing-head w-100">
                      <div className="pricing-title mb-4">
                        <h4 className="card-title title text-center">
                          {t('Valuation')}:
                          <br />
                          <NumberFormat
                            value={valuation}
                            displayType="text"
                            {...currencyFormat}
                          />
                        </h4>
                      </div>
                      <div className="card-text mb-4">
                        <div className="row">
                          <div className="col-6 text-center">
                            <span className="h5 fw-500">
                              <NumberFormat
                                value={dilution}
                                displayType="text"
                                suffix="%"
                                {...numberFormatDecimals}
                              />
                            </span>
                            <span className="sub-text">
                              {t('PercentDilution')}
                            </span>
                          </div>
                          <div className="col-6 text-center">
                            <span className="h5 fw-500">
                              <NumberFormat
                                value={totalContribution}
                                displayType="text"
                                {...currencyFormatDecimals}
                              />
                            </span>
                            <span className="sub-text">{t('IncrAport')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="nk-block-head-content">
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={!touched || isDemo}
                        onClick={handleSaveDraft}
                      >
                        {t('SaveDraft')}
                      </button>
                    </div>
                    <div className="custom-control custom-control-sm custom-checkbox custom-control-pro mt-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="countForFD-checkbox"
                        checked={
                          status === safeStatus.var.SAFE_ACTIVE ||
                          status === safeStatus.var.SAFE_EXECUTED
                        }
                        onChange={(event) =>
                          changeNcStatus(event.target.checked)
                        }
                        disabled={
                          status === safeStatus.var.SAFE_EXECUTED || isDemo
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="countForFD-checkbox"
                      >
                        {t('InForce')}*
                      </label>
                    </div>
                    <p className="sub-text mt-2 text-justify">
                      {t('SAFEMemo')}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* LIST */}
            <div className="invest-field form-group">
              <div className="form-label-group">
                <label className="form-label" />
              </div>
              <div className="nk-block nk-block-lg">
                <div className="card card-preview">
                  <div className="card-inner px-0">
                    <div className="nk-block-head-content d-flex flex-row justify-content-between screen-only">
                      <button
                        type="button"
                        className="btn btn-success mb-3 screen-only"
                        onClick={() =>
                          dispatch(
                            setModal(
                              <AddPartnerDraftModal
                                addPartnerToDraft={addPartnerToDraft}
                                updatePartnerToDraft={updatePartnerToDraft}
                                currentDraft={draft}
                                valuation={valuation}
                                premium={premium}
                                noteConditions={{
                                  discountPercent,
                                  dueDate,
                                }}
                                draftType={draftType}
                                setTouched={setTouched}
                              />
                            )
                          )
                        }
                      >
                        {t('AddNewInvestor')}
                      </button>
                      <div className="form-group mt-2">
                        <div className="custom-control custom-control-xs custom-checkbox">
                          <input
                            type="checkbox"
                            id="show-partners"
                            className="custom-control-input"
                            value={showAllPartners}
                            checked={showAllPartners}
                            onChange={(event) =>
                              setShowAllPartners(event.target.checked)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="show-partners"
                          >
                            {t('ShowAllPartners')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <table
                      className="datatable-init nk-tb-list nk-tb-ulist"
                      data-auto-responsive="false"
                      id="safe-table"
                    >
                      <thead>
                        <tr className="nk-tb-item nk-tb-head">
                          <th className="nk-tb-col text-center">
                            <span className="sub-text">{t('Partner')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('Date')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">
                              {t('Contribution')}
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('Discount')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text">{t('NewSh')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text">{t('TotalSh')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text">{t('%ND')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text">{t('%FD')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text">
                              {t('%PotentialFD')}
                            </span>
                          </th>

                          <th className="nk-tb-col nk-tb-col-tools text-right" />
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                      {/* Add a footer with summary of each column */}
                      <tfoot className="tb-tnx-head border-top">
                        <tr className="nk-tb-item nk-tb-head">
                          <th className="nk-tb-col text-center">
                            <span className="sub-text">Total</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text" />
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text text-right">
                              <NumberFormat
                                value={summaryRow?.totalContribution}
                                displayType="text"
                                {...currencyFormatDecimals}
                              />
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text" />
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text  text-right">
                              <NumberFormat
                                value={summaryRow?.totalSharesNew}
                                displayType="text"
                                {...numberFormat}
                              />
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text  text-right">
                              <NumberFormat
                                value={summaryRow?.totalSharesTotal}
                                displayType="text"
                                {...numberFormat}
                              />
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text text-right">
                              <NumberFormat
                                value={summaryRow?.totalNDTotal}
                                displayType="text"
                                suffix="%"
                                {...numberFormatDecimals}
                              />
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text text-right">
                              <NumberFormat
                                value={summaryRow?.totalFDTotal}
                                displayType="text"
                                suffix="%"
                                {...numberFormatDecimals}
                              />
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-xl text-center">
                            <span className="sub-text text-right">
                              <NumberFormat
                                value={summaryRow?.totalFDPotential}
                                displayType="text"
                                suffix="%"
                                {...numberFormatDecimals}
                              />
                            </span>
                          </th>
                          <th className="nk-tb-col nk-tb-col-tools text-right" />
                        </tr>
                      </tfoot>
                    </table>

                    {showPartnerBook ? (
                      <div className="nk-block nk-block-embeded mw-none">
                        <PartnerBook
                          rows={rows}
                          updatedPartners={allUpdatedPartners}
                          summaryRow={allSummaryRow}
                          draftView
                          convertibleNoteView
                          capitalizedNote={
                            status === safeStatus.var.SAFE_ACTIVE ||
                            status === safeStatus.var.SAFE_EXECUTED
                          }
                        />
                      </div>
                    ) : (
                      <DraftBanner setShowPartnerBook={setShowPartnerBook} />
                    )}

                    <ReactHTMLTableToExcel
                      id="tableBtn"
                      className="d-none"
                      table="safe-table"
                      filename={downloadFileName}
                      sheet={t('CapitalIncrease')}
                      buttonText={`${t('Download')}`}
                      buttonComponent={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <button
                          type="button"
                          className="btn btn-white btn-outline-light"
                        >
                          <em className="icon ni ni-file-download" />
                        </button>
                      }
                    />
                    <ReactHTMLTableToExcel
                      id="equityBtn"
                      className="d-none"
                      table="partnerBookTable"
                      filename={`${t('PartnerBook')} - ${actualSociety?.name}`}
                      sheet="Libro de Socios"
                      buttonText={`${t('Download')}`}
                      buttonComponent={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <button
                          type="button"
                          className="btn btn-white btn-outline-light"
                        >
                          <em className="icon ni ni-file-download" />
                        </button>
                      }
                    />
                    <ReactHTMLTableToExcel
                      id="summaryBtn"
                      className="d-none"
                      table="partnerClassTable"
                      filename={`${t('Summary')} - ${actualSociety?.name}`}
                      sheet="Libro Registro de Socios"
                      buttonText={`${t('Download')}`}
                      buttonComponent={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <button
                          type="button"
                          className="btn btn-white btn-outline-light"
                        >
                          <em className="icon ni ni-file-download" />
                        </button>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default DraftSafe;
