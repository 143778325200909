/* eslint-disable react/react-in-jsx-scope */

import documentTypes from 'constants/documentTypes';
import { updateSociety } from 'modules/society/redux/societyActions';
import { store } from 'redux/store';
import Swal from 'sweetalert2';
import { setModal } from 'modules/_shared/redux/modalActions';
import ChangeCouncilMinuteNameModal from './ChangeCouncilMinuteNameModal';
import DownloadCouncilMinuteModal from './DownloadCouncilMinuteModal';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-edit-alt',
      text: i18n.t('Rename'),
      action: ({ currentCouncilMinute, actualSociety }) => {
        store.dispatch(
          setModal(
            <ChangeCouncilMinuteNameModal
              actualSociety={actualSociety}
              currentCouncilMinute={currentCouncilMinute}
            />
          )
        );
      },
      dontShowCurrent: ({ isDemo }) => isDemo === true,
    },
    {
      icon: 'ni-file-pdf',
      text: i18n.t('Discharge'),
      action: async ({ documents, currentCouncilMinute }) => {
        const docs = documents
          ?.filter(
            (document) =>
              document?.category === documentTypes.COUNCILMINUTES &&
              document?.subcategory === currentCouncilMinute['_id']
          )
          .sort((a, b) => new Date(a.date) - new Date(b.date));

        store.dispatch(
          setModal(
            <DownloadCouncilMinuteModal
              documents={docs}
              currentCouncilMinute={currentCouncilMinute}
            />
          )
        );
      },
    },
    {
      icon: 'ni-trash-alt',
      text: i18n.t('Delete'),
      action: ({ currentCouncilMinute, actualSociety }) => {
        Swal.fire({
          icon: 'warning',
          title: `<h4 className="nk-block-title page-title">${i18n.t(
            'AreYouSureAboutDelCouncilMinute'
          )}  `,
          html: `<h3 class="text-primary fw-normal">${
            currentCouncilMinute.label
          } </h3><br /><div class="caption-text">${i18n.t(
            'ThisProcedureCantUndo'
          )}</div>`,
          confirmButtonText: i18n.t('OkDelete'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            const newCouncilMinuteData = actualSociety?.councilMinutes.filter(
              (councilMinute) =>
                councilMinute['_id'] !== currentCouncilMinute['_id']
            );
            store.dispatch(
              updateSociety(actualSociety['_id'], {
                councilMinutes: newCouncilMinuteData,
              })
            );
          }
        });
      },
      dontShowCurrent: ({ isDemo }) => isDemo === true,
    },
  ];
}
export default menuOptions;
