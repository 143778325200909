import i18n from 'i18n/config';

export default [
  {
    id: 'my-profile',
    icon: 'ni-user-alt',
    text: i18n.t('MyProfile'),
    url: '/perfil-usuario/datos',
  },
  {
    id: 'users-with-access',
    icon: 'ni-lock-alt',
    text: i18n.t('UsersWithAccess'),
    url: '/perfil-usuario/administradores',
  },
];
