/* eslint-disable react/jsx-indent */
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Society } from 'types';

import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import { setModal } from 'modules/_shared/redux/modalActions';
import menuOptions from './menuOptions';

type Props = {
  user: Record<string, any>;
  isDemo: boolean;
  isAdmin: boolean;
  actualSociety: Society;
  partnerRepresentants: any;
};

const RepresentantsListHeader: FC<Props> = (props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { user, isDemo, isAdmin, actualSociety, partnerRepresentants } = props;

  return (
    <PageListHeader
      title={actualSociety?.name}
      subTitle={t('PartnerRepresentants')}
      description={t('RepresentantsListDescription', {
        societyName: actualSociety?.name,
        count: partnerRepresentants?.length || 0,
      })}
      actions={
        isAdmin
          ? [
              <OperationMenuDots
                title={t('AddPartnerRepresentant')}
                menuOptions={menuOptions}
                handleModal={(Modal: any) =>
                  dispatch(
                    setModal(
                      <Modal user={user} societyId={actualSociety?.['_id']} />
                    )
                  )
                }
                disabled={isDemo}
              />,
            ]
          : []
      }
    />
  );
};

export default RepresentantsListHeader;
