import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';

import { Partner, PartnerCategory, Society } from 'types';

type Option = {
  label: string;
  value: string;
};

type Props = {
  currentPartner: Partner;
  actualSociety: Society;
  partnerCategories: PartnerCategory[];
};

export function useGetPartnerSelectedOptions({
  currentPartner,
  actualSociety,
  partnerCategories,
}: Props) {
  const { t } = useTranslate();

  const [selectedCategoryOption, setSelectedCategoryOption] =
    useState<Option>();
  const [selectedSindicationOption, setSelectedSindicationOption] =
    useState<Option>();
  const [selectedCivilStatusOption, setSelectedCivilStatusOption] =
    useState<Option>();

  useEffect(() => {
    const category = partnerCategories.find(
      (category) => category?._id === currentPartner?.category
    );
    if (category) {
      const option = {
        value: category?._id,
        label: category?.name,
      };
      setSelectedCategoryOption(option);
    }
  }, [currentPartner, partnerCategories]);

  useEffect(() => {
    const sindicationPartner = actualSociety?.partners?.find(
      (partner) => partner?.['_id'] === currentPartner?.sindication
    );
    if (sindicationPartner) {
      const option = {
        value: currentPartner?.sindication || '',
        label: `${sindicationPartner?.cif} | ${sindicationPartner?.name}`,
      };
      setSelectedSindicationOption(option);
    }
  }, [actualSociety, currentPartner, partnerCategories]);

  useEffect(() => {
    const option = {
      value: currentPartner?.civilStatus || '',
      label: t(currentPartner?.civilStatus) || t('SelectCivilStatus'),
    };
    setSelectedCivilStatusOption(option);
  }, [currentPartner]); // eslint-disable-line

  return {
    data: {
      selectedCategoryOption,
      selectedSindicationOption,
      selectedCivilStatusOption,
    },
    isLoading: false,
    isError: false,
    actions: {
      setSelectedCategoryOption,
      setSelectedSindicationOption,
      setSelectedCivilStatusOption,
    },
  };
}
