import i18n from 'i18n/config';

import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';

import { store } from 'redux/store';
import { updateSociety } from 'modules/society/redux/societyActions';
import {
  addDocument,
  getDocumentsByReference,
} from 'modules/documents/redux/documentActions';

import { User } from 'types';

type UploadDocumentProps = {
  file: File;
  societyId: string;
  subcategory: string;
  user: User;
  linkedTo?: [
    {
      linkedId: String;
      linkedType: String;
    }
  ];
};

const uploadDocument = async (data: UploadDocumentProps) => {
  const { file, societyId, subcategory, user } = data;

  const newDocument = {
    file,
    date: new Date(Date.now()),
    size: file?.size,
    name: file?.name,
    author: user?.['_id'],
    society: societyId,
    fileType: fileTypes[file?.type as keyof typeof fileTypes],
    category: documentTypes.SCRIPTURES,
    subcategory,
    description: i18n.t('DocumentLinkedManually'),
    isForAdmins: false,
    linkedTo: data?.linkedTo || [],
  };

  const uploadedDocument = await store.dispatch(addDocument(newDocument));
  const uploadData = {
    docs: {
      add: uploadedDocument,
      ...{ partner: [user?.['_id']] },
    },
  };

  store.dispatch(updateSociety(societyId, uploadData, false));
  store.dispatch(getDocumentsByReference({ societyId }));

  return uploadedDocument;
};

export default uploadDocument;
