import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { Board } from 'types';

import boardTypes from 'constants/boardTypes';
import boardStatus from 'constants/boardStatus';
import votePeriods from 'constants/votePeriods';
import attendanceTypes from 'constants/attendanceTypes';
import { formatDateToTime } from 'constants/formats';

import { updateBoard } from 'modules/boards/redux/boardActions';
import { format } from 'date-fns';

type BoardCardDetailsProps = {
  board: Board;
  boardLink: string;
  isDemo: boolean;
};

const BoardCardDetails: FC<BoardCardDetailsProps> = ({
  board,
  boardLink,
  isDemo,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const {
    _id,
    name,
    details,
    isActive,
    status,
    date,
    place,
    attendanceType,
    votePeriod,
    boardType,
  } = board;

  const handleChangeActiveValue = async () => {
    dispatch(updateBoard(_id, { isActive: !isActive }, false));
  };

  return (
    <div className="col-md-8">
      <div className="sp-plan-info card-inner">
        <div className="row gx-0 gy-3">
          <div className="col-xl-9 col-sm-8">
            <div className="sp-plan-name">
              <h6 className="title">
                <Link to={boardLink}>
                  {name}&nbsp;
                  <span
                    className={`badge badge-pill ${boardStatus?.[status]?.style}`}
                  >
                    {boardStatus?.[status]?.text || ''}
                  </span>
                </Link>
              </h6>
              <p>
                {t('Details')}: <span className="text-base">{details}</span>
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-sm-4">
            <div className="sp-plan-opt">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`board-status-switch-${_id}`}
                  checked={isActive}
                  onChange={handleChangeActiveValue}
                  disabled={isDemo}
                />

                <label
                  className="custom-control-label text-soft"
                  htmlFor={`board-status-switch-${_id}`}
                >
                  {t('ActiveVoting')}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sp-plan-desc card-inner">
        <ul className="row gx-1">
          <li className="col-6 col-lg-4">
            <p>
              <span className="text-soft">{t('Date')}</span>{' '}
              {format(new Date(date), 'dd/MM/yyyy')}
            </p>
          </li>
          <li className="col-6 col-lg-4">
            <p>
              <span className="text-soft">{t('StartTime')}</span>{' '}
              {formatDateToTime(date)}
            </p>
          </li>

          {attendanceType === attendanceTypes.FACE_TO_FACE.value ||
          attendanceType === attendanceTypes.HYBRID.value ? (
            <li className="col-6 col-lg-4">
              <p>
                <span className="text-soft">{t('PlaceOfCelebration')}</span>{' '}
                {place || '-'}
              </p>
            </li>
          ) : (
            <></>
          )}

          <li className="col-6 col-lg-4">
            <p>
              <span className="text-soft">{t('AssistanceForm')}</span>{' '}
              {attendanceTypes?.[attendanceType]?.text || '-'}
            </p>
          </li>

          <li className="col-6 col-lg-4">
            <p>
              <span className="text-soft">{t('VotingPeriod')}</span>{' '}
              {votePeriods.find((period) => period.value === votePeriod)
                ?.text || '-'}
            </p>
          </li>
          <li className="col-6 col-lg-4">
            <p>
              <span className="text-soft">{t('BoardType')}</span>{' '}
              {boardTypes?.[boardType]?.text || '-'}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default memo(BoardCardDetails);
