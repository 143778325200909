/* eslint-disable no-use-before-define */
import { Document } from './Document';
import { Partner } from './Partner';
import { Share } from './Share';

export type Society = {
  _id: string;
  name: string;
  cif: string;
  web: string;
  phone: string;
  creationDate: Date;
  legalAddress: {
    line1: string;
    zip: string;
    city: string;
    country: string;
  };
  additional: {
    logo: string;
    description: string;
    certificateSign: string;
  };
  language: string;
  societyLanguage: string;
  societyType: keyof typeof SocietyTypes;
  activePartners: number;
  currentPartner: number;
  currentBeneficiary: number;
  currentInvestor: number;
  nominalValue: number;
  annualValues: {
    year: string;
    contableValue: number;
    billing: number;
    ebitda: number[];
    modifiedDate: Date;
  }[];
  sharesCount: {
    actual: number;
    future: number;
    total: number;
  };
  mainAdmin: string;
  documents: Document[];
  shares: Share[];
  operations: Record<string, any>[];
  stockPlans: Record<string, any>[];
  drafts: Record<string, any>[];
  tenderOffers: Record<string, any>[];
  boards: Record<string, any>[];
  partners: Partner[];
  beneficiaries: Record<string, any>[];
  investors: Record<string, any>[];
  administrators: Record<string, any>[];
  guests: Record<string, any>[];
  employees: Record<string, any>[];
  employeesPlans: Record<string, any>[];
  sendNotifications: boolean;
  societyValues: SocietyValue[];
  minutebookFolders: {
    label: string;
  }[];
  myFolders: {
    label: string;
  }[];
  councilMinutes: {
    label: string;
  }[];
  mainDirector: string;
  directors: Record<string, any>[];
  representatives: Record<string, any>[];
  auditors: Record<string, any>[];
  communications: Record<string, any>[];
  updateSocietyValueND: boolean;
  updateSocietyValueFD: boolean;
  integrations: {
    factorial: {
      enabled: boolean;
      key: string;
      validated: boolean;
    };
    personio: {
      enabled: boolean;
      key: string;
      validated: boolean;
    };
  };
  isSocietyHolding: boolean;
  isSocietyDemo: boolean;
  hasAccessCaptable: boolean;
  hasShareTags: boolean;
  sharesCertificateTemplate: string;
  demos: Record<string, any>[];
  customization: SocietyCustomization;
  configuration: SocietyConfiguration;
};

export type SocietyChart = {
  holdingId: string;
  holdingName: string;
  holdingCIF: string;
  holdingLogo: string;
  holdingShares: number;
  holdingPercentND: number | null;
  holdingPercentFD: number | null;
  holdingReference: string[] | null;
  holdingReferenceLevel: number;
  holdingKey: string;
  holdingValue?: number;
  holdingPartners?: any;
  holdingEmail?: string;
  holdingUserType?: string;
};

export type SocietyCustomization = {
  theme: {
    logo: string;
    headerColor: string;
    headerTextColor: string;
    headerTextHoverColor: string;
    emailFrameColor: string;
    emailFrameLogo: string;
    emailHeadingsColor: string;
    emailBodyTextColor: string;
    emailLinkColor: string;
    emailButtonColor: string;
    emailButtonTextColor: string;
    buttonRounded: boolean;
  };
};

export type SocietyConfiguration = {
  hasWhiteBranding: boolean;
  hasCustomLanding?: boolean;
  hasLoginRedirect?: boolean;
  hasOldPartnersOn?: boolean;
  communications?: SocietyCommunicationsConfiguration;
};

export type SocietyEmailTheme = {
  logo: string;
  frameColor: string;
  headingsColor: string;
  bodyTextColor: string;
  linkColor: string;
  buttonColor: string;
  buttonTextColor: string;
  buttonRounded: string;
};

export type SocietyCommunicationsConfiguration = {
  senderName: string;
  senderEmail: string;
  senderDomain?: string;
  replyEnabled: boolean;
  replyEmail: string;
  customLogo: string;
  customBackgroundColor: string;
  hasWhiteBranding: boolean;
  emailSignatureEnabled: boolean;
  emailSignatureHTML: string;
  emailSignature?: SocietyEmailSignature;
  theme: SocietyEmailTheme;
};

export type SocietyEmailSignature = {
  name: string;
  jobTitle: string;
  logo: string;
  website: string;
  phone: string;
  address: string;
  footer: string;
};

export enum SocietyTypes {
  SL = 'SL', // eslint-disable-line
  SA = 'SA', // eslint-disable-line
  COOP = 'COOP', // eslint-disable-line
  OTHER = 'OTHER', // eslint-disable-line
}

export type SocietyPermissions = {
  customLanding: { enabled: boolean };
  dashboard: { enabled: boolean };
  partners: { enabled: boolean };
  portfolio: { enabled: boolean };
  drafts: { enabled: boolean };
  plans: { enabled: boolean };
  transactions: { enabled: boolean };
  communications: { enabled: boolean };
  boards: { enabled: boolean };
  documents: { enabled: boolean };
  societyConfiguration: { enabled: boolean };
};

export type SocietyValue = {
  date: Date;
  value: number;
  shareValue: number;
  isPriceByShare: boolean;
  futureValues?: number[];
};
