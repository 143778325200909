import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { dateFormat } from 'constants/formats';
import invitationStatus from 'constants/invitationStatus';
import { calculateConsolidationPercent } from 'utils/consolidationPeriods';
import { getBeneficiaryAmount } from 'utils/filters';
import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import BeneficiaryPlansTabRow from './BeneficiaryPlansTabRow';

function BeneficiaryPlansTab({ society, beneficiary, beneficiaryAllPlans }) {
  const { t } = useTranslate();

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [rows, setRows] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);

  const getSocietyShares = () =>
    Number(
      society.sharesCount?.actual +
        society.sharesCount?.future +
        society?.sharesCount?.drafts
    );

  const getConsolidatedPercent = (b) => {
    if (b?.isCanceled && new Date(b?.cancelationDate) <= new Date()) {
      return Number((b.finalConsolidatedUnits * 100) / b.sharesCount.future);
    }

    return calculateConsolidationPercent(Date.now(), b, b.planData);
  };

  const getInvitationStatus = (b) => {
    const status = b?.invitationStatus;

    if (status === invitationStatus.PENDING.value || status === '') {
      return invitationStatus.PENDING;
    }

    return invitationStatus[status];
  };

  const getTotalUnits = (beneficiaries) => {
    let total = 0;

    beneficiaries.forEach((b) => {
      total += b.sharesCount?.future;
    });

    return total;
  };

  useEffect(() => {
    setRows(
      beneficiaryAllPlans.map((b, index) => {
        const plan = b.planData;
        const amount = getBeneficiaryAmount(b, b.planData);
        const sharePrice =
          b?.customConditions?.sharePrice || b.planData?.sharePrice;
        const planName = b.planData?.name;
        const grantDate = format(new Date(b.planStartDate), dateFormat);
        const unitPrice = formatCurrencyDecimals(sharePrice);
        const unitTotal = formatNumber(b.sharesCount?.future);
        const FDPercent = (
          (b.sharesCount?.future * 100) /
          getSocietyShares()
        ).toFixed(2);
        const exercisePrice = formatCurrencyDecimals(amount);
        const consolidatedPercent = formatNumberDecimals(
          getConsolidatedPercent(b)
        );
        const invitationStatus = getInvitationStatus(b);

        return (
          <BeneficiaryPlansTabRow
            key={b['_id']}
            index={index + 1}
            plan={plan}
            society={society}
            beneficiary={b}
            planName={planName}
            grantDate={grantDate}
            unitPrice={unitPrice}
            unitTotal={unitTotal}
            FDpercent={FDPercent}
            exercisePrice={exercisePrice}
            consolidatedPercent={consolidatedPercent}
            invitationStatus={invitationStatus}
          />
        );
      })
    );
    setTotalUnits(getTotalUnits(beneficiaryAllPlans));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaryAllPlans]);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">{t('PlansTabTitle')}</h4>
            <div className="nk-block-des">
              <p>
                {t('PlansTabSubtitle', {
                  societyName: society?.name || '',
                  beneficiaryName: beneficiary?.name || '',
                })}
              </p>
            </div>
          </div>
        </div>
      </div>

      {rows.length > 0 && (
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered card-preview">
            <table className="table table-striped" id="consolidationTable">
              <thead>
                <tr>
                  <th scope="col" className="d-none d-md-table-cell">
                    {t('Nr')}
                  </th>
                  <th scope="col">{t('Plan')}</th>
                  <th scope="col" className="d-none d-md-table-cell">
                    {t('GrantDate')}
                  </th>
                  <th scope="col" className="d-none d-md-table-cell">
                    {t('StrikePrice')}
                  </th>
                  <th scope="col" className="d-none d-md-table-cell">
                    {t('UnitsNr')}
                  </th>
                  <th scope="col" className="d-none d-md-table-cell">
                    {t('%FD')}
                  </th>
                  <th scope="col" className="d-none d-md-table-cell">
                    {t('ExercisePrice')}
                  </th>
                  <th scope="col" className="d-none d-md-table-cell">
                    {t('%Consolid.')}
                  </th>
                  <th scope="col" className="d-none d-md-table-cell">
                    {t('Invitation')}
                  </th>
                  {!isDemo && (
                    <th scope="col" className="text-center">
                      {t('+Info')}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
              <tfoot>
                <tr>
                  <td colSpan="4" className="fw-bold d-none d-md-table-cell">
                    {t('SumTotalUnitsGranted')}
                  </td>
                  <td className="fw-bold d-none d-md-table-cell text-right">
                    {formatNumber(totalUnits)}
                  </td>
                  <td colSpan="4" className="d-none d-md-table-cell" />
                  {!isDemo && <td className="text-right" />}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default BeneficiaryPlansTab;
