import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';

import CellData from 'modules/_shared/components/Tables/CellData';
import BeneficiaryInvitation from 'modules/beneficiaries/modals/BeneficiaryInvitation';

import { isMobileScreen } from 'utils/getScreenSize';

function BeneficiaryPlansTabRow({
  index,
  planName,
  grantDate,
  unitPrice,
  unitTotal,
  FDpercent,
  exercisePrice,
  consolidatedPercent,
  invitationStatus,

  plan,
  society,
  beneficiary,
}) {
  const dispatch = useDispatch();
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  return (
    <>
      <tr
        className="cursor-pointer"
        onClick={() =>
          dispatch(
            setModal(
              <BeneficiaryInvitation
                beneficiary={beneficiary}
                society={society}
                plan={plan}
              />
            )
          )
        }
      >
        <td className="fw-bold d-none d-md-table-cell">{index}</td>
        <td
          id={plan?.['_id']}
          className={`text-left d-block text-ellipsis text-primary ${
            isMobileScreen() ? 'w-200px' : 'w-100px'
          }`}
        >
          {planName}
          <Tooltip anchorId={plan?.['_id']} place="bottom">
            {planName}
          </Tooltip>
        </td>
        <td className="d-none d-md-table-cell text-center">
          {grantDate || '-'}
        </td>
        <td className="d-none d-md-table-cell text-right">
          {unitPrice || '0€'}
        </td>
        <td className="d-none d-md-table-cell text-right">
          {unitTotal || '0'}
        </td>
        <td className="d-none d-md-table-cell text-right">
          {FDpercent || '0'}%
        </td>
        <td className="d-none d-md-table-cell text-right">
          {exercisePrice || '0€'}
        </td>
        <td className="d-none d-md-table-cell text-right">
          {consolidatedPercent || '0'}%
        </td>
        <td className="d-none d-md-table-cell text-left">
          {CellData.BadgeText({
            text: invitationStatus?.text,
            style: invitationStatus?.color || 'dark',
          })}
        </td>
        {!isDemo && (
          <td className="text-center p-0">
            <span
              id={`beneficiary-plan-link-${index}`}
              className="btn btn-icon btn-trigger"
              onClick={() =>
                dispatch(
                  setModal(
                    <BeneficiaryInvitation
                      beneficiary={beneficiary}
                      society={society}
                      plan={plan}
                    />
                  )
                )
              }
            >
              <em className="icon ni ni-external text-primary" />
            </span>
          </td>
        )}
      </tr>
    </>
  );
}

export default BeneficiaryPlansTabRow;
