/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

export default function reducer(drafts = initialState.drafts, action) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.drafts;

    case actionTypes.CREATE_DRAFT:
      if (action.draft) {
        const draftParsed = {
          ...action.draft,
          draftPartners: JSON.parse(action.draft?.draftPartners),
        };
        return [...drafts, draftParsed];
      }
      return [...drafts];

    case actionTypes.GET_DRAFTS:
      return action.data;

    case actionTypes.UPDATE_DRAFT:
      const currentId = action.draft['_id'];

      const updatedDrafts = drafts?.map((draft) => {
        if (draft['_id'] === currentId) {
          return action.draft;
        }
        return draft;
      });
      return [...updatedDrafts];

    default:
      return drafts;
  }
}
