/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Swal from 'sweetalert2';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { updateSociety } from 'modules/society/redux/societyActions';

import { EMAIL_PATTERN, SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import documentLinkTypes from 'constants/documentLinkTypes';
import removeAdminDeletedDocuments from 'utils/removeAdminDeletedDocuments';
import uploadAdminDocuments from 'utils/uploadAdminDocuments';

import CommentsCharCounter from 'modules/_shared/components/CommentsCharCounter';
import UploadDocumentsSection from 'modules/documents/components/UploadDocumentsSection';
import representativeTypesOptions from 'modules/society/components/SocietyRepresentative/representativeTypesOptions';
import statusTypesOptions from 'modules/society/components/SocietyRepresentative/statusTypeOptions';
import statusTypes from 'modules/society/components/SocietyRepresentative/statusTypes';

function AddSocietyRepresentative({
  societyId,
  action,
  representativeData,
  representativeId,
  userId,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const partners = useSelector(
    (state) => state.society.actualSociety?.partners
  );

  const [initialDocuments, setInitialDocuments] = useState([]);
  const [socialDenomination, setSocialDenomination] = useState('');
  const [representativeType, setRepresentativeType] = useState(
    representativeTypesOptions[0].value
  );
  const [nationality, setNationality] = useState('');
  const [comments, setComments] = useState('');
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const [files, setFiles] = useState([]);
  const [nif, setNif] = useState('');
  const [tag, setTag] = useState('');
  const [isPartnerAlready, setIsPartnerAlready] = useState(false);
  const animatedComponents = makeAnimated();
  const [partnerOptions, setPartnerOptions] = useState([]);

  const handlePartnerOptionChange = (selectedOption) => {
    setSocialDenomination(selectedOption.value?.name);
    setNif(selectedOption.value?.cif);
    setEmail(selectedOption.value?.email);
  };

  const handleChangeIsAlreadyPartner = () => {
    setIsPartnerAlready(!isPartnerAlready);
  };

  const getRepresentativeDocuments = async (representativeId) => {
    await removeAdminDeletedDocuments(initialDocuments, files);
    const documents = await uploadAdminDocuments(
      files,
      userId,
      representativeId,
      societyId,
      initDate,
      documentLinkTypes.REPRESENTATIVE,
      t('FileAssociatedRepresentative')
    );
    return documents;
  };

  const handleUpdateRepresentative = async (event) => {
    event.preventDefault();
    let newRepresentativesArray = [];
    let updatedRepresentatives = [];

    const newRepresentative = {
      socialDenomination,
      nif,
      nationality,
      address,
      email,
      tag,
      comments,
      representativeType,
      initDate,
      endDate,
      representativeStatus: status,
    };

    const documents = await getRepresentativeDocuments(representativeId);
    newRepresentative.documents = documents;

    updatedRepresentatives = actualSociety?.representatives.map(
      (representative) => {
        if (representative['_id'] === representativeId) {
          return { ...newRepresentative };
        }
        return representative;
      }
    );

    newRepresentativesArray =
      action === 'ADD'
        ? [...updatedRepresentatives, newRepresentative]
        : updatedRepresentatives;
    const newSocietyData = {
      representatives: newRepresentativesArray,
    };

    dispatch(updateSociety(societyId, newSocietyData, false));
    dispatch(setModal(null));
    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${
        action === 'ADD'
          ? t('RepresentativeAddedSuccessfully')
          : t('RepresentativeUpdatedSuccessfully')
      }<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  const loadInitialValues = () => {
    setSocialDenomination(representativeData.socialDenomination);
    setNif(representativeData.nif);
    setNationality(representativeData.nationality);
    setAddress(representativeData.address);
    setEmail(representativeData.email);
    setTag(representativeData?.tag || '');
    setComments(representativeData?.comments || '');
    setRepresentativeType(representativeData.representativeType);
    if (representativeData.initDate)
      setInitDate(format(new Date(representativeData.initDate), 'yyyy-MM-dd'));
    if (representativeData.endDate)
      setEndDate(format(new Date(representativeData.endDate), 'yyyy-MM-dd'));
    if (representativeData.representativeStatus)
      setStatus(representativeData.representativeStatus);
    if (representativeData?.documents) {
      setInitialDocuments(representativeData?.documents);
    }
  };

  useEffect(() => {
    if (action === 'EDIT' && representativeData) {
      loadInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representativeData]);

  useEffect(() => {
    if (representativeId && societyId) {
      dispatch(
        getDocuments(
          { representative: representativeId, society: societyId },
          setFiles
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representativeId, societyId]);

  useEffect(() => {
    if (!representativeData?.representativeStatus) {
      if (!endDate || endDate === '' || new Date(endDate) - new Date() > 0)
        setStatus(statusTypes.var.CURRENT);
      else setStatus(statusTypes.var.REVOKED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  useEffect(() => {
    if (partners) {
      const options = partners
        .filter((partner) => partner?.sharesCount?.actual > 0)
        .map((partner) => ({
          value: partner,
          label: `${partner?.cif} | ${partner?.name}`,
        }));
      setPartnerOptions(options);
    }
  }, [partners]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === 'ADD' ? `${t('Add')} ` : `${t('Edit')} `}
          {t('Representative')}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <form
        className="form-validate is-alter"
        onSubmit={handleUpdateRepresentative}
      >
        <Modal.Body>
          <div className="row gy-4">
            {action === 'ADD' && (
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    {t('IsItCurrentlyAPartner?')}
                  </label>

                  <div className="custom-control custom-control-sm custom-radio custom-control-pro ml-5">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="already-partner"
                      id="already-partner"
                      checked={isPartnerAlready}
                      onChange={handleChangeIsAlreadyPartner}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="already-partner"
                    >
                      {t('Yes')}
                    </label>
                  </div>

                  <div className="custom-control custom-control-sm custom-radio custom-control-pro ml-5">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="no-already-partner"
                      id="no-already-partner"
                      checked={!isPartnerAlready}
                      onChange={handleChangeIsAlreadyPartner}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="no-already-partner"
                    >
                      {t('Nope')}
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-6">
              <div className="form-group">
                {isPartnerAlready ? (
                  <div className="form-control-wrap">
                    <label
                      className="form-label"
                      htmlFor="societyDirectors-socialDenom"
                    >
                      {t('Partner')} *
                    </label>
                    <Select
                      closeMenuOnSelect
                      className="react-select"
                      options={partnerOptions}
                      components={animatedComponents}
                      onChange={handlePartnerOptionChange}
                      placeholder={SELECCIONAR_SOCIO}
                    />
                  </div>
                ) : (
                  <div>
                    <label
                      className="form-label"
                      htmlFor="societyRepresentative-socialDenom"
                    >
                      {t('NameAndSurname')} *
                    </label>
                    <input
                      type="text"
                      id="societyRepresentative-socialDenom"
                      className="form-control form-control-lg"
                      value={socialDenomination}
                      onChange={(event) =>
                        setSocialDenomination(event.target.value)
                      }
                      placeholder={t('NameAndSurname')}
                      required
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyRepresentative-email"
                >
                  {t('Email')} *
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="societyRepresentative-email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder={t('Email')}
                  pattern={EMAIL_PATTERN}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyRepresentative-initDate"
                >
                  {t('RepresentativeDate')}
                </label>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyRepresentative-initDate"
                  value={initDate}
                  onChange={(event) => setInitDate(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="d-flex">
                  <label
                    className="form-label"
                    htmlFor="societyRepresentative-endDate"
                  >
                    {t('RevocationDate')}
                  </label>
                  <span className="sub-text ml-1 fs-12px">
                    {t('IndefiniteDefault')}
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyRepresentative-endDate"
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyRepresentative-representativeType"
                >
                  {t('TypeOfAuthority')} *
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      id="societyRepresentative-representativeType"
                      value={representativeType}
                      onChange={(event) => {
                        setRepresentativeType(event.target.value);
                      }}
                      required
                    >
                      {representativeTypesOptions?.length > 0 &&
                        representativeTypesOptions
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((type) => (
                            <option value={type.value} key={type.value}>
                              {type.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyRepresentative-status"
                >
                  {t('Status')}
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      id="societyRepresentative-status"
                      value={status}
                      onChange={(event) => {
                        setStatus(event.target.value);
                      }}
                      required
                    >
                      {statusTypesOptions?.length > 0 &&
                        statusTypesOptions.map((type) => (
                          <option value={type.value} key={type.value}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyRepresentative-nif"
                >
                  {t('Nif')}
                </label>
                <input
                  type="text"
                  id="societyDirectors-cif"
                  className="form-control form-control-lg"
                  value={nif}
                  onChange={(event) => setNif(event.target.value)}
                  placeholder={t('Nif')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyRepresentative-nationality"
                >
                  {t('Nationality')}
                </label>
                <input
                  type="text"
                  id="societyRepresentative-nationality"
                  className="form-control form-control-lg"
                  value={nationality}
                  onChange={(event) => setNationality(event.target.value)}
                  placeholder={t('Nationality')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyRepresentative-address"
                >
                  {t('Address')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="societyRepresentative-address"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  placeholder={t('Address')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyRepresentative-tag"
                >
                  {t('Tag')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="societyRepresentative-tag"
                  value={tag}
                  onChange={(event) => setTag(event.target.value)}
                  placeholder={t('Tag')}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label" htmlFor="operation-comments">
                  {t('CommentsLabel')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-editor-custom">
                    <textarea
                      id="operation-comments"
                      maxLength="500"
                      className="form-control form-control-lg no-resize"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-note d-flex justify-content-end">
                  <CommentsCharCounter comments={comments} />
                </div>
              </div>
            </div>

            <div className="col-12">
              <UploadDocumentsSection
                initialDocumentsCount={
                  representativeData?.documents?.length || 0
                }
                documents={files}
                setDocuments={setFiles}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={!socialDenomination || !email || !representativeType}
              >
                {t('Save')}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-light ml-3"
                onClick={() => dispatch(setModal(null))}
              >
                {t('ToClose')}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </>
  );
}

export default AddSocietyRepresentative;
