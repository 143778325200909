import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import { User } from 'types';

import isSuperAdmin from 'utils/isSuperAdmin';

import '../SideNav.scss';
import MenuItem from './MenuItem';

type Props = {
  societyId: string;
  user: User;
  isDemo: boolean;
};

const SocietyDataMenu: FC<Props> = ({ societyId, user, isDemo }) => {
  const { t } = useTranslate();

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="9">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-setting" />
            </span>
            <span className="nk-menu-text">{t('SocietyData')}</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="9">
        <ul className="nk-menu-sub">
          <MenuItem
            linkTo={`/perfil-sociedad/datos/${societyId}`}
            text="SocietyProfile"
          />
          <MenuItem
            linkTo={`/perfil-sociedad/organo/${societyId}`}
            text="Directorship"
          />
          <MenuItem
            linkTo={`/perfil-sociedad/representative/${societyId}`}
            text="Representatives"
          />
          <MenuItem
            linkTo={`/perfil-sociedad/auditors/${societyId}`}
            text="Auditors"
          />
          <MenuItem
            linkTo={`/perfil-sociedad/valor/${societyId}`}
            text="ValueSociety"
          />
          <MenuItem
            linkTo={`/perfil-sociedad/valor-participaciones/${societyId}`}
            text="ValueShares"
          />
          {!isDemo && (
            <>
              <MenuItem
                linkTo={`/perfil-sociedad/administradores/${societyId}`}
                text="OtherUsers"
              />
              {isSuperAdmin(user) && (
                <MenuItem
                  linkTo={`/perfil-sociedad/integraciones/${societyId}`}
                  text="Integrations"
                />
              )}
            </>
          )}
          <MenuItem
            linkTo={`/perfil-sociedad/configuracion/${societyId}`}
            text="Customization"
          />
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default SocietyDataMenu;
