import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Board, BoardOrder } from 'types';

import getOrder from './utils/getOrder';

import useGetOrderOptions from './hooks/useGetOrderOptions';
import useGetRepresentedPartners from './hooks/useGetRepresentedPartners';

import BoardPartnerDelegarionsInstructionsTable from './components/BoardPartnerDelegarionsInstructionsTable';

import './BoardPartnerDelegations.scss';

type Props = {
  partnerId: string;
  board: Board;
};

const BoardPartnerDelegations: FC<Props> = ({ board, partnerId }) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const [selectedOrder, setSelectedOrder] = useState<BoardOrder>();

  const { orderOptions } = useGetOrderOptions({ board }).data;
  const { representedPartners } = useGetRepresentedPartners({
    board,
    partnerId,
    order: selectedOrder,
  }).data;

  const handleChangeOrder = (selectedOption: any) => {
    const orderId = selectedOption?.value;
    const order = getOrder(orderId, board);
    setSelectedOrder(order);
  };

  return (
    <>
      <div className="nk-block">
        <div className="row">
          <div className="form-group mb-1 col-md-6">
            <label className="form-label" htmlFor="subjects-option-selector">
              <h6>{t('Subjects')}</h6>
            </label>
            <div className="form-control-wrap">
              <Select
                id="subjects-option-selector"
                closeMenuOnSelect
                className="react-select react-select-lg"
                options={orderOptions}
                components={animatedComponents}
                onChange={handleChangeOrder}
                placeholder={t('SelectSubject')}
              />
            </div>
          </div>
        </div>
        <div className="row pt-4" id="board-partner-delegations">
          <div className="col-12">
            <BoardPartnerDelegarionsInstructionsTable
              representedPartners={representedPartners}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardPartnerDelegations;
