/* eslint-disable import/imports-first */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';
import sizes from 'constants/sizes';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getTenderOffersShares } from 'modules/transactions/redux/tenderOffersSharesActions';

import { getActualSociety } from 'utils/filters';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import AddTenderOfferShares from 'modules/_shared/components/Modals/AddTenderOfferShares';
import TenderOfferSharesRow from 'modules/transactions/components/TenderOfferSellRow';

import { tenderOfferSharesTypes } from 'utils/tenderOfferSharesTypes';
import TenderOfferSharesBanner from './TenderOfferSharesBanner';

const TenderOfferSharesList = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const user = useSelector((state: any) => state.user);
  const tenderOffersShares = useSelector((state: any) =>
    state.tenderOffersShares.filter(
      (offer: any) => offer.type === tenderOfferSharesTypes.SELL
    )
  );
  const actualSociety = useSelector(
    (state: any) => state.society?.actualSociety
  );
  const isDemo = useSelector((state: any) => state.society?.role?.isDemo);

  const setCurrentMenu = () => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
  };

  const getCurrentValues = async () => {
    dispatch(getTenderOffersShares(societyId));
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentMenu();
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
            <p />
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('TenderOffersShares')}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>{t('TenderOffersSharesList')}</p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            dispatch(
                              setModal(
                                <AddTenderOfferShares
                                  size={sizes.LG}
                                  type={tenderOfferSharesTypes.SELL}
                                />
                              )
                            )
                          }
                          disabled={isDemo}
                        >
                          <em className="icon ni ni-plus" />
                          <span>{t('ConfigureOffer')}</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {tenderOffersShares.length > 0 &&
            tenderOffersShares
              ?.sort(
                (a: Record<string, any>, b: Record<string, any>) =>
                  new Date(b.startDate).getTime() -
                  new Date(a.startDate).getTime()
              )
              ?.map((offer: Record<string, any>) => (
                <TenderOfferSharesRow
                  key={offer?._id || offer?.name}
                  data={{ ...offer }}
                />
              ))}

          <TenderOfferSharesBanner />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default TenderOfferSharesList;
