import PreviewDropdown from 'modules/_shared/components/Dropdowns/PreviewDropdown';
import { useTranslation } from 'react-i18next';

type Props = {
  documentName: string;
  templateName: string;
  menuOptions: any;
  params: any;
  fullScreen: boolean;
  handleSaveDocument: () => void;
  handleToggleFullScreen: () => void;
  isEditing: boolean;
  isDemo: boolean;
};

const PreviewHeader = ({
  documentName,
  templateName,
  menuOptions,
  params,
  fullScreen,
  handleSaveDocument,
  handleToggleFullScreen,
  isEditing,
  isDemo,
}: Props) => {
  const { t } = useTranslation();

  const fullScreenIcon = fullScreen ? 'ni ni-minimize' : 'ni ni-maximize';

  return (
    <div className="nk-block-head nk-block-head-sm">
      <div className="nk-block-between position-relative">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title page-title col-12">
            {t('Document')}:&nbsp;
            <span className="text-muted ff-alt text-right">
              {documentName || ''}
            </span>
          </h5>

          {templateName && (
            <div className="nk-block-des text-soft">
              <p>
                {t('UsedTemplate')}:{' '}
                <span className="text-primary">{templateName}</span>
              </p>
            </div>
          )}
        </div>

        {!isDemo && (
          <div className="nk-block-head-content">
            <ul className="nk-block-tools g-3">
              <li>
                <button
                  type="button"
                  className="btn btn-outline-primary bg-transparent px-1"
                  onClick={handleToggleFullScreen}
                >
                  <em className={`icon ni ${fullScreenIcon}`} />
                </button>
              </li>
              {!isEditing ? (
                <li>
                  <PreviewDropdown
                    menuOptions={menuOptions}
                    params={params}
                    direction="down"
                  />
                </li>
              ) : (
                <li>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveDocument}
                  >
                    <em className="icon ni ni-check-round-cut mr-2" />
                    {t('Save')}
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewHeader;
