import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { Board, BoardOrder } from 'types';

import InstructionsTableView from './SendVoteInstructionsTableView';
import SendVoteWizardController from './SendVoteWizardController';
import SendVoteModalBodySteps from './SendVoteModalBodySteps';

type Props = {
  order: BoardOrder;
  board: Board;
  voteValue: string;
  voteStep: number;
  selectedRepresented: any[];
  handleChangeVoteValue: any;
  handleExcecuteVote: () => void;
};

const SendVoteModalBody: FC<Props> = ({
  order,
  board,
  voteValue,
  voteStep,
  selectedRepresented,
  handleChangeVoteValue,
  handleExcecuteVote,
}) => (
  <Modal.Body id="send-vote-modal">
    <SendVoteModalBodySteps voteStep={voteStep} />

    <div className="row">
      <div className="col-12 col-md-6">
        <InstructionsTableView
          board={board}
          order={order}
          selectedRepresented={selectedRepresented}
          voteValue={voteValue}
        />
      </div>
      <div className="col-12 col-md-6">
        <SendVoteWizardController
          order={order}
          voteValue={voteValue}
          voteStep={voteStep}
          handleChangeVoteValue={handleChangeVoteValue}
          handleExcecuteVote={handleExcecuteVote}
        />
      </div>
    </div>
  </Modal.Body>
);

export default SendVoteModalBody;
