import { useTranslate } from 'hooks/useTranslate';
import React, { useState } from 'react';

import 'assets/styles/Components.scss';

type Props = {
  className?: string;
  defaultPermissions?: Array<Record<string, any>>;
  onChangePermissions?: (permissions: Array<Record<string, any>>) => void; // eslint-disable-line no-unused-vars
};

const SocietyAdministratorPermissionsForm = (props: Props) => {
  const { t } = useTranslate();

  const {
    className = '',
    defaultPermissions = [],
    onChangePermissions,
  } = props;

  const [permissions, setPermissions] = useState(defaultPermissions);
  const [isOpened, setIsOpened] = useState(false);

  const toggleAccordion = () => {
    setIsOpened(!isOpened);
  };

  const handleSelectPermission = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, checked } = event.target;

    const permission = permissions.find(
      (permission) => `checkbox-${permission.name}` === id
    );

    if (!permission?.enabled) {
      return;
    }

    permission.checked = checked;

    const newPermissions = permissions.map((p) =>
      p.name === permission.name ? permission : p
    );

    setPermissions(newPermissions);

    if (onChangePermissions) {
      onChangePermissions(newPermissions);
    }
  };

  return (
    <div id="accordion" className={`accordion border ${className}`}>
      <div className="accordion-item">
        <span
          className={`accordion-head cursor-pointer ${
            isOpened ? '' : 'collapsed'
          }`}
          onClick={toggleAccordion}
        >
          <h6 className="title">
            <em className="icon ni ni-lock-fill mr-2" />
            Limitar acceso a secciones
          </h6>
          <span className="accordion-icon" />
        </span>
        <div
          className={`accordion-body collapse ${isOpened ? 'show' : 'hide'}`}
          id="accordion-item-1"
          data-bs-parent="#accordion"
        >
          <div className="accordion-inner">
            <p>
              Puedes limitar el acceso a ciertas secciones de la plataforma para
              este administrador
            </p>

            <li className="list list-unstyled">
              {permissions?.map((permission) => (
                <ul className="list list-unstyled">
                  <div className="custom-control custom-control-xs custom-checkbox">
                    <input
                      type="checkbox"
                      id={`checkbox-${permission.name}`}
                      className="custom-control-input"
                      checked={permission.checked}
                      disabled={!permission.enabled}
                      onChange={handleSelectPermission}
                    />
                    <label
                      className={`custom-control-label ${
                        !permission.enabled
                          ? 'text-muted cursor-not-allowed'
                          : ''
                      }`}
                      htmlFor={`checkbox-${permission.name}`}
                    >
                      {t(permission.label)}
                    </label>
                  </div>
                </ul>
              ))}
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocietyAdministratorPermissionsForm;
