import i18n from 'i18n/config';
import { useSelector } from 'react-redux';
import { FC, useEffect, useState } from 'react';

import { store } from 'redux/store';
import { State } from 'redux/initialState';

import { Society } from 'types';
import { useTranslate } from 'hooks/useTranslate';

import { setModal } from 'modules/_shared/redux/modalActions';
import CommunicationPreview from 'modules/communications/components/components/CommunicationPreview';

import NotificationModal from './NotificationModal';
import {
  generateCommunicationBodyEN,
  generateCommunicationBodyES,
  generateCommunicationBodyFR,
} from './utils/generateCommsBody';

type NotificationViewProps = {
  actualSociety: Society;
};

const NotificationView: FC<NotificationViewProps> = ({ actualSociety }) => {
  const { t } = useTranslate();
  const isDemo: boolean = useSelector(
    (state: State) => state.society?.role?.isDemo
  );

  const communicationBodyES = generateCommunicationBodyES(actualSociety);
  const communicationBodyEN = generateCommunicationBodyEN(actualSociety);
  const communicationBodyFR = generateCommunicationBodyFR(actualSociety);

  const [communicationBody, setCommunicationBody] =
    useState<string>(communicationBodyEN);

  const handleReviewNotification = () => {
    store.dispatch(
      setModal(
        <NotificationModal
          actualSociety={actualSociety}
          templateBody={communicationBody}
        />
      )
    );
  };

  useEffect(() => {
    if (i18n.language === 'es') {
      setCommunicationBody(communicationBodyES);
    } else if (i18n.language === 'en') {
      setCommunicationBody(communicationBodyEN);
    } else {
      setCommunicationBody(communicationBodyFR);
    }
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="nk-fmg-body-content">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between position-relative">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title title">{t('NotifyPartners')} </h5>
              <div className="nk-block-des text-soft">
                <p>{t('NotifyDetails')}</p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <ul className="nk-block-tools g-3">
                <li>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleReviewNotification}
                    disabled={isDemo}
                  >
                    {t('ReviewAndSendNotification')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <CommunicationPreview
          data={{ communication: { body: communicationBody } }}
        />
      </div>
    </>
  );
};

export default NotificationView;
