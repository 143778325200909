/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { useTranslate } from 'hooks/useTranslate';
import { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import sizes from 'constants/sizes';
import { Communication } from 'types';
import { getActualSociety } from 'utils/filters';

import Inbox from 'modules/communications/components/Inbox';
import pages from 'modules/communications/components/constants/pages';
import Sidenav from 'modules/communications/components/Sidenav';
import SendEmail from 'modules/_shared/components/Modals/SendEmail';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import InboxDetails from 'modules/communications/components/Inbox/components/InboxDetails';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import CommunicationSettings from 'modules/communications/components/components/CommunicationSettings';
import { getDocumentsByReference } from 'modules/documents/redux/documentActions';
import { State } from 'redux/initialState';

type Page = keyof typeof pages;

const Communications: FC = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId, communicationId } = useParams();

  const user = useSelector((state: State) => state.user);
  const isAdmin = useSelector((state: State) => state.society?.role?.isAdmin);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const isDemo = useSelector((state: State) => state.society?.role?.isDemo);

  const [activePage, setActivePage] = useState<Page>();
  const [currentCommunication, setCurrentCommunication] = useState();

  const communicationPages = {
    INBOX: <Inbox communications={actualSociety?.communications} />,
    DETAILS: <InboxDetails communication={currentCommunication} />,
    SETTINGS: <CommunicationSettings society={actualSociety} />,
  };

  const handleChangePage = (page: Page) => {
    setActivePage(page);
  };

  const handleOpenNewCommsModal = () => {
    // @ts-ignore
    dispatch(setModal(<SendEmail size={sizes.XL} />));
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (societyId) {
      dispatch(getDocumentsByReference({ societyId }));
    }
  }, [societyId]); // eslint-disable-line

  useEffect(() => {
    if (actualSociety?.communications?.length > 0) {
      setCurrentCommunication(
        actualSociety?.communications?.find(
          (communication: Communication) =>
            communication['_id'] === communicationId
        )
      );
    }
    if (communicationId) {
      setActivePage(pages.DETAILS as Page);
    }
  }, [actualSociety, communicationId]);

  useEffect(() => {
    setActivePage(pages.INBOX as Page);
  }, []);

  return (
    <div className="nk-content-body">
      <PageListHeader
        title={actualSociety?.name}
        subTitle={t('Communications')}
        actions={[
          <GoBackButton />,
          isAdmin && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleOpenNewCommsModal}
              disabled={isDemo}
            >
              {t('NewCommunication')}
            </button>
          ),
        ]}
      />

      <div className="nk-content-wrap">
        <div className="nk-ibx">
          <Sidenav
            activePage={activePage as string}
            onChangePage={handleChangePage}
          />

          <div className="nk-ibx-body bg-white">
            {communicationPages[activePage as Page]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Communications);
