import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
} from '@react-pdf/renderer';
import { useTranslate } from 'hooks/useTranslate';
import SignatureBox from 'modules/_shared/components/SignatureBox';
import toggleFullWidth, {
  setFullWidth,
} from 'modules/_shared/redux/fullWidthActions';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { getDocumentsByReference } from 'modules/documents/redux/documentActions';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import { updateSociety } from 'modules/society/redux/societyActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getActualSociety } from 'utils/filters';
import { dayMonthYear } from 'utils/formatDate';
import { formatCurrency } from 'utils/formats';

const CertificateSignature = () => {
  const { t, i18n } = useTranslate();
  const { societyId } = useParams();
  const dispatch = useDispatch();

  const { actualSociety, user } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
    user: state.user,
  }));
  const [signature, setSignature] = useState(null);
  const [certificate, setCertificate] = useState();

  const [currDirectorTypes, setCurrDirectorTypes] = useState(
    directorTypes(i18n)
  );

  const styles = StyleSheet.create({
    body: {
      paddingTop: 70,
      paddingBottom: 35,
      paddingHorizontal: 50,
    },
    title: {
      color: '#1C1A1A',
      fontSize: 16,
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: 20,
    },
    pdf: {
      width: '210mm',
      height: '180mm',
    },
    sign: {
      width: 250,
      height: 100,
    },
    titleBottom: {
      color: '#1C1A1A',
      fontSize: 16,
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: 70,
    },
    author: {
      color: '#1C1A1A',
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'left',
      marginBottom: 40,
      display: 'block',
    },
    text: {
      color: '#1C1A1A',
      fontSize: 13,
      fontWeight: 100,
      textAlign: 'justify',
      lineHeight: 1.6,
      display: 'block',
      marginBottom: 20,
    },
    textList: {
      color: '#1C1A1A',
      paddingLeft: '20px',
      fontSize: 13,
      fontWeight: 100,
      textAlign: 'justify',
      lineHeight: 1.6,
      display: 'block',
      marginBottom: 20,
    },
  });

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);
  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(
    () =>
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety?.['_id'] || null,
          societyName: actualSociety?.name,
        })
      ),
    [actualSociety, dispatch]
  );

  useEffect(() => {
    if (actualSociety) {
      const director = actualSociety?.directors.find(
        (d) => d.email === user?.email
      );
      const certificateData = {
        societyName: actualSociety?.name,
        societyCIF: actualSociety?.cif,
        societyAddress: Object.values(actualSociety?.legalAddress)
          .map((value) => value)
          .join(', '),
        societyCity: actualSociety?.legalAddress?.city,
        societyAdmin: director,
        societyNominalValue: formatCurrency(actualSociety?.nominalValue),
        nominalValueShares: formatCurrency(
          +(actualSociety?.sharesCount?.actual * actualSociety?.nominalValue)
        ),
        lastYear: new Date().getFullYear() - 1,
        downloadDate: dayMonthYear(new Date()),
      };

      if (director) {
        const doc = (
          <Document title={t('CertificatePreview')}>
            <Page size="A4" style={styles.body}>
              <Text style={styles.text}>
                {certificateData.societyAdmin.socialDenomination} en calidad de{' '}
                {
                  currDirectorTypes.name[
                    certificateData.societyAdmin.directorType
                  ]
                }{' '}
                de {certificateData.societyName} con NIF{' '}
                {certificateData.societyCIF} y domicilio en{' '}
                {certificateData.societyAddress};
              </Text>
              <Text style={styles.title}>Certifica</Text>
              <Text style={styles.text}>
                Que el socio [Nombre Socio] con NIF XXXX, posee XXXX{' '}
                participaciones a fecha 31 de diciembre de{' '}
                {certificateData.lastYear}.
              </Text>
              <Text style={styles.textList}>
                1. Que el Valor Nominal de las XXXX participaciones es de XXXX,
                teniendo en cuenta que el valor nominal de cada participación de
                la sociedad es de {certificateData.societyNominalValue} y el
                total del valor nominal de la sociedad es de{' '}
                {certificateData.nominalValueShares}.
              </Text>
              <Text style={styles.textList}>
                2. Que el valor teórico contable de las participaciones de{' '}
                [Nombre Socio] de esta sociedad, según balance cerrado a 31 de
                diciembre del {certificateData.lastYear}, es de XXXX.
              </Text>
              <Text style={styles.textList}>
                3. Que el valor de las XXXX participaciones que resulta de
                capitalizar al tipo del 20% el promedio de los beneficios de los
                últimos ejercicios sociales de la misma, es de XXXX.
              </Text>

              <Text style={styles.text}>
                Y para que así conste a los efectos oportunos, extiendo el
                presente certificado en {certificateData.societyCity}, a{' '}
                {certificateData.downloadDate}.
              </Text>

              <Text style={styles.text}>
                {certificateData.societyAdmin.socialDenomination}
              </Text>
              <Text style={styles.text}>{certificateData.societyName}</Text>
              {signature ? (
                <Image style={styles.sign} src={signature} />
              ) : (
                <></>
              )}
            </Page>
          </Document>
        );
        setCertificate(doc);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (signature) {
      dispatch(
        updateSociety(
          societyId,
          {
            additional: {
              ...actualSociety.additional,
              certificateSign: signature,
            },
          },
          false
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  useEffect(() => {
    setCurrDirectorTypes(directorTypes(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    dispatch(setFullWidth(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="custom-page-background page-full-height__with-header pt-0 row">
      <div className="nk-content-body col-md-6">
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <SignatureBox
            title={t('Sign')}
            signature={signature}
            setSignature={setSignature}
          />
        </div>
      </div>
      <div className="nk-content-body col-md-6">
        <h5 className="title nk-block-title mb-3">Previsualización:</h5>
        {certificate ? (
          <PDFViewer style={styles.pdf}>{certificate}</PDFViewer>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CertificateSignature;
