/* eslint-disable array-callback-return */
import { FC, memo } from 'react';
import NoItemsImage from 'assets/images/no-items.svg';

type NoItemsAvailableProps = {
  mainMessage?: string;
  infoMessage?: string;
  colspan?: number;
};

const NoItemsAvailable: FC<NoItemsAvailableProps> = ({
  mainMessage,
  infoMessage,
  colspan = 9,
}) => (
  <tr className="tb-tnx-item">
    <td colSpan={colspan}>
      <div className="nk-help justify-content-center my-4">
        <div className="nk-help-img">
          <img src={NoItemsImage} alt="No items available" />
        </div>

        <div className="nk-help-text flex-grow-0">
          {mainMessage && <h5>{mainMessage}</h5>}
          {infoMessage && <p className="text-soft">{infoMessage}</p>}
        </div>
      </div>
    </td>
  </tr>
);

export default memo(NoItemsAvailable);
