import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';

import { store } from 'redux/store';
import { State } from 'redux/initialState';

import TabHeader from 'modules/_shared/components/Headers/TabHeader';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getGuests } from 'modules/society/redux/guestsActions';
import EmptyStateForTable from 'modules/_shared/components/EmptyStates/EmptyStateForTable';

import restrictedview from 'assets/images/restricted-view.svg';

import SocietyGuestsTable from './components/SocietyGuestsTable';
import SocietyGuestsAddModal from './components/SocietyGuestsAddModal';

const SocietyGuests: FC = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const isDemo = useSelector((state: State) => state.society?.role?.isDemo);
  const societyGuests = useSelector((state: State) => state?.guests);

  const handleAddGuest = async () => {
    await store.dispatch(setModal(<SocietyGuestsAddModal />));
  };

  useEffect(() => {
    if (societyId) {
      dispatch(getGuests({ societyId }));
    }
  }, [societyId]); // eslint-disable-line

  return (
    <div className="nk-block">
      <TabHeader
        title={t('GuestUsersSocietyTabTitle')}
        subTitle={t('GuestUsersSocietyTabDescription')}
        actions={[
          <button
            type="button"
            className="btn btn-success"
            onClick={handleAddGuest}
            disabled={isDemo}
          >
            {t('AddUsers')}
          </button>,
        ]}
      />

      {!isDemo ? (
        <SocietyGuestsTable societyGuests={societyGuests} />
      ) : (
        <div className="d-flex justify-content-center">
          <EmptyStateForTable
            mainMessage={t('RestrictedInfo')}
            infoMessage={t('NoAccessToThisInfo')}
            imageIcon={restrictedview}
          />
        </div>
      )}
    </div>
  );
};

export default SocietyGuests;
