/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import { currencyFormatDecimals } from 'constants/formats';
import userTypes from 'constants/userTypes';
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getBeneficiaryFDPercent,
  getDecimalScale,
  getSocietyValue,
} from 'utils/filters';
import MenuDots from '../../../_shared/components/MenuDots';
import menuOptions from './menuOptions';

// TODO* move to constants file
const beneficiaryStatus = {
  CONSOLIDATED: i18n.t('Consolidated'),
  ACTIVE: i18n.t('Active'),
  CANCELED: i18n.t('Canceled'),
};

const BeneficiaryCard = ({ beneficiary, totalShares, society }) => {
  const { t } = useTranslate();
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [partnerTotalShares, setPartnerTotalShares] = useState();
  const [percent, setPercent] = useState();

  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const societyValue = getSocietyValue(society);
  const [decimalScale] = useState(
    societyValue ? getDecimalScale(societyValue.value) : 2
  );

  const filterOptions = (beneficiary) => {
    const options = currentMenuOptions.map((option) => ({ ...option }));
    options[0].url = `/detalle-beneficiario/${society?.['_id']}/${beneficiary?.planId}`;
    if (!isAdmin || isDemo) {
      options[1].disabled = true;
      options[2].disabled = true;
    }
    return options;
  };

  useEffect(() => {
    if (beneficiary) {
      setPartnerTotalShares(
        beneficiary?.isCanceled
          ? beneficiary?.finalConsolidatedUnits
          : beneficiary?.sharesCount?.future
      );
      setPercent(
        getBeneficiaryFDPercent(beneficiary, society).toFixed(decimalScale)
      );
    }
  }, [totalShares, beneficiary, partnerTotalShares]);

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
  }, [i18n.language]);

  return (
    <div className="col-sm-6 col-xl-4">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="team">
            <MenuDots
              menuOptions={filterOptions(beneficiary)}
              id={beneficiary['_id']}
              params={{
                data: beneficiary,
                societyId: society['_id'],
                userType: userTypes.BENEFICIARY,
              }}
            />
            {beneficiary.status === beneficiaryStatus.CANCELED && (
              <span className="badge rounded-pill text-danger float-right">
                {beneficiaryStatus.CANCELED}
              </span>
            )}
            {beneficiary.status === beneficiaryStatus.CONSOLIDATED && (
              <span className="badge rounded-pill text-success float-right">
                {beneficiaryStatus.CONSOLIDATED}
              </span>
            )}
            {beneficiary.status === beneficiaryStatus.ACTIVE && (
              <span className="badge rounded-pill text-warning float-right">
                {beneficiaryStatus.ACTIVE}
              </span>
            )}
            <div className="user-card user-card-s2">
              <div className="user-avatar md bg-primary">
                {beneficiary.image ? (
                  <img src={beneficiary.image} alt={beneficiary.name || ''} />
                ) : (
                  <span>{beneficiary?.name?.slice(0, 2)?.toUpperCase()}</span>
                )}
              </div>
              <div className="user-info">
                <h6>{beneficiary.name || ''}</h6>
                <span className="sub-text">{beneficiary?.jobTitle}</span>
                <span className="sub-text">{beneficiary.planName}</span>
              </div>
            </div>
            <div className="team-details">
              <p>{beneficiary?.description}</p>
            </div>
            <ul className="team-statistics">
              <li>
                <span>{partnerTotalShares || 0}</span>
                <span>{t('UnitsNr')}</span>
              </li>
              <li>
                <span>{`${percent > 0 ? percent : 0}%`}</span>
                <span>%</span>
              </li>
              <li>
                <span>
                  <NumberFormat
                    value={beneficiary.amount || 0}
                    displayType="text"
                    renderText={(value) => <div>{value}</div>}
                    {...currencyFormatDecimals}
                  />
                </span>
                <span>
                  {t('Amount')}
                  <br />
                  {t('Awarded')}
                </span>
              </li>
            </ul>
            <div className="team-view">
              <Link
                to={`/detalle-beneficiario/${society?.['_id']}/${beneficiary?.planId}/${beneficiary?.['_id']}`}
                className="btn btn-round btn-outline-light w-150px"
              >
                <span>{t('SeeProfile')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryCard;
