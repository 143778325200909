/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import {
  addDocument,
  getDocumentsByReference,
} from 'modules/documents/redux/documentActions';

import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import '../Modals.scss';

function SaveTemplates({ data }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isCommon, setIsCommon] = useState(true);

  function handleChangeCheckbox() {
    const checkbox1 = document.getElementById('checkbox1');
    const checkbox2 = document.getElementById('checkbox2');

    if (isCommon) {
      checkbox1.checked = true;
      checkbox2.checked = false;
    } else {
      checkbox1.checked = false;
      checkbox2.checked = true;
    }
    setIsCommon(!isCommon);
  }

  async function handleSaveDraft(event) {
    event.preventDefault();

    try {
      const newTemplate = {
        ...data,
        name,
        description,
        isCommon,
        fileType: fileTypes.TEMPLATE,
        category: documentTypes.TEMPLATES,
      };

      await store.dispatch(addDocument(newTemplate));
      store.dispatch(
        getDocumentsByReference({ societyId: newTemplate.society })
      );
      dispatch(addAlert(alertBodyTypes.DOCUMENT_ADDED));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setModal(null));
    }
  }

  return (
    <>
      <form className="form-validate is-alter" onSubmit={handleSaveDraft}>
        <Modal.Header
          style={{ borderBottom: 'none', padding: '36px 40px 0 40px' }}
        >
          <h5 className="modal-title">{t('SaveTemplate')}</h5>
          <a
            className="close cursor-pointer"
            onClick={() => dispatch(setModal(null))}
          >
            <em className="icon ni ni-cross" />
          </a>
        </Modal.Header>
        <Modal.Body style={{ margin: '0px 16px 0px 16px' }}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="partner-file">
                  {t('Name')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      type="text"
                      id="draft-name"
                      name="draft-name"
                      className="form-control form-control-lg"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="partner-file">
                  {t('Description')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      type="text"
                      id="template-description"
                      name="template-description"
                      className="form-control form-control-lg"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-preview mb-4">
            <div className="card-inner">
              <div className="row gy-3">
                <div className="col-md-6 col-sm-6">
                  <div className="preview-block">
                    <div className="custom-control custom-checkbox checked">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        className="custom-control-input"
                        onChange={() => handleChangeCheckbox()}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox1"
                      >
                        {`${t('TemplateOnlyFor')} ${actualSociety.name}`}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="preview-block">
                    <div className="custom-control custom-checkbox checked">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="checkbox2"
                        onChange={() => handleChangeCheckbox()}
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox2"
                      >
                        {t('TemplateForAnyCompany')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-upload-list d-none">
            <label className="form-label w-100 mt-2" htmlFor="settings-sharing">
              {t('Configuración')}
            </label>
            <div className="row g-3 align-center">
              <div className="col-lg-9">
                <div className="form-group">
                  <label className="form-label" htmlFor="site-off">
                    ¿Quieres utilizar la plantilla en otras Sociedades tuyas?
                  </label>
                  <span className="form-note">
                    Marca la casilla si quieres utilizar la plantilla en otras
                    sociedades tuyas. Recomendado si es una plantilla modelo que
                    utilizas en otras sociedades.
                  </span>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="form-group">
                  <div className="custom-control custom-switch checked">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="reg-public"
                      id="site-off"
                      disabled
                    />
                    <label className="custom-control-label" htmlFor="site-off">
                      Sí
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: 'none', padding: '0px 40px 36px 40px' }}
        >
          <button
            type="button"
            className="btn btn-lg btn-outline-primary  border-0"
            onClick={() => dispatch(setModal(null))}
          >
            {t('Cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name}
          >
            {t('Save')}
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}

export default SaveTemplates;
