/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';

import screens from 'constants/screens';
import MenuDots from 'modules/_shared/components/MenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';

import categoryOptions from './categoryOptions';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Nr'),
      field: 'index',
      hide: screens.SM,
      selector: (row, index) => index + 1,
      cell: (row, index) => index + 1,
      disableSortBy: true,
      disableFilters: true,
      center: true,
      grow: 0.5,
      compact: true,
      minWidth: 20,
      export: true,
    },
    {
      name: i18n.t('Name'),
      field: 'name',
      selector: (row) => row[i18n.t('Name')],
      cell: (row) =>
        CellData.PlainTextLink({
          text: row[i18n.t('Name')],
          url: `/socios/${row.societyId}?categoria=${row.categoryId}`,
        }),
      grow: 2,
      sortable: true,
      left: true,
      export: true,
    },
    {
      name: i18n.t('Partners'),
      field: 'partnersCount',
      selector: (row) => row[i18n.t('Partners')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.NumberNoDecimals({ value: row[i18n.t('Partners')] }),
      export: true,
    },
    {
      name: i18n.t('%ND'),
      field: 'NDPercent',
      selector: (row) => row[i18n.t('%ND')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('%ND')],
          decimals: row.decimals,
          noPadding: true,
        }),
      export: true,
    },
    {
      name: i18n.t('%FD'),
      field: 'FDPercent',
      selector: (row) => row[i18n.t('%FD')],
      sortable: true,
      center: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('%FD')],
          decimals: row.decimals,
        }),
      export: true,
    },
    {
      name: i18n.t('%PotentialFD'),
      field: 'FDPotential',
      selector: (row) => row[i18n.t('%PotentialFD')],
      sortable: true,
      center: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('%PotentialFD')],
          decimals: row.decimals,
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) => {
        if (!row?.isDemo) {
          return (
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="dropdown">
                  <MenuDots
                    disabled={!row?.isAdmin}
                    menuOptions={categoryOptions}
                    direction="left"
                    id={row?.categoryId}
                    params={{
                      data: row?.partnerCategory,
                    }}
                  />
                </div>
              </li>
            </ul>
          );
        }
      },
      export: false,
      right: true,
      width: '50px',
      hide: screens.SM,
      allowOverflow: true,
    },
  ];
}

export default tableColumns;
