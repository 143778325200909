import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Tooltip } from 'react-tooltip';

import { Partner, PartnerCategory } from 'types';

import { format } from 'date-fns';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import { dateFormatInverted } from 'constants/formats';
import isValidEmail from 'utils/isValidEmail';
import normalizeEmail from 'utils/normalizeEmail';

import InputError from 'modules/_shared/components/InputError';

import { State } from 'redux/initialState';
import { useGetPartnerOptions } from '../hooks/useGetPartnerOptions';
import { useGetPartnerSelectedOptions } from '../hooks/useGetPartnerSelectedOptions';
import AddedEmailsView from './AddedEmailsView';

type Props = {
  isNaturalPerson: boolean;
  originalPartner: Partner;
  partner: Partner;
  handleChangePartnerData: any;
};

const PersonalDataView: FC<Props> = ({
  isNaturalPerson,
  originalPartner,
  partner,
  handleChangePartnerData,
}) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const user = useSelector((state: State) => state.user);
  const isAdmin: boolean = useSelector(
    (state: State) => state.society?.role?.isAdmin
  );
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const partnerCategories = useSelector(
    (state: State) => state?.partnerCategories
  ) as PartnerCategory[];

  const {
    data: { categoryOptions, sindicationOptions, civilStatusOptions },
  } = useGetPartnerOptions({
    currentPartner: partner,
    actualSociety,
    partnerCategories,
  });

  const selectedOptionsResult = useGetPartnerSelectedOptions({
    currentPartner: partner,
    actualSociety,
    partnerCategories,
  });

  const {
    selectedCategoryOption,
    selectedSindicationOption,
    selectedCivilStatusOption,
  } = selectedOptionsResult.data;

  const {
    setSelectedCategoryOption,
    setSelectedSindicationOption,
    setSelectedCivilStatusOption,
  } = selectedOptionsResult.actions;

  const [name, setName] = useState<string>(partner?.name || '');
  const [cif, setCif] = useState<string>(partner?.cif || '');
  const [email, setEmail] = useState<string>(partner?.email || '');
  const [birthdate, setBirthdate] = useState<string>(
    partner?.birthdate
      ? format(new Date(partner?.birthdate), dateFormatInverted)
      : ''
  );
  const [employeeNumber, setEmployeeNumber] = useState<string>(
    partner?.employeeNumber || ''
  );
  const [nationality, setNationality] = useState<string>(
    partner?.nationality || ''
  );

  const [phone, setPhone] = useState<string>(partner?.phone || '');

  const [image, setImage] = useState<string>(partner?.image || '');

  const handleChangeName = (event: any) => {
    setName(event.target.value);
    handleChangePartnerData({ ...partner, name: event.target.value });
  };

  const handleChangeCif = (event: any) => {
    setCif(event.target.value);
    handleChangePartnerData({ ...partner, cif: event.target.value });
  };

  const handleChangeEmail = (event: any) => {
    const newEmail = event.target.value;
    const normalizedEmail = normalizeEmail(newEmail);

    setEmail(normalizedEmail);
    handleChangePartnerData({ ...partner, email: normalizedEmail });
  };

  const handleChangeBirthdate = (event: any) => {
    setBirthdate(event.target.value);
    handleChangePartnerData({ ...partner, birthdate: event.target.value });
  };

  const handleChangeEmployeeNumber = (event: any) => {
    setEmployeeNumber(event.target.value);
    handleChangePartnerData({ ...partner, employeeNumber: event.target.value });
  };

  const handleChangeNationality = (event: any) => {
    setNationality(event.target.value);
    handleChangePartnerData({ ...partner, nationality: event.target.value });
  };

  const handleChangePhone = (event: any) => {
    setPhone(event.target.value);
    handleChangePartnerData({ ...partner, phone: event.target.value });
  };

  const handleChangeCategory = (selectedOption: any) => {
    setSelectedCategoryOption(selectedOption);
    handleChangePartnerData({ ...partner, category: selectedOption.value });
  };

  const handleChangeSindication = (selectedOption: any) => {
    setSelectedSindicationOption(selectedOption);
    handleChangePartnerData({ ...partner, sindication: selectedOption.value });
  };

  const handleChangeCivilStatus = (selectedOption: any) => {
    setSelectedCivilStatusOption(selectedOption);
    handleChangePartnerData({ ...partner, civilStatus: selectedOption.value });
  };

  const handleChangeImage = async (event: any) => {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        const result = e.target?.result || '';
        setImage(result as string);
        handleChangePartnerData({ ...partner, image: result as string });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="tab-pane active">
          <div className="row gy-4">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="partner-name">
                  {isNaturalPerson ? t('Name') : t('SocialDenomination')}
                </label>
                <input
                  id="partner-name"
                  type="text"
                  className="form-control form-control-lg"
                  value={name}
                  onChange={handleChangeName}
                  placeholder={t('IntroduceFullName')}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="partner-cif">
                  {t('FiscalIdentification')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-cc-alt2" />
                  </div>
                  <input
                    id="partner-cif"
                    type="text"
                    className="form-control form-control-lg"
                    value={cif}
                    onChange={handleChangeCif}
                    placeholder={t('EnterTheCifOrNif')}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="partner-email">
                  {t('MainEmail')}
                  <em
                    className="icon ni ni-info text-gray ml-2"
                    id="modal-email-category-main-info"
                  />
                  <Tooltip
                    anchorId="modal-email-category-main-info"
                    place="right"
                    style={{
                      zIndex: 9999,
                    }}
                  >
                    {t('EmailCategoryMainInfo')}
                  </Tooltip>
                </label>

                <input
                  id="patner-email"
                  type="email"
                  className="form-control form-control-lg"
                  value={email}
                  onChange={handleChangeEmail}
                  disabled={!isAdmin && user['_id'] !== partner?.user}
                  placeholder={t('IntroduceNewEmail')}
                  pattern={EMAIL_PATTERN}
                />
                {!isValidEmail(email) && (
                  <InputError errorMessage={t('InvalidEmail')} />
                )}
              </div>

              <AddedEmailsView
                originalPartner={originalPartner}
                currentPartner={partner}
                handleUpdatePartner={handleChangePartnerData}
              />
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="partner-birthday">
                  {isNaturalPerson ? t('DateOfBirth') : t('ConstitutionDate')}
                </label>
                <input
                  id="partner-birthday"
                  type="date"
                  className="form-control form-control-lg date-picker"
                  value={birthdate}
                  onChange={handleChangeBirthdate}
                  placeholder={t('IntroduceBirthDate')}
                  max="2100-01-01"
                  min="1000-01-01"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="partner-employee-number">
                  {t('EmployeeNumber')}
                </label>
                <div className="form-control-wrap">
                  <input
                    id="partner-employee-number"
                    type="text"
                    className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                    value={employeeNumber}
                    onChange={handleChangeEmployeeNumber}
                    placeholder={t('IntroduceEmployeeNumber')}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label w-100"
                  htmlFor="partner-nationality"
                >
                  {t('Nationality')}
                </label>
                <input
                  id="partner-nationality"
                  type="text"
                  className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                  value={nationality}
                  onChange={handleChangeNationality}
                  placeholder={t('IntroduceNationality')}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="partner-phone">
                  {t('Telephone')}
                </label>
                <input
                  id="partner-phone"
                  type="text"
                  className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                  value={phone}
                  onChange={handleChangePhone}
                  placeholder={t('IntroducePhoneNumber')}
                />
              </div>
            </div>

            {isAdmin && (
              <div className="col-md-6">
                <label className="form-label" htmlFor="partner-category">
                  {t('Category')}
                </label>
                <div id="partner-category" className="form-control-wrap">
                  <Select
                    closeMenuOnSelect
                    className="react-select react-select-lg"
                    value={selectedCategoryOption}
                    options={categoryOptions}
                    components={animatedComponents}
                    onChange={handleChangeCategory}
                    placeholder={t('SelectCategory')}
                  />
                </div>
              </div>
            )}

            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label w-100"
                  htmlFor="sindication-shares"
                >
                  {t('SindicationShares')}
                </label>

                <div className="form-control-wrap">
                  <Select
                    closeMenuOnSelect
                    value={selectedSindicationOption}
                    className="react-select react-select-lg"
                    options={sindicationOptions}
                    components={animatedComponents}
                    onChange={handleChangeSindication}
                    placeholder={t('SelectPartner')}
                  />
                </div>
              </div>
            </div>

            {isNaturalPerson && (
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label w-100"
                    htmlFor="partner-civil-satus"
                  >
                    {t('CivilStatus')}
                  </label>

                  <div id="partner-civil-status" className="form-control-wrap">
                    <Select
                      closeMenuOnSelect
                      value={selectedCivilStatusOption}
                      className="react-select react-select-lg"
                      options={civilStatusOptions}
                      components={animatedComponents}
                      onChange={handleChangeCivilStatus}
                      placeholder={t('SelectCivilStatus')}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="col-md-3 col-4">
              <label className="form-label mr-2" htmlFor="imageInput">
                {t('Image')}
              </label>
              <input
                type="file"
                id="imageInput"
                className="d-none"
                onChange={handleChangeImage}
                accept="image/*"
              />
              <label
                htmlFor="imageInput"
                className="btn btn-primary form-control-lg m-0"
              >
                {t('Choose')}
              </label>
            </div>

            <div className="col-md-3 col-4">
              <div
                className="user-avatar mx-auto lg bg-primary-dim"
                style={{
                  maxWidth: '80px',
                  maxHeight: '80px',
                }}
              >
                {image ? (
                  <img
                    id="user-image"
                    src={image}
                    alt={name}
                    style={{
                      maxWidth: '80px',
                      maxHeight: '80px',
                    }}
                  />
                ) : (
                  <span>{name ? name.slice(0, 2).toUpperCase() : ''}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default PersonalDataView;
