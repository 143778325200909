import { HoldingClass, Transaction } from 'types';

export const getTransactionHoldingClass = (
  holdingClasses: HoldingClass[],
  transaction: Transaction
) => {
  const transactionHoldingClass: string = transaction.shareClass as string;

  console.log('🚀 cclog ~ getTransactionHoldingClass ~ storeHoldingClasses:', {
    holdingClasses,
    transactionHoldingClass,
  });
  if (!transactionHoldingClass || !holdingClasses.length) {
    return null;
  }

  return (
    holdingClasses.find(
      (holdingClass) => holdingClass._id === transactionHoldingClass
    ) || null
  );
};
