/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-has-content */
import InboxRow from './components/InboxRow';

const Inbox = ({ communications }) => (
  <div className="nk-ibx-list overflow-x-hidden" data-simplebar="init">
    <div className="simplebar-wrapper" style={{ margin: '0px' }}>
      <div className="simplebar-height-auto-observer-wrapper">
        <div className="simplebar-height-auto-observer" />
      </div>
      <div className="simplebar-mask">
        <div
          className="simplebar-offset"
          style={{ right: '0px', bottom: '0px', height: '100% !important' }}
        >
          <div
            className="simplebar-content-wrapper"
            role="region"
            aria-label="scrollable content"
            style={{
              height: '100%',
              overflow: 'hidden scroll',
            }}
          >
            <div className="simplebar-content" style={{ padding: '0px' }}>
              {communications
                ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                ?.map((communication) => (
                  <InboxRow communication={communication} />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="simplebar-placeholder"
        style={{ width: '910px', height: '1167px' }}
      />
    </div>
    <div
      className="simplebar-track simplebar-horizontal"
      style={{ visibility: 'hidden' }}
    >
      <div
        className="simplebar-scrollbar"
        style={{ width: '0px', display: 'none' }}
      />
    </div>
    <div
      className="simplebar-track simplebar-vertical"
      style={{ visibility: 'visible' }}
    >
      <div
        className="simplebar-scrollbar"
        style={{
          height: '80px',
          transform: 'translate3d(0px, 35px, 0px)',
          display: 'block',
        }}
      />
    </div>
  </div>
);

export default Inbox;
