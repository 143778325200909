import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import { decryptResponse } from 'utils/token';
import { addConfigurationToEmailData } from 'utils/emails';

import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';
import { setModal } from '../../_shared/redux/modalActions';
import { reSetSociety } from '../../society/redux/societyActions';

export function sendCommEmail(newEmailData, showAlert = true) {
  const { files, ...emailData } = newEmailData;
  const formData = new FormData();

  return async (dispatch, getState) => {
    try {
      const state = getState();
      const emailDataWithConfiguration = addConfigurationToEmailData(
        state.society.actualSociety,
        emailData
      );

      if (files) {
        [...files].forEach((file) => {
          formData.append('files', file);
        });
      }
      formData.append('data', JSON.stringify(emailDataWithConfiguration));

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/comms`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      );

      const decodedData = await decryptResponse(data);
      dispatch(setModal(null));
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes['COMMUNICATIONS_EMAIL_SENT']));
      }
      dispatch(reSetSociety(decodedData.society));
      return decodedData.communication;
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_COMMUNICATIONS_EMAIL']));
    }
  };
}

export function getEmails() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/contact`
      );

      const decodedData = await decryptResponse(data);
      dispatch({ type: 'GET_EMAILS', payload: decodedData });
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_GET_EMAILS']));
    }
  };
}

export const getRecipientsInfo = (communicationId, queryType) => async () => {
  const query = { params: { queryType } };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/comms/${communicationId}`,
      query
    );

    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    console.log(error);
  }
};

export const updateCommEmail =
  (id, societyId, emailData) => async (dispatch) => {
    const body = {
      id,
      societyId,
      ...emailData,
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/comms`,
        body
      );

      const decodedData = await decryptResponse(data);
      dispatch(reSetSociety(decodedData));
    } catch (error) {
      console.log('[ERROR UPDATING COMM EMAIL]', error);
    }
  };

export const deleteCommEmail = (id) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/comms/${id}`
    );

    const decodedData = await decryptResponse(data);
    dispatch(reSetSociety(decodedData));
  } catch (error) {
    console.log('[ERROR DELETING COMM EMAIL]', error);
  }
};
