/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-newline */
import { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { updateDraft } from 'modules/drafts/redux/draftActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';

import downloadWord from 'utils/downloadWord';
import cleanHtmlEntities from 'utils/editor';
import {
  getActualSociety,
  getOperationById,
  getUsedSharesFromPlan,
} from 'utils/filters';

import draftSellSharesTemplates from 'constants/draftSellSharesTemplates';
import draftTypes from 'constants/draftTypes';
import {
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import menuTypes from 'constants/menuTypes';
import operationTypes from 'constants/operationTypes';
import operationTypesRealNames from 'constants/operationTypesRealNames';

import BadgeDraftStatus from 'modules/_shared/components/Badges/BadgeDraftStatus';
import ExcelButton from 'modules/_shared/components/Buttons/ExcelButton';
import PrintButton from 'modules/_shared/components/Buttons/PrintButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import DraftToBuySellShares from 'modules/_shared/components/Modals/DraftToBuySellShares';
import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';
import SaveDrafts from 'modules/_shared/components/Modals/SaveDrafts';
import SellParticipations from 'modules/_shared/components/Modals/SellParticipations';
import DraftBanner from 'modules/drafts/components/DraftBanner';
import DraftSellSharesRow from 'modules/drafts/components/DraftSellSharesRow';

import { MAX_PARTNERS_CAPTABLE } from 'constants/defaultConstants';
import { useTranslate } from 'hooks/useTranslate';
import { getDocument } from 'modules/documents/redux/documentActions';
import PartnerBook from 'modules/partners/pages/PartnerBook';
import { store } from 'redux/store';
import downloadOptions from './utils/downloadOptions';

const DraftSellParticipations = () => {
  const printRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslate();
  const { societyId, draftId } = useParams();

  const user = useSelector((state) => state?.user);
  const drafts = useSelector((state) => state?.drafts);
  const isAdmin = useSelector((state) => state?.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const holdingClasses = useSelector((state) => state?.holdingClasses);
  const plans = useSelector((state) => state?.plans);
  const tenderOffers = useSelector((state) => state?.tenderOffers);
  const investors = useSelector(
    (state) => state?.society?.actualSociety?.investors
  );
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [draft, setDraft] = useState({});
  const [draftName, setDraftName] = useState('');

  const [rows, setRows] = useState([]);
  const [summaryRow, setSummaryRow] = useState({});
  const [rowsPrintVersion, setRowsPrintVersion] = useState([]);

  const [operationData, setOperationData] = useState({});
  const [currentDownloadOptions] = useState(downloadOptions(i18n));
  const downloadFileName = `${draftName || t('SaleOfParticipations')} - ${
    actualSociety?.name
  }`;

  const [showPartnerBook, setShowPartnerBook] = useState(false);

  const [touched, setTouched] = useState(false);

  const [updatedPartners, setUpdatedPartners] = useState([]);
  const [allSummaryRow, setAllSummaryRow] = useState({
    totalSharesInitial: 0,
    totalSharesNew: 0,
    totalSharesTotal: 0,
  });

  const [draftPartners, setDraftPartners] = useState([]);

  const [totalCN, setTotalCN] = useState(0);
  const [plansTypeShares, setPlansTypeShares] = useState({
    stockAssignedShares: 0,
    stockUnassignedShares: 0,
    phantomUnassignedShares: 0,
    phantomAssignedShares: 0,
  });

  const getActualDraft = (draftId) =>
    drafts?.find((draft) => draft?._id === draftId);

  const loadInitialValues = (draftId) => {
    if (!draftId) return;
    const actualDraft = getActualDraft(draftId);

    if (!actualDraft) {
      history.push(`/borradores/${societyId}`);
      return;
    }

    if (
      actualDraft?.draftPartners === null ||
      actualDraft?.draftPartners === undefined
    ) {
      actualDraft.draftPartners = JSON.stringify([]);
    }
    setDraft(actualDraft);
    setDraftName(actualDraft?.name);
    setOperationData(actualDraft?.operationData);
  };

  const handleSaveDraft = () => {
    if (draftId) {
      dispatch(updateDraft(draftId, draft));
      setTouched(false);
    } else {
      dispatch(
        setModal(<SaveDrafts draftData={draft} setTouched={setTouched} />)
      );
    }
  };

  const updateDraftData = () => {
    const newDraft = {
      draftType: draftTypes.DRAFT_BUY_SELL_SHARES,
      society: actualSociety?._id,
      percent: summaryRow?.totalND,
      totalContribution: summaryRow?.totalPrice,
      draftPartners: JSON.stringify([]),
      operationData,
    };

    setDraft(newDraft);
  };

  const getMergedNewShares = (partnersData) => {
    const result = partnersData.reduce((acc, partner) => {
      acc[partner.id] = (acc[partner.id] ?? 0) + partner.shares;
      return acc;
    }, {});
    return result;
  };

  const generateUpdatedPartners = (partnersData) => {
    const societyActualShares = actualSociety.sharesCount?.actual || 0;

    const totalFDShares =
      societyActualShares +
      plansTypeShares.stockAssignedShares +
      plansTypeShares.phantomAssignedShares +
      totalCN;

    const totalPFDShares =
      societyActualShares +
      plansTypeShares.stockAssignedShares +
      plansTypeShares.stockUnassignedShares +
      plansTypeShares.phantomAssignedShares +
      plansTypeShares.phantomUnassignedShares +
      totalCN;

    const newMergedShares = getMergedNewShares(partnersData);
    const societyPartners = [];
    const partners = [...actualSociety?.partners, ...draftPartners];

    partners?.forEach((partner) => {
      // Filter the transactions where the current partner appears
      const transactions = partnersData.filter(
        (currentPartner) => currentPartner.id === partner['_id']
      );
      const actualShares = partner?.sharesCount?.actual;
      const futureShares =
        (partner?.sharesCount?.actual || 0) +
        (partner?.sharesCount?.future || 0) +
        (partner?.sharesCount?.drafts || 0);
      const shares = {
        initial: actualShares,
        totalFuture: futureShares - (partner?.carriedShares || 0),
        new: 0,
        total: actualShares,
      };
      const result = {
        name: partner.name,
        societyPartnerId: partner['_id'],
        holdingClass: holdingClasses[0]?.['_id'],
        shares,
        isPartnerAlready: partner?.isPartnerAlready ?? true,
        NDTotal: (shares.total * 100) / societyActualShares,
        FDTotal: (shares.total * 100) / totalFDShares,
        FDPotential: (shares.totalFuture * 100) / totalPFDShares,
      };

      if (transactions.length === 0) {
        societyPartners.push(result);
      } else {
        transactions.forEach((transactionPartner) => {
          const newShares = transactionPartner?.shares ?? 0;
          const holdingClass =
            transactionPartner?.holdingClass ?? holdingClasses?.[0]?.['_id'];

          const newTotal = shares.total + newMergedShares[partner['_id']];
          const newTotalFuture =
            shares.totalFuture + newMergedShares[partner['_id']];

          societyPartners.push({
            ...result,
            holdingClass,
            shares: {
              ...shares,
              new: newShares,
              total: newTotal,
            },
            isPartnerAlready: partner?.isPartnerAlready ?? true,
            NDTotal: (newTotal * 100) / societyActualShares,
            FDPotential: (newTotalFuture * 100) / totalFDShares,
            FDTotal: (newTotalFuture * 100) / totalPFDShares,
          });
        });
      }
    });

    setUpdatedPartners(societyPartners);
  };

  const parseOperationData = (data) => {
    if (!data?.transactions) return;
    const transactions = data?.transactions;

    const transactionsPairs = [];
    for (let i = 0; i < transactions.length; i += 2) {
      transactionsPairs.push({
        buyerId: transactions[i].partner,
        sellerId: transactions[i + 1].partner,
        shareClass: transactions[i].shareClass,
        shareFrom: transactions[i].shareFrom,
        shareTo: transactions[i].shareTo,
        totalShares: transactions[i].shareTo - transactions[i].shareFrom + 1,
        sharePrice: transactions[i].sharePrice,
        totalPrice:
          (transactions[i].shareTo - transactions[i].shareFrom + 1) *
          transactions[i].sharePrice,
        sharePremium: transactions[i].sharePremium,
      });
    }

    let summaryTotalShares = 0;
    let summaryTotalPrice = 0;
    let summaryTotalND = 0;

    const partnersData = [];

    const transactionRows = transactionsPairs?.map((transaction, index) => {
      if (!actualSociety?.partners) return;

      const seller = [...actualSociety?.partners, ...draftPartners]?.find(
        (partner) => partner._id === transaction.sellerId
      );

      const buyer = [...actualSociety?.partners, ...draftPartners]?.find(
        (partner) => partner._id === transaction.buyerId
      );

      const currentClass = holdingClasses?.find(
        (holdingClass) => holdingClass._id === transaction.shareClass
      );

      const societyActualShares = actualSociety?.sharesCount.actual;

      const rowData = {
        name: seller?.name,
        email: seller?.email,
        sellerId: seller?._id,
        buyerId: buyer?._id,
        buyerName: buyer?.name,
        totalShares: transaction.totalShares,
        shareClass: currentClass?.name,
        shareFrom: transaction.shareFrom,
        shareTo: transaction.shareTo,
        sharePrice: transaction.sharePrice,
        totalPrice: transaction.totalPrice,
        totalND: (transaction.totalShares * 100) / societyActualShares,
      };

      summaryTotalShares += rowData.totalShares;
      summaryTotalPrice += rowData.totalPrice;
      summaryTotalND += rowData.totalND;

      partnersData.push(
        {
          id: transaction.sellerId,
          shares: -transaction.totalShares,
          holdingClass: transaction.shareClass,
        },
        {
          id: transaction.buyerId,
          shares: transaction.totalShares,
          holdingClass: transaction.shareClass,
        }
      );

      return (
        <DraftSellSharesRow
          key={transaction.partner}
          index={index + 1}
          data={{ ...rowData }}
        />
      );
    });

    generateUpdatedPartners(partnersData);

    setRows(transactionRows);
    setSummaryRow({
      totalShares: summaryTotalShares,
      totalPrice: summaryTotalPrice,
      totalND: summaryTotalND,
    });
  };

  const validatePartnerBookVisibility = (society) => {
    if (
      society?.partners?.filter((partner) => partner?.sharesCount?.actual > 0)
        ?.length < MAX_PARTNERS_CAPTABLE
    ) {
      setShowPartnerBook(true);
    }
  };
  const formatOperationForScripture = () => {
    const buyers = [];
    const sellers = [];

    const societyActualShares = actualSociety?.sharesCount.actual;

    operationData?.transactions?.forEach((transaction) => {
      let currentPartner = actualSociety?.partners?.find(
        (partner) => partner._id === transaction.partner
      );

      const sourceOperation = transaction?.operation
        ? getOperationById(transaction?.operation, actualSociety)
        : null;

      const shareBuyer =
        transaction?.transactionType === 'SELL'
          ? operationData?.transactions?.find(
              (t) =>
                t.transactionType === 'BUY' &&
                t.shareFrom === transaction.shareFrom &&
                t.shareTo === transaction.shareTo &&
                t.shareClass === transaction.shareClass
            )?.partner
          : null;

      const currentBuyer = actualSociety?.partners?.find(
        (partner) => partner._id === shareBuyer
      );

      currentPartner = {
        ...currentPartner,
        ...transaction,
        shareBuyer: currentBuyer?.name || '',
        sharesTotal: transaction.shareTo - transaction.shareFrom + 1,
        sharesPercent:
          ((transaction.shareTo - transaction.shareFrom + 1) * 100) /
          societyActualShares,
        sourceOperation: {
          type: sourceOperation?.operationType || '',
          date: sourceOperation?.date || '',
        },
      };

      if (transaction.transactionType === 'BUY') {
        buyers.push(currentPartner);
      } else {
        sellers.push(currentPartner);
      }
    });

    const society = actualSociety;

    return {
      draft,
      buyers,
      sellers,
      society,
      summaryRow,
      notary: {
        name: operationData?.notarialRegistration?.notaryName || '',
        city: operationData?.notarialRegistration?.notaryCity || '',
        protocolNumber: operationData?.notarialRegistration?.protocolNr || '',
      },
      operationDate: operationData?.date,
      operationType:
        operationTypesRealNames[operationTypes.SELL_PARTICIPATIONS],
    };
  };

  const generateScripture = async () => {
    try {
      const templateId = draftSellSharesTemplates[process.env.NODE_ENV];

      const currentTemplate = await store.dispatch(getDocument(templateId));

      const templateUpdated = updateDocumentWithValues(
        currentTemplate?.editorModel,
        tags({
          partner: {},
          society: actualSociety,
          tenderOffer: {},
          tenderOfferShares: {},
          plan: {},
          beneficiary: {},
          operation: formatOperationForScripture(),
        })
      );
      const templateModel = templateUpdated.html;
      const docHTML = cleanHtmlEntities(templateModel);

      await downloadWord(docHTML, 'Borrador Escritura Compraventa.docx');
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
    dispatch(getHoldingClasses(actualSociety?.['_id']));
    validatePartnerBookVisibility(actualSociety);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (!holdingClasses?.length || !actualSociety || !draftId) return;
    loadInitialValues(draftId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId, actualSociety, holdingClasses]);

  useEffect(() => {
    updateDraftData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryRow]);

  useEffect(() => {
    if (!operationData) return;

    parseOperationData(operationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationData]);

  useEffect(() => {
    setRowsPrintVersion(
      rows.map(({ props }) => (
        <DraftSellSharesRow
          print
          key={props.index}
          index={props.index}
          data={{ ...props.data }}
        />
      ))
    );
  }, [rows]);

  useEffect(() => {
    let totalSharesInitial = 0;
    let totalSharesNew = 0;
    let totalSharesTotal = 0;

    const visitedPartners = [];

    if (updatedPartners) {
      updatedPartners.forEach((partner) => {
        if (!visitedPartners.includes(partner.societyPartnerId)) {
          totalSharesInitial += partner?.shares?.initial;
          totalSharesTotal += partner?.shares?.total;
          visitedPartners.push(partner.societyPartnerId);
        }
        totalSharesNew += partner?.shares?.new;
      });
    }
    setAllSummaryRow({
      totalSharesInitial,
      totalSharesNew,
      totalSharesTotal,
    });
  }, [updatedPartners]);

  useEffect(() => {
    if (plans?.length && actualSociety) {
      const result = plans
        .filter((plan) => plan.society === societyId && !plan.isDraft)
        .reduce(
          (acc, plan) => {
            const assigned =
              getUsedSharesFromPlan(plan, actualSociety, tenderOffers) || 0;
            const total = plan?.sharesTotal || 0;
            const unassigned = total - assigned || 0;

            if (
              plan.planType === 'STOCK_OPTION' ||
              plan.planType === 'WARRANTS'
            ) {
              acc.stockAssignedShares += assigned;
              acc.stockUnassignedShares += unassigned;
            }

            if (plan.planType === 'PHANTOM_SHARE') {
              acc.phantomAssignedShares += assigned;
              acc.phantomUnassignedShares += unassigned;
            }
            return acc;
          },
          {
            stockAssignedShares: 0,
            stockUnassignedShares: 0,
            phantomUnassignedShares: 0,
            phantomAssignedShares: 0,
          }
        );
      setPlansTypeShares(result);
    }
  }, [plans, actualSociety, tenderOffers]);

  useEffect(() => {
    if (investors?.length) {
      const sum = investors.reduce(
        (acc, investor) => acc + investor?.sharesCount?.drafts || 0,
        0
      );
      setTotalCN(sum);
    } else {
      setTotalCN(0);
    }
  }, [investors]);

  return actualSociety ? (
    <>
      <Prompt
        message={`${t('Hello')}\n ${t('DraftDontSaved')}`}
        when={touched && !isDemo}
      />
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('CalculateSellShares')}
                </h3>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <>
                          {rows?.length > 0 ? (
                            <li>
                              <button
                                type="button"
                                className="btn btn-success"
                                disabled={draft?.isConverted || isDemo}
                                onClick={() =>
                                  dispatch(
                                    setModal(
                                      <DraftToBuySellShares
                                        draftId={draftId}
                                        draftData={draft}
                                        operationData={operationData}
                                        setTouched={setTouched}
                                        newPartners={draftPartners}
                                      />
                                    )
                                  )
                                }
                              >
                                {t('SaveAsOperation')}
                              </button>
                            </li>
                          ) : (
                            <li>
                              <button
                                type="button"
                                className="btn btn-success"
                                disabled={draft?.isConverted}
                                onClick={() =>
                                  dispatch(
                                    setModal(
                                      <SellParticipations
                                        isDraft
                                        setDraftData={setOperationData}
                                        draftPartners={draftPartners}
                                        setDraftPartners={setDraftPartners}
                                        transmittingType={
                                          operationTypes.SELL_PARTICIPATION
                                        }
                                      />
                                    )
                                  )
                                }
                              >
                                {t('AddSellShares')}
                              </button>
                            </li>
                          )}
                          <li className="preview-item">
                            <OperationMenuDots
                              title={t('GenerateDocument')}
                              variant="light"
                              menuOptions={currentDownloadOptions}
                              disabled={!rows?.length}
                              params={{
                                generateScripture,
                                outlined: true,
                              }}
                            />
                          </li>
                          <li>
                            <ExcelButton
                              tableRef="buy-sell-shares-table"
                              fileName={downloadFileName}
                              label={t('Download')}
                              sheetName={t('Buy-Share')}
                            />
                          </li>

                          <li>
                            <PrintButton
                              fileName={downloadFileName}
                              printRef={printRef}
                              hideLabel
                            />
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* HEADER */}
          <div ref={printRef} id="margin-print-only">
            <PrintHeader title={t('PrintHeaderDraftSell')} />

            <div className="card card-bordered sp-plan">
              <div className="row no-gutters">
                <div className="col-7">
                  <div className="sp-plan-info card-inner">
                    <div className="row gx-0 gy-3">
                      <div className="col-12">
                        <div className="sp-plan-name">
                          <h6 className="title">
                            {t('SaleData')}

                            <BadgeDraftStatus draft={draft} />
                          </h6>
                          <h5 className="sub-title text-primary">
                            {draftId && draftName}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sp-plan-desc card-inner">
                    <ul className="row gx-1 text-left align-items-end">
                      <li className="col-4">
                        <p>
                          <span className="text-soft">{t('SoldShares')}</span>
                          <NumberFormat
                            value={summaryRow?.totalShares || 0}
                            displayType="text"
                            {...numberFormat}
                          />
                        </p>
                      </li>
                      <li className="col-4">
                        <p>
                          <span className="text-soft">{t('TotalSoldND')}</span>
                          <NumberFormat
                            value={summaryRow?.totalND || 0}
                            displayType="text"
                            {...numberFormatDecimals}
                            suffix="%"
                          />
                        </p>
                      </li>
                      <li className="col-4">
                        <p>
                          <span className="text-soft">
                            {t('TotalSoldPrice')}
                          </span>
                          <NumberFormat
                            value={summaryRow?.totalPrice || 0}
                            displayType="text"
                            {...currencyFormatDecimals}
                          />
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-5 screen-only d-block">
                  <div className="sp-plan-action card-inner">
                    <div className="pricing-head w-100">
                      <div className="pricing-title mb-4">
                        <h4 className="card-title title text-center">
                          {t('SoldShares')}:
                          <br />
                          <NumberFormat
                            value={summaryRow?.totalShares || 0}
                            displayType="text"
                            {...numberFormat}
                          />
                        </h4>
                      </div>
                    </div>
                    <div className="nk-block-head-content">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSaveDraft}
                        disabled={!touched || draft?.isConverted || isDemo}
                      >
                        {t('SaveDraft')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* LIST */}
            <div className="invest-field form-group mt-4">
              <div className="nk-block">
                <div className="card card-preview card-bordered">
                  <table
                    className="datatable-init nk-tb-list nk-tb-ulist"
                    data-auto-responsive="false"
                  >
                    <thead>
                      <tr className="nk-tb-item nk-tb-head">
                        <th className="nk-tb-col">
                          <span className="sub-text">{t('Nr')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('Seller')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('Buyer')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('NrPart')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('Class')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('SharesFrom')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('SharesTo')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('SharePrice')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('TotalPrice')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('%ND')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows?.length ? (
                        rows
                      ) : (
                        <NoItemsAvailable
                          mainMessage={t('NoTransactions')}
                          infoMessage={t('NoTransactionsMessage')}
                          colspan={10}
                        />
                      )}
                    </tbody>
                    {rows?.length > 0 && (
                      <tfoot
                        className="tb-tnx-head border-top"
                        style={{ display: 'table-footer-group' }}
                      >
                        <tr className="nk-tb-item nk-tb-head">
                          <th className="nk-tb-col" />
                          <th className="nk-tb-col text-center">
                            <span className="sub-text">Total</span>
                          </th>
                          <th className="nk-tb-col text-center" />
                          <th className="nk-tb-col text-center">
                            <span className="sub-text text-right">
                              <NumberFormat
                                value={summaryRow.totalShares}
                                displayType="text"
                                {...numberFormat}
                              />
                            </span>
                          </th>
                          <th className="nk-tb-col" colSpan={4} />
                          <th className="nk-tb-col text-center">
                            <span className="sub-text text-right">
                              <NumberFormat
                                value={summaryRow.totalPrice}
                                displayType="text"
                                {...currencyFormatDecimals}
                              />
                            </span>
                          </th>
                          <th className="nk-tb-col text-center">
                            <span className="sub-text  text-right">
                              <NumberFormat
                                value={summaryRow.totalND}
                                displayType="text"
                                {...currencyFormatDecimals}
                                suffix="%"
                              />
                            </span>
                          </th>
                        </tr>
                      </tfoot>
                    )}
                  </table>

                  {/* PRINT VERSION */}
                  <table
                    className="datatable-init nk-tb-list nk-tb-ulist d-none"
                    data-auto-responsive="false"
                    id="buy-sell-shares-table"
                  >
                    <thead>
                      <tr className="nk-tb-item nk-tb-head">
                        <th className="nk-tb-col">
                          <span className="sub-text">{t('Nr')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('Seller')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('SellerEmail')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('Buyer')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('NrPart')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('Class')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('SharesFrom')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('SharesTo')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('SharePrice')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('TotalPrice')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('%ND')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{rowsPrintVersion}</tbody>
                  </table>
                </div>

                {showPartnerBook ? (
                  <div className="nk-block nk-block-embeded">
                    <PartnerBook
                      rows={rows}
                      updatedPartners={updatedPartners}
                      summaryRow={allSummaryRow}
                      draftView
                      buySellDraft
                    />
                  </div>
                ) : (
                  <DraftBanner setShowPartnerBook={setShowPartnerBook} />
                )}
              </div>
            </div>
          </div>
          <div />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default DraftSellParticipations;
