/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';
import { v4 as uuidv4 } from 'uuid';
import { read } from 'xlsx';
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import html2pdf from 'html3pdf';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';

import addHtmlAlign from 'utils/addHtmlAlign';
import { startSignProcess, toBase64 } from 'utils/docusignActions';
import { getSingleDocument } from 'utils/downloadDocument';
import { createBlobMerged } from 'utils/downloadMergedPDF';
import cleanHtmlEntities from 'utils/editor';
import { getUsedSharesFromPlan } from 'utils/filters';
import { excelDateToJSDate } from 'utils/formatDate';
import { getExcelBeneficiaries } from 'utils/getExcelData';
import { generateCalendarPlanTable } from 'utils/htmlTemplates';
import trackEvent from 'utils/trackEvent';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { addBeneficiary } from 'modules/_shared/redux/modalsActions';
import { addDocument } from 'modules/documents/redux/documentActions';

import cliffOptions from 'constants/cliffOptions';
import { JSPDF_MARGINS } from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import { cleanValue } from 'constants/formats';
import invitationStatus from 'constants/invitationStatus';
import signatureTypes from 'constants/signatureTypes';
import sizes from 'constants/sizes';
import vestingOptions from 'constants/vestingOptions';

import { clearTableLines } from 'utils/clearTableLines';
import { getConsolidationPeriods } from 'utils/consolidationPeriods';
import { generatePdfBlob } from 'utils/generatePdfBlob';
import getEnvelopeData from 'utils/getEnvelopeData';
import normalizeCif from 'utils/normalizeCif';
import normalizeEmail from 'utils/normalizeEmail';
import hasShowDetails, { hasNewPDF } from 'utils/showDetails';
import { transformHtmlTables } from 'utils/transformHtmlTables';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';

import '../Modals.scss';

import BeneficiariesReview from '../BeneficiariesReview';
import errors from './constants/errors';
import getInvalidEmails from './utils/getInvalidEmails';

const templateURL = process.env.REACT_APP_TEMPLATE_BENEFICIARIES_URL;

const statusType = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NO_APPLY: 'NO_APPLY',
};

const colorStatus = {
  PENDING: 'bg-warning text-dark',
  SUCCESS: 'bg-success text-dark',
  ERROR: 'bg-danger text-white',
  NO_APPLY: 'bg-light text-dark',
};

function UploadBeneficiaryModal({ isDraft = false }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const stockPlans = useSelector((state) => state.plans);
  const user = useSelector((state) => state.user);
  const tenderOffers = useSelector((state) => state.tenderOffers);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [plan, setPlan] = useState('');
  const [file, setFile] = useState();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isValidFile, setIsValidFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiariesStatus, setBeneficiariesStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [errorUploading, setErrorUploading] = useState(false);
  const [finishUploading, setFinishUploading] = useState(true);

  const [invitationCode, setInvitationCode] = useState('');
  const [invitationStatusType, setInvitationStatusType] = useState('');
  const [attachmentDocument, setAttachmentDocument] = useState(null);

  const getAttachedDocument = async (documentId) => {
    if (documentId) {
      const attachmentBlob = await getSingleDocument(documentId);
      setAttachmentDocument(attachmentBlob);
    }
  };

  async function uploadList(event) {
    setIsLoading(true);
    event.preventDefault();
    setErrorUploading(false);
    setFinishUploading(false);
    let currentBeneficiaries = {};

    try {
      for (const beneficiary of beneficiaries) {
        const rowNum = beneficiary['__rowNum__'];
        const currentStatus = currentBeneficiaries;
        currentStatus[`row${rowNum}`] = {
          rowNum,
          name: beneficiary['NOMBRE Y APELLIDOS'],
        };
        if (currentPlan?.hasInvitation) {
          currentStatus[`row${rowNum}`].signStatus = statusType.PENDING;
        } else {
          currentStatus[`row${rowNum}`].signStatus = statusType.NO_APPLY;
        }
        setBeneficiariesStatus({ ...currentBeneficiaries, ...currentStatus });

        const salary = beneficiary['SUELDO'] || '';
        let laboralCategory = { name: '', percent: 0 };
        let shares = beneficiary['UNIDADES'];
        if (
          currentPlan?.hasLaboralCategories &&
          currentPlan?.laboralCategories.length &&
          !currentPlan?.isFixedPrice
        ) {
          if (beneficiary['CATEGORIA'] && beneficiary['SUELDO']) {
            const category = currentPlan?.laboralCategories.find(
              (c) =>
                c.name.toUpperCase() ===
                beneficiary['CATEGORIA'].toString().toUpperCase()
            );
            if (category && salary) {
              const cleanSalary = cleanValue(beneficiary['SUELDO']);
              const years = currentPlan?.annualUnits
                ? currentPlan?.vestingPeriod / 12
                : 1;
              const unitsByYear = Math.floor(
                (category.percent * cleanSalary) / 100 / currentPlan?.sharePrice
              );
              shares = Math.floor(unitsByYear * years);
              laboralCategory = category;
            }
          }
        }

        if (hasShowDetails()) {
          console.log(
            'cclog - NIF',
            typeof beneficiary?.['NIF'],
            beneficiary?.['NIF']
          );
        }

        const cif =
          beneficiary?.['NIF'] &&
          ['string', 'number'].includes(typeof beneficiary['NIF'])
            ? normalizeCif(beneficiary['NIF'].toString())
            : '';

        const email = normalizeEmail(beneficiary['EMAIL']);

        const newBeneficiary = {
          // ///// from Sheet data
          name: beneficiary['NOMBRE Y APELLIDOS'],
          email,
          address: {
            line1: beneficiary['DIRECCION'] || '',
            zip: '',
            city: '',
            state: '',
            country: '',
          },
          userId: user?.['_id'],
          userEmail: user?.email,
          planStartDate: excelDateToJSDate(beneficiary['FECHA DE ADHESION']),
          cif,
          jobTitle: beneficiary['CARGO'] || '',
          sharesCount: { future: shares },
          // ///// generic
          society: actualSociety['_id'],
          societyName: actualSociety?.name,
          plan: currentPlan['_id'],
          planName: currentPlan?.name,
          planType: currentPlan?.planType,
          salary,
          laboralCategory,
          amount: currentPlan?.isFixedPrice
            ? currentPlan?.sharePrice
            : shares * currentPlan?.sharePrice,
          hasInvitation: currentPlan?.hasInvitation,
          invitationCode,
          invitationStatus: invitationStatusType,
          isDraft,
          // ///// fiscal info
          employeeNumber: beneficiary?.['NRO EMPLEADO'] || '',
          bankAccountNumber: beneficiary?.['CUENTA BANCARIA'] || '',
          taxAddress: {
            line1: beneficiary?.['RESIDENCIA FISCAL'] || '',
            zip: '',
            city: '',
            country: '',
          },
        };

        const documents = [];
        let fileName = '';
        let invitationFile = '';
        let invitationDocument = '';
        let hasSocietySign = false;

        if (
          currentPlan?.hasInvitation &&
          currentPlan?.invitationModel &&
          !isDraft
        ) {
          const invitationUpdated = updateDocumentWithValues(
            currentPlan?.invitationModel?.html,
            tags({
              plan: currentPlan,
              society: actualSociety,
              beneficiary: newBeneficiary,
              partner: null,
            })
          );

          fileName = `Carta_de_Invitación_${newBeneficiary.name}_${currentPlan.name}.pdf`;
          const invitationModel = invitationUpdated.html;
          const {
            hasConsolidationCalendar,
            hasConsolidationCalendarNoStatus,
            hasConsolidationCalendarNoStatusENES,
          } = invitationUpdated;
          hasSocietySign = invitationUpdated.hasSocietySign;

          const invitationHTML = cleanHtmlEntities(invitationModel);
          const alignedText = addHtmlAlign(invitationHTML);

          let mainBlob = null;
          if (hasNewPDF()) {
            const transformedHtml = transformHtmlTables(invitationModel);
            const clearTablesHtml = clearTableLines(transformedHtml);
            mainBlob = await generatePdfBlob(clearTablesHtml);
          } else {
            // eslint-disable-next-line new-cap
            const pdf = new jsPDF('p', 'pt', 'a4');

            const margins = JSPDF_MARGINS;

            await new Promise((resolve, reject) => {
              pdf.fromHTML(
                alignedText,
                JSPDF_MARGINS.left,
                JSPDF_MARGINS.top,
                {
                  width: JSPDF_MARGINS.width,
                },
                () => {
                  try {
                    mainBlob = pdf.output('blob');
                    resolve();
                  } catch (error) {
                    reject(error);
                  }
                },
                margins
              );
            });
          }

          const mainFile = new File([mainBlob], fileName, {
            type: 'application/pdf',
          });

          const tableFiles = [];
          if (
            hasConsolidationCalendar ||
            hasConsolidationCalendarNoStatus ||
            hasConsolidationCalendarNoStatusENES
          ) {
            const consolidationTables = generateCalendarPlanTable(
              getConsolidationPeriods(newBeneficiary, currentPlan),
              {
                hasConsolidationCalendar,
                hasConsolidationCalendarNoStatus,
                hasConsolidationCalendarNoStatusENES,
              }
            );

            const options = {
              filename: 'attachment',
              margin: [10, 10, 10, 10], // top, right, bottom, left
              pagebreak: { mode: 'avoid-all' },
              html2canvas: { scale: 2 },
              jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
              },
            };
            // Create a file for each table
            for (const table of consolidationTables) {
              const tableBlob = await html2pdf()
                .from(table)
                .set(options)
                .output('blob');

              const tableFile = new File([tableBlob], fileName, {
                type: 'application/pdf',
              });
              tableFiles.push(tableFile);
            }
          }

          let attachmentFile;
          if (attachmentDocument) {
            attachmentFile = new File([attachmentDocument], fileName, {
              type: 'application/pdf',
            });
          }

          const mergedBlob =
            tableFiles?.length > 0 || attachmentFile
              ? await createBlobMerged({
                  documents: [mainFile, ...tableFiles, attachmentFile],
                })
              : mainBlob;

          invitationFile = new File([mergedBlob], fileName, {
            type: 'application/pdf',
          });

          const newDocument = {
            name: fileName,
            file: invitationFile,
            size: invitationFile?.size || 0,
            date: new Date(),
            lastAccess: new Date(),
            author: user['_id'],
            society: actualSociety['_id'],
            isGenerated: true,
            fileType: fileTypes.PDF,
            category: documentTypes.LETTERS,
            description: t('DocumentLinkedToIncentivePlan'),
          };

          invitationDocument = await store.dispatch(addDocument(newDocument));
          if (invitationDocument?.['_id']) {
            documents.push([invitationDocument?._id]);
          }
        }

        const uploadedBeneficiary = await store.dispatch(
          addBeneficiary({
            ...newBeneficiary,
            documents,
          })
        );

        if (uploadedBeneficiary) {
          currentStatus[`row${rowNum}`].addStatus = statusType.SUCCESS;
        } else {
          currentStatus[`row${rowNum}`].addStatus = statusType.ERROR;
        }
        setBeneficiariesStatus({ ...currentBeneficiaries, ...currentStatus });

        if (
          currentPlan?.hasInvitation &&
          currentPlan?.invitationModel &&
          !isDraft
        ) {
          const signFileBase64 = await toBase64(invitationFile);

          const signers = [
            {
              id: uploadedBeneficiary['_id'],
              email: newBeneficiary?.email,
              name: newBeneficiary?.name,
              role: 'BENEFICIARY',
              type: signatureTypes.INVITATION_LETTER,
            },
          ];

          if (hasSocietySign) {
            signers.push({
              id: '',
              email: hasSocietySign
                ? currentPlan?.invitationExtraSigner?.email || ''
                : '',
              name: hasSocietySign
                ? currentPlan?.invitationExtraSigner?.name || ''
                : '',
              role: 'SOCIETY',
              type: signatureTypes.FREE_SIGNATURE,
            });
          }

          const beneficiaries = [
            { id: uploadedBeneficiary['_id'], planId: currentPlan['_id'] },
          ];

          const envelopeData = getEnvelopeData({
            signers,
            signType: signatureTypes.INVITATION_LETTER,
            fileName,
            fileType: fileTypes.PDF,
            linkedType: 'BENEFICIARY',
            role: 'BENEFICIARIES',
            userId: user?._id,
            societyId: actualSociety?._id,
            beneficiaries,
            documentId: invitationDocument['_id'],
          });

          const signProcessData = {
            signers,
            societyName: actualSociety?.name,
            documentName: fileName || '',
            planName: currentPlan?.name,
            language: actualSociety?.societyLanguage || 'es',
            signatureType: signatureTypes.INVITATION_LETTER,
            signatureFile: signFileBase64,
            signatureFileName: fileName,
            envelopeData,
          };

          const signProcess = await startSignProcess(signProcessData);

          if (hasShowDetails()) {
            console.log('🚀 cclog - signProcess', signProcess);
          }

          let signStatus;
          if (signProcess?.status === 200) {
            signStatus = statusType.SUCCESS;
          } else {
            signStatus = statusType.ERROR;
          }

          currentStatus[`row${rowNum}`].signStatus = signStatus;
          setBeneficiariesStatus({
            ...currentBeneficiaries,
            ...currentStatus,
          });
        }

        trackEvent(mixpanel, eventTypes.ADD_BENEFICIARY, {
          userId: user?._id,
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?._id,
          societyName: actualSociety?.name,
          operation: eventTypes.ADD_BENEFICIARY,
        });

        currentBeneficiaries = currentStatus;
      }
    } catch (error) {
      console.log(error);
      setErrorUploading(true);
    } finally {
      setFinishUploading(true);
    }
  }

  const closeModal = () => {
    dispatch(setModal(null));
    if (errorUploading) {
      dispatch(addAlert(alertBodyTypes[500]));
    } else {
      dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
    }
  };

  // Validations
  const findInvalidCategoryAndUnitsFields = (plan, beneficiariesInput) => {
    const result = {
      missingCategories: [],
      missingUnits: [],
      missingSalaries: [],
    };
    if (!plan?.hasLaboralCategories) {
      result.missingUnits = beneficiariesInput
        .filter((beneficiary) => !beneficiary['UNIDADES'])
        .map((beneficiary) => beneficiary['NIF']);
      return result;
    }
    for (let i = 0; i < beneficiariesInput.length; i += 1) {
      const beneficiary = beneficiariesInput[i];
      if (beneficiary['CATEGORIA']) {
        const existsCategory = plan?.laboralCategories.some(
          (category) =>
            category.name.toString().toUpperCase() ===
            beneficiary['CATEGORIA'].toString().toUpperCase()
        );
        if (!existsCategory) result.missingCategories.push(beneficiary['NIF']);
        if (!beneficiary['SUELDO'])
          result.missingSalaries.push(beneficiary['NIF']);
      } else if (!beneficiary['UNIDADES'])
        result.missingUnits.push(beneficiary['NIF']);
    }
    return result;
  };

  const checkValidUnits = (plan, beneficiariesInput) => {
    const usedShares = getUsedSharesFromPlan(
      plan,
      actualSociety,
      tenderOffers,
      isDraft
    );
    const categories = plan?.laboralCategories;
    const availableShares = plan.sharesTotal - usedShares;
    const total = beneficiariesInput.reduce((acc, beneficiary) => {
      if (
        beneficiary['CATEGORIA'] &&
        beneficiary['SUELDO'] &&
        plan?.hasLaboralCategories &&
        !plan?.isFixedPrice
      ) {
        const currentCategory = categories.find(
          (category) =>
            category.name.toString().toUpperCase() ===
            beneficiary['CATEGORIA'].toString().toUpperCase()
        );
        const years = plan?.annualUnits ? plan?.vestingPeriod / 12 : 1;
        const units = Math.floor(
          (currentCategory?.percent * +beneficiary['SUELDO']) /
            100 /
            plan?.sharePrice
        );
        const finalUnits = Math.floor(units * years);
        return acc + finalUnits;
      }
      return acc + +beneficiary['UNIDADES'];
    }, 0);
    if (total > availableShares) return false;
    return true;
  };

  const findDuplicatedCifsInPlan = (beneficiariesInput, society, planId) => {
    const existingCifs = society?.beneficiaries
      ?.filter((beneficiary) => beneficiary.plan === planId)
      .map((beneficiary) => beneficiary?.cif);
    const result = beneficiariesInput
      .filter((beneficiary) => existingCifs.includes(beneficiary?.['NIF']))
      .map((beneficiary) => beneficiary?.['NIF']);
    return result;
  };

  const findDuplicateCifInTemplate = (beneficiariesInput) => {
    const result = [];
    for (let i = 0; i < beneficiariesInput.length - 1; i += 1) {
      for (let j = i + 1; j < beneficiariesInput.length; j += 1) {
        if (beneficiariesInput[i]['NIF'] === beneficiariesInput[j]['NIF']) {
          result.push(beneficiariesInput[i]['NOMBRE Y APELLIDOS']);
        }
      }
    }
    return result;
  };

  const hasMissingKeyFields = (beneficiariesInput) =>
    beneficiariesInput.some(
      (beneficiary) =>
        !beneficiary['NIF'] ||
        !beneficiary['NOMBRE Y APELLIDOS'] ||
        !beneficiary['EMAIL'] ||
        !beneficiary['FECHA DE ADHESION']
    );

  const findInvalidTypes = (beneficiariesInput) => {
    const result = {
      invalidDates: [],
      invalidSalaries: [],
      invalidUnits: [],
    };
    for (let i = 0; i < beneficiariesInput.length; i += 1) {
      const beneficiary = beneficiariesInput[i];
      if (Number.isNaN(excelDateToJSDate(beneficiary['FECHA DE ADHESION'])))
        result.invalidDates.push(beneficiary['NIF']);
      if (beneficiary['UNIDADES'] && !Number.isInteger(beneficiary['UNIDADES']))
        result.invalidUnits.push(beneficiary['NIF']);
      if (beneficiary['SUELDO'] && Number.isNaN(beneficiary['SUELDO']))
        result.invalidSalaries.push(beneficiary['NIF']);
      return result;
    }
  };

  async function handleSelectFile(currentFile) {
    setFile(currentFile);
    const data = await currentFile.arrayBuffer();
    const workbook = read(data);
    const newBeneficiaries = getExcelBeneficiaries(workbook);

    if (!newBeneficiaries?.error) {
      const invalidEmails = getInvalidEmails(newBeneficiaries);

      if (newBeneficiaries.length === 0) {
        setIsValidFile(false);
        setErrorMessage(errors.DATA_NOT_FOUND);
        return null;
      }
      if (invalidEmails?.length > 0) {
        setIsValidFile(false);
        setErrorMessage(`${errors.INVALID_EMAILS} (${invalidEmails.join(
          ' | '
        )}).
            ${t('UpdateInfoAndTryAgain')}`);
        return null;
      }
      if (hasMissingKeyFields(newBeneficiaries)) {
        setIsValidFile(false);
        setErrorMessage(errors.DATA_INCOMPLETE);
        return null;
      }
      const { invalidDates, invalidSalaries, invalidUnits } =
        findInvalidTypes(newBeneficiaries);
      if (invalidDates.length) {
        setIsValidFile(false);
        setErrorMessage(`${errors.INVALID_DATES} (${invalidDates.join(' | ')}).
        ${t('UpdateInfoAndTryAgain')}`);
        return null;
      }
      if (invalidSalaries.length) {
        setIsValidFile(false);
        setErrorMessage(`${errors.INVALID_SALARIES} (${invalidSalaries.join(
          ' | '
        )}).
        ${t('UpdateInfoAndTryAgain')}`);
        return null;
      }
      if (invalidUnits.length) {
        setIsValidFile(false);
        setErrorMessage(`${errors.INVALID_UNITS} (${invalidUnits.join(' | ')}).
        ${t('UpdateInfoAndTryAgain')}`);
        return null;
      }
      setIsValidFile(true);
      setErrorMessage('');
      setBeneficiaries(newBeneficiaries);
    } else {
      setIsValidFile(false);
      setErrorMessage(errors.DATA_WRONG_MODEL);
    }
  }

  const handleOpenBeneficiaryReview = () => {
    setIsReviewOpen(true);
  };

  function parseVestingMonths(months) {
    return vestingOptions.find((option) => option.value === Number(months))
      ?.text;
  }

  function parseCliffMonths(months) {
    return cliffOptions.find((option) => option.value === Number(months))?.text;
  }

  useEffect(() => {
    const selectedPlan = stockPlans.find((p) => p['_id'] === plan);
    if (selectedPlan) {
      if (selectedPlan?.hasInvitation) {
        const generatedCode = uuidv4();
        setInvitationCode(generatedCode);
        setInvitationStatusType(invitationStatus.PENDING.value);
      } else {
        setInvitationStatusType('');
      }
    }
    setCurrentPlan(selectedPlan);
    getAttachedDocument(selectedPlan?.invitationAttachedDocument);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  useEffect(() => {
    if (currentPlan && beneficiaries.length) {
      const { missingCategories, missingUnits, missingSalaries } =
        findInvalidCategoryAndUnitsFields(currentPlan, beneficiaries);
      if (missingCategories.length) {
        setIsValidFile(false);
        setErrorMessage(`${errors.MISSING_CATEGORIES} (${missingCategories.join(
          ' | '
        )}).
          ${t('UpdateInfoAndTryAgain')}`);
      } else if (missingUnits.length) {
        setIsValidFile(false);
        setErrorMessage(`${errors.MISSING_UNITS} (${missingUnits.join(' | ')}).
          ${t('UpdateInfoAndTryAgain')}`);
      } else if (missingSalaries.length) {
        setIsValidFile(false);
        setErrorMessage(`${errors.MISSING_SALARIES} (${missingSalaries.join(
          ' | '
        )}).
          ${t('UpdateInfoAndTryAgain')}`);
      } else if (!checkValidUnits(currentPlan, beneficiaries)) {
        setIsValidFile(false);
        setErrorMessage(t('UnitsValuesExceedAvailables'));
      } else {
        setIsValidFile(true);
        setErrorMessage('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan, beneficiaries]);

  return !isLoading ? (
    <>
      {!isReviewOpen && (
        <>
          <Modal.Header>
            <h5 className="modal-title">{t('UploadBeneficiaryList')}</h5>
            <a
              className="close cursor-pointer"
              onClick={() => dispatch(setModal(null))}
            >
              <em className="icon ni ni-cross" />
            </a>
          </Modal.Header>
          <Modal.Body>
            <form className="form-validate is-alter" onSubmit={uploadList}>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-group">
                    <label className="form-label" htmlFor="beneficiary-plan">
                      {t('StockOptionsPlan')}
                      <span className="sub-text">
                        {t('SelectTheStockOptionsPlanAdd')}:
                      </span>
                    </label>
                    <div className="form-control-wrap ">
                      <div className="form-control-select">
                        <select
                          className="form-control"
                          id="beneficiary-plan"
                          name="beneficiary-plan"
                          value={plan}
                          onChange={(event) => setPlan(event.target.value)}
                        >
                          <option value="default_option">{t('Select')}</option>
                          {isDraft &&
                            stockPlans?.length &&
                            stockPlans
                              .filter(
                                (plan) =>
                                  plan?.isDraft &&
                                  plan.society === actualSociety['_id']
                              )
                              .map((plan) => (
                                <option value={plan['_id']} key={plan['_id']}>
                                  {plan.name}
                                </option>
                              ))}
                          {!isDraft &&
                            stockPlans?.length &&
                            stockPlans
                              .filter(
                                (plan) =>
                                  !plan?.isDraft &&
                                  plan.society === actualSociety['_id']
                              )
                              .map((plan) => (
                                <option value={plan['_id']} key={plan['_id']}>
                                  {plan.name}
                                </option>
                              ))}
                        </select>
                      </div>
                      {currentPlan && (
                        <>
                          <label className="form-label" htmlFor="pay-amount">
                            {t('Conditions')}:
                          </label>
                          <span>
                            {` ${t('Vesting')}: ${parseVestingMonths(
                              currentPlan?.vestingPeriod
                            )}  |  ${t('Cliff')}:
                      ${parseCliffMonths(currentPlan?.cliff)}
                      | ${t('Consolidation')}: ${currentPlan?.consolidation}`}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="form-label"
                      htmlFor="beneficiary-plan-file"
                    >
                      {t('ListOfBeneficiaries')}
                    </label>
                    <div className="form-control-wrap">
                      <div className="custom-file">
                        <input
                          id="beneficiary-plan-file"
                          name="beneficiary-plan-file"
                          type="file"
                          accept=".csv,.xls,.xlsx"
                          className="custom-file-input"
                          onChange={(event) =>
                            handleSelectFile(event.target.files[0])
                          }
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          {file?.name || t('SelectFile')}
                        </label>
                      </div>
                    </div>
                  </div>

                  {file &&
                    (isValidFile ? (
                      <div
                        className="alert alert-icon alert-success"
                        role="alert"
                      >
                        <em className="icon ni ni-check-circle" />
                        <strong>{t('ValidatedFile')}</strong>.{' '}
                        {t('HaveBeenFoundBeneficiarie', {
                          beneficiaries: beneficiaries?.length,
                        })}
                        .&nbsp;
                        <strong
                          className="btn-link cursor-pointer alert-success font-weight-bold"
                          onClick={handleOpenBeneficiaryReview}
                        >
                          {t('Review')}
                        </strong>
                      </div>
                    ) : (
                      <div
                        className="alert alert-icon alert-danger"
                        role="alert"
                      >
                        <em className="icon ni ni-alert-circle" />
                        <strong>{t('Non-ValidFile')}</strong>. {errorMessage}
                      </div>
                    ))}
                </div>
              </div>

              <div className="row">
                <div className="col mb-4">
                  <a
                    href={templateURL}
                    target="_blank"
                    className="btn btn-block btn-white btn-outline-light"
                    rel="noopener noreferrer"
                  >
                    <em className="icon ni ni-file-download" />
                    <span className="fs-16px">
                      {t('DownloadExampleTemplateTo')}
                    </span>
                  </a>
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={!plan || !file || !isValidFile}
              >
                {t('AddBeneficiary')}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <span className="sub-text">{t('TheBeneficiaryWillReceive')}</span>
          </Modal.Footer>
        </>
      )}

      {isReviewOpen && (
        <Modal
          size={sizes.XL}
          show
          onHide={() => setIsReviewOpen(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <BeneficiariesReview
            beneficiaries={beneficiaries}
            onClose={setIsReviewOpen}
          />
        </Modal>
      )}
    </>
  ) : (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {t('UploadProcess')}
          <span className="text-primary ml-3">
            {Object.keys(beneficiariesStatus).length}/{beneficiaries.length}
          </span>
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="card card-bordered card-preview">
          <table className="table table-tranx">
            <thead>
              <tr className="tb-tnx-head">
                <th className="tb-tnx-id">
                  <span className="">#</span>
                </th>
                <th className="tb-tnx-info">
                  <span className="tb-tnx-desc d-none d-sm-inline-block">
                    <span>{t('Beneficiary')}</span>
                  </span>
                </th>
                <th className="tb-tnx-amount">
                  <span className="tb-tnx-total">{t('Added')}</span>
                </th>
                <th className="tb-tnx-amount">
                  <span className="tb-tnx-total">{t('SignSent')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(beneficiariesStatus).map(([row, beneficiary]) => (
                <tr className="tb-tnx-item" key={beneficiary.rowNum}>
                  <td className="tb-tnx-id py-1">
                    <span className="text-primary">{beneficiary?.rowNum}</span>
                  </td>
                  <td className="tb-tnx-info py-1">
                    <div className="">
                      <span className="title">{beneficiary?.name}</span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount py-1">
                    <div className="tb-tnx-total">
                      <span
                        className={`badge b-none ${
                          colorStatus[
                            beneficiary?.addStatus || statusType.PENDING
                          ]
                        }`}
                      >
                        {t(beneficiary?.addStatus || statusType.PENDING)}
                      </span>
                    </div>
                  </td>
                  <td className="tb-tnx-amount py-1">
                    <div className="tb-tnx-status d-inline">
                      <span
                        className={`badge b-none ${
                          colorStatus[
                            beneficiary?.signStatus || statusType.PENDING
                          ]
                        }`}
                      >
                        {t(beneficiary?.signStatus || statusType.PENDING)}
                      </span>
                      {beneficiary?.invitationModel && (
                        <span
                          className="btn btn-icon btn-trigger ml-1"
                          onClick={() =>
                            Swal.fire({
                              title: `<h4 class="nk-modal-title"><u>${beneficiary?.name}</u></h4>`,
                              html: `<div style="text-align: initial !important">${beneficiary?.invitationModel}</div>`,
                              confirmButtonText: 'Ok',
                              confirmButtonColor: '#6576ff',
                              allowOutsideClick: true,
                              showCancelButton: false,
                              width: '80%',
                            })
                          }
                        >
                          <em className="icon ni ni-eye-fill text-primary" />
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-lg btn-primary mt-4 "
            disabled={!finishUploading}
            onClick={() => closeModal(null)}
          >
            {t('Close')}
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">{t('TheBeneficiaryWillReceive')}</span>
      </Modal.Footer>
    </>
  );
}

export default UploadBeneficiaryModal;
