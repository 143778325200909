import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import InstructionsTablePlaceholder from 'modules/_shared/components/Modals/SendVote/components/SendVoteInstructionsTablePlaceholder';
import BoardPartnerDelegationsInstructionsTableRow from './BoardPartnerDelegationsInstructionsTableRow';

type Props = {
  representedPartners: any[];
};

const BoardPartnerDelegarionsInstructionsTable: FC<Props> = ({
  representedPartners,
}: Props) => {
  const { t } = useTranslate();

  return (
    <>
      <h6>{t('VoteInstructions')}</h6>
      <div className="card card-bordered card-preview instructions-table-wrapper">
        <table className="table">
          <thead className="table-light">
            <tr>
              <th className="w-65">{t('Name')}</th>
              <th className="w-25">{t('Instructions')}</th>
            </tr>
          </thead>
          <tbody>
            {representedPartners?.length > 0 ? (
              representedPartners?.map((partner: any, index: number) => (
                <BoardPartnerDelegationsInstructionsTableRow
                  index={index}
                  partner={partner}
                />
              ))
            ) : (
              <InstructionsTablePlaceholder />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BoardPartnerDelegarionsInstructionsTable;
