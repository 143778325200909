import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';

import { decryptResponse } from 'utils/token';
import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export const createHoldingClass =
  (holdingClass, showAlert = true) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/holdingClasses`,
        holdingClass
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.CREATE_HOLDING_CLASS,
        holdingClass: decodedData,
      });
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.HOLDING_CLASS_ADDED));
      }
      return decodedData;
    } catch (error) {
      dispatch(addAlert(alertBodyTypes[500]));
    }
  };

export const getHoldingClasses = (societyId) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/holdingClasses`,
      {
        params: {
          societyId,
        },
      }
    );

    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_HOLDING_CLASSES,
      data: decodedData,
    });
  } catch (error) {
    return dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const updateHoldingClass =
  (holdingClassId, updateHoldingClassData) => async (dispatch) => {
    const body = {
      _id: holdingClassId,
      ...updateHoldingClassData,
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/holdingClasses`,
        body
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_HOLDING_CLASS,
        holdingClass: decodedData,
      });
      dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes[500]));
    }
  };
