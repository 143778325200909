/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { currencyFormatDecimals, numberFormat } from 'constants/formats';
import NumberFormat from 'react-number-format';

import PartnerDividends from 'modules/partners/components/PartnerDividends';
import PartnerSharesRow from 'modules/partners/components/PartnerSharesRow';

import {
  getDecimalScale,
  getPartnerAmount,
  getPartnerFDPercent,
  getPartnerNDPercent,
  groupBy,
} from 'utils/filters';
import { formatNumber, formatNumberDecimalsLong } from 'utils/formats';
import RepresentativeSocietyDetailsList from './components/RepresentativeSocietyDetailsList/RepresentativeSocietyDetailsList';
import RepresentativeSocietyMetrics from './components/RepresentativeSocietyMetrics';
import './RepresentativeSociety.scss';

function RepresentativeSociety({ partner, society, representative }) {
  const { t } = useTranslate();
  const { isAdmin } = useSelector((state) => ({
    documents: state.documents,
    isAdmin: state.society?.role?.isAdmin,
  }));

  const [fullName] = useState(representative?.socialDenomination || '');

  const [rows, setRows] = useState([]);
  const [partnerShares, setPartnerShares] = useState([]);
  const [totalPartnerShares, setTotalPartnerShares] = useState(0);

  const [decimalScale] = useState(
    partner?.societyValue ? getDecimalScale(partner?.societyValue.value) : 2
  );
  const [NDpercent] = useState(
    partner ? getPartnerNDPercent(partner, society).toFixed(decimalScale) : ''
  );
  const [FDpercent] = useState(
    partner ? getPartnerFDPercent(partner, society).toFixed(decimalScale) : ''
  );

  useEffect(() => {
    if (partner && society) {
      const shares = society.shares
        .filter((share) => share.isActive && share.partner === partner['_id'])
        .map((filteredShare) => ({
          nominalValue: society?.nominalValue,
          from: filteredShare.from,
          to: filteredShare.to,
          sharePremium: filteredShare.sharePremium,
          shareClass: filteredShare.shareClass?.name || '-',
        }));
      const groupByClass = groupBy(shares, 'shareClass');
      setPartnerShares(groupByClass.flat());
    }
  }, [partner, society]);

  useEffect(() => {
    if (partnerShares?.length) {
      const shareRows = partnerShares?.map((share, index) => (
        <PartnerSharesRow
          share={share}
          index={index + 1}
          key={`share-${index}`}
        />
      ));
      const total = partnerShares?.reduce(
        (acc, curr) => acc + curr?.to - curr?.from + 1,
        0
      );
      setTotalPartnerShares(total);
      setRows(shareRows);
    }
  }, [partnerShares]);

  return (
    <div className="card-inner">
      <div className="card-inner-group">
        <div className="card-inner">
          <div className="user-card user-card-s2">
            <div className="user-avatar lg bg-primary">
              {society.additional.logo ? (
                <img src={society.additional.logo} alt="Logo" />
              ) : (
                <span>{society.name.slice(0, 2).toUpperCase()}</span>
              )}
            </div>
            <div className="user-info">
              <h5>{fullName}</h5>
              <div className="badge badge-outline badge-pill text-primary ucap">
                {t('Representative')}
              </div>
            </div>
          </div>
        </div>

        {partner ? (
          <>
            <div className="card-inner">
              <h6 className="overline-title-alt mb-2">
                {t('InvestmentOfFullnameInSoc', {
                  fullName,
                  societyName: society.name,
                })}
              </h6>
              <div className="row text-center">
                <div className="col-4">
                  <div className="profile-balance-amount">
                    <div className="number">
                      <NumberFormat
                        value={partner.sharesCount?.actual || 0}
                        displayType="text"
                        {...numberFormat}
                      />
                    </div>
                  </div>
                  <div className="profile-stats">
                    <span className="badge badge-outline-primary">
                      {t('Shares')}
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="profile-balance-amount">
                    <div className="number">
                      {`${
                        NDpercent > 0 ? formatNumberDecimalsLong(+NDpercent) : 0
                      }%`}
                    </div>
                  </div>
                  <div className="profile-stats">
                    <span className="badge badge-outline-primary">
                      {t('Percentage')}
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="profile-balance-amount">
                    <div className="number">
                      <NumberFormat
                        value={getPartnerAmount(partner.shares, society)}
                        displayType="text"
                        {...currencyFormatDecimals}
                      />
                    </div>
                  </div>
                  <div className="profile-stats">
                    <span className="badge badge-outline-primary">
                      {t('Invested')}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="nk-fmg-status">
              <h6 className="nk-fmg-status-title">
                <em className="icon ni ni-meter" />
                <span>
                  {`${t('Percentage')}: ${
                    NDpercent > 0 ? formatNumberDecimalsLong(+NDpercent) : 0
                  }%`}
                </span>
              </h6>
              <div className="progress progress-md bg-light">
                <div
                  className="progress-bar"
                  data-progress={NDpercent || 0}
                  style={{
                    width: `${NDpercent > 0 ? +NDpercent : 0}%`,
                  }}
                />
              </div>
              <div className="nk-fmg-status-info">
                {`${
                  NDpercent > 0 ? formatNumberDecimalsLong(+NDpercent) : 0
                }% ND - ${
                  FDpercent > 0 ? formatNumberDecimalsLong(+FDpercent) : 0
                }% FD`}
              </div>
              {isAdmin && (
                <div className="nk-fmg-status-action">
                  <Link
                    to={`/socios-libro/${society['_id']}`}
                    className="link link-primary link-sm"
                  >
                    {t('SeeBookOfPartners')}
                  </Link>
                </div>
              )}
            </div>

            <div className="card-inner px-0">
              <h6 className="overline-title-alt mb-2">
                {t('ParticipationDetail')}
              </h6>
              <div className="card card-bordered card-preview">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: '20px !important' }}>
                        {t('Nr')}
                      </th>
                      <th scope="col" className="text-right">
                        {t('NrPart.')}
                      </th>
                      <th scope="col" className="text-right">
                        {t('Nominal')}
                      </th>
                      <th scope="col" className="d-none d-xl-block">
                        {t('Class')}
                      </th>

                      <th scope="col" className="text-right">
                        {t('From')}
                      </th>
                      <th scope="col" className="text-right">
                        {t('To')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                  <tfoot>
                    <tr>
                      <td className="fw-bold">{t('Total')}</td>
                      <td className="fw-bold text-right">
                        {formatNumber(totalPartnerShares)}
                      </td>
                      <td colSpan={4} />
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div className="card-inner px-0">
              <PartnerDividends partner={partner} society={society} />
            </div>
          </>
        ) : (
          <>
            <RepresentativeSocietyMetrics representative={representative} />

            <RepresentativeSocietyDetailsList representative={representative} />
          </>
        )}
      </div>
    </div>
  );
}

export default RepresentativeSociety;
