/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { dateFormat, numberFormat } from 'constants/formats';

import DownloadButton from './DownloadButton';
import createPartnersArray from '../utils/createPartnersArray';
import filterValue from './filterValue';
import { columns, transactionNames } from '../constants/partnerBookConstants';

import '../PartnerBook.scss';

function PartnerBookTable({
  view,
  bookDates,
  bookColumns,
  bookHeaders,
  bookComments,
  bookPartners,
  bookOperations,
  bookTotals,
  bookTotalPartners,
  bookOrder,
  bookDocuments,
  text = false,
}) {
  const { t } = useTranslate();

  const [tableTotalWidth, setTableTotalWidth] = useState(0);

  const tableRef = useRef(null);
  const contentRef = useRef(null);
  const topScrollBarRef = useRef(null);
  const bottomScrollBarRef = useRef(null);

  const tableTotalRows = bookPartners?.length + 2;
  const tableTotalHeight = `${tableTotalRows * 30 + 50 + 1}px`;

  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const societyId = useSelector(
    (state) => state.society?.actualSociety?.['_id']
  );

  const syncScroll = (sourceRef, targetRefs) => {
    if (!sourceRef.current) return;

    const sourceScrollLeft = sourceRef.current.scrollLeft;

    const table = document.getElementById('table-overlap');
    if (table) {
      table.style.left = `${+sourceScrollLeft}px`;
    }

    targetRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.scrollLeft = sourceScrollLeft; // eslint-disable-line no-param-reassign
      }
    });
  };

  useEffect(() => {
    const updateWidth = () => {
      if (tableRef.current) {
        setTableTotalWidth(tableRef.current.scrollWidth);
      }
    };

    const observer = new MutationObserver(updateWidth);
    if (tableRef.current) {
      observer.observe(tableRef.current, {
        childList: true, // observe direct children
        subtree: true, // and lower descendants too
        characterData: true, // observe text changes
      });
    }

    updateWidth();

    return () => {
      observer.disconnect();
    };
  }, [bookPartners]);

  useEffect(() => {
    const handleTopScroll = () =>
      syncScroll(topScrollBarRef, [bottomScrollBarRef, contentRef]);
    const handleBottomScroll = () =>
      syncScroll(bottomScrollBarRef, [topScrollBarRef, contentRef]);

    const topScrollBar = topScrollBarRef.current;
    const bottomScrollBar = bottomScrollBarRef.current;

    if (topScrollBar) {
      topScrollBar.addEventListener('scroll', handleTopScroll);
    }
    if (bottomScrollBar) {
      bottomScrollBar.addEventListener('scroll', handleBottomScroll);
    }

    return () => {
      if (topScrollBar) {
        topScrollBar.removeEventListener('scroll', handleTopScroll);
      }
      if (bottomScrollBar) {
        bottomScrollBar.removeEventListener('scroll', handleBottomScroll);
      }
    };
  }, []);

  return (
    <>
      <div className={view === 0 && text ? 'nk-block' : 'd-none'}>
        <div className="partnerBookTableWrapper card card-bordered">
          <div ref={topScrollBarRef} className="scroll-sync">
            <div
              className="top-scroll"
              style={{ width: `${tableTotalWidth}px` }}
            />
          </div>

          <div
            className="content table-responsive"
            ref={contentRef}
            style={{ height: tableTotalHeight }}
          >
            <table
              className="table table-striped cloned pos-abs partner-book-table"
              id="table-overlap"
            >
              <thead>
                <tr className="table-header">
                  <th />
                </tr>
                <tr className="table-subheader">
                  <th>{t('Partners')}</th>
                </tr>
              </thead>

              <tbody>
                {createPartnersArray(bookPartners)?.map((partner, index) => (
                  <tr key={`${partner?.id}-${index}`}>
                    <td
                      id={partner.id}
                      className={
                        !(index % 2 === 0)
                          ? 'bg-light text-left w-200px d-block text-ellipsis'
                          : 'bg-white text-left w-200px d-block text-ellipsis'
                      }
                    >
                      {partner.data[0]}
                      <Tooltip anchorId={partner.id} place="bottom">
                        {partner.data[0]}
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                <tr
                  className={
                    !(bookTotalPartners % 2 === 0)
                      ? 'bg-light font-weight-bold'
                      : 'bg-white font-weight-bold'
                  }
                >
                  <td className="text-left">{t('Total').toUpperCase()}</td>
                </tr>
              </tfoot>
            </table>

            <table
              className="table table-striped partner-book-table"
              id={text ? 'partnerBookTable' : 'partnerBookNumberTable'}
              ref={tableRef}
            >
              <thead>
                <tr className="table-header">
                  <th className="w-200px" />

                  {Object.entries(bookHeaders)?.map(
                    ([, header], index) =>
                      header !== '' && (
                        <th
                          key={`${header}-${index}`}
                          colSpan={
                            bookOperations[index] !== '' &&
                            columns[bookOperations[index]].colSpan
                          }
                          className={
                            bookOrder[index] % 2 === 0
                              ? 'bg-light-vert'
                              : 'bg-white'
                          }
                        >
                          <div
                            style={{ zIndex: 1 }}
                            id={`${index}-${societyId}`}
                          >
                            {bookHeaders[index] !== '' &&
                              transactionNames[bookHeaders[index]]}
                            <br />
                            {bookDates[index] !== '' &&
                              format(new Date(bookDates[index]), dateFormat)}
                          </div>

                          {isAdmin && (
                            <Tooltip
                              anchorId={`${index}-${societyId}`}
                              place="bottom"
                            >
                              {bookComments[index]}
                            </Tooltip>
                          )}

                          <DownloadButton documents={bookDocuments?.[index]} />
                        </th>
                      )
                  )}
                </tr>

                <tr className="text-center table-subheader">
                  {Object.entries(bookColumns)?.map(([, column], index) =>
                    index === 0 ? (
                      <td
                        key={`${column}-${index}`}
                        className="bg-white w-200px"
                      >
                        {t('Partners')}
                      </td>
                    ) : (
                      <td
                        key={`${column}-${index}`}
                        className={
                          bookOrder[index] % 2 === 0
                            ? 'bg-light-vert no-break '
                            : 'bg-white no-break '
                        }
                      >
                        {column !== '' && column}
                      </td>
                    )
                  )}
                </tr>
              </thead>

              <tbody>
                {createPartnersArray(bookPartners)?.map(
                  (partner, partnerIndex) => (
                    <tr
                      key={`${partner?.id}-${partnerIndex}`}
                      className={
                        !(partnerIndex % 2 === 0) ? 'bg-light' : 'bg-white'
                      }
                    >
                      {partner.data?.map((data, index) => (
                        <td
                          key={`${partner?.id}-${partnerIndex}-${index}`}
                          className={
                            bookOrder[index] % 2 === 0
                              ? index === 0
                                ? 'bg-white text-right d-block text-ellipsis'
                                : 'bg-light-vert text-right'
                              : index === 0
                              ? 'text-right d-block text-ellipsis'
                              : 'text-right'
                          }
                        >
                          {filterValue({
                            partnerIndex,
                            index,
                            data: partner.data[index],
                            column: bookColumns[index],
                            text,
                            bookPartners,
                            bookOperations,
                          })}
                        </td>
                      ))}
                    </tr>
                  )
                )}
              </tbody>

              <tfoot>
                <tr
                  className={
                    !(bookTotalPartners % 2 === 0)
                      ? 'bg-light font-weight-bold'
                      : 'font-weight-bold'
                  }
                >
                  {Object.entries(bookTotals)?.map(([, total], index) =>
                    index === 0 ? (
                      <td key={`${total}-${index}`} className="w-200px">
                        TOTAL
                      </td>
                    ) : (
                      <td
                        key={`${total}-${index}`}
                        className={
                          bookOrder[index] % 2 === 0 ? 'bg-light-vert' : ''
                        }
                      >
                        {bookColumns[index] === '%'
                          ? total !== '' &&
                            (text
                              ? `${parseFloat(total)?.toFixed(2)}%`
                              : parseFloat(total?.toFixed(2)))
                          : total !== '' &&
                            (text ? (
                              <NumberFormat
                                displayType="text"
                                value={total}
                                {...numberFormat}
                              />
                            ) : (
                              total
                            ))}
                      </td>
                    )
                  )}
                </tr>
              </tfoot>
            </table>
          </div>

          <div ref={bottomScrollBarRef} className="scroll-sync">
            <div
              className="bottom-scroll"
              style={{ width: `${tableTotalWidth}px` }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerBookTable;
