/* eslint-disable react/jsx-props-no-spreading */

import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Board } from 'types';
import BoardBadges from './BoardBadges';

type Props = {
  societyId: string;
  currentBoard: Board;
  representativeName: string;
  statusStyle: string;
  statusText: string;
  isDelegated: boolean;
  isAbsent: boolean;
  isActive: boolean;
  isAdmin: boolean;
};

const BoardPartnerHeader: FC<Props> = ({
  societyId,
  currentBoard,
  representativeName,
  statusStyle,
  statusText,
  isDelegated,
  isAbsent,
  isActive,
  isAdmin,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <div className="nk-block-head nk-block-head-lg pb-4">
        <div className="nk-block-head-sub">
          <Link
            to={`/juntas/${societyId}`}
            className={`back-to ${!isAdmin && 'is-disable'}`}
          >
            <em className="icon ni ni-arrow-left" />
            <span>{t('BoardsList')}</span>
          </Link>
        </div>

        <div className="nk-block-between-md g-4">
          <div className="nk-block-head-content col-12">
            <h3 className="nk-block-title page-title">{currentBoard.name} </h3>

            <div className="d-flex flex-wrap" style={{ gap: 10 }}>
              <BoardBadges.Status t={t} style={statusStyle} text={statusText} />

              {currentBoard?.date && (
                <BoardBadges.Date date={currentBoard.date} />
              )}

              {currentBoard?.onlineAccess && (
                <BoardBadges.OnlineAccess
                  t={t}
                  url={currentBoard.onlineAccess}
                />
              )}

              {isDelegated && (
                <BoardBadges.Delegation t={t} name={representativeName} />
              )}

              {isAbsent && <BoardBadges.Assistance t={t} />}

              {!isActive && <BoardBadges.Close t={t} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardPartnerHeader;
