/* eslint-disable no-underscore-dangle */
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import i18n from 'i18n/config';

import CKEditor from 'modules/_shared/components/CKEditor';
import EditorWYSIWYG from 'modules/_shared/components/EditorWYSIWYG';
import ReadOnlyEditor from 'modules/_shared/components/ReadOnlyEditor';
import DocumentsGenerator from 'modules/documents/components/Creators/DocumentsGenerator';
import { updatePreview } from 'modules/_shared/redux/previewActions';
import { updateDocument } from 'modules/documents/redux/documentActions';

import errorSwal from 'utils/errorSwal';
import hasShowDetails from 'utils/showDetails';

import menuOptions from './menuOptions';

import './Preview.scss';
import PreviewHeader from './PreviewHeader';

const Preview = ({ hasEdit = false, fullScreen, handleToggleFullScreen }) => {
  const { t } = useTranslate();

  const user = useSelector((state) => state.user);
  const preview = useSelector((state) => state.preview);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  // DEPRECATED States, //TODO: Remove after release
  const [download, setDownload] = useState(false);
  const [showEditor, setShowEditor] = useState(hasEdit);
  const [htmlContent, setHtmlContent] = useState(preview?.documentModel || '');
  const [editorReference, setEditorReference] = useState(null);

  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );
  const [editorInstance, setEditorInstance] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const handleEditDocument = () => {
    if (editorInstance) {
      editorInstance.disableReadOnlyMode('readOnly');
      setIsEditing(true);
    }
  };

  const handleExportPDF = () => {
    if (editorInstance) {
      const config = editorInstance.config.get('exportPdf');
      editorInstance.execute('exportPdf', config);
    }
  };

  const handleExportWord = () => {
    if (editorInstance) {
      const config = editorInstance.config.get('exportWord');
      editorInstance.execute('exportWord', config);
    }
  };

  const handleSaveDocument = async () => {
    try {
      const htmlContent = editorInstance?.getData() || '';

      const document = (
        <DocumentsGenerator
          invitationModel={htmlContent}
          hasConsolidationCalendar={false}
          consolidationCalendar={{}}
        />
      );
      await store.dispatch(
        updatePreview({ document, documentModel: htmlContent })
      );
      await store.dispatch(
        updateDocument({ ...preview.bdDocument, editorModel: htmlContent })
      );
    } catch (error) {
      errorSwal(t('ErrorSavingDocument'));
    } finally {
      editorInstance.enableReadOnlyMode('readOnly');
      setIsEditing(false);
    }
  };

  // DEPRECATED Effect, //TODO: Remove after release
  useEffect(() => {
    if (editorReference && download) {
      editorReference.plugins.export.download('clientpdf', {});
      setDownload(false);
    }
  }, [editorReference, download]);

  // DEPRECATED Effect, //TODO: Remove after release
  useEffect(() => {
    setShowEditor(hasEdit);
  }, [hasEdit]);

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setIsEditing(true);
  }, []);

  return (
    <>
      <div
        className={`nk-fmg-body-content ${
          fullScreen ? 'nk-fmg-body-content__no-padding' : ''
        }`}
      >
        <PreviewHeader
          documentName={preview?.name}
          templateName={preview?.template?.name}
          menuOptions={currentMenuOptions}
          params={{
            handleEditDocument,
            handleExportPDF,
            handleExportWord,
            society: actualSociety,
            user,
            preview,
            setDownload,
          }}
          fullScreen={fullScreen}
          handleSaveDocument={handleSaveDocument}
          handleToggleFullScreen={handleToggleFullScreen}
          isEditing={isEditing}
          isDemo={isDemo}
        />

        {!hasShowDetails() ? (
          // DEPRECATED Template, //TODO: Remove after release
          <div>
            {preview?.documentModel && (
              <div>
                {!showEditor ? (
                  <ReadOnlyEditor editorContent={htmlContent} />
                ) : (
                  <EditorWYSIWYG
                    editorContent={htmlContent}
                    setEditorContent={setHtmlContent}
                  />
                )}
              </div>
            )}
            <div style={{ display: 'none' }}>
              <EditorWYSIWYG
                editorContent={htmlContent}
                setEditorContent={setHtmlContent}
                setEditorReference={setEditorReference}
              />
            </div>
          </div>
        ) : (
          <div className="avoid-default-font">
            <CKEditor
              editorInstance={editorInstance}
              setEditorInstance={setEditorInstance}
              documentName={preview?.name || 'export'}
              documentModel={preview?.documentModel || ''}
              enableMentions={false}
              fullScreen={fullScreen}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Preview;
