/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/rules-of-hooks */
import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import { ContentState, EditorState } from 'draft-js';
import { useTranslate } from 'hooks/useTranslate';
import htmlToDraft from 'html-to-draftjs';
import html2pdf from 'html3pdf';
import i18n from 'i18n/config';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import PreviewDropdown from 'modules/_shared/components/Dropdowns/PreviewDropdown';
import EditorWYSIWYG from 'modules/_shared/components/EditorWYSIWYG';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { updatePreview } from 'modules/_shared/redux/previewActions';
import { updateBoard } from 'modules/boards/redux/boardActions';
import DocumentsGenerator from 'modules/documents/components/Creators/DocumentsGenerator';
import {
  addDocument,
  getDocumentsByReference,
  updateDocument,
} from 'modules/documents/redux/documentActions';
import { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';
import menuOptions from './menuOptions';

import './Preview.scss';

const BoardAnnouncementPreview = ({ board }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const printRef = useRef();

  const { user, preview, actualSociety } = useSelector((state) => ({
    user: state.user,
    preview: state.preview,
    actualSociety: state.society?.actualSociety,
  }));
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const [showEditor, setShowEditor] = useState(false);
  const [editorState, setEditorState] = useState();
  const [htmlContent, setHtmlContent] = useState(preview?.documentModel || '');

  const handleCloseEditor = async () => {
    try {
      const document = (
        <DocumentsGenerator
          invitationModel={htmlContent}
          hasConsolidationCalendar={false}
          consolidationCalendar={{}}
        />
      );
      await store.dispatch(
        updatePreview({ document, documentModel: htmlContent })
      );
      await store.dispatch(
        updateDocument(
          { ...preview.bdDocument, editorModel: htmlContent },
          false
        )
      );
      // delete old announcement document and generate the new one
      if (board.announcementDocument) {
        await store.dispatch(
          updateDocument(
            {
              _id: board.announcementDocument,
              category: documentTypes.DELETED,
            },
            false
          )
        );
      }
      const options = {
        filename: `${t('AnnouncementBoard')}.pdf`,
        margin: [10, 10, 10, 10], // top, right, bottom, left
        pagebreak: { mode: 'avoid-all' },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
        },
      };
      const fileBlob = await html2pdf()
        .from(htmlContent)
        .set(options)
        .output('blob');

      const announcementFile = new File(
        [fileBlob],
        `${t('AnnouncementBoard')}.pdf`,
        {
          type: 'application/pdf',
        }
      );
      const newAnnouncementDocument = {
        name: `${t('AnnouncementBoard')}.pdf`,
        file: announcementFile,
        fileType: fileTypes.PDF,
        size: announcementFile.size,
        date: new Date(),
        lastAccess: new Date(),
        category: documentTypes.DOCUMENTS,
        author: user['_id'],
        society: actualSociety['_id'],
        isGenerated: true,
        description: `Generado por la plantilla ${t('AnnouncementTemplate')}_${
          preview?.name
        }`,
      };
      const announcementDocument = await store.dispatch(
        addDocument(newAnnouncementDocument)
      );
      if (announcementDocument) {
        dispatch(
          updateBoard(board['_id'], {
            announcementDocument: announcementDocument?.['_id'],
          })
        );
        dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
        dispatch(addAlert(alertBodyTypes.DOCUMENT_GENERATED));
      }
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_GENERATING_DOCUMENT));
    } finally {
      setShowEditor(false);
    }
  };

  const printOption = {
    id: 'print',
    icon: 'ni-printer',
    text: i18n.t('Print'),
    print: true,
    content: printRef.current,
    documentTitle: preview?.name,
    disabled: false,
  };

  useEffect(() => {
    if (showEditor && preview?.documentModel) {
      const contentToEditor = htmlToDraft(preview?.documentModel);
      const contentState = ContentState.createFromBlockArray(
        contentToEditor.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditor]);

  useEffect(() => {
    if (preview) {
      setHtmlContent(preview?.documentModel);
    }
  }, [preview]);

  return (
    <>
      {showEditor ? (
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between position-relative">
            <div className="nk-block-head-content" style={{ width: '940px' }}>
              <div className="float-left">
                <h5 className="title nk-block-title">{t('Announcement')}</h5>
                <div className="nk-block-des">
                  <p>
                    {t('UsedTemplate')}:{' '}
                    <span className="text-primary">{preview?.name}</span>
                  </p>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary d-flex float-right"
                onClick={handleCloseEditor}
              >
                <em className="icon ni ni-check-round-cut mr-2" />
                {t('Save')}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between position-relative">
            <div className="nk-block-head-content">
              <h5 className="title nk-block-title">{t('Announcement')}</h5>
              <div className="nk-block-des">
                <p>
                  {t('UsedTemplate')}:{' '}
                  <span className="text-primary">{preview?.name}</span>
                </p>
              </div>
            </div>
            {!isDemo && (
              <div className="nk-block-head-content">
                <ul className="nk-block-tools g-3">
                  <li>
                    <PreviewDropdown
                      menuOptions={[printOption, ...currentMenuOptions]}
                      params={{
                        society: actualSociety,
                        user,
                        preview,
                        setShowEditor,
                        board,
                      }}
                    />
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      {preview?.documentModel && !showEditor && (
        <div className="card card-bordered w-100" id="preview--box">
          <div className="card-inner card-inner-xl" ref={printRef}>
            {ReactHtmlParser(preview?.documentModel)}
          </div>
        </div>
      )}
      {preview?.documentModel && showEditor && (
        <EditorWYSIWYG
          editorContent={htmlContent}
          setEditorContent={setHtmlContent}
        />
      )}
    </>
  );
};

export default BoardAnnouncementPreview;
