import { useDispatch, useSelector } from 'react-redux';
import { setModal } from 'modules/_shared/redux/modalActions';
import i18n from 'i18n/config';

import 'assets/styles/EmailTemplate.scss';

import { createAnnouncementEmailData } from 'modules/_shared/utils';
import useGetAnnouncementData from './hooks/useGetAnnouncementData';
import useGetAnnouncementCommunication from './hooks/useGetAnnouncementCommunication';
import AnnouncementTemplateViewerHeader from './components/AnnouncementTemplateViewerHeader';
import AnnouncementTemplateViewerBody from './components/AnnouncementTemplateViewerBody';

import SendTestEmail from '../SendTestEmail';

import '../Modals.scss';

const AnnouncementTemplateViewer = ({ board }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user);

  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  const { announcementData } = useGetAnnouncementData(board, actualSociety);

  const { announcementCommunication } = useGetAnnouncementCommunication(
    i18n,
    announcementData,
    actualSociety
  );

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleSendTest = () => {
    const emailData = createAnnouncementEmailData({
      announcementData,
      userId: user?._id,
      societyId: actualSociety?._id,
    });

    dispatch(setModal(<SendTestEmail emailData={emailData} />));
  };

  return (
    <>
      <AnnouncementTemplateViewerHeader
        boardName={board?.name || ''}
        handleCloseModal={handleCloseModal}
      />
      <AnnouncementTemplateViewerBody
        announcementCommunication={announcementCommunication}
        handleSendTest={handleSendTest}
      />
    </>
  );
};

export default AnnouncementTemplateViewer;
