/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { store } from 'redux/store';
import Swal from 'sweetalert2';

import { setMenu } from 'modules/_shared/redux/menuActions';
import {
  getBoard,
  getBoards,
  updateBoard,
} from 'modules/boards/redux/boardActions';
import { getDocumentsByReference } from 'modules/documents/redux/documentActions';

import {
  getActualSociety,
  getPartnerActualShares,
  getPartnerById,
} from 'utils/filters';
import { generateVotationToast } from 'utils/toasts';

import boardStatus from 'constants/boardStatus';
import menuTypes from 'constants/menuTypes';
import voteValues from 'constants/voteValues';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';
import { setModal } from 'modules/_shared/redux/modalActions';
import BoardAct from 'modules/boards/components/BoardAct';
import BoardAnnouncement from 'modules/boards/components/BoardAnnouncement';
import BoardAttendance from 'modules/boards/components/BoardAttendance';
import BoardDocuments from 'modules/boards/components/BoardDocuments';
import BoardParticipants from 'modules/boards/components/BoardParticipants';

import { useTranslate } from 'hooks/useTranslate';
import { validateDeliveredAnnouncement } from 'modules/_shared/api/validationActions';
import { getPartnerRepresentants } from 'modules/partners/redux/partnerRepresentantsActions';
import SendAnnouncementModal from './SendAnnouncementModal';
import SendReminderModal from './SendReminderModal';
import actionOptions from './actionOptions';
import BoardDetailsSignatureView from './components/BoardDetailsSignatureView';

const showDetails = JSON.parse(localStorage.getItem('showDetails'));

const BoardDetails = () => {
  const { t, i18n } = useTranslate();
  const { societyId, boardId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const boards = useSelector((state) => state.boards);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const documents = useSelector((state) => state.documents);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [currentBoard, setCurrentBoard] = useState();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [announcementTemplateDoc, setAnnouncementTemplateDoc] = useState();
  const [partnersOnline, setPartnersOnline] = useState([]);
  const [currentActionOptions, setCurrentActionOptions] = useState(
    actionOptions(i18n)
  );

  const isSendButtonEnabled = () => {
    if (!currentBoard) return false;

    if (currentBoard.status === boardStatus.CLOSED.value) return false;
    if (currentBoard.status === boardStatus.CANCELED.value) return false;
    if (currentBoard.status === boardStatus.CELEBRATED.value) return false;

    return true;
  };

  const isEditButtonEnabled = () => {
    if (!currentBoard) return false;

    if (currentBoard.status === boardStatus.CLOSED.value) return false;
    if (currentBoard.status === boardStatus.CANCELED.value) return false;
    if (currentBoard.status === boardStatus.CELEBRATED.value) return false;

    return true;
  };

  const isCloseButtonEnabled = () => {
    if (!currentBoard) return false;

    if (currentBoard.status === boardStatus.CLOSED.value) return false;
    if (currentBoard.status === boardStatus.CANCELED.value) return false;

    return true;
  };

  const isParticipantAllowedForReminder = (participant) => {
    const participantShares = getPartnerActualShares(
      participant.member,
      actualSociety
    );

    if (participantShares === 0) return false;

    return (
      (participant?.isRepresented && !participant.hasDelegated) ||
      (!participant?.assists &&
        !participant?.isAbsent &&
        !participant?.hasDelegated)
    );
  };

  const handleCloseBoard = async () => {
    if (currentBoard && actualSociety) {
      Swal.fire({
        html: `<div class="modal-body modal-body-lg text-center">
                <div class="nk-modal">
                  <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-na bg-danger"></em>
                  <h4 class="nk-modal-title">¿Cerrar Junta?</h4>
                  <div class="nk-modal-text">
                    <p class="lead">
                      Al cerrar esta junta los participantes no podrán efectuar ni modificar votos.
                    </p>
                    <p class="text-soft fs-14px">
                      Esta acción no se puede deshacer.
                    </p>
                  </div>
                </div>
              </div>`,
        confirmButtonText: `${t('Yes')}, ${t('Close')}`,
        confirmButtonColor: '#e85347',
        showCloseButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            dispatch(
              updateBoard(currentBoard._id, {
                isActive: false,
                status: boardStatus.CLOSED.value,
              })
            );
            const participantsToUpdate = currentBoard?.participants?.filter(
              (participant) => !participant?.assists && !participant?.isAbsent
            );
            // eslint-disable-next-line no-restricted-syntax
            for (const currentParticipant of participantsToUpdate) {
              // eslint-disable-next-line no-await-in-loop
              await store.dispatch(
                updateBoard(
                  currentBoard._id,
                  {
                    participantData: {
                      ...currentParticipant,
                      assists: false,
                      isAbsent: true,
                    },
                  },
                  false
                )
              );
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
  };

  const handleSendAnnouncement = async () => {
    if (currentBoard && actualSociety) {
      dispatch(
        setModal(
          <SendAnnouncementModal
            currentBoard={currentBoard}
            actualSociety={actualSociety}
            announcementTemplateDoc={announcementTemplateDoc}
          />
        )
      );
    }
  };

  const handleSendReminder = async () => {
    if (currentBoard && actualSociety) {
      dispatch(
        setModal(
          <SendReminderModal
            currentBoard={currentBoard}
            actualSociety={actualSociety}
          />
        )
      );
    }
  };

  const setCurrentMenu = () => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
  };

  const getCurrentValues = async () => {
    dispatch(getBoards(societyId));
  };

  const validateRealTimeVotes = async (data) => {
    try {
      const { boards } = store.getState();
      const currentBoard = boards.find((board) => board['_id'] === boardId);

      if (data?.documentKey?._id !== boardId) return;

      const { updatedFields } = data.updateDescription;

      if (!updatedFields) return;

      const keyString = Object.keys(updatedFields)[0];
      const voteKeys = ['participants', 'votes', 'vote'];

      const hasParticipants = keyString.includes(voteKeys[0]);

      if (!hasParticipants) return;

      const participantIndex = Number(keyString.split('.')[1]);
      const participantData = Object.values(
        data.updateDescription.updatedFields
      )[0];
      const participantVotes = participantData.votes;
      const participantPicture = getPartnerById(
        actualSociety,
        participantData.member
      )?.image;

      const previousVotes = currentBoard?.participants[participantIndex]?.votes;

      if (!previousVotes) return;

      const hasNewVote = participantVotes.length > previousVotes.length;
      const newVote = participantVotes[participantVotes.length - 1];

      if (hasNewVote || !hasNewVote) {
        const orderId = newVote.order;
        const orderIndex =
          currentBoard?.orders?.findIndex((order) => order['_id'] === orderId) +
          1;

        const participantName = participantData.name;
        const participantVote = voteValues[newVote.vote];

        const alertData = {
          order: orderIndex,
          name: participantName,
          vote: participantVote,
          picture: participantPicture,
        };

        dispatch(getBoard(boardId));

        return Swal.fire({
          toast: true,
          position: 'top-end',
          timer: 10000,
          timerProgressBar: true,
          showConfirmButton: false,
          html: generateVotationToast(alertData),
          customClass: {
            header: 'd-none !important',
            htmlContainer: 'py-0 my-0 !important',
            content: 'mx-0 !important',
          },
        });
      }
    } catch (error) {
      console.log('Error receiving vote', error);
    }
  };

  const validateRealTimeParticipants = async (data) => {
    try {
      if (!data?.clientsOrigins?.length) return;

      const currentBoardKeys = ['voto-junta', societyId, boardId];

      const routes = data.clientsOrigins;
      const routesRelated = routes.filter(
        (route) =>
          route && currentBoardKeys.every((key) => route?.includes(key))
      );

      const participants = routesRelated.map((route) => route.split('/').pop());
      const uniqueParticipants = [...new Set(participants)];
      const hasNewParticipant =
        uniqueParticipants?.length > partnersOnline?.length;

      setPartnersOnline(uniqueParticipants);

      if (hasNewParticipant) {
        return Swal.fire({
          toast: true,
          icon: 'success',
          position: 'top-end',
          timer: 5000,
          timerProgressBar: true,
          showConfirmButton: false,
          title: 'Nuevo socio conectado!',
        });
      }
    } catch (error) {
      console.log('Error receiving participant connected', error);
    }
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
      dispatch(getPartnerRepresentants({ societyId: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (currentBoard && documents) {
      setAnnouncementTemplateDoc(
        documents.find(
          (doc) => doc['_id'] === currentBoard?.announcementTemplate
        )
      );
    }
  }, [documents, currentBoard]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentMenu();
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (boards.length) {
      const actualBoard = boards.find((board) => board['_id'] === boardId);
      setCurrentBoard(actualBoard);
    }
  }, [boards, boardId]);

  useEffect(() => {
    if (currentBoard) {
      setPages([
        <BoardAnnouncement key="board-announcement" board={currentBoard} />,
        <BoardAttendance key="board-attendance" board={currentBoard} />,
        <BoardParticipants key="board-participants" board={currentBoard} />,
        <BoardAct key="board-minutes" board={currentBoard} />,
        <BoardDetailsSignatureView key="signature-view" board={currentBoard} />,
        <BoardDocuments key="board-documents" board={currentBoard} />,
      ]);

      if (currentBoard?.participants?.length > 0) {
        currentBoard.participants.forEach((participant) => {
          isParticipantAllowedForReminder(participant);
        });
      }
    }
  }, [currentBoard]);

  useEffect(() => {
    setCurrentActionOptions(actionOptions(i18n));
  }, [i18n.language]);

  useEffect(() => {
    console.log('Connecting to WebSocket server');
    let ws = new WebSocket(process.env.REACT_APP_API_WSS);

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    ws.onmessage = (msg) => {
      let data;
      try {
        data = JSON.parse(msg.data);
        validateRealTimeVotes(data);
        validateRealTimeParticipants(data);
      } catch (error) {
        console.error('Invalid JSON:', error);
      }
    };

    ws.onclose = () => {
      console.log('disconnected');
      // automatically try to reconnect on connection loss
      ws = new WebSocket(process.env.REACT_APP_API_WSS);
    };

    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (currentBoard && actualSociety && announcementTemplateDoc) {
      dispatch(
        validateDeliveredAnnouncement(
          actualSociety,
          currentBoard,
          announcementTemplateDoc
        )
      );
    }
  }, [currentBoard, announcementTemplateDoc]);

  return currentBoard ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head pb-0">
          <div className="nk-block-between-md g-4">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {currentBoard.name}{' '}
              </h3>
              <span
                className={`badge badge-pill ${
                  boardStatus?.[currentBoard.status]?.style
                }`}
              >
                {boardStatus?.[currentBoard.status]?.text || ''}
              </span>
              {showDetails && (
                <>
                  <span
                    className="badge badge-pill badge-light ml-2 cursor-help"
                    id="board-partners-online"
                  >
                    {t('PartnersOnline')}: {partnersOnline?.length || 0}
                  </span>

                  {partnersOnline?.length > 0 && (
                    <Tooltip
                      anchorId="board-partners-online"
                      place="bottom"
                      style={{
                        zIndex: 9999, // Adjust the value as needed
                      }}
                    >
                      {partnersOnline?.map((partner) => (
                        <div className="text-white my-2">
                          <CellData.User
                            key={partner}
                            partner={getPartnerById(actualSociety, partner)}
                            societyId={actualSociety['_id']}
                            hasLink={false}
                            size="sm"
                            color="white"
                          />
                        </div>
                      ))}
                    </Tooltip>
                  )}
                </>
              )}
            </div>
            <div className="nk-block-head-content">
              <ul className="nk-block-tools justify-content-md-end g-3 flex-wrap">
                <li className="preview-item">
                  <OperationMenuDots
                    title={t('Actions')}
                    menuOptions={currentActionOptions}
                    params={{
                      currentBoard,
                      isEditButtonEnabled,
                      handleCloseBoard,
                      isCloseButtonEnabled,
                      handleSendAnnouncement,
                      isSendButtonEnabled,
                      handleSendReminder,
                      isDemo,
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="nk-block">
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <p
                className={`nav-link ${page === 0 ? 'active' : ''}`}
                onClick={() => setPage(0)}
              >
                <span>{t('Announcement')}</span>
              </p>
            </li>
            <li className="nav-item">
              <p
                className={`nav-link ${page === 1 ? 'active' : ''}`}
                onClick={() => setPage(1)}
              >
                <span>{t('Attendance')}</span>
              </p>
            </li>
            <li className="nav-item">
              <p
                className={`nav-link ${page === 2 ? 'active' : ''}`}
                onClick={() => setPage(2)}
              >
                <span>{t('Votes')}</span>
              </p>
            </li>
            <li className="nav-item">
              <p
                className={`nav-link ${page === 3 ? 'active' : ''}`}
                onClick={() => setPage(3)}
              >
                <span>{t('Act')}</span>
              </p>
            </li>
            <li className="nav-item">
              <p
                className={`nav-link ${page === 4 ? 'active' : ''}`}
                onClick={() => setPage(4)}
              >
                <span>{t('Signatures')}</span>
              </p>
            </li>
            <li className="nav-item">
              <p
                className={`nav-link ${page === 5 ? 'active' : ''}`}
                onClick={() => setPage(5)}
              >
                <span>{t('Documents')}</span>
              </p>
            </li>
          </ul>
          {pages[page]}
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default BoardDetails;
