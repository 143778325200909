/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import consolidationOptions from 'constants/consolidationOptions';
import { numberFormat } from 'constants/formats';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import sizes from 'constants/sizes';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import PlanInvitation from 'modules/_shared/components/Modals/PlanInvitation';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPlanPool, getUsedSharesFromPlan } from 'utils/filters';
import MenuDots from '../../../_shared/components/MenuDots';
import menuOptions from './menuOptions';

function BeneficiaryPlanRow({ index, plan, society, tenderOffers }) {
  const { t } = useTranslate();
  const user = useSelector((state) => state?.user);
  const [currentMenuOptions, setCurrentMenuOptions] = useState(menuOptions);

  const assignedShares = getUsedSharesFromPlan(plan, society, tenderOffers);

  const {
    cliff,
    name,
    pool,
    sharePrice,
    isFixedPrice,
    isDraft = false,
    startDate,
    vestingPeriod,
    consolidation,
    sharesTotal,
    planType,
    hasInvitation,
    invitationModel,
  } = plan;

  useEffect(() => {
    if (hasInvitation && invitationModel) {
      setCurrentMenuOptions([
        ...menuOptions,
        {
          icon: 'ni-edit',
          text: t('EditInvitationLetter'),
          modal: (
            <PlanInvitation
              plan={plan}
              society={society}
              size={sizes.XL}
              action="EDIT"
              invitationModel={invitationModel}
            />
          ),
        },
      ]);
    }
  }, [hasInvitation, invitationModel, plan, society]);

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col tb-col-xl">
        <span>{index}</span>
      </td>

      <td className="nk-tb-col">
        {isDraft ? (
          <Link to={`/beneficiarios-borradores/${society?._id}/${plan?._id}`}>
            <span className="tb-lead">{name}</span>
            <span className="badge rounded-pill mt-1 bg-info text-white">
              {t('Draft')}
            </span>
          </Link>
        ) : (
          <Link to={`/beneficiarios/${society?._id}/${plan?._id}`}>
            <span className="tb-lead">{name}</span>
          </Link>
        )}
      </td>

      <td className="nk-tb-col">
        <span className="d-block d-md-none">
          {format(new Date(startDate), 'dd/MM/yy')}
        </span>
        <span className="d-none d-md-block">
          {format(new Date(startDate), 'dd/MM/yyyy')}
        </span>
      </td>

      <td className="nk-tb-col tb-col-md text-right">
        <div className="d-flex flex-sm-row align-center flex-column justify-content-end">
          <span>{sharePrice}&nbsp;€&nbsp;</span>
          {isFixedPrice ? (
            <span className="badge badge-sm badge-dim rounded-pill badge-info p-1">
              {t('Permanent')}
            </span>
          ) : (
            <></>
          )}
        </div>
      </td>

      <td className="nk-tb-col tb-col-xl text-right">
        <span>{cliff}</span>
      </td>

      <td className="nk-tb-col tb-col-xl text-right">
        <span>{vestingPeriod}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>
          {consolidationOptions.find((c) => c.value === consolidation)?.text ||
            ''}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl text-right">
        <span>{getPlanPool(plan, society)}%</span>
      </td>

      <td className="nk-tb-col">
        <span>{operationTypesRealNames[planType]}</span>
      </td>

      <td className="nk-tb-col tb-col-xl text-right">
        <span>
          <NumberFormat
            value={assignedShares}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
          /
          <NumberFormat
            value={sharesTotal}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        </span>
      </td>

      <td className="nk-tb-col nk-tb-col-tools">
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              <MenuDots
                menuOptions={currentMenuOptions}
                id={plan['_id']}
                params={{
                  planName: plan?.name,
                  user,
                  societyId: society['_id'],
                }}
                direction="left"
              />
            </div>
          </li>
        </ul>
      </td>
    </tr>
  );
}

export default BeneficiaryPlanRow;
