/* eslint-disable react/jsx-indent */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import downloadDocument from 'utils/downloadDocument';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getDocumentsByReference } from 'modules/documents/redux/documentActions';

import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';

import DocumentModal from 'modules/documents/pages/Documents/documentModal';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import MenuDots from 'modules/_shared/components/MenuDots';

import handleGenerateCertificatePDF from 'utils/handleGenerateCertificatePDF';
import handleGeneratePdfFromHtml from 'utils/handleGeneratePdfFromHtml';

import defaultIcon from 'assets/images/file-types/default.svg';
import docIcon from 'assets/images/file-types/doc.svg';
import pdfIcon from 'assets/images/file-types/pdf.svg';
import pptIcon from 'assets/images/file-types/ppt.svg';
import templateIcon from 'assets/images/file-types/templates.svg';
import xlsIcon from 'assets/images/file-types/xls.svg';

import menuOptions from './menuOptions';

const BoardDocuments = ({ board, partnerId = false }) => {
  const dispatch = useDispatch();
  const { user, isAdmin, actualSociety, societyDocuments, communications } =
    useSelector((state) => ({
      user: state?.user,
      isAdmin: state?.society?.role?.isAdmin,
      actualSociety: state.society?.actualSociety,
      societyDocuments: state.documents,
      communications: state.society?.actualSociety?.communications,
    }));

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const { t } = useTranslate();
  const {
    announcementComm,
    announcementDocument,
    attachedDocuments,
    delegationDocuments,
    privateDocuments,
  } = board;

  const [boardDocs, setBoardDocs] = useState([]);
  const [allDelegationDocs, setAllDelegationDocs] = useState([]);
  const [allPrivateDocs, setAllPrivateDocs] = useState([]);
  const [partnerDelegationDocs, setPartnerDelegationDocs] = useState([]);
  const [commData, setCommData] = useState();
  const [announcementDoc, setAnnouncementDoc] = useState();
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const [isLoading, setIsLoading] = useState(false);

  const generateCertificatePDF = async () => {
    setIsLoading(true);
    try {
      await handleGenerateCertificatePDF(
        commData,
        `${t('CommunicationCall')}_${board?.name}.pdf`
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error); // eslint-disable-line
      setIsLoading(false);
    }
  };
  const getDocumentIcon = (document) => {
    const { fileType } = document;

    switch (fileType) {
      case fileTypes.PDF:
        return pdfIcon;

      case fileTypes.DOC:
      case fileTypes.DOCX:
        return docIcon;

      case fileTypes.XLS:
      case fileTypes.XLSX:
        return xlsIcon;

      case fileTypes.PPT:
      case fileTypes.PPTX:
        return pptIcon;

      case fileTypes.TEMPLATE:
        return templateIcon;

      default:
        return defaultIcon;
    }
  };

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const docs = societyDocuments.filter(
      (doc) =>
        doc?.category !== documentTypes.DELETED &&
        attachedDocuments?.includes(doc['_id'])
    );
    const delegationDocs = societyDocuments.filter(
      (doc) =>
        doc?.category !== documentTypes.DELETED &&
        delegationDocuments?.includes(doc['_id'])
    );
    const privateDocs = societyDocuments.filter(
      (doc) =>
        doc?.category !== documentTypes.DELETED &&
        privateDocuments?.includes(doc['_id'])
    );
    setBoardDocs(docs);
    setAllDelegationDocs(delegationDocs);
    setAllPrivateDocs(privateDocs);
    // filter partner delegation documents
    if (partnerId) {
      const partner = board?.participants?.find((p) => p.member === partnerId);
      const partnerDocs = partner?.delegationDocuments || [];
      setPartnerDelegationDocs(
        delegationDocs.filter((doc) => partnerDocs.includes(doc['_id']))
      );
    }
    setAnnouncementDoc(
      societyDocuments.find((doc) => doc['_id'] === announcementDocument)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyDocuments, board]);

  useEffect(() => {
    if (actualSociety?.communications?.length && announcementComm) {
      const comm = communications.find((c) => c['_id'] === announcementComm);
      setCommData(comm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return (
    <>
      <div className="nk-content-body pt-4">
        <div className="nk-content-wrap">
          <div className="nk-block-head">
            <div className="nk-block-between justify-content-center justify-content-md-end">
              {isAdmin && (
                <div className="nk-block-head-content">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      dispatch(
                        setModal(
                          <DocumentModal
                            userId={user['_id']}
                            societyId={actualSociety['_id']}
                            boardId={board['_id']}
                          />
                        )
                      )
                    }
                    disabled={isDemo}
                  >
                    <em className="icon ni ni-upload mr-1" />
                    {t('UpDocument')}
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* section for partners only */}
          {partnerId &&
          (boardDocs?.length ||
            partnerDelegationDocs?.length ||
            announcementDocument) ? (
            <div className="nk-block mb-5">
              <div className="nk-files nk-files-view-list">
                <div className="nk-files-head">
                  <div className="nk-file-item">
                    <div className="nk-file-info">
                      <span>{t('Name')}</span>
                    </div>
                    <div className="nk-file-meta">
                      <span>{t('Date')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Author')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Actions')}</span>
                    </div>
                    <div className="nk-file-members" />
                  </div>
                </div>
                <div className="nk-files-list">
                  {announcementDoc ? (
                    <div className="nk-file-item nk-file">
                      <div className="nk-file-info">
                        <div className="nk-file-title">
                          <div className="nk-file-icon">
                            <span className="nk-file-icon-type">
                              <img
                                src={getDocumentIcon(announcementDoc)}
                                alt="icon-file"
                              />
                            </span>
                          </div>
                          <div className="nk-file-name">
                            <div className="nk-file-name-text">
                              <span className="title">
                                {announcementDoc?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nk-file-meta">
                        <div className="tb-lead">
                          {announcementDoc?.date
                            ? format(
                                new Date(announcementDoc?.date),
                                'dd/MM/yy'
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="nk-file-members">
                        {actualSociety.name}
                      </div>
                      <div className="nk-file-members">
                        <span
                          className="btn btn-icon btn-trigger"
                          onClick={async () =>
                            downloadDocument({
                              documentId: announcementDoc?.['_id'],
                              documentName: announcementDoc?.name,
                            })
                          }
                        >
                          <em className="icon ni ni-file-download" />
                        </span>
                      </div>
                      {!isDemo && (
                        <div className="nk-file-members">
                          <MenuDots
                            menuOptions={currentMenuOptions.map(
                              (option, index) =>
                                index === 1 || index === 2
                                  ? { ...option, disabled: true }
                                  : option
                            )}
                            id={announcementDoc?.['_id']}
                            params={{
                              documentId: announcementDoc?.['_id'],
                              documentName: announcementDoc?.name,
                              document: announcementDoc,
                              society: actualSociety,
                              user,
                              isAdmin,
                            }}
                            direction="left"
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {partnerDelegationDocs.map((doc) => (
                    <div className="nk-file-item nk-file">
                      <div className="nk-file-info">
                        <div className="nk-file-title">
                          <div className="nk-file-icon">
                            <span className="nk-file-icon-type">
                              <img src={getDocumentIcon(doc)} alt="icon-file" />
                            </span>
                          </div>
                          <div className="nk-file-name">
                            <div className="nk-file-name-text">
                              <span className="title">{doc?.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nk-file-meta">
                        <div className="tb-lead">
                          {doc?.date
                            ? format(new Date(doc?.date), 'dd/MM/yy')
                            : '-'}
                        </div>
                      </div>
                      <div className="nk-file-members">
                        {actualSociety.name}
                      </div>
                      <div className="nk-file-members">
                        <span
                          className="btn btn-icon btn-trigger"
                          onClick={async () =>
                            downloadDocument({
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                            })
                          }
                        >
                          <em className="icon ni ni-file-download" />
                        </span>
                      </div>
                      {!isDemo && (
                        <div className="nk-file-members">
                          <MenuDots
                            menuOptions={currentMenuOptions.map(
                              (option, index) =>
                                index === 1 || index === 2
                                  ? { ...option, disabled: true }
                                  : option
                            )}
                            id={doc?.['_id']}
                            params={{
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                              document: doc,
                              society: actualSociety,
                              user,
                              isAdmin,
                            }}
                            direction="left"
                          />
                        </div>
                      )}
                    </div>
                  ))}

                  {boardDocs.map((doc) => (
                    <div className="nk-file-item nk-file">
                      <div className="nk-file-info">
                        <div className="nk-file-title">
                          <div className="nk-file-icon">
                            <span className="nk-file-icon-type">
                              <img src={getDocumentIcon(doc)} alt="icon-file" />
                            </span>
                          </div>
                          <div className="nk-file-name">
                            <div className="nk-file-name-text">
                              <span className="title">{doc?.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nk-file-meta">
                        <div className="tb-lead">
                          {doc?.date
                            ? format(new Date(doc?.date), 'dd/MM/yy')
                            : '-'}
                        </div>
                      </div>
                      <div className="nk-file-members">
                        {actualSociety.name}
                      </div>
                      <div className="nk-file-members">
                        <span
                          className="btn btn-icon btn-trigger"
                          onClick={async () =>
                            downloadDocument({
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                            })
                          }
                        >
                          <em className="icon ni ni-file-download" />
                        </span>
                      </div>
                      {!isDemo && (
                        <div className="nk-file-members">
                          <MenuDots
                            menuOptions={currentMenuOptions.map(
                              (option, index) =>
                                index === 1 || index === 2
                                  ? { ...option, disabled: true }
                                  : option
                            )}
                            id={doc?.['_id']}
                            params={{
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                              document: doc,
                              society: actualSociety,
                              user,
                              isAdmin,
                            }}
                            direction="left"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* sections for admin only */}
          {!partnerId && (announcementDocument || announcementComm) ? (
            <div className="nk-block mb-5">
              <h6 className="title">{t('AnnouncementAndMinutes')}</h6>
              <div className="nk-files nk-files-view-list">
                <div className="nk-files-head">
                  <div className="nk-file-item">
                    <div className="nk-file-info">
                      <span>{t('Name')}</span>
                    </div>
                    <div className="nk-file-meta">
                      <span>{t('Date')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Author')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Actions')}</span>
                    </div>
                    <div className="nk-file-members" />
                  </div>
                </div>
                <div className="nk-files-list">
                  {commData ? (
                    <>
                      {/* Announcement Communication */}
                      <div className="nk-file-item nk-file">
                        <div className="nk-file-info">
                          <div className="nk-file-title">
                            <div className="nk-file-icon">
                              <span className="nk-file-icon-type">
                                <img src={defaultIcon} alt="icon-file" />
                              </span>
                            </div>
                            <div className="nk-file-name">
                              <div className="nk-file-name-text">
                                <Link
                                  to={`/comunicaciones/${actualSociety['_id']}/${announcementComm}`}
                                  className={`${
                                    !announcementComm && 'is-disable'
                                  }`}
                                  target="_blank"
                                >
                                  <span className="title">
                                    {t('CommunicationCall')}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-file-meta">
                          <div className="tb-lead">
                            {commData && commData?.date
                              ? format(new Date(commData?.date), 'dd/MM/yy')
                              : '-'}
                          </div>
                        </div>
                        <div className="nk-file-members">
                          {announcementComm ? actualSociety.name : '-'}
                        </div>
                        <div className="nk-file-members">
                          <span
                            className={`btn btn-icon btn-trigger ${
                              !announcementComm && 'is-disable'
                            }`}
                            onClick={async () =>
                              announcementComm &&
                              handleGeneratePdfFromHtml(
                                commData?.body,
                                `${t('CommunicationCall')}_${board?.name}.pdf`
                              )
                            }
                          >
                            <em className="icon ni ni-file-download" />
                          </span>
                        </div>
                        <div className="nk-file-members" />
                      </div>
                      {/* Announcement Communication Certificate */}
                      <div className="nk-file-item nk-file">
                        <div className="nk-file-info">
                          <div className="nk-file-title">
                            <div className="nk-file-icon">
                              <span className="nk-file-icon-type">
                                <img src={defaultIcon} alt="icon-file" />
                              </span>
                            </div>
                            <div className="nk-file-name">
                              <div className="nk-file-name-text">
                                <Link
                                  to={`/comunicaciones/${actualSociety['_id']}/${announcementComm}/certificacion`}
                                  className={`${
                                    !announcementComm && 'is-disable'
                                  }`}
                                  target="_blank"
                                >
                                  <span className="title">
                                    {t('CommunicationDeliveryCertificate')}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-file-meta">
                          <div className="tb-lead">
                            {commData && commData?.date
                              ? format(new Date(commData?.date), 'dd/MM/yy')
                              : '-'}
                          </div>
                        </div>
                        <div className="nk-file-members">
                          {announcementComm ? actualSociety.name : '-'}
                        </div>
                        <div className="nk-file-members">
                          <span
                            className={`btn btn-icon btn-trigger ${
                              !announcementComm && 'is-disable'
                            }`}
                            onClick={generateCertificatePDF}
                          >
                            <em className="icon ni ni-file-download" />
                          </span>
                        </div>
                        <div className="nk-file-members" />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {announcementDoc ? (
                    <div className="nk-file-item nk-file">
                      <div className="nk-file-info">
                        <div className="nk-file-title">
                          <div className="nk-file-icon">
                            <span className="nk-file-icon-type">
                              <img
                                src={getDocumentIcon(announcementDoc)}
                                alt="icon-file"
                              />
                            </span>
                          </div>
                          <div className="nk-file-name">
                            <div className="nk-file-name-text">
                              <span className="title">
                                {announcementDoc?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nk-file-meta">
                        <div className="tb-lead">
                          {announcementDoc?.date
                            ? format(
                                new Date(announcementDoc?.date),
                                'dd/MM/yy'
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="nk-file-members">
                        {actualSociety.name}
                      </div>
                      <div className="nk-file-members">
                        <span
                          className="btn btn-icon btn-trigger"
                          onClick={async () =>
                            downloadDocument({
                              documentId: announcementDoc?.['_id'],
                              documentName: announcementDoc?.name,
                            })
                          }
                        >
                          <em className="icon ni ni-file-download" />
                        </span>
                      </div>
                      {!isDemo && (
                        <div className="nk-file-members">
                          <MenuDots
                            menuOptions={currentMenuOptions.map((option) =>
                              option.index === 3
                                ? { ...option, disabled: true }
                                : option
                            )}
                            id={announcementDoc?.['_id']}
                            params={{
                              documentId: announcementDoc?.['_id'],
                              documentName: announcementDoc?.name,
                              document: announcementDoc,
                              society: actualSociety,
                              user,
                              boardId: board['_id'],
                              isAdmin,
                            }}
                            direction="left"
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {!partnerId && allDelegationDocs?.length ? (
            <div className="nk-block mb-5">
              <h6 className="title">{t('DelegationDocuments')}</h6>
              <div className="nk-files nk-files-view-list">
                <div className="nk-files-head">
                  <div className="nk-file-item">
                    <div className="nk-file-info">
                      <span>{t('Name')}</span>
                    </div>
                    <div className="nk-file-meta">
                      <span>{t('Date')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Author')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Actions')}</span>
                    </div>
                    <div className="nk-file-members" />
                  </div>
                </div>
                <div className="nk-files-list">
                  {allDelegationDocs.map((doc) => (
                    <div className="nk-file-item nk-file">
                      <div className="nk-file-info">
                        <div className="nk-file-title">
                          <div className="nk-file-icon">
                            <span className="nk-file-icon-type">
                              <img src={getDocumentIcon(doc)} alt="icon-file" />
                            </span>
                          </div>
                          <div className="nk-file-name">
                            <div className="nk-file-name-text">
                              <span className="title">{doc?.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nk-file-meta">
                        <div className="tb-lead">
                          {doc?.date
                            ? format(new Date(doc?.date), 'dd/MM/yy')
                            : '-'}
                        </div>
                      </div>
                      <div className="nk-file-members">
                        {actualSociety.name}
                      </div>
                      <div className="nk-file-members">
                        <span
                          className="btn btn-icon btn-trigger"
                          onClick={async () =>
                            downloadDocument({
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                            })
                          }
                        >
                          <em className="icon ni ni-file-download" />
                        </span>
                      </div>
                      {!isDemo && (
                        <div className="nk-file-members">
                          <MenuDots
                            menuOptions={currentMenuOptions.filter(
                              (option) => option.index !== 1
                            )}
                            id={doc?.['_id']}
                            params={{
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                              document: doc,
                              society: actualSociety,
                              user,
                              isAdmin,
                            }}
                            direction="left"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {!partnerId && boardDocs?.length ? (
            <div className="nk-block mb-5">
              <h6 className="title">{t('AttachedDocuments')}</h6>
              <div className="nk-files nk-files-view-list">
                <div className="nk-files-head">
                  <div className="nk-file-item">
                    <div className="nk-file-info">
                      <span>{t('Name')}</span>
                    </div>
                    <div className="nk-file-meta">
                      <span>{t('Date')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Author')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Actions')}</span>
                    </div>
                    <div className="nk-file-members" />
                  </div>
                </div>
                <div className="nk-files-list">
                  {boardDocs.map((doc) => (
                    <div className="nk-file-item nk-file">
                      <div className="nk-file-info">
                        <div className="nk-file-title">
                          <div className="nk-file-icon">
                            <span className="nk-file-icon-type">
                              <img src={getDocumentIcon(doc)} alt="icon-file" />
                            </span>
                          </div>
                          <div className="nk-file-name">
                            <div className="nk-file-name-text">
                              <span className="title">{doc?.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nk-file-meta">
                        <div className="tb-lead">
                          {doc?.date
                            ? format(new Date(doc?.date), 'dd/MM/yy')
                            : '-'}
                        </div>
                      </div>
                      <div className="nk-file-members">
                        {actualSociety.name}
                      </div>
                      <div className="nk-file-members">
                        <span
                          className="btn btn-icon btn-trigger"
                          onClick={async () =>
                            downloadDocument({
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                            })
                          }
                        >
                          <em className="icon ni ni-file-download" />
                        </span>
                      </div>
                      {!isDemo && (
                        <div className="nk-file-members">
                          <MenuDots
                            menuOptions={currentMenuOptions.filter(
                              (option) => option.index !== 1
                            )}
                            id={doc?.['_id']}
                            params={{
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                              document: doc,
                              society: actualSociety,
                              user,
                              isAdmin,
                            }}
                            direction="left"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {!partnerId && allPrivateDocs?.length ? (
            <div className="nk-block mb-5">
              <h6 className="title">{t('PrivateDocuments')}</h6>
              <div className="nk-files nk-files-view-list">
                <div className="nk-files-head">
                  <div className="nk-file-item">
                    <div className="nk-file-info">
                      <span>{t('Name')}</span>
                    </div>
                    <div className="nk-file-meta">
                      <span>{t('Date')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Author')}</span>
                    </div>
                    <div className="nk-file-members">
                      <span>{t('Actions')}</span>
                    </div>
                    <div className="nk-file-members" />
                  </div>
                </div>
                <div className="nk-files-list">
                  {allPrivateDocs.map((doc) => (
                    <div className="nk-file-item nk-file">
                      <div className="nk-file-info">
                        <div className="nk-file-title">
                          <div className="nk-file-icon">
                            <span className="nk-file-icon-type">
                              <img src={getDocumentIcon(doc)} alt="icon-file" />
                            </span>
                          </div>
                          <div className="nk-file-name">
                            <div className="nk-file-name-text">
                              <span className="title">{doc?.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nk-file-meta">
                        <div className="tb-lead">
                          {doc?.date
                            ? format(new Date(doc?.date), 'dd/MM/yy')
                            : '-'}
                        </div>
                      </div>
                      <div className="nk-file-members">
                        {actualSociety.name}
                      </div>
                      <div className="nk-file-members">
                        <span
                          className="btn btn-icon btn-trigger"
                          onClick={async () =>
                            downloadDocument({
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                            })
                          }
                        >
                          <em className="icon ni ni-file-download" />
                        </span>
                      </div>
                      {!isDemo && (
                        <div className="nk-file-members">
                          <MenuDots
                            menuOptions={currentMenuOptions.filter(
                              (option) => option.index !== 1
                            )}
                            id={doc?.['_id']}
                            params={{
                              documentId: doc?.['_id'],
                              documentName: doc?.name,
                              document: doc,
                              society: actualSociety,
                              user,
                              isAdmin,
                            }}
                            direction="left"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default BoardDocuments;
