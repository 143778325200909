import { FC, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import { dateFormat } from 'constants/formats';
import { useTranslate } from 'hooks/useTranslate';
import { Notary, Society } from 'types';

import EditButton from 'modules/_shared/components/Buttons/EditButton';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import { setModal } from 'modules/_shared/redux/modalActions';
import EditCommentsModal from 'modules/_shared/components/Modals/EditComments';

type TransactionLabelObject = {
  [key: string]: string;
};

type TransactionDetailsHeaderProps = {
  transaction: any;
  actualSociety: Society;
};

const TransactionDetailsHeader: FC<TransactionDetailsHeaderProps> = ({
  transaction,
  actualSociety,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const EMPTY_NOTARY: Notary = {
    notaryName: '',
    protocolNr: '',
    notaryCity: '',
  };

  const [transactionName, setTransactionName] = useState<string>('');
  const [transactionComents, setTransactionComents] = useState<string>('');
  const [transactionDate, setTransactionDate] = useState<string>('');
  const [notarialRegistration, setNotarialRegistration] =
    useState<Notary>(EMPTY_NOTARY);

  const transactionLabels: TransactionLabelObject = {
    ADD_PARTNER: t('AddPartner'),
    CONSTITUTION: t('Constitution'),
    CAPITAL_INCREASE: t('CapitalIncrease'),
    CAPITAL_DECREASE: t('CapitalDecrease'),
    SELL_PARTICIPATION: t('SELL_PARTICIPATION'),
    HERITAGE: t('Heritage'),
    DONATION: t('Donation'),
    SPLIT: t('Split'),
    RENUMERATION: t('Renumeration'),
    RECLASSIFICATION: t('Reclassification'),
    DIVIDENDS_DISTRIBUTION: t('DividendsDistribution'),
  };

  useEffect(() => {
    if (transaction) {
      setTransactionName(
        transactionLabels[transaction?.alias || transaction.operationType]
      );
      setTransactionComents(transaction?.comments);
      setTransactionDate(format(new Date(transaction?.date), dateFormat));
      setNotarialRegistration(
        transaction?.notarialRegistration || EMPTY_NOTARY
      );
    }
  }, [transaction]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PrintHeader title={t('PrintHeaderTransactionsList')} />

      <div className="invoice-brand text-center d-flex justify-content-between">
        <div className="user-avatar lg bg-primary">
          {actualSociety?.additional?.logo ? (
            <img src={actualSociety.additional.logo} alt="Logo" />
          ) : (
            <span>{actualSociety.name.slice(0, 2).toUpperCase()}</span>
          )}
        </div>
      </div>

      <div className="invoice-head">
        <div className="invoice-contact flex-grow-1">
          <span className="overline-title">{t('TransactionType')}</span>

          <div className="invoice-contact-info">
            <h4 className="title">{transactionName}</h4>

            <ul className="list-plain">
              {transactionComents && (
                <li>
                  <em className="icon ni ni-notes-alt" />
                  <span>{transactionComents || '-'}</span>
                  <EditButton
                    className="ml-1 pb-1"
                    action={() =>
                      dispatch(
                        setModal(<EditCommentsModal operation={transaction} />)
                      )
                    }
                    disabled={false}
                  />
                </li>
              )}
              <li>
                <em className="icon ni ni-calender-date" />
                <span>{transactionDate || '-'}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="invoice-contact flex-grow-1">
          <span className="overline-title">
            {t('NotarialRegistration')}
            <EditButton
              className="ml-1 pb-1"
              action={() =>
                dispatch(
                  setModal(<EditCommentsModal operation={transaction} />)
                )
              }
              disabled={false}
            />
          </span>

          <ul className="list-plain mt-2">
            <li>
              <em className="icon ni ni-user-list" />
              <span>{notarialRegistration?.notaryName || '-'}</span>
            </li>
            <li>
              <em className="icon ni ni-article" />
              <span>{notarialRegistration?.protocolNr || '-'}</span>
            </li>
            <li>
              <em className="icon ni ni-map-pin" />
              <span>{notarialRegistration?.notaryCity || '-'}</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default memo(TransactionDetailsHeader);
