/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'redux/initialState';

import EditButton from 'modules/_shared/components/Buttons/EditButton';
import { setModal } from 'modules/_shared/redux/modalActions';
import dashboardSectionType from 'modules/dashboard/constants/dashboardSectionType';
import EditCustomDashboard from 'modules/dashboard/modals/EditCustomDashboard';
import { Landing, Section } from 'modules/dashboard/types/CustomDashboard';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import DeleteButton from 'modules/_shared/components/Buttons/DeleteButton';
import Dialog from 'modules/_shared/components/Dialog';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { saveCustomDashboard } from 'modules/dashboard/api/customDashboard';
import { store } from 'redux/store';
import DashboardFolder from '../DashboardFolder';

const DashboardSection = (section: Section) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { index, title, description, folders } = section;

  const customDashboard = useSelector(
    (state: State) => state.society?.actualSociety?.configuration?.customLanding
  );

  const hasWhiteBranding = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.hasWhiteBranding
  );
  const isAdmin = useSelector((state: State) => state.society?.role?.isAdmin);

  const handleSaveDashboard = async (currentDashboard: Landing) => {
    try {
      await store.dispatch(saveCustomDashboard(currentDashboard));
      dispatch(addAlert(alertBodyTypes.UPDATING_CUSTOM_DASHBOARD_SUCCESSFULLY));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.UPDATING_CUSTOM_DASHBOARD_ERROR));
    } finally {
      dispatch(setModal(null));
    }
  };

  const handleOpenDashboardEdit = () => {
    dispatch(
      setModal(
        <EditCustomDashboard
          type={dashboardSectionType.SECTION}
          sectionIndex={index}
        />
      )
    );
  };

  const deleteSection = async () => {
    const updatedSections = customDashboard.sections.filter(
      (s: Section) => s.index !== index
    );

    const updatedDashboard = {
      ...customDashboard,
      sections: updatedSections,
    };

    await handleSaveDashboard(updatedDashboard);
  };

  const handleDeleteSection = () => {
    Dialog({
      icon: 'ni-trash',
      title: t('AreYouSure'),
      body: t('DeleteSectionConfirmation'),
      buttonText: t('YesDelete'),
      onConfirm: async () => deleteSection(),
    });
  };

  return (
    <section key={index}>
      <div className="d-flex flex-row justify-content-between">
        <h3>{title}</h3>

        {hasWhiteBranding && isAdmin && (
          <div>
            <EditButton
              className="position-relative align-self-end bg-white"
              action={handleOpenDashboardEdit}
            />

            <DeleteButton
              className="position-relative align-self-end bg-white ml-2"
              action={handleDeleteSection}
            />
          </div>
        )}
      </div>

      {description && <p>{description}</p>}

      <div className="nk-files nk-files-view-grid row">
        {folders?.map((folder) => (
          <div className="col-6 col-sm-6 col-md-4 col-xl-3">
            <DashboardFolder
              key={folder.title}
              sectionIndex={String(index)}
              {...folder}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default DashboardSection;
