import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { decryptResponse } from 'utils/token';

import axios from '../../../interceptors/axios';
import { addAlert } from '../redux/alertActions';

type SignRedirectionProps = {
  envelopeId: string;
  signerId: string;
};

const getSignRedirection =
  ({ envelopeId, signerId }: SignRedirectionProps): any =>
  async (dispatch: any) => {
    try {
      const query = {
        params: { envelopeId, signerId },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/signRedirection`,
        query
      );

      const decodedData = await decryptResponse(data);
      return decodedData;
    } catch (error) {
      return dispatch(addAlert(alertBodyTypes[500]));
    }
  };

export default getSignRedirection;
