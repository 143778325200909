/* eslint-disable react/jsx-curly-newline */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { getPartnerCategories } from 'modules/partners/redux/partnerCategoryActions';
import { State } from 'redux/initialState';

import {
  getActualSociety,
  getCategoryFDPercent,
  getCategoryNDPercent,
  getCategoryPotentialFD,
  getDecimalScale,
  getSocietyValue,
} from 'utils/filters';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';

import { useTranslate } from 'hooks/useTranslate';
import { PartnerCategory } from 'types';
import menuOptions from './menuOptions';
import tableColumns from './tableColumns';

function PartnerCategoriesList() {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const columns = tableColumns(i18n);

  const user = useSelector((state: State) => state?.user);
  const isAdmin = useSelector((state: State) => state.society?.role?.isAdmin);
  const actualSociety = useSelector(
    (state: State) => state?.society?.actualSociety
  );
  const partnerCategories = useSelector(
    (state: State) => state?.partnerCategories
  ) as PartnerCategory[];
  const plans = useSelector((state: any) => state?.plans);
  const isDemo = useSelector((state: State) => state.society?.role?.isDemo);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId && user)
      getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety && partnerCategories && plans) {
      const societyValue = getSocietyValue(actualSociety);
      const decimalScale = societyValue
        ? getDecimalScale(societyValue.value)
        : 2;

      const currentTableData: Record<string, any>[] = partnerCategories?.map(
        (partnerCategory, index) => ({
          index: index + 1,
          partnerCategory,
          societyId: actualSociety?.['_id'],
          categoryId: partnerCategory?.['_id'],
          decimals: decimalScale,
          name: partnerCategory?.name,
          partnersCount: actualSociety?.partners?.filter(
            (partner: any) => partner?.category === partnerCategory?.['_id']
          )?.length,
          NDPercent: getCategoryNDPercent(partnerCategory, actualSociety),
          FDPercent: getCategoryFDPercent(partnerCategory, actualSociety),
          FDPotential: getCategoryPotentialFD(
            partnerCategory,
            actualSociety,
            plans
          ),
          isAdmin,
          isDemo,
        })
      );

      setTableData(
        transformData({
          data: currentTableData,
          columns: tableColumns(i18n),
        })
      );
    } else {
      setTableData([]);
    }
  }, [partnerCategories, actualSociety, plans, isAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getStockPlans(societyId));
    dispatch(getPartnerCategories(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
            <p />
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('PartnerCategories')}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`${actualSociety?.name} ${t('HavePartnerCategories', {
                      count: partnerCategories?.length,
                    })}`}
                  </p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <li>
                          <OperationMenuDots
                            title={t('AddPartnerCategory')}
                            menuOptions={menuOptions}
                            handleModal={(Modal: any) =>
                              dispatch(
                                setModal(
                                  <Modal
                                    user={user}
                                    societyId={actualSociety?.['_id']}
                                  />
                                )
                              )
                            }
                            disabled={isDemo}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tableData.length ? (
            <div className="nk-block">
              <div className="card card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner p-0">
                    {/* @ts-ignore */}
                    <CustomDataTable
                      data={transformData({
                        data: tableData,
                        columns,
                      })}
                      columns={columns}
                      searchBy={t('Name')}
                      pagination
                      rowsPerPage={10}
                      className="nk-tb-list"
                      actions
                      showDense
                      showDownload
                      fileName={`${t('PartnerCategories')} ${
                        actualSociety?.name
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default PartnerCategoriesList;
