import documentLinkTypes from 'constants/documentLinkTypes';
import uploadDocument from 'modules/profile/components/UserMyData/utils/uploadDocument';
import { User } from 'types';

type PartnerData = {
  name: string;
  cif: string;
  email: string;
  emails: string[];
  birthdate: string;
  employeeNumber: string;

  nationality: string;
  phone: string;
  category: string;
  sindication: string;
  civilStatus: string;
  image: string;
  address: string;
  taxAddress: {
    line1: string;
    city: string;
    zip: string;
    country: string;
  };
  bankAccountNumber: string;
  isNaturalPerson: string;
  representative?: string;
  legalInfo?: { register: string; section: string; sheet: string };
  constitutionDocument?: string;
  appointmentDocument?: string;
  ownershipDocument?: string;
};

type Props = {
  updatedPartner: PartnerData;
  constitutionFile: File | null;
  appointmentFile: File | null;
  ownershipFile: File | null;
  partnerId: string;
  societyId: string;
  user: User;
};

export default async function getPartnerData({
  updatedPartner,
  constitutionFile,
  appointmentFile,
  ownershipFile,
  partnerId,
  societyId,
  user,
}: Props) {
  const partnerData: PartnerData = {
    name: updatedPartner?.name,
    cif: updatedPartner?.cif,
    email: updatedPartner?.email,
    emails: updatedPartner?.emails,
    birthdate: updatedPartner?.birthdate,
    employeeNumber: updatedPartner?.employeeNumber,
    nationality: updatedPartner?.nationality,
    phone: updatedPartner?.phone,
    category: updatedPartner?.category,
    sindication: updatedPartner?.sindication,
    civilStatus: updatedPartner?.civilStatus,
    image: updatedPartner?.image,
    address: updatedPartner?.address,
    taxAddress: updatedPartner?.taxAddress,
    bankAccountNumber: updatedPartner?.bankAccountNumber,
    isNaturalPerson: updatedPartner?.isNaturalPerson,
  };

  if (!updatedPartner?.isNaturalPerson) {
    partnerData.representative = updatedPartner?.representative;
    partnerData.legalInfo = updatedPartner?.legalInfo;

    if (constitutionFile) {
      const uploadedDocument = await uploadDocument({
        file: constitutionFile,
        societyId,
        subcategory: '',
        user,
        linkedTo: [
          { linkedId: partnerId, linkedType: documentLinkTypes.PARTNER },
        ],
      });

      partnerData.constitutionDocument = uploadedDocument?.['_id'];
    }
    if (appointmentFile) {
      const uploadedDocument = await uploadDocument({
        file: appointmentFile,
        societyId,
        subcategory: '',
        user,
        linkedTo: [
          { linkedId: partnerId, linkedType: documentLinkTypes.PARTNER },
        ],
      });

      partnerData.appointmentDocument = uploadedDocument?.['_id'];
    }
    if (ownershipFile) {
      const uploadedDocument = await uploadDocument({
        file: ownershipFile,
        societyId,
        subcategory: '',
        user,
        linkedTo: [
          { linkedId: partnerId, linkedType: documentLinkTypes.PARTNER },
        ],
      });

      partnerData.ownershipDocument = uploadedDocument?.['_id'];
    }
  }
  return partnerData;
}
