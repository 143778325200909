/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import html2pdf from 'html3pdf';
import bigDecimal from 'js-big-decimal';
import jsPDF from 'jspdf';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { addBeneficiary } from 'modules/_shared/redux/modalsActions';
import { updateBeneficiary } from 'modules/beneficiaries/redux/beneficiaryActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';

import cliffOptions from 'constants/cliffOptions';
import consolidationOptions from 'constants/consolidationOptions';
import { EMAIL_PATTERN, JSPDF_MARGINS } from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import {
  cleanValue,
  currencyFormatDecimals,
  numberFormat,
} from 'constants/formats';
import invitationStatus from 'constants/invitationStatus';
import operationTypes from 'constants/operationTypes';
import signatureTypes from 'constants/signatureTypes';
import vestingOptions from 'constants/vestingOptions';

import addHtmlAlign from 'utils/addHtmlAlign';
import { clearTableLines } from 'utils/clearTableLines';
import { getConsolidationPeriods } from 'utils/consolidationPeriods';
import { startSignProcess, toBase64 } from 'utils/docusignActions';
import { getSingleDocument } from 'utils/downloadDocument';
import { createBlobMerged } from 'utils/downloadMergedPDF';
import cleanHtmlEntities from 'utils/editor';
import {
  getActualSociety,
  getUsedSharesFromPlan,
  isBeneficiaryAlready,
} from 'utils/filters';
import { generatePdfBlob } from 'utils/generatePdfBlob';
import getEnvelopeData from 'utils/getEnvelopeData';
import { generateCalendarPlanTable } from 'utils/htmlTemplates';
import isValidEmail from 'utils/isValidEmail';
import normalizeCif from 'utils/normalizeCif';
import normalizeEmail from 'utils/normalizeEmail';
import hasShowDetails, { hasNewPDF } from 'utils/showDetails';
import trackEvent from 'utils/trackEvent';
import { transformHtmlTables } from 'utils/transformHtmlTables';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';

import calculateTotalPeriods from 'modules/beneficiaries/utils/calculateTotalPeriods';
import createPeriodsArray from 'modules/beneficiaries/utils/createPeriodsArray';

import {
  BeneficiaryAlreadyExistsAlert,
  EmptyInvitationLetterAlert,
} from 'modules/beneficiaries/utils/alerts';
import hasEmptyInvitationLetter from 'modules/beneficiaries/utils/hasEmptyInvitationLetter';
import parseBeneficiaryData from 'modules/beneficiaries/utils/parseBeneficiaryData';

import InputError from 'modules/_shared/components/InputError';
import createInvitationDocument from 'modules/beneficiaries/utils/createInvitationDocument';
import createInvitationEnvelope from 'modules/beneficiaries/utils/createInvitationEnvelope';
import hasInvitationLetter from 'modules/beneficiaries/utils/hasInvitationLetter';
import parseUpdatedBeneficiaryData from 'modules/beneficiaries/utils/parseUpdatedBeneficiaryData';
import uploadBeneficiaryDocument from 'modules/beneficiaries/utils/uploadBeneficiaryDocument';

import '../Modals.scss';
import './AddBeneficiary.scss';

const consolidationTypes = {
  BY_UNITS: 'BY_UNITS',
  BY_PERCENT: 'BY_PERCENT',
};

const actions = {
  ADD: 'add',
  EDIT: 'edit',
  WATCH: 'watch',
  DUPLICATE: 'duplicate',
};

function AddBeneficiaryModal({
  action = actions.ADD,
  currentBeneficiary,
  isDraft = false,
}) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state.user);
  const stockPlans = useSelector((state) => state.plans);
  const tenderOffers = useSelector((state) => state.tenderOffers);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [name, setName] = useState('');
  const [cif, setCif] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [plan, setPlan] = useState('');
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [invitationDate, setInvitationDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [shares, setShares] = useState();
  const [file, setFile] = useState();
  const [currentPlan, setCurrentPlan] = useState('');
  const [currentPlanShares, setCurrentPlanShares] = useState(0);
  const [amount, setAmount] = useState(0);

  const [currentPlanLaboralCategories, setCurrentPlanLaboralCategories] =
    useState([]);
  const [laboralCategory, setLaboralCategory] = useState('');
  const [salary, setSalary] = useState('');

  const [sendInvitation, setSendInvitation] = useState(false);
  const [invitationCode, setInvitationCode] = useState('');
  const [invitationStatusType, setInvitationStatusType] = useState();

  // Custom conditions
  const [hasCustomConditions, setHasCustomConditions] = useState(false);
  const [sharePrice, setSharePrice] = useState();
  const [isFixedPrice, setIsFixedPrice] = useState(false);
  const [cliff, setCliff] = useState(0);
  const [vestingPeriod, setVestingPeriod] = useState();
  const [hasEndMonthConsolidation, setHasEndMonthConsolidation] =
    useState(false);
  const [hasDecimalConsolidation, setHasDecimalConsolidation] = useState(false);
  const [consolidation, setConsolidation] = useState();
  const [customConsolidation, setCustomConsolidation] = useState(false);

  const [consolidationPeriodsPercent, setConsolidationPeriodsPercent] =
    useState([]);
  const [consolidationPeriodsUnits, setConsolidationPeriodsUnits] = useState(
    []
  );
  const [customConsolidationType, setCustomConsolidationType] = useState(null);

  const [attachmentDocument, setAttachmentDocument] = useState(null);
  const [validEmail, setValidEmail] = useState(true);

  const isByPercentDisabled = () =>
    action === actions.WATCH ||
    customConsolidationType !== consolidationTypes.BY_PERCENT;

  const isByUnitsDisabled = () =>
    action === actions.WATCH ||
    customConsolidationType !== consolidationTypes.BY_UNITS;

  const calculateSharesFromPercent = (index, percent, totalShares) => {
    if (!totalShares) return 0;

    const sharesFromThisPeriod = Math.floor((totalShares * +percent) / 100);
    const newConsolidationPeriodsUnits = [...consolidationPeriodsUnits];
    newConsolidationPeriodsUnits[index] = sharesFromThisPeriod;
    setConsolidationPeriodsUnits(newConsolidationPeriodsUnits);
  };

  const calculatePercentFromShares = (index, units, totalShares) => {
    if (!totalShares) return 0;

    const percentFromThisPeriod = +bigDecimal.divide(+units * 100, totalShares);
    const newConsolidationPeriodsPercent = [...consolidationPeriodsPercent];
    newConsolidationPeriodsPercent[index] = percentFromThisPeriod;
    setConsolidationPeriodsPercent(newConsolidationPeriodsPercent);
  };

  const handleSelectPeriodsByPercent = () => {
    setCustomConsolidationType(consolidationTypes.BY_PERCENT);
  };

  const handleSelectPeriodsByUnits = () => {
    setCustomConsolidationType(consolidationTypes.BY_UNITS);
  };

  const handleChangeEndMonthConsolidation = () => {
    setHasEndMonthConsolidation(!hasEndMonthConsolidation);
  };

  const handleChangeDecimalConsolidation = () => {
    setHasDecimalConsolidation(!hasDecimalConsolidation);
  };

  const handleConsolidationPeriodsChange = (
    index,
    value,
    consolidationType
  ) => {
    if (consolidationType === consolidationTypes.BY_PERCENT) {
      const newConsolidationPeriods = [...consolidationPeriodsPercent];
      newConsolidationPeriods[index] = +value;
      setConsolidationPeriodsPercent(newConsolidationPeriods);

      calculateSharesFromPercent(index, value, shares);
    } else {
      const newConsolidationPeriods = [...consolidationPeriodsUnits];
      newConsolidationPeriods[index] = +value;
      setConsolidationPeriodsUnits(newConsolidationPeriods);

      calculatePercentFromShares(index, value, shares);
    }
  };

  const handleChangeCif = (event) => {
    const { value } = event.target;

    const normalicedCif = normalizeCif(value);
    setCif(normalicedCif);
  };

  const handleChangeEmail = (event) => {
    const { value } = event.target;

    const normalicedEmail = normalizeEmail(value);
    setEmail(normalicedEmail);

    const isValid = isValidEmail(normalicedEmail);
    setValidEmail(isValid);
  };

  const parseVestingMonths = (months) =>
    vestingOptions.find((option) => option.value === Number(months))?.text;

  const parseCliffMonths = (months) =>
    cliffOptions.find((option) => option.value === Number(months))?.text;

  const getAttachedDocument = async (documentId) => {
    if (documentId) {
      const attachmentBlob = await getSingleDocument(documentId);
      setAttachmentDocument(attachmentBlob);
    }
  };

  const updateConsolidationPeriodsValues = (
    newConsolidationPeriods,
    currentBeneficiary
  ) => {
    const periodsWithValues = currentBeneficiary?.customConditions
      ?.consolidationPeriods?.length
      ? currentBeneficiary?.customConditions?.consolidationPeriods
      : newConsolidationPeriods;

    if (customConsolidationType === consolidationTypes.BY_UNITS) {
      setConsolidationPeriodsUnits(periodsWithValues);
      setConsolidationPeriodsPercent(newConsolidationPeriods);
    } else if (customConsolidationType === consolidationTypes.BY_PERCENT) {
      setConsolidationPeriodsPercent(periodsWithValues);
      setConsolidationPeriodsUnits(newConsolidationPeriods);
    } else {
      setConsolidationPeriodsPercent(newConsolidationPeriods);
      setConsolidationPeriodsUnits(newConsolidationPeriods);
    }
  };

  const getCurrentSendInvitation = (currentPlan) => {
    if (currentPlan?.sendInvitationToAll) {
      return true;
    }

    if (
      currentPlan?.hasInvitation &&
      currentPlan?.sendInvitationToAll === undefined
    ) {
      return true;
    }

    return false;
  };

  const savePartner = async (event) => {
    event.preventDefault();
    try {
      const parsedCategory =
        currentPlan?.hasLaboralCategories && laboralCategory
          ? JSON.parse(laboralCategory)
          : { name: '', percent: 0 };

      const consolidationPeriods =
        customConsolidationType === consolidationTypes.BY_PERCENT
          ? consolidationPeriodsPercent
          : consolidationPeriodsUnits;

      const isCustomDilutionByUnits = !!(
        customConsolidationType === consolidationTypes.BY_UNITS
      );

      const newBeneficiary = {
        email,
        society: actualSociety?.['_id'],
        societyLogo: actualSociety?.additional?.['logo'],
        userId: user['_id'],
        userEmail: user.email,
        societyName: actualSociety?.name,
        address: {
          line1: addressLine,
          zip: '',
          city: '',
          state: '',
          country: '',
        },
        cif,
        plan,
        planName: currentPlan?.name,
        planType: currentPlan?.planType,
        planStartDate: new Date(date),
        amount,
        sharesCount: { future: shares },
        name,
        jobTitle,
        laboralCategory: {
          name: parsedCategory.name,
          percent: parsedCategory.percent,
        },
        salary,
        hasInvitation: currentPlan?.hasInvitation,
        invitationDate: currentPlan?.hasInvitation
          ? new Date(invitationDate)
          : null,
        invitationCode,
        invitationStatus: invitationStatusType,
        isDraft,
      };

      if (hasCustomConditions) {
        newBeneficiary.customConditions = {
          sharePrice,
          isFixedPrice,
          cliff,
          vestingPeriod,
          consolidation,
          customConsolidation,
          consolidationPeriods,
          isCustomDilutionByUnits,
          hasEndMonthConsolidation,
          hasDecimalConsolidation,
        };
      }

      if (action === actions.ADD || action === actions.DUPLICATE) {
        let validBeneficiary = true;
        const isCifAlready = isBeneficiaryAlready(cif, plan, actualSociety);

        if (!isDraft && hasEmptyInvitationLetter(currentPlan)) {
          return Swal.fire({
            icon: 'warning',
            title: `<h4 class="nk-modal-title">${t(
              'EmptyInvitationLetter'
            )}</h4><br /><p class="caption-text">${t(
              'EmptyInvitationLetterMessage'
            )}</p>`,
            confirmButtonText: t('Accept'),
            confirmButtonColor: '#6576ff',
            allowOutsideClick: false,
          });
        }

        if (isCifAlready) {
          await Swal.fire({
            icon: 'warning',
            title: `<h4 class="nk-modal-title">${t('BeneficiaryAlreadyExists', {
              cif,
              planName: currentPlan?.name,
            })}</h4>`,
            confirmButtonText: t('Accept'),
            cancelButtonText: t('Cancel'),
            confirmButtonColor: '#6576ff',
            allowOutsideClick: false,
            showCancelButton: true,
          }).then((result) => {
            if (!result.isConfirmed) {
              validBeneficiary = false;
            }
          });
        }

        if (validBeneficiary) {
          let uploadedDocument = '';

          if (file) {
            const fileName = `${operationTypes.ADD_BENEFICIARY_FILENAME}_${file.name}`;
            const newDocument = {
              file,
              date,
              name: fileName,
              author: user['_id'],
              society: actualSociety['_id'],
              size: file?.size || 0,
              lastAccess: new Date(),
              category: documentTypes.LETTERS,
              isGenerated: true,
              fileType: fileTypes[file?.type],
              description: t('DocumentLinkedToBeneficiary'),
            };
            uploadedDocument = await store.dispatch(addDocument(newDocument));
          }

          const documents = [];
          if (uploadedDocument?.['_id']) {
            documents.push(uploadedDocument?.['_id']);
          }

          let fileName = '';
          let invitationFile = '';
          let invitationDocument = '';
          let hasSocietySign = false;

          if (
            currentPlan?.hasInvitation &&
            currentPlan?.invitationModel &&
            !isDraft &&
            sendInvitation
          ) {
            const invitationUpdated = updateDocumentWithValues(
              currentPlan?.invitationModel?.html,
              tags({
                plan: currentPlan,
                society: actualSociety,
                beneficiary: newBeneficiary,
                partner: null,
                board: null,
              })
            );

            fileName = `Carta_de_Invitación_${newBeneficiary.name}_${currentPlan.name}.pdf`;
            const invitationModel = invitationUpdated.html;
            const {
              hasConsolidationCalendar,
              hasConsolidationCalendarNoStatus,
              hasConsolidationCalendarNoStatusENES,
            } = invitationUpdated;
            hasSocietySign = invitationUpdated.hasSocietySign;

            const invitationHTML = cleanHtmlEntities(invitationModel);
            const alignedText = addHtmlAlign(invitationHTML);
            let mainBlob = null;

            if (hasNewPDF()) {
              const transformedHtml = transformHtmlTables(invitationModel);
              const clearTablesHtml = clearTableLines(transformedHtml);
              mainBlob = await generatePdfBlob(clearTablesHtml);
            } else {
              // eslint-disable-next-line new-cap
              const pdf = new jsPDF('p', 'pt', 'a4');

              const margins = JSPDF_MARGINS;

              await new Promise((resolve, reject) => {
                pdf.fromHTML(
                  alignedText,
                  JSPDF_MARGINS.left,
                  JSPDF_MARGINS.top,
                  {
                    width: JSPDF_MARGINS.width,
                  },
                  () => {
                    try {
                      mainBlob = pdf.output('blob');
                      resolve();
                    } catch (error) {
                      reject(error);
                    }
                  },
                  margins
                );
              });
            }

            const mainFile = new File([mainBlob], fileName, {
              type: 'application/pdf',
            });

            const tableFiles = [];
            if (
              hasConsolidationCalendar ||
              hasConsolidationCalendarNoStatus ||
              hasConsolidationCalendarNoStatusENES
            ) {
              const consolidationTables = generateCalendarPlanTable(
                getConsolidationPeriods(newBeneficiary, currentPlan),
                {
                  hasConsolidationCalendar,
                  hasConsolidationCalendarNoStatus,
                  hasConsolidationCalendarNoStatusENES,
                }
              );

              const options = {
                filename: 'attachment',
                margin: [10, 10, 10, 10], // top, right, bottom, left
                pagebreak: { mode: 'avoid-all' },
                html2canvas: { scale: 2 },
                jsPDF: {
                  unit: 'mm',
                  format: 'a4',
                  orientation: 'portrait',
                },
              };
              // Create a file for each table
              for (const table of consolidationTables) {
                const tableBlob = await html2pdf()
                  .from(table)
                  .set(options)
                  .output('blob');

                const tableFile = new File([tableBlob], fileName, {
                  type: 'application/pdf',
                });
                tableFiles.push(tableFile);
              }
            }

            let attachmentFile;
            if (attachmentDocument) {
              attachmentFile = new File([attachmentDocument], fileName, {
                type: 'application/pdf',
              });
            }

            const mergedBlob =
              tableFiles?.length > 0 || attachmentFile
                ? await createBlobMerged({
                    documents: [mainFile, ...tableFiles, attachmentFile],
                  })
                : mainBlob;

            invitationFile = new File([mergedBlob], fileName, {
              type: 'application/pdf',
            });

            const newDocument = {
              name: fileName,
              file: invitationFile,
              size: invitationFile?.size || 0,
              date: new Date(),
              fileType: fileTypes.PDF,
              lastAccess: new Date(),
              isGenerated: true,
              author: user['_id'],
              society: actualSociety['_id'],
              category: documentTypes.LETTERS,
              description: t('DocumentGenerated'),
            };

            invitationDocument = await store.dispatch(addDocument(newDocument));
            if (invitationDocument?.['_id']) {
              documents.push(invitationDocument?.['_id']);
            }
          }

          const uploadedBeneficiary = await store.dispatch(
            addBeneficiary({
              ...newBeneficiary,
              documents,
            })
          );

          if (
            currentPlan?.hasInvitation &&
            currentPlan?.invitationModel &&
            !isDraft
          ) {
            const signFileBase64 = await toBase64(invitationFile);

            const signers = [
              {
                id: uploadedBeneficiary['_id'],
                email: newBeneficiary?.email,
                name: newBeneficiary?.name,
                role: 'BENEFICIARY',
                type: signatureTypes.INVITATION_LETTER,
              },
            ];

            if (hasSocietySign) {
              signers.push({
                id: '',
                email: currentPlan?.invitationExtraSigner?.email || '',
                name: currentPlan?.invitationExtraSigner?.name || '',
                role: 'SOCIETY',
                type: signatureTypes.FREE_SIGNATURE,
              });
            }

            const beneficiaries = [
              { id: uploadedBeneficiary['_id'], planId: currentPlan['_id'] },
            ];

            const envelopeData = getEnvelopeData({
              signers,
              signType: signatureTypes.INVITATION_LETTER,
              fileName,
              fileType: fileTypes.PDF,
              linkedType: 'BENEFICIARY',
              role: 'BENEFICIARIES',
              userId: user?._id,
              societyId: actualSociety?._id,
              beneficiaries,
              documentId: invitationDocument['_id'],
            });

            const signProcessData = {
              signers,
              societyName: actualSociety?.name,
              documentName: fileName,
              planName: currentPlan?.name,
              language: actualSociety?.societyLanguage || 'es',
              signatureType: signatureTypes.INVITATION_LETTER,
              signatureFile: signFileBase64,
              signatureFileName: fileName,
              envelopeData,
            };

            await startSignProcess(signProcessData);
          }

          trackEvent(mixpanel, eventTypes.ADD_BENEFICIARY, {
            userId: user?._id,
            userName: user?.name,
            userEmail: user?.email,
            societyId: actualSociety?._id,
            societyName: actualSociety?.name,
            operation: eventTypes.ADD_BENEFICIARY,
          });

          dispatch(setModal(null));

          dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
        }
      } else if (action === actions.EDIT) {
        if (cif !== currentBeneficiary?.cif) {
          const isCifAlready = isBeneficiaryAlready(cif, plan, actualSociety);
          if (isCifAlready) {
            dispatch(addAlert(alertBodyTypes.DUPLICATED_CIF));
            return;
          }
        }

        const parsedCategory =
          currentPlan?.hasLaboralCategories && laboralCategory
            ? JSON.parse(laboralCategory)
            : { name: '', percent: 0 };

        const updatedBeneficiary = {
          planStartDate: new Date(date),
          amount,
          sharesCount: { future: shares },
          laboralCategory: {
            name: parsedCategory.name,
            percent: parsedCategory.percent,
          },
          salary,
        };
        if (hasCustomConditions) {
          updatedBeneficiary.customConditions = {
            sharePrice,
            isFixedPrice,
            cliff,
            vestingPeriod,
            consolidation,
            customConsolidation,
            consolidationPeriods,
            isCustomDilutionByUnits,
            hasEndMonthConsolidation,
            hasDecimalConsolidation,
          };
        }
        await store.dispatch(
          updateBeneficiary({
            id: currentBeneficiary['_id'],
            data: {
              ...updatedBeneficiary,
              userId: user['_id'],
              societyId: actualSociety['_id'],
              cascade: false,
            },
            showAlert: false,
          })
        );
        getActualSociety(user, actualSociety['_id']);
        dispatch(setModal(null));
      } else {
        dispatch(setModal(null));

        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
    } catch (error) {
      console.log('Error adding beneficiary', error, error?.message);
      dispatch(addAlert(alertBodyTypes.ERROR_ADDING_BENEFICIARY));
    }
  };

  const saveBeneficiary = async (event) => {
    event.preventDefault();

    try {
      const beneficiaryFormData = {
        email,
        actualSociety,
        user,
        addressLine,
        cif,
        plan,
        currentPlan,
        date,
        shares,
        name,
        jobTitle,
        laboralCategory,
        salary,
      };
      const newBeneficiary = parseBeneficiaryData(beneficiaryFormData);
      const isCifAlready = isBeneficiaryAlready(
        newBeneficiary.cif,
        newBeneficiary.plan,
        actualSociety
      );
      const hasNotInvitationLetter = hasEmptyInvitationLetter(currentPlan);

      if ([actions.ADD, actions.DUPLICATE].includes(action)) {
        let isValidBeneficiary = true;

        if (!isDraft && hasNotInvitationLetter) {
          return EmptyInvitationLetterAlert(t);
        }

        if (isCifAlready) {
          await BeneficiaryAlreadyExistsAlert(t, cif, currentPlan.name).then(
            (result) => {
              if (!result.isConfirmed) {
                isValidBeneficiary = false;
              }
            }
          );
        }

        if (isValidBeneficiary) {
          const documents = [];

          const uploadedDocument = await uploadBeneficiaryDocument({
            translate: t,
            file,
            date,
            user,
            actualSociety,
          });

          if (uploadedDocument?.['_id']) {
            documents.push(uploadedDocument?.['_id']);
          }

          const fileName = `Carta_de_Invitación_${newBeneficiary.name}_${currentPlan.name}.pdf`;
          let hasSocietySign = false;
          let invitationFile = null;

          if (!isDraft && hasInvitationLetter(currentPlan)) {
            const {
              invitationDocument: currentInvitationDocument,
              invitationFile: currentInvitationFile,
              hasSocietySign: currentSocietySign,
            } = await createInvitationDocument({
              jsPDF,
              translate: t,
              currentPlan,
              newBeneficiary,
              user,
              actualSociety,
              attachmentDocument,
              fileName,
            });

            if (currentInvitationDocument?.['_id']) {
              documents.push(currentInvitationDocument?.['_id']);
            }

            invitationFile = currentInvitationFile;
            hasSocietySign = currentSocietySign;
          }

          const uploadedBeneficiary = await store.dispatch(
            addBeneficiary({
              ...newBeneficiary,
              documents,
            })
          );

          if (!isDraft && hasInvitationLetter(currentPlan)) {
            await createInvitationEnvelope({
              currentPlan,
              newBeneficiary,
              user,
              actualSociety,
              uploadedBeneficiary,
              invitationFile,
              hasSocietySign,
              fileName,
            });
          }

          trackEvent(mixpanel, eventTypes.ADD_BENEFICIARY, {
            userId: user?._id,
            userName: user?.name,
            userEmail: user?.email,
            societyId: actualSociety?._id,
            societyName: actualSociety?.name,
            operation: eventTypes.ADD_BENEFICIARY,
          });

          dispatch(setModal(null));
          dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
          return;
        }
      }

      if ([actions.EDIT].includes(action)) {
        if (isCifAlready) {
          dispatch(addAlert(alertBodyTypes.DUPLICATED_CIF));
          return;
        }

        const consolidationPeriods =
          customConsolidationType === consolidationTypes.BY_PERCENT
            ? consolidationPeriodsPercent
            : consolidationPeriodsUnits;

        const isCustomDilutionByUnits = !!(
          customConsolidationType === consolidationTypes.BY_UNITS
        );

        const updatedBeneficiary = parseUpdatedBeneficiaryData({
          date,
          amount,
          shares,
          laboralCategory,
          salary,
          hasCustomConditions,
          sharePrice,
          isFixedPrice,
          cliff,
          currentPlan,
          vestingPeriod,
          consolidation,
          customConsolidation,
          consolidationPeriods,
          isCustomDilutionByUnits,
          hasEndMonthConsolidation,
          hasDecimalConsolidation,
        });

        await store.dispatch(
          updateBeneficiary({
            id: currentBeneficiary['_id'],
            data: {
              ...updatedBeneficiary,
              userId: user['_id'],
              societyId: actualSociety['_id'],
              cascade: false,
            },
            showAlert: false,
          })
        );

        getActualSociety(user, actualSociety['_id']);
        dispatch(setModal(null));
      }

      if ([actions.WATCH].includes(action)) {
        dispatch(setModal(null));
        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
    } catch (error) {
      console.error('Error saving beneficiary', error);
    }
  };

  function setFormattedShares(value) {
    const numberValue = Number(value);
    if (numberValue > 0 && currentPlanShares >= numberValue) {
      setShares(numberValue);
    }
    if (value === '') {
      setShares();
    }
  }

  useEffect(() => {
    const selectedPlan = stockPlans.find((p) => p['_id'] === plan);
    if (selectedPlan) {
      if (selectedPlan?.hasInvitation) {
        const generatedCode = uuidv4();
        setInvitationCode(generatedCode);
        setInvitationStatusType(invitationStatus.PENDING.value);
      } else {
        setInvitationStatusType('');
      }
    }
    setCurrentPlan(selectedPlan);
    getAttachedDocument(selectedPlan?.invitationAttachedDocument);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  useEffect(() => {
    if (action === actions.WATCH) return;
    if (!customConsolidation) return;

    if (consolidation !== 0.033) {
      const totalPeriods = calculateTotalPeriods(vestingPeriod, consolidation);
      const newConsolidationPeriods = createPeriodsArray(totalPeriods);

      updateConsolidationPeriodsValues(newConsolidationPeriods);
    } else {
      setCustomConsolidation(false);
      setConsolidationPeriodsPercent([]);
      setConsolidationPeriodsUnits([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vestingPeriod, consolidation, customConsolidation]);

  useEffect(() => {
    if (currentPlan) {
      const usedShares = getUsedSharesFromPlan(
        currentPlan,
        actualSociety,
        tenderOffers,
        isDraft
      );

      const categories = currentPlan.laboralCategories;
      let availableShares = currentPlan.sharesTotal - usedShares;

      if (action === actions.EDIT && currentBeneficiary)
        availableShares += currentBeneficiary?.sharesCount?.future;

      setCurrentPlanShares(Math.floor(availableShares)); // round to integer down
      setCurrentPlanLaboralCategories(categories);
      if (action !== actions.EDIT) {
        setSalary('');
        setShares(0);
      }
      setSendInvitation(getCurrentSendInvitation(currentPlan));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, currentPlan]);

  useEffect(() => {
    if (laboralCategory && laboralCategory === 'default_option')
      setLaboralCategory({});
    else if (currentPlan && salary && Object.entries(laboralCategory).length) {
      const category = JSON.parse(laboralCategory);
      const cleanSalary = cleanValue(salary);
      const years = currentPlan?.annualUnits
        ? currentPlan?.vestingPeriod / 12
        : 1;
      if (hasCustomConditions && !isFixedPrice && sharePrice) {
        const units = Math.floor(
          (category.percent * cleanSalary) / 100 / sharePrice
        );
        setShares(Math.floor(units * years));
      } else if (!currentPlan?.isFixedPrice) {
        const units = Math.floor(
          (category.percent * cleanSalary) / 100 / currentPlan?.sharePrice
        );
        setShares(Math.floor(units * years));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salary, laboralCategory, currentPlan, isFixedPrice, sharePrice]);

  useEffect(() => {
    let computedAmount = 0;
    if (hasCustomConditions && sharePrice) {
      computedAmount = isFixedPrice ? sharePrice : shares * sharePrice;
    } else {
      computedAmount = currentPlan?.isFixedPrice
        ? currentPlan?.sharePrice
        : shares * currentPlan?.sharePrice;
    }
    setAmount(computedAmount);
  }, [sharePrice, currentPlan, shares, isFixedPrice, hasCustomConditions]);

  useEffect(() => {
    if (action === actions.DUPLICATE) {
      setPlan(currentBeneficiary?.plan);
      setDate(
        format(new Date(currentBeneficiary?.planStartDate), 'yyyy-MM-dd')
      );
      setShares(currentBeneficiary?.sharesCount?.future);
    }
    if (action === actions.EDIT) {
      setName(currentBeneficiary?.name);
      setCif(currentBeneficiary?.cif);
      setEmail(currentBeneficiary?.email);
      setJobTitle(currentBeneficiary?.jobTitle || '');
      setPlan(currentBeneficiary?.plan);
      if (currentBeneficiary.invitationDate) {
        setInvitationDate(
          format(new Date(currentBeneficiary?.invitationDate), 'yyyy-MM-dd')
        );
      }
      if (currentBeneficiary?.customConditions) {
        setHasCustomConditions(true);
        setIsFixedPrice(currentBeneficiary?.customConditions?.isFixedPrice);
        setSharePrice(currentBeneficiary?.customConditions?.sharePrice);
        setCliff(currentBeneficiary?.customConditions?.cliff);
        setVestingPeriod(currentBeneficiary?.customConditions?.vestingPeriod);
        setCustomConsolidation(
          currentBeneficiary?.customConditions?.customConsolidation
        );
        setHasEndMonthConsolidation(
          currentBeneficiary?.customConditions?.hasEndMonthConsolidation
        );
        setHasDecimalConsolidation(
          currentBeneficiary?.customConditions?.hasDecimalConsolidation
        );
        setConsolidation(currentBeneficiary?.customConditions?.consolidation);

        const { isCustomDilutionByUnits, consolidationPeriods } =
          currentBeneficiary?.customConditions;

        setCustomConsolidationType(
          isCustomDilutionByUnits
            ? consolidationTypes.BY_UNITS
            : consolidationTypes.BY_PERCENT
        );

        if (isCustomDilutionByUnits) {
          setConsolidationPeriodsUnits(consolidationPeriods);
          // consolidationPeriods?.forEach((period, index) => {
          //   calculatePercentFromShares(
          //     index,
          //     period,
          //     currentBeneficiary?.sharesCount?.future
          //   );
          // });
        } else {
          setConsolidationPeriodsPercent(consolidationPeriods);
          // consolidationPeriods?.forEach((period, index) => {
          //   calculateSharesFromPercent(
          //     index,
          //     period,
          //     currentBeneficiary?.sharesCount?.future
          //   );
          // });
        }
      }
      setDate(
        format(new Date(currentBeneficiary?.planStartDate), 'yyyy-MM-dd')
      );
      setShares(currentBeneficiary?.sharesCount?.future);
      setLaboralCategory(
        JSON.stringify(currentBeneficiary?.laboralCategory) || ''
      );
      setSalary(cleanValue(currentBeneficiary?.salary) || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hasShowDetails()) {
    console.log('🚀 cclog ~ currentPlan:', {
      hasInvitation: currentPlan?.hasInvitation,
      sendInvitationToAll: currentPlan?.sendInvitationToAll,
      sendInvitation,
    });
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === actions.EDIT ? t('EditBeneficiary') : t('AddBeneficiary')}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={savePartner}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-name">
                  {t('NameAndSurnameOfTheBenefi')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">
                      {t('NameAndSurname')}
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="beneficiary-name"
                    name="beneficiary-name"
                    value={name}
                    placeholder={t('NameAndSurname')}
                    onChange={(event) => setName(event.target.value)}
                    required
                    disabled={action === actions.EDIT}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-identity">
                  {t('NifPassportNie')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">
                      {t('NifPassportNie')}
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="beneficiary-identity"
                    name="beneficiary-identity"
                    value={cif}
                    onChange={handleChangeCif}
                    placeholder={t('NifNoPassportOrDen')}
                    required
                    disabled={action === actions.EDIT}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-email">
                  {t('BeneficiaryEmail')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('Email')}</span>
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    id="beneficiary-email"
                    name="beneficiary-email"
                    placeholder={t('EmailOfTheNewBeneficiary')}
                    value={email}
                    onChange={handleChangeEmail}
                    pattern={EMAIL_PATTERN}
                    required
                    disabled={action === actions.EDIT}
                  />
                </div>
                {!validEmail && <InputError errorMessage={t('InvalidEmail')} />}
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-address">
                  {t('Address')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('Address')}</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="beneficiary-address"
                    name="beneficiary-address"
                    value={addressLine}
                    placeholder={`${t('Address')} (${t('Optional')})`}
                    onChange={(event) => setAddressLine(event.target.value)}
                    disabled={action === actions.EDIT}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-job">
                  {t('JobTitle')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('JobTitle')}</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="beneficiary-job"
                    name="beneficiary-job"
                    placeholder={t('JobTitleOptional')}
                    value={jobTitle}
                    onChange={(event) => setJobTitle(event.target.value)}
                    disabled={action === actions.EDIT}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-plan">
                  {t('StockOptionsPlan')}
                  <span className="sub-text">
                    {t('SelectTheStockOptionsPlanInclude')}
                  </span>
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control"
                      id="beneficiary-plan"
                      name="beneficiary-plan"
                      value={plan}
                      onChange={(event) => setPlan(event.target.value)}
                      disabled={action === actions.EDIT}
                    >
                      <option value="default_option">{t('Select')}</option>
                      {!isDraft &&
                        stockPlans?.length &&
                        stockPlans
                          ?.filter(
                            (plan) =>
                              !plan?.isDraft &&
                              plan.society === actualSociety['_id']
                          )
                          .map((plan) => (
                            <option value={plan['_id']} key={plan['_id']}>
                              {plan.name}
                            </option>
                          ))}
                      {isDraft &&
                        stockPlans?.length &&
                        stockPlans
                          ?.filter(
                            (plan) =>
                              plan?.isDraft &&
                              plan.society === actualSociety['_id']
                          )
                          .map((plan) => (
                            <option value={plan['_id']} key={plan['_id']}>
                              {plan.name}
                            </option>
                          ))}
                    </select>
                  </div>
                  <div className="row col-md-12">
                    <div className="col-md-8 px-0">
                      <label className="form-label" htmlFor="beneficiary-plan">
                        {t('Conditions')}:
                      </label>
                      {currentPlan && (
                        <span className="ml-1">
                          {t('Vesting')}:{' '}
                          <span className="text-primary">
                            {parseVestingMonths(currentPlan?.vestingPeriod)}
                          </span>{' '}
                          | {t('Cliff')}:{' '}
                          <span className="text-primary">
                            {parseCliffMonths(currentPlan?.cliff)}
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="custom-control custom-control-xs custom-checkbox mt-1">
                        <input
                          type="checkbox"
                          id="checkbox-conditions"
                          className="custom-control-input"
                          value={hasCustomConditions}
                          checked={hasCustomConditions}
                          disabled={action === actions.WATCH}
                          onChange={(event) =>
                            setHasCustomConditions(event.target.checked)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-conditions"
                        >
                          {t('EditConditionsForBenef')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {hasCustomConditions ? (
                <div className="card card-bordered mb-2">
                  <div className="card-inner">
                    <div className="form-group">
                      {isFixedPrice ? (
                        <label className="form-label" htmlFor="pay-amount">
                          {t('FixedPriceExercisePrice')}
                        </label>
                      ) : (
                        <label className="form-label" htmlFor="pay-amount">
                          {t('PricePerUnitExercisePrice')}
                        </label>
                      )}
                      <div className="form-control-wrap">
                        <div className="form-text-hint">
                          <span className="overline-title">Eur</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          value={sharePrice}
                          onChange={(event) =>
                            setSharePrice(+event.target.value)
                          }
                          disabled={action === actions.WATCH}
                          onWheel={(event) => event.target.blur()}
                          min="0"
                          step="0.000000001"
                          placeholder={
                            isFixedPrice
                              ? t('FixedPriceToPay')
                              : t('PriceToPayForEachUnit')
                          }
                        />
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div className="custom-control-sm custom-switch float-right d-flex align-content-center">
                            <span
                              style={{
                                marginRight: '45px',
                                fontSize: '12px',
                                lineHeight: '1.125rem',
                                paddingTop: '0',
                              }}
                            >
                              {t('FixedPrice')}
                            </span>
                            <input
                              type="checkbox"
                              id="price-switch"
                              className="custom-control-input float-right"
                              checked={!isFixedPrice}
                              disabled={action === actions.WATCH}
                              onChange={(event) =>
                                setIsFixedPrice(!event.target.checked)
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="price-switch"
                            >
                              {t('PriceByUnit')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="default-06">
                        {t('Cliff')}
                      </label>
                      <span className="sub-text mb-3">
                        {t('MinimumPermanenceTimeThat')}:
                      </span>
                      <div className="form-control-wrap">
                        <div className="form-control-select">
                          <select
                            className="form-control"
                            value={cliff}
                            disabled={action === actions.WATCH}
                            onChange={(event) => setCliff(+event.target.value)}
                          >
                            {cliffOptions.map((cliff) => (
                              <option
                                value={cliff.value}
                                key={JSON.stringify(cliff)}
                              >
                                {cliff.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="default-01">
                        {t('VestingConsolidationPeriod')}
                      </label>
                      <span className="sub-text mb-3">
                        {t('TheBeneficiariesWillBeEnt')}:
                      </span>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <select
                            className="form-control"
                            value={vestingPeriod}
                            disabled={action === actions.WATCH}
                            onChange={(event) =>
                              setVestingPeriod(+event.target.value)
                            }
                          >
                            <option value="default-option">
                              {t('Select')}
                            </option>
                            {vestingOptions.map((vesting) => (
                              <option
                                value={+vesting.value}
                                key={JSON.stringify(vesting)}
                              >
                                {vesting.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label" htmlFor="default-01">
                        {t('ConsolidationSpeed')}
                      </label>
                      <span className="sub-text mb-3">
                        {t('PeriodOfTimeItTakesToCons')}
                      </span>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <select
                            className="form-control"
                            value={+consolidation}
                            disabled={action === actions.WATCH}
                            onChange={(event) =>
                              setConsolidation(+event.target.value)
                            }
                          >
                            <option value="default-option">
                              {t('Select')}
                            </option>
                            {consolidationOptions.map((option) => (
                              <option
                                value={+option.value}
                                key={JSON.stringify(option)}
                                disabled={option?.value > vestingPeriod}
                              >
                                {option.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="custom-control custom-control-xs custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          id="checkbox-end-month-consolidation"
                          className="custom-control-input"
                          value={hasEndMonthConsolidation}
                          checked={hasEndMonthConsolidation}
                          disabled={
                            !vestingPeriod ||
                            vestingPeriod === 'default-option' ||
                            !consolidation ||
                            consolidation === 0.033 ||
                            +vestingPeriod < +consolidation ||
                            action === actions.WATCH
                          }
                          onChange={handleChangeEndMonthConsolidation}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-end-month-consolidation"
                        >
                          {t('ConsolidateAtEndOfMonth')}
                        </label>
                      </div>

                      <div className="custom-control custom-control-xs custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          id="checkbox-decimal-consolidation"
                          className="custom-control-input"
                          value={hasDecimalConsolidation}
                          checked={hasDecimalConsolidation}
                          disabled={
                            !vestingPeriod ||
                            vestingPeriod === 'default-option' ||
                            !consolidation ||
                            consolidation === 0.033 ||
                            +vestingPeriod < +consolidation ||
                            action === actions.WATCH
                          }
                          onChange={(event) =>
                            setHasDecimalConsolidation(event.target.checked)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-decimal-consolidation"
                        >
                          {t('ConsolidateAsUnitsAccumulatedDecimals')}
                        </label>
                      </div>

                      <div className="custom-control custom-control-xs custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          id="checkbox-consolidation-period"
                          className="custom-control-input"
                          value={customConsolidation}
                          checked={customConsolidation}
                          disabled={
                            !vestingPeriod ||
                            vestingPeriod === 'default-option' ||
                            !consolidation ||
                            consolidation === 0.033 ||
                            +vestingPeriod < +consolidation ||
                            action === actions.WATCH
                          }
                          onChange={(event) =>
                            setCustomConsolidation(event.target.checked)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-consolidation-period"
                        >
                          {t('CustomizeTheDifferentCons')}
                        </label>
                      </div>
                    </div>

                    {customConsolidation && (
                      <div className="card card-bordered card-preview mb-3">
                        <div className="card-inner row">
                          <div className="col-6">
                            <div className="custom-control custom-control-xs custom-checkbox mb-3">
                              <input
                                type="checkbox"
                                id="checkbox-period-by-percent"
                                className="custom-control-input"
                                checked={
                                  customConsolidationType ===
                                  consolidationTypes.BY_PERCENT
                                }
                                onChange={handleSelectPeriodsByPercent}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkbox-period-by-percent"
                              >
                                {t('ByPercents')}
                              </label>
                            </div>

                            {consolidationPeriodsPercent.map(
                              (period, index) => (
                                <div
                                  className="form-group"
                                  key={`period-by-percent-${index + 1}`}
                                >
                                  <label
                                    className="form-label"
                                    htmlFor="default-01"
                                  >
                                    {t('Section')} {index + 1}
                                  </label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={period}
                                      disabled={isByPercentDisabled()}
                                      onChange={(event) =>
                                        handleConsolidationPeriodsChange(
                                          index,
                                          event.target.value,
                                          consolidationTypes.BY_PERCENT
                                        )
                                      }
                                      onWheel={(event) => event.target.blur()}
                                      min="0"
                                      step="0.000000001"
                                      placeholder="%"
                                      required
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>

                          <div className="col-6">
                            <div className="custom-control custom-control-xs custom-checkbox mb-3">
                              <input
                                type="checkbox"
                                id="checkbox-period-by-units"
                                className="custom-control-input"
                                checked={
                                  customConsolidationType ===
                                  consolidationTypes.BY_UNITS
                                }
                                onChange={handleSelectPeriodsByUnits}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkbox-period-by-units"
                              >
                                {t('ByUnits')}
                              </label>
                            </div>

                            {consolidationPeriodsUnits.map((period, index) => (
                              <div
                                className="form-group"
                                key={`period-by-units-${index + 1}`}
                              >
                                <label
                                  className="form-label"
                                  htmlFor="default-01"
                                >
                                  {t('Section')} {index + 1}
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={period}
                                    disabled={isByUnitsDisabled()}
                                    onChange={(event) =>
                                      handleConsolidationPeriodsChange(
                                        index,
                                        event.target.value,
                                        consolidationTypes.BY_UNITS
                                      )
                                    }
                                    onWheel={(event) => event.target.blur()}
                                    min="0"
                                    step="0.000000001"
                                    placeholder="%"
                                    required
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {currentPlan?.hasLaboralCategories && (
                <div className="row">
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="laboral-category">
                      {t('LaboralCategory')}
                    </label>
                    <div className="form-control-wrap ">
                      <div className="form-control-select">
                        <select
                          className="form-control"
                          id="laboral-category"
                          name="laboral-category"
                          value={laboralCategory}
                          onChange={(event) =>
                            setLaboralCategory(event.target.value)
                          }
                        >
                          <option value="default_option">{t('Select')}</option>
                          {currentPlanLaboralCategories?.length > 0 &&
                            currentPlanLaboralCategories.map((category) => (
                              <option
                                value={JSON.stringify({
                                  name: category.name,
                                  percent: category.percent,
                                })}
                                key={category['_id']}
                              >
                                {category.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="user-salary">
                      {t('AnnualSalary')}
                    </label>
                    <div className="form-control-wrap ">
                      <div className="form-text-hint">
                        <span className="overline-title">EUR</span>
                      </div>
                      <NumberFormat
                        id="outlined-normal"
                        className="form-control form-control-outlined"
                        value={salary}
                        onChange={(e) => setSalary(e.target.value)}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...numberFormat}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-plan-date">
                  {t('DateOfConcessionOfTheBene')}
                </label>
                <div className="form-control-wrap">
                  <input
                    id="beneficiary-plan-date"
                    name="beneficiary-plan-date"
                    type="date"
                    className="form-control date-picker"
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                    required
                    max="2100-01-01T00:00"
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-plan-shares">
                  {t('UnitsGrantedToTheBenefici')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    {plan && (
                      <span className="overline-title">
                        /{currentPlanShares}
                      </span>
                    )}
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="beneficiary-plan-shares"
                    name="beneficiary-plan-shares"
                    value={shares}
                    disabled={
                      !currentPlanShares ||
                      (salary && Object.entries(laboralCategory).length)
                    }
                    placeholder={t('TotalShares')}
                    onChange={(event) => setFormattedShares(event.target.value)}
                    onWheel={(event) => event.target.blur()}
                    min="0"
                  />
                </div>
                <label className="form-label" htmlFor="beneficiary-plan-shares">
                  {currentPlan?.isFixedPrice
                    ? `(*) ${t('FixedPrice')}:`
                    : `(*) ${t('UnitPrice')}:`}
                  &nbsp;
                </label>
                <span className="text-primary">
                  <NumberFormat
                    value={
                      hasCustomConditions && sharePrice
                        ? sharePrice
                        : currentPlan?.sharePrice || ''
                    }
                    displayType="text"
                    {...currencyFormatDecimals}
                  />
                </span>
                <label className="form-label" htmlFor="beneficiary-plan-shares">
                  &nbsp;| {t('TotalToPay')}:&nbsp;
                </label>
                <span className="text-primary">
                  <NumberFormat
                    value={amount}
                    displayType="text"
                    {...currencyFormatDecimals}
                  />
                </span>
              </div>

              {currentPlan?.hasInvitation && (
                <div className="form-group">
                  <label className="form-label" htmlFor="beneficiary-inv-date">
                    {t('InvitationDate')}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      id="beneficiary-inv-date"
                      name="beneficiary-inv-date"
                      type="date"
                      className="form-control date-picker"
                      value={invitationDate}
                      onChange={(event) =>
                        setInvitationDate(event.target.value)
                      }
                      max="2100-01-01T00:00"
                    />
                  </div>
                </div>
              )}

              {action !== actions.EDIT && (
                <>
                  <div className="form-group">
                    <label
                      className="form-label"
                      htmlFor="beneficiary-plan-file"
                    >
                      {t('AddDocument')}
                    </label>
                    <div className="form-control-wrap">
                      <div className="custom-file">
                        <input
                          id="beneficiary-plan-file"
                          name="beneficiary-plan-file"
                          type="file"
                          accept="application/pdf"
                          className="custom-file-input"
                          onChange={(event) => setFile(event.target.files[0])}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          {file?.name || t('SelectDocument')}
                        </label>
                      </div>
                    </div>
                  </div>

                  {currentPlan?.hasInvitation && (
                    <div className="custom-control custom-control-xs custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        id="checkbox-send-to-all"
                        className="custom-control-input"
                        value={sendInvitation}
                        checked={sendInvitation}
                        disabled={!currentPlan}
                        onChange={(event) =>
                          setSendInvitation(event.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label mb-2"
                        htmlFor="checkbox-send-to-all"
                      >
                        {t('SendInvitationLetterToThisBeneficiary')}
                      </label>
                    </div>
                  )}
                </>
              )}

              <span className="sub-text text-dark">
                {t('ThisPriceIsSubjectToThePlanAndLetter')}
              </span>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={
              !name ||
              !cif ||
              !email ||
              !plan ||
              !date ||
              !shares ||
              !validEmail
            }
          >
            {action === actions.EDIT ? t('Save') : t('AddBeneficiary')}
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">{t('TheBeneficiaryWillReceive')}</span>
      </Modal.Footer>
    </>
  );
}

export default AddBeneficiaryModal;
