/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
import { useTranslate } from 'hooks/useTranslate';
import bigDecimal from 'js-big-decimal';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';
import Swal from 'sweetalert2';

import { setModal } from 'modules/_shared/redux/modalActions';
import {
  phantomSharesAction,
  stockOptionAction,
  warrantAction,
} from 'modules/_shared/redux/modalsActions';
import { updateStockPlan } from 'modules/beneficiaries/redux/planActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import { updateUser } from 'modules/profile/redux/userActions';
import { updateSociety } from 'modules/society/redux/societyActions';

import hasPlanCustomDilution from 'utils/customDilution';
import {
  getActualSociety,
  getSocietyActualShares,
  getSocietyTotalShares,
  getUsedSharesFromPlan,
} from 'utils/filters';
import { yearNmonthDay } from 'utils/formatDate';
import trackEvent from 'utils/trackEvent';

import cliffOptions from 'constants/cliffOptions';
import consolidationOptions from 'constants/consolidationOptions';
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import operationTypes from 'constants/operationTypes';
import poolTypes from 'constants/poolTypes';
import sizes from 'constants/sizes';
import userTypes from 'constants/userTypes';
import vestingOptions from 'constants/vestingOptions';

import ReactDualList from 'modules/_shared/components/DualList';

import '../Modals.scss';
import PlanInvitation from '../PlanInvitation';

const categoryTypes = {
  NAME: 'name',
  PERCENT: 'percent',
};

function StockOptionsModal({ action = 'add', currentPlan }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const user = useSelector((state) => state.user);
  const plans = useSelector((state) => state.plans);
  const actualSociety = useSelector((state) => state.society.actualSociety);
  const beneficiaries = useSelector(
    (state) => state.society.actualSociety.beneficiaries
  );
  const tenderOffers = useSelector((state) => state?.tenderOffers);

  const [planType, setPlanType] = useState();
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState();
  const [sharePrice, setSharePrice] = useState();
  const [isFixedPrice, setIsFixedPrice] = useState(false);
  const [cliff, setCliff] = useState(0);
  const [vestingPeriod, setVestingPeriod] = useState();
  const [hasEndMonthConsolidation, setHasEndMonthConsolidation] =
    useState(false);
  const [hasDecimalConsolidation, setHasDecimalConsolidation] = useState(false);
  const [consolidation, setConsolidation] = useState(
    consolidationOptions[0].value
  );
  const [customConsolidation, setCustomConsolidation] = useState(false);
  const [consolidationPeriods, setConsolidationPeriods] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [consolidationTotalPeriods, setConsolidationTotalPeriods] = useState(0);
  const [poolByPercent, setPoolByPercent] = useState(true);
  const [poolType, setPoolType] = useState(poolTypes.PRE_MONEY);
  const [pool, setPool] = useState('');
  const [isValidPool, setIsValidPool] = useState(true);
  const [file, setFile] = useState();
  const [societyShares, setSocietyShares] = useState();
  const [futureSocietyShares, setFutureSocietyShares] = useState();
  const [currentPlanShares, setCurrentPlanShares] = useState('');
  const [laboralCategories, setLaboralCategories] = useState([
    { name: '', percent: '' },
  ]);
  const [hasLaboralCategories, setHasLaboralCategories] = useState(false);
  const [annualUnits, setAnnualUnits] = useState(true);
  const [isDraft, setIsDraft] = useState(false);
  const [hasInvitation, setHasInvitation] = useState(false);
  const [sendInvitationToAll, setSendInvitationToAll] = useState(true);
  const [hasCustomDilution, setHasCustomDilution] = useState(false);
  const [customDiluents, setCustomDiluents] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [usedShares, setUsedShares] = useState(0);
  const [usedLaboralCategories, setUsedLaboralCategories] = useState([]);

  const modalTitle = {
    add: t('AddStockPhantomsPlan'),
    edit: t('UpdatePlan'),
    watch: t('PlanDetail'),
  };

  async function saveStockOption(event) {
    event.preventDefault();
    try {
      const finalLaboralCategories =
        !laboralCategories[laboralCategories.length - 1].name ||
        !laboralCategories[laboralCategories.length - 1].percent
          ? laboralCategories.slice(0, laboralCategories.length - 1)
          : laboralCategories;

      if (action === 'add') {
        let uploadedDocument = null;

        if (file) {
          const newDocument = {
            file,
            date: new Date(),
            size: file.size,
            name: operationTypes.STOCK_OPTION_FILENAME,
            author: user['_id'],
            society: actualSociety['_id'],
            fileType: fileTypes[file.type],
            category: documentTypes.SCRIPTURES,
            description: t('DocumentLinkedToOperation'),
          };
          uploadedDocument = await store.dispatch(addDocument(newDocument));
        }

        const documentId = uploadedDocument?.['_id'];

        const newOperation = {
          planType,
          name,
          startDate,
          sharePrice,
          isFixedPrice,
          cliff,
          vestingPeriod,
          consolidation,
          customConsolidation,
          consolidationPeriods,
          hasLaboralCategories,
          isDraft,
          annualUnits,
          hasInvitation,
          sendInvitationToAll,
          invitationModel: hasInvitation ? { html: '' } : null,
          laboralCategories: finalLaboralCategories,
          pool,
          poolType,
          sharesTotal: currentPlanShares,
          hasCustomDilution,
          hasDecimalConsolidation,
          hasEndMonthConsolidation,
          customDiluents,
          society: actualSociety['_id'],
          user: user['_id'],
          documents: documentId ? [documentId] : [],
        };

        const newPlan = await store.dispatch(
          planType === operationTypes.STOCK_OPTION
            ? stockOptionAction(newOperation)
            : planType === operationTypes.WARRANT
            ? warrantAction(newOperation)
            : phantomSharesAction(newOperation)
        );

        if (hasCustomDilution) {
          const allPartners = actualSociety?.partners || [];

          if (allPartners.length) {
            const partnersToUpdate = allPartners.filter(
              (partner) => !customDiluents.includes(partner._id)
            );

            // eslint-disable-next-line no-restricted-syntax
            for (const partner of partnersToUpdate) {
              await store.dispatch(
                updateUser(
                  partner?.user,
                  {
                    partner: partner._id,
                    society: actualSociety._id,
                    partnerData: {
                      email: partner.email,
                      isNotDiluted: true,
                    },
                  },
                  user._id,
                  false
                )
              );
            }
          }
        }

        const currentEvent =
          planType === operationTypes.STOCK_OPTION
            ? eventTypes.STOCK_OPTION
            : eventTypes.PHANTOM_SHARES;

        trackEvent(mixpanel, currentEvent, {
          userId: user?._id,
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?._id,
          societyName: actualSociety?.name,
          operation: currentEvent,
        });

        getActualSociety(user, actualSociety?.['_id']);

        if (hasInvitation) {
          dispatch(
            setModal(
              <PlanInvitation
                plan={newPlan}
                society={actualSociety}
                size={sizes.XL}
              />
            )
          );
        } else {
          dispatch(setModal(null));
        }
      } else {
        const newPlanData = {
          name,
          sharePrice,
          isFixedPrice,
          pool,
          poolType,
          sharesTotal: currentPlanShares,
          sendInvitationToAll,
          customConsolidation,
          consolidationPeriods,
          hasLaboralCategories,
          laboralCategories: finalLaboralCategories,
          hasDecimalConsolidation,
          hasEndMonthConsolidation,
        };
        await store.dispatch(
          updateStockPlan(currentPlan['_id'], newPlanData, false)
        );
        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t(
            'PlanUpdatedSuccessfully'
          )}<br></h4>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
        dispatch(setModal(null));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `<h4 class="nk-modal-title">${t('Error')}</h4>`,
        confirmButtonText: t('Accept'),
        confirmButtonColor: '#6576FF',
        allowOutsideClick: false,
        showCancelButton: false,
      });
    }
  }

  function setFormattedPool(value) {
    if (value === '') {
      setPool('');
      setCurrentPlanShares('');
      setIsValidPool(true);
    } else if (poolByPercent && +value > 0 && +value <= 100) {
      setPool(+value);
      let computedShares;
      if (poolType === poolTypes.PRE_MONEY) {
        computedShares = Math.floor((societyShares * +value) / 100);
      } else
        computedShares = Math.floor(
          (futureSocietyShares * +value) / (100 - +value)
        );
      setCurrentPlanShares(computedShares);
      if (computedShares >= 1) setIsValidPool(true);
    } else if (
      !poolByPercent &&
      Number.isInteger(+value) &&
      poolType === poolTypes.PRE_MONEY &&
      +value >= 1 &&
      +value <= societyShares
    ) {
      setCurrentPlanShares(+value);
      const computedPool = +bigDecimal.divide(+value * 100, societyShares);
      setPool(+computedPool);
      setIsValidPool(true);
    } else if (
      !poolByPercent &&
      Number.isInteger(+value) &&
      poolType === poolTypes.POST_MONEY &&
      +value >= 1 &&
      +value <= societyShares * 2
    ) {
      setCurrentPlanShares(+value);
      const computedPool = +bigDecimal.divide(
        +value * 100,
        futureSocietyShares + +value
      );
      setPool(+computedPool);
      setIsValidPool(true);
    } else {
      setIsValidPool(false);
      setPool(+value);
      setCurrentPlanShares('');
    }
  }

  const getPartnersOptions = (society) => {
    if (!society?.partners?.length) return [];

    const options =
      society.partners.map((option) => ({
        value: option?._id,
        label: option?.name,
        type: userTypes.PARTNER,
      })) || [];

    return options;
  };

  const getDiluentInfo = (diluentId) => {
    const diluent = actualSociety.partners?.find(
      (partner) => partner._id === diluentId
    );
    return {
      cif: diluent?.cif || '-',
      name: diluent?.name || '-',
    };
  };

  const getNullPeriods = (totalPeriods) =>
    Array.from({ length: totalPeriods }, () => 0);

  const handleSetInvitation = (value) => {
    if (value) {
      if (actualSociety?.sendNotifications) {
        setHasInvitation(value);
      } else {
        Swal.fire({
          icon: 'warning',
          title: `<h4 class="nk-modal-title">${t(
            'SocietySendNotificationsOff'
          )}</h4>`,
          confirmButtonText: t('Accept'),
          cancelButtonText: t('Cancel'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(
              updateSociety(actualSociety['_id'], { sendNotifications: true })
            );
            setHasInvitation(value);
          } else {
            setHasInvitation(false);
          }
        });
      }
    } else {
      setHasInvitation(value);
    }
  };

  const handleChangeEndMonthConsolidation = () => {
    setHasEndMonthConsolidation(!hasEndMonthConsolidation);
  };

  const handleConsolidationPeriodsChange = (index, value) => {
    const newConsolidationPeriods = [...consolidationPeriods];
    newConsolidationPeriods[index] = +value;
    setConsolidationPeriods(newConsolidationPeriods);
  };

  const handleLaboralCategoriesChange = (index, property, value) => {
    const newLaboralCategories = [...laboralCategories];
    newLaboralCategories[index][property] = value;
    // if (property === 'name' && laboralCategories.some((category) => category.name === value))
    //   newLaboralCategories[index][property] = '';
    setLaboralCategories(newLaboralCategories);
  };

  const handleAddCategory = () => {
    setLaboralCategories([...laboralCategories, { name: '', percent: '' }]);
  };

  const handleRemoveCategory = (index) => {
    const newLaboralCategories = [...laboralCategories];
    newLaboralCategories.splice(index, 1);
    setLaboralCategories(newLaboralCategories);
  };

  useEffect(() => {
    if (actualSociety) {
      setSocietyShares(getSocietyActualShares(actualSociety));
      setFutureSocietyShares(getSocietyTotalShares(actualSociety));
      setPartnersList(getPartnersOptions(actualSociety));
    }
  }, [actualSociety]);

  useEffect(() => {
    if (action === 'add' || action === 'edit') {
      if (customConsolidation) {
        if (consolidation !== 0.033) {
          const totalPeriods = Math.ceil(+vestingPeriod / +consolidation);

          setConsolidationTotalPeriods(totalPeriods);

          consolidationPeriods.length = totalPeriods;
          if (action === 'add') {
            const newConsolidationPeriods = getNullPeriods(totalPeriods);
            setConsolidationPeriods(newConsolidationPeriods);
          }
        } else {
          setCustomConsolidation(false);
          setHasDecimalConsolidation(false);
          setConsolidationTotalPeriods(0);
          setConsolidationPeriods([]);
        }
      } else {
        setConsolidationTotalPeriods(0);
        setConsolidationPeriods([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vestingPeriod, consolidation, customConsolidation]);

  useEffect(() => {
    if (!hasLaboralCategories) {
      setLaboralCategories([{ name: '', percent: '' }]);
    }
  }, [hasLaboralCategories]);

  useEffect(() => {
    if (action === 'watch' || action === 'edit') {
      setPlanType(currentPlan?.planType);
      setName(currentPlan?.name);
      setCurrentPlanShares(currentPlan?.sharesTotal);
      setStartDate(yearNmonthDay(currentPlan?.startDate));
      setSharePrice(currentPlan?.sharePrice);
      setIsFixedPrice(currentPlan?.isFixedPrice);
      setIsDraft(currentPlan?.isDraft);
      setCliff(currentPlan?.cliff);
      setVestingPeriod(currentPlan?.vestingPeriod);
      setConsolidation(currentPlan?.consolidation);
      setCustomConsolidation(currentPlan?.customConsolidation);
      setConsolidationPeriods(currentPlan?.consolidationPeriods);
      setHasDecimalConsolidation(currentPlan?.hasDecimalConsolidation);
      setPool(currentPlan?.pool);
      setPoolType(currentPlan?.poolType);
      setAnnualUnits(currentPlan?.annualUnits);
      setHasLaboralCategories(currentPlan?.hasLaboralCategories);
      setLaboralCategories(
        currentPlan?.laboralCategories?.length > 0
          ? [
              ...(currentPlan?.laboralCategories ?? []),
              { name: '', percent: '' },
            ]
          : [{ name: '', percent: '' }]
      );
      setHasCustomDilution(currentPlan?.hasCustomDilution);
      setHasEndMonthConsolidation(currentPlan?.hasEndMonthConsolidation);
      setCustomDiluents(currentPlan?.customDiluents);
      setHasInvitation(currentPlan?.hasInvitation);
      setSendInvitationToAll(currentPlan?.sendInvitationToAll || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan]);

  useEffect(() => {
    const usedShares = getUsedSharesFromPlan(
      currentPlan,
      actualSociety,
      tenderOffers
    );
    setUsedShares(usedShares || 0);
  }, [actualSociety, currentPlan, tenderOffers]);

  useEffect(() => {
    if (currentPlan && beneficiaries.length) {
      const usedCatogories = [];
      const filteredBeneficiaries = beneficiaries.filter(
        (beneficiary) => beneficiary.plan === currentPlan?.['_id']
      );
      currentPlan?.laboralCategories?.forEach((category) => {
        filteredBeneficiaries?.forEach((beneficiary) => {
          if (
            category?.name.toUpperCase() ===
            beneficiary?.laboralCategory?.name.toUpperCase()
          ) {
            usedCatogories.push(true);
          } else usedCatogories.push(false);
        });
      });
      setUsedLaboralCategories(usedCatogories);
    }
  }, [currentPlan, beneficiaries]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{modalTitle[action]}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form
          id="form-stock-plan"
          className="form-validate is-alter"
          onSubmit={(event) => saveStockOption(event)}
        >
          {/* PLAN TYPE */}
          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              {t('SelectTheTypeOfPlan')}
            </label>
            <div className="row">
              <div className="col-md-4">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="plan-stock"
                    id="plan-stock"
                    value={operationTypes.STOCK_OPTION}
                    checked={planType === operationTypes.STOCK_OPTION}
                    disabled={action === 'watch' || action === 'edit'}
                    onChange={(event) => setPlanType(event.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="plan-stock">
                    {t('StockOptions')}
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="plan-phantom"
                    id="plan-phantom"
                    value={operationTypes.PHANTOM_SHARE}
                    checked={planType === operationTypes.PHANTOM_SHARE}
                    disabled={action === 'watch' || action === 'edit'}
                    onChange={(event) => setPlanType(event.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="plan-phantom"
                  >
                    {t('PhantomShares')}
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="plan-warrant"
                    id="plan-warrant"
                    value={operationTypes.WARRANT}
                    checked={planType === operationTypes.WARRANT}
                    disabled={action === 'watch' || action === 'edit'}
                    onChange={(event) => setPlanType(event.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="plan-warrant"
                  >
                    {t('Warrants')}
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="custom-control custom-control-xs custom-checkbox mt-3">
                  <input
                    type="checkbox"
                    id="checkbox-draft"
                    className="custom-control-input"
                    value={isDraft}
                    checked={isDraft}
                    disabled={action === 'watch' || action === 'edit'}
                    onChange={(event) => setIsDraft(event.target.checked)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox-draft"
                  >
                    {t('CreatePlanAsDraft')}
                  </label>
                </div>
              </div>
              {isDraft ? (
                <div className="col-md-12">
                  <span className="sub-text text-primary mt-1">
                    {t('ThisPlanDoesntCountForFD')}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <hr />

          {/* PLAN BASIC INFORMATION */}
          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              {t('PlanName')}
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">{t('PlanName')}</span>
              </div>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(event) => setName(event.target.value)}
                disabled={action === 'watch'}
                placeholder={t('PEPlanQNewPlanEtc')}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('DateStartOfThePlan')}
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control date-picker"
                value={startDate}
                disabled={action === 'watch' || action === 'edit'}
                onChange={(event) => setStartDate(event.target.value)}
                required
              />
            </div>
          </div>

          {/* STRIKE PRICE */}
          <div className="form-group">
            {isFixedPrice ? (
              <label className="form-label" htmlFor="pay-amount">
                {t('FixedPriceExercisePrice')}
              </label>
            ) : (
              <label className="form-label" htmlFor="pay-amount">
                {t('PricePerUnitExercisePrice')}
              </label>
            )}
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">Eur</span>
              </div>
              {/* TODO: revisar porque tenderOffers > 0 bloquea el precio */}
              <input
                type="number"
                className="form-control"
                value={sharePrice}
                onChange={(event) => setSharePrice(event.target.value)}
                disabled={
                  action === 'watch' ||
                  (tenderOffers?.length > 0 && action === 'edit')
                }
                onWheel={(event) => event.target.blur()}
                min="0"
                step="0.000000001"
                placeholder={
                  isFixedPrice
                    ? t('FixedPriceToPay')
                    : t('PriceToPayForEachUnit')
                }
                required
              />
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <div className="custom-control-sm custom-switch float-right d-flex align-content-center">
                  <span
                    style={{
                      marginRight: '45px',
                      fontSize: '12px',
                      lineHeight: '1.125rem',
                      paddingTop: '0',
                    }}
                  >
                    {t('FixedPrice')}
                  </span>
                  {/* TODO: revisar porque tenderOffers > 0 bloquea el precio */}
                  <input
                    type="checkbox"
                    id="price-switch"
                    className="custom-control-input float-right"
                    checked={!isFixedPrice}
                    disabled={
                      action === 'watch' ||
                      (tenderOffers?.length > 0 && action === 'edit')
                    }
                    onChange={(event) => setIsFixedPrice(!event.target.checked)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="price-switch"
                  >
                    {t('PriceByUnit')}
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* LABORAL CATEGORIES */}
          <div className="form-group">
            <div className="custom-control custom-control-xs custom-checkbox mt-1">
              <input
                type="checkbox"
                id="checkbox-categories"
                className="custom-control-input"
                value={hasLaboralCategories}
                checked={hasLaboralCategories}
                disabled={!name || action === 'watch'}
                onChange={(event) =>
                  setHasLaboralCategories(event.target.checked)
                }
              />
              <label
                className="custom-control-label mb-2"
                htmlFor="checkbox-categories"
              >
                {t('IncludeSectionsByLaborCat')}
              </label>
            </div>

            {hasLaboralCategories && (
              <>
                {laboralCategories.map((category, index) => (
                  <div className="form-control-wrap pb-1" key={index}>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon3">
                          {t('Category')} {index + 1}
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id={`category-${index + 1}`}
                        value={laboralCategories[index].name}
                        disabled={
                          action === 'watch' || usedLaboralCategories[index]
                        }
                        onChange={(event) =>
                          handleLaboralCategoriesChange(
                            index,
                            categoryTypes.NAME,
                            event.target.value
                          )
                        }
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t('%Salary')}</span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        id={`salary-${index + 1}`}
                        size="3"
                        value={laboralCategories[index].percent}
                        disabled={
                          action === 'watch' || usedLaboralCategories[index]
                        }
                        onChange={(event) =>
                          handleLaboralCategoriesChange(
                            index,
                            categoryTypes.PERCENT,
                            event.target.value
                          )
                        }
                        onWheel={(event) => event.target.blur()}
                      />

                      {laboralCategories.length > 1 &&
                      index < laboralCategories.length - 1 ? (
                        <button
                          className="btn btn-outline-primary btn-dim border-radius-0 col-4"
                          type="button"
                          disabled={
                            action === 'watch' || usedLaboralCategories[index]
                          }
                          onClick={() => handleRemoveCategory(index)}
                        >
                          <em className="icon ni ni-minus" />
                          <span>{t('DeleteCategory')}</span>
                        </button>
                      ) : (
                        <button
                          className="btn btn-outline-primary btn-dim border-radius-0 col-4"
                          type="button"
                          disabled={
                            !category.name ||
                            !category.percent ||
                            action === 'watch'
                          }
                          onClick={() => handleAddCategory()}
                        >
                          <em className="icon ni ni-plus" />
                          <span>{t('AddCategory')}</span>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                {action !== 'watch' && (
                  <span className="sub-text mt-1">
                    {t('TheyWillBeAdded')}{' '}
                    <b className="text-primary">
                      {!laboralCategories[laboralCategories.length - 1].name ||
                      !laboralCategories[laboralCategories.length - 1].percent
                        ? laboralCategories.length - 1
                        : laboralCategories.length}
                    </b>{' '}
                    {t('Categories')}
                  </span>
                )}

                <div className="custom-control custom-control-xs custom-checkbox mt-1">
                  <input
                    type="checkbox"
                    id="checkbox-anual-units"
                    className="custom-control-input"
                    value={annualUnits}
                    checked={annualUnits}
                    disabled={action === 'watch'}
                    onChange={(event) => setAnnualUnits(event.target.checked)}
                  />
                  <label
                    className="custom-control-label mb-2"
                    htmlFor="checkbox-anual-units"
                  >
                    {t('AnnualUnitsCondition')}
                  </label>
                </div>
              </>
            )}
          </div>

          {/* PLAN CONFIGURATION */}
          <div className="form-group">
            <label className="form-label" htmlFor="default-06">
              {t('Cliff')}
            </label>
            <span className="sub-text mb-3">
              {t('MinimumPermanenceTimeThat')}:
            </span>
            <div className="form-control-wrap">
              <div className="form-control-select">
                <select
                  className="form-control"
                  value={cliff}
                  disabled={action === 'watch' || action === 'edit'}
                  onChange={(event) => setCliff(+event.target.value)}
                >
                  {cliffOptions.map((cliff) => (
                    <option value={cliff.value} key={JSON.stringify(cliff)}>
                      {cliff.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              {t('VestingConsolidationPeriod')}
            </label>
            <span className="sub-text mb-3">
              {t('TheBeneficiariesWillBeEnt')}:
            </span>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select
                  className="form-control"
                  value={vestingPeriod}
                  disabled={action === 'watch' || action === 'edit'}
                  onChange={(event) => setVestingPeriod(+event.target.value)}
                >
                  <option value="default-option">{t('Select')}</option>
                  {vestingOptions.map((vesting) => (
                    <option
                      value={+vesting.value}
                      key={JSON.stringify(vesting)}
                    >
                      {vesting.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              {t('ConsolidationSpeed')}
            </label>
            <span className="sub-text mb-3">
              {t('PeriodOfTimeItTakesToCons')}
            </span>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select
                  className="form-control"
                  value={+consolidation}
                  disabled={action === 'watch' || action === 'edit'}
                  onChange={(event) => setConsolidation(+event.target.value)}
                >
                  {consolidationOptions.map((option) => (
                    <option
                      value={+option.value}
                      key={JSON.stringify(option)}
                      selected={option.value === consolidationOptions[0].value}
                      disabled={option?.value > vestingPeriod}
                    >
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="custom-control custom-control-xs custom-checkbox mt-2">
              <input
                type="checkbox"
                id="checkbox-decimal-consolidation"
                className="custom-control-input"
                value={hasDecimalConsolidation}
                checked={hasDecimalConsolidation}
                disabled={
                  !vestingPeriod ||
                  vestingPeriod === 'default-option' ||
                  !consolidation ||
                  consolidation === 0.033 ||
                  +vestingPeriod < +consolidation ||
                  action === 'watch'
                }
                onChange={(event) =>
                  setHasDecimalConsolidation(event.target.checked)
                }
              />
              <label
                className="custom-control-label"
                htmlFor="checkbox-decimal-consolidation"
              >
                {t('ConsolidateAsUnitsAccumulatedDecimals')}
              </label>
            </div>

            <div className="custom-control custom-control-xs custom-checkbox mt-2">
              <input
                type="checkbox"
                id="checkbox-end-month-consolidation"
                className="custom-control-input"
                value={hasEndMonthConsolidation}
                checked={hasEndMonthConsolidation}
                disabled={
                  !vestingPeriod ||
                  vestingPeriod === 'default-option' ||
                  !consolidation ||
                  consolidation === 0.033 ||
                  +vestingPeriod < +consolidation ||
                  action === 'watch'
                }
                onChange={handleChangeEndMonthConsolidation}
              />
              <label
                className="custom-control-label"
                htmlFor="checkbox-end-month-consolidation"
              >
                {t('ConsolidateAtEndOfMonth')}
              </label>
            </div>

            <div className="custom-control custom-control-xs custom-checkbox mt-2">
              <input
                type="checkbox"
                id="checkbox-consolidation-period"
                className="custom-control-input"
                value={customConsolidation}
                checked={customConsolidation}
                disabled={
                  !vestingPeriod ||
                  vestingPeriod === 'default-option' ||
                  !consolidation ||
                  consolidation === 0.033 ||
                  +vestingPeriod < +consolidation
                }
                onChange={(event) =>
                  setCustomConsolidation(event.target.checked)
                }
              />
              <label
                className="custom-control-label"
                htmlFor="checkbox-consolidation-period"
              >
                {t('CustomizeTheDifferentCons')}
              </label>
            </div>
          </div>

          {customConsolidation && (
            <div className="card card-bordered card-preview mb-3">
              <div className="card-inner">
                {consolidationPeriods.map((period, index) => (
                  <div
                    className="form-group"
                    key={`period-by-percent-${index + 1}`}
                  >
                    <label className="form-label" htmlFor="default-01">
                      {t('Section')} {index + 1}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control"
                        value={period}
                        onChange={(event) =>
                          handleConsolidationPeriodsChange(
                            index,
                            event.target.value
                          )
                        }
                        onWheel={(event) => event.target.blur()}
                        min="0"
                        step="0.000000001"
                        placeholder="%"
                        required
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* POOL */}
          <div className="form-group">
            {poolByPercent ? (
              <label className="form-label" htmlFor="pay-amount">
                {t('PercentageOfPlanParticipa')}
              </label>
            ) : (
              <label className="form-label" htmlFor="pay-amount">
                {t('PlanParticipationsPool')}
              </label>
            )}
            <div className="form-control-wrap">
              <div className="form-text-hint">
                {poolByPercent ? (
                  <span className="overline-title">
                    {t('%OfParticipations')}
                  </span>
                ) : (
                  <span className="overline-title">{t('Shares')}</span>
                )}
              </div>
              <input
                type="number"
                className="form-control"
                placeholder={
                  poolByPercent
                    ? t('%OfThePlanParticipationsP')
                    : t('PlanParticipationsPool')
                }
                value={poolByPercent ? pool : currentPlanShares}
                onChange={(event) => setFormattedPool(event.target.value)}
                disabled={action === 'watch'}
                min={poolByPercent ? '0.000000001' : '1'}
                max={poolByPercent ? '100' : societyShares * 2}
                step={poolByPercent ? '0.000000001' : '1'}
                onWheel={(event) => event.target.blur()}
              />
            </div>
            {isValidPool && !poolByPercent && !pool && (
              <span className="sub-text text-primary mt-1 mb-3">
                {t('TotalParticipationsAvaila', { societyShares })}
              </span>
            )}
            {!isValidPool && (
              <span className="sub-text text-danger mt-1 mb-3">
                {t('InvalidPoolValue')}
              </span>
            )}
            {isValidPool && pool !== '' && poolByPercent && (
              <span className="sub-text mt-1 mb-3">
                {t('TheNumberOfParticipations', { pool, currentPlanShares })}
              </span>
            )}
            {isValidPool && pool !== '' && !poolByPercent && (
              <span className="sub-text mt-1 mb-3">
                {t('ThePercentageOfThePoolFor', { currentPlanShares, pool })}
              </span>
            )}
            {action === 'edit' && usedShares > currentPlanShares && (
              <div className="alert alert-icon alert-danger mt-2" role="alert">
                <em className="icon ni ni-alert-circle" />
                {t('PlanSharesInfo')}
              </div>
            )}
            <div className="row mt-2">
              <div className="col-md-3">
                <div className="custom-control-sm custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="pre-money"
                    id="pre-money"
                    value={poolTypes.PRE_MONEY}
                    checked={poolType === poolTypes.PRE_MONEY}
                    disabled={action === 'watch'}
                    onChange={(event) => setPoolType(event.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="pre-money">
                    Non diluted
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="custom-control-sm custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="post-money"
                    id="post-money"
                    value={poolTypes.POST_MONEY}
                    checked={poolType === poolTypes.POST_MONEY}
                    disabled={action === 'watch'}
                    onChange={(event) => setPoolType(event.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="post-money">
                    Fully diluted
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="custom-control-sm custom-switch float-right d-flex align-content-center">
                  <span
                    style={{
                      marginRight: '45px',
                      fontSize: '12px',
                      lineHeight: '1.125rem',
                      paddingTop: '0',
                    }}
                  >
                    {t('No')}
                  </span>
                  <input
                    type="checkbox"
                    id="checkbox"
                    className="custom-control-input float-right"
                    value={poolByPercent}
                    checked={poolByPercent}
                    disabled={action === 'watch'}
                    onChange={(event) => setPoolByPercent(event.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor="checkbox">
                    %
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* DOCUMENTATION */}
          {action === 'add' && (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="default-06">
                  {t('AddDetailsOfThePlanThisDo')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      id="customFile"
                      type="file"
                      accept="application/pdf"
                      className="custom-file-input"
                      onChange={(event) => setFile(event.target.files[0])}
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      {file?.name || t('SelectDocument')}
                    </label>
                  </div>
                </div>
                <span className="sub-text mt-2 text-dark">
                  {t('ThisPriceIsSubjectToThePlanAndLetter')}
                </span>
              </div>
            </>
          )}

          {/* CUSTOM DILUTION */}
          {!hasPlanCustomDilution(plans) && (
            <div className="form-group row">
              <div className="col-6">
                <label className="form-label" htmlFor="default-06">
                  {t('DoAllPartnersDilute')}
                </label>
              </div>
              <div className="col-2">
                <div className="custom-control-sm custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="partners-dilution-yes"
                    id="partners-dilution-yes"
                    checked={!hasCustomDilution}
                    disabled={action === 'watch' || action === 'edit'}
                    onChange={() => {
                      setHasCustomDilution(false);
                      setCustomDiluents([]);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="partners-dilution-yes"
                  >
                    {t('Yes')}
                  </label>
                </div>
              </div>
              <div className="col-2">
                <div className="custom-control-sm custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="partners-dilution-not"
                    id="partners-dilution-not"
                    checked={hasCustomDilution}
                    disabled={action === 'watch' || action === 'edit'}
                    onChange={() => setHasCustomDilution(true)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="partners-dilution-not"
                  >
                    {t('Not')}
                  </label>
                </div>
              </div>
            </div>
          )}

          {hasCustomDilution && action === 'add' && (
            <div className="card mb-4">
              <ReactDualList
                options={partnersList}
                icon
                canFilter
                setValue={setCustomDiluents}
              />
            </div>
          )}

          {(action === 'watch' || action === 'edit') && (
            <div className="form-group">
              <label className="form-label">
                {t('PartnersWhoDiluteWithThisPlan')}:
              </label>
              <div className="card card-bordered card-preview">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">{t('Nr')}</th>
                      <th scope="col">{t('Cif')}</th>
                      <th scope="col">{t('Name')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPlan?.customDiluents?.map((diluent, index) => (
                      <tr key={diluent}>
                        <td>{index + 1}</td>
                        <td>{getDiluentInfo(diluent)?.cif}</td>
                        <td>{getDiluentInfo(diluent)?.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* INVITATION LETTER */}
          {(action === 'add' || action === 'edit') && (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="default-06">
                  {t('GenerateConditionsTitle')}
                </label>

                <span className="sub-text">{t('GenerateConditionsText')}</span>

                <div className="custom-control custom-control-xs custom-checkbox mt-2">
                  <input
                    type="checkbox"
                    id="checkbox-invitation"
                    className="custom-control-input"
                    value={hasInvitation}
                    checked={hasInvitation}
                    disabled={!name || action === 'edit'}
                    onChange={(event) =>
                      handleSetInvitation(event.target.checked)
                    }
                  />
                  <label
                    className="custom-control-label mb-2"
                    htmlFor="checkbox-invitation"
                  >
                    {t('GenerateConditionsCheckText')}
                  </label>
                </div>

                {hasInvitation && (
                  <div className="custom-control custom-control-xs custom-checkbox mt-1">
                    <input
                      type="checkbox"
                      id="checkbox-send-to-all"
                      className="custom-control-input"
                      value={sendInvitationToAll}
                      checked={sendInvitationToAll}
                      disabled={!name}
                      onChange={(event) =>
                        setSendInvitationToAll(event.target.checked)
                      }
                    />
                    <label
                      className="custom-control-label mb-2"
                      htmlFor="checkbox-send-to-all"
                    >
                      {t('GenerateConditionsCheckSendToAll')}
                    </label>
                  </div>
                )}
              </div>

              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={
                  !planType ||
                  !name ||
                  !startDate ||
                  !sharePrice ||
                  vestingPeriod === 'default-option' ||
                  !consolidation ||
                  !pool ||
                  !isValidPool ||
                  usedShares > currentPlanShares
                }
              >
                {action === 'add' ? t('AddPlan') : t('Update')}
              </button>
            </>
          )}
        </form>
      </Modal.Body>
    </>
  );
}

export default StockOptionsModal;
