import sizes from 'constants/sizes';
import { setModal } from 'modules/_shared/redux/modalActions';
import EditPartnerModal from 'modules/partners/modals/EditPartner';
import { store } from 'redux/store';
import removePartner from 'utils/removePartner';

function partnerOptions(i18n) {
  return [
    {
      icon: 'ni-eye',
      text: i18n.t('Watch'),
      url: '/detalle-socio',
    },
    {
      icon: 'ni-pen',
      text: i18n.t('Edit'),
      action: ({ data, societyId }) => {
        store.dispatch(
          setModal(
            <EditPartnerModal
              partner={data}
              societyId={societyId}
              size={sizes.XL}
            />
          )
        );
      },
      dontShowCurrent: ({ isReadOnlyUser }) => isReadOnlyUser,
    },
    {
      icon: 'ni-repeat',
      text: i18n.t('Transactions'),
      url: '/detalle-socio',
      urlSuffix: '2',
    },
    {
      icon: 'ni-file',
      text: i18n.t('Documents'),
      url: '/detalle-socio',
      urlSuffix: '3',
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: async ({ data, society, userId }) => {
        await removePartner(data, society, userId, i18n);
      },
      dontShowCurrent: ({ data, isDemo, isReadOnlyUser }) =>
        data?.operations?.length > 1 || isDemo || isReadOnlyUser,
    },
  ];
}
export default partnerOptions;
