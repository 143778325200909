/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line no-restricted-syntax
// eslint-disable-next-line no-await-in-loop
import React, { useEffect, useState } from 'react';

import { store } from 'redux/store';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getUsers } from 'modules/profile/redux/userActions';
import UserWithAccessRow from './UserWithAccessRow';
import AddUserWithAccess from './AddUserWithAccess';

function UserWithAccess({ user }) {
  const [rows, setRows] = useState([]);
  const [accountAdmins, setAccountAdmins] = useState([]);

  const getAdmins = async (admins) => {
    try {
      const adminsList = await store.dispatch(
        getUsers({
          usersList: admins,
        })
      );
      setAccountAdmins(adminsList);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (user?.accountAdmins.length) {
      getAdmins(user?.accountAdmins);
    } else setAccountAdmins([]);
  }, [user?.accountAdmins]);

  useEffect(() => {
    if (accountAdmins) {
      setRows(
        accountAdmins?.map((admin) => (
          <UserWithAccessRow
            admin={admin}
            userId={user['_id']}
            key={admin['_id']}
          />
        ))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAdmins]);

  return (
    <div className="card-inner card-inner-lg">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">Usuarios con acceso a tu cuenta</h4>
          <div className="nk-block-des">
            <p>
              Puedes dar diferentes niveles de acceso a tu cuenta a otros
              usuarios.
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <p
            className="btn btn-success"
            onClick={() => store.dispatch(setModal(<AddUserWithAccess />))}
          >
            Añadir usuarios
          </p>
        </div>
      </div>
      <div className="nk-block">
        {rows.length ? (
          <div className="card card-bordered card-preview">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
                  <th className="nk-tb-col">
                    <span>Nombre y Apellidos</span>
                  </th>

                  <th className="nk-tb-col tb-col-xl">
                    <span>Fecha alta</span>
                  </th>

                  <th className="nk-tb-col tb-col-xl">
                    <span>Último acceso</span>
                  </th>

                  <th className="nk-tb-col tb-col-xl">
                    <span>Email</span>
                  </th>

                  <th className="nk-tb-col">
                    <span>Nivel de acceso</span>
                  </th>

                  <th className="nk-tb-col nk-tb-col-tools tb-col-end">
                    <span>Menu</span>
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default UserWithAccess;
