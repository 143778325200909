import { ReactNode } from 'react';

type Props = {
  title: string;
  subTitle?: string;
  subTitleSuffix?: string;
  description?: string;
  actions?: ReactNode[];
};

const getActionKey = (index: number) => `page-list-header-action-${index}`;

const PageListHeader = (props: Props) => {
  const { title, subTitle, subTitleSuffix, description, actions } = props;

  return (
    <>
      {title && (
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">{title}</h3>
          <p />
        </div>
      )}

      <div className="nk-block-head">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              {subTitle}
              {subTitleSuffix && (
                <>
                  &nbsp;/&nbsp;
                  <strong className="text-primary small">
                    {subTitleSuffix}
                  </strong>
                </>
              )}
            </h3>

            {description && (
              <div className="nk-block-des text-soft">
                <p>{description}</p>
              </div>
            )}
          </div>

          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <div className="toggle-expand-content" data-content="pageMenu">
                <ul className="nk-block-tools g-3">
                  {actions &&
                    actions?.length > 0 &&
                    actions?.map((action, index) => (
                      <li key={getActionKey(index)}>{action}</li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageListHeader;
