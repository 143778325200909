import { updateSociety } from 'modules/society/redux/societyActions';
import { store } from 'redux/store';
import Swal from 'sweetalert2';
import { editUser } from 'utils/modals';

function investorOptions(i18n) {
  return [
    {
      icon: 'ni-eye',
      text: i18n.t('Watch'),
      url: '/detalle-inversor',
    },
    {
      icon: 'ni-pen',
      text: i18n.t('Edit'),
      action: editUser,
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: ({ data, society }) => {
        Swal.fire({
          icon: 'warning',
          title: `<h4 className="nk-block-title page-title">${i18n.t(
            'SureYouWantToDeleteInvestor'
          )}  `,
          html: `<h3 class="text-primary fw-normal">${data?.name} </h3><br /><div class="caption-text"></div>`,
          confirmButtonText: i18n.t('OkDelete'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-light',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            const filteredInvestors = society?.investors?.filter(
              (investor) => investor['_id'] !== data['_id']
            );

            const newSocietyData = {
              investors: filteredInvestors,
            };

            store.dispatch(
              updateSociety(society['_id'], newSocietyData, false)
            );
            Swal.fire({
              title: `${i18n.t('InvestorRemovedSuccessfully')}`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            });
          }
        });
      },
    },
  ];
}
export default investorOptions;
