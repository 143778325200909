/* eslint-disable react/jsx-props-no-spreading */
import { FC, RefObject, useEffect, useRef } from 'react';

import { Participant, Society } from 'types';
import { useTranslate } from 'hooks/useTranslate';
import PrintButton from 'modules/_shared/components/Buttons/PrintButton';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import { getFullAddress } from 'utils/filters';
import { Tooltip } from 'react-tooltip';
import NumberFormat from 'react-number-format';
import { numberFormatDecimals } from 'constants/formats';
import { formatNumberDecimals } from 'utils/formats';

type BeneficiarySellInvoiceHeaderProps = {
  actualSociety: Society;
  participant: Participant;
  printRef: RefObject<HTMLDivElement>;
  simulatorSocietyValue: number;
  simulatorRetention: number;
  simulatorIncludeAllUnits: boolean;
  simulatorLastSocietyValue: number;
  onChangeSimulatorSocietyValue: (event: any) => void; // eslint-disable-line
  onChangeSimulatorRetention: (event: any) => void; // eslint-disable-line
  onChangeIncludeAllUnits: (event: any) => void; // eslint-disable-line
};

const BeneficiarySellInvoiceHeader: FC<BeneficiarySellInvoiceHeaderProps> = ({
  actualSociety,
  participant,
  printRef,
  simulatorSocietyValue,
  simulatorRetention,
  simulatorIncludeAllUnits,
  simulatorLastSocietyValue,
  onChangeSimulatorSocietyValue,
  onChangeSimulatorRetention,
  onChangeIncludeAllUnits,
}) => {
  const { t } = useTranslate();

  const simulatorSocietyValueRef = useRef<HTMLInputElement | null>(null);

  const downloadFileName = `${t('PrintHeaderIncentiveValueSimulator')} - ${
    actualSociety?.name
  }`;

  const handleChangeSimulatorSocietyValue = (event: any) => {
    onChangeSimulatorSocietyValue(event);
  };

  const handleChangeSimulatorRetention = (event: any) => {
    onChangeSimulatorRetention(event);
  };

  const handleChangeIncludeAllUnits = (event: any) => {
    onChangeIncludeAllUnits(event);
  };

  useEffect(() => {
    if (simulatorSocietyValueRef.current) {
      simulatorSocietyValueRef.current.focus();
    }
  }, []);

  return (
    <header>
      <PrintButton
        fileName={downloadFileName}
        printRef={printRef ? (printRef as RefObject<HTMLDivElement>) : null}
        hideLabel
        className="border-0 bg-transparent text-primary btn-trigger p-1 position-absolute absolute-top-right mr-5 mt-4"
      />
      <PrintHeader title={t('PrintHeaderIncentiveValueSimulator')} />

      <div className="invoice-head d-print-block">
        <div className="invoice-contact col-12 col-md-6 mb-4 mb-md-0 d-print-inline-block">
          <span className="overline-title">{t('Beneficiary')}</span>

          <div className="invoice-contact-info">
            <h5 className="title">{participant.name}</h5>
            <ul className="list-plain d-flex flex-column mt-3">
              <li className="d-inline-flex">
                <em className="icon ni ni-wallet" />
                <span>{participant.cif}</span>
              </li>
              <li className="d-inline-flex">
                <em className="icon ni ni-map-pin-fill" />
                <span>{getFullAddress(participant.address)}</span>
              </li>
              <li className="d-inline-flex">
                <em className="icon ni ni-mail" />
                <span>{participant.email}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="invoice-contact col-12 col-md-6 d-print-inline-block">
          <span className="overline-title">{t('Conditions')}</span>

          <div className="invoice-contact-info">
            <h5 className="title mb-3">{t('Customizables')}</h5>
          </div>

          <div className="row">
            <div className="form-group col-12 col-md-7">
              <label className="form-label" htmlFor="simulator-society-value">
                {t('SocietyValuationFD')}
                <em
                  className="icon ni ni-info notification-icon ml-1 text-primary"
                  id="icon-society-value-tooltip"
                />
                <Tooltip
                  anchorId="icon-society-value-tooltip"
                  place="top"
                  style={{ maxWidth: '300px' }}
                >
                  {t('SocietyValueSwitchMessage')}
                </Tooltip>
              </label>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-right">
                  <em className="icon ni ni-sign-eur" />
                </div>

                <NumberFormat
                  id="simulator-society-value"
                  className="form-control form-control-outlined"
                  value={simulatorSocietyValue}
                  onChange={handleChangeSimulatorSocietyValue}
                  isAllowed={(inputObj) => {
                    const { floatValue, formattedValue } = inputObj;
                    if (formattedValue === '') return true;

                    // @ts-ignore
                    if (floatValue >= 1 && Number.isInteger(floatValue))
                      return true;
                    return false;
                  }}
                  {...numberFormatDecimals}
                  getInputRef={(inputRef: any) => {
                    if (simulatorSocietyValueRef.current) {
                      simulatorSocietyValueRef.current = inputRef; // eslint-disable-line
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-group col-12 col-md-5">
              <label className="form-label" htmlFor="simulator-society-value">
                {t('Retention')}
              </label>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-right">
                  <em className="icon ni ni-percent" />
                </div>

                <NumberFormat
                  id="simulator-society-value"
                  className="form-control form-control-outlined"
                  value={simulatorRetention}
                  onChange={handleChangeSimulatorRetention}
                  isAllowed={(inputObj) => {
                    const { floatValue, formattedValue } = inputObj;
                    if (formattedValue === '') return true;

                    // @ts-ignore
                    if (floatValue >= 1 && Number.isInteger(floatValue))
                      return true;
                    return false;
                  }}
                  {...numberFormatDecimals}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="custom-control custom-checkbox custom-control-sm">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="include-all-units"
                  id="include-all-units"
                  checked={simulatorIncludeAllUnits}
                  onChange={handleChangeIncludeAllUnits}
                />
                <label
                  className="custom-control-label"
                  htmlFor="include-all-units"
                >
                  {t('IncludeAllVestedUnits')}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="invoice-head mt-2">
        <div className="invoice-contact col-12 col-md-4">
          <span className="overline-title">{t('LastSocietyValuation')}</span>
          <div className="invoice-contact-info mt-2">
            <ul className="list-plain d-flex flex-column">
              <li className="d-inline-flex">
                <em className="icon ni ni-growth" />
                <span>
                  {formatNumberDecimals(simulatorLastSocietyValue) || '-'}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default BeneficiarySellInvoiceHeader;
