/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';

import { setPreview } from 'modules/_shared/redux/previewActions';
import { getDocumentsByReference } from 'modules/documents/redux/documentActions';

import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import BoardActCertificatePreview from 'modules/boards/components/BoardActCertificatePreview';
import BoardActPreview from 'modules/boards/components/BoardActPreview';
import menuOptions from 'modules/boards/components/BoardDocuments/menuOptions';
import DocumentsGenerator from 'modules/documents/components/Creators/DocumentsGenerator';

const tabs = {
  ACT: 'ACT',
  CERTIFICATE: 'CERTIFICATE',
};

const BoardAct = ({ board }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(tabs.ACT);

  const preview = useSelector((state) => state.preview);
  const societyDocuments = useSelector((state) => state.documents);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const partnerRepresentants = useSelector(
    (state) => state.partnerRepresentants
  );

  const { actTemplate, actCertificateTemplate } = board;

  const [actTemplateData, setActTemplateData] = useState();
  const [actCertificateTemplateData, setActCertificateTemplateData] =
    useState();

  const [previewAct, setPreviewAct] = useState();
  const [previewActCertificate, setPreviewActCertificate] = useState();

  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const createActPreview = () => {
    const actUpdated = updateDocumentWithValues(
      actTemplateData?.editorModel,
      tags({
        society: actualSociety,
        board,
        partnerRepresentants,
      })
    );
    const actDataToDocument = (
      <DocumentsGenerator
        invitationModel={actUpdated?.html}
        hasConsolidationCalendar={false}
        consolidationCalendar={{}}
      />
    );

    setPreviewAct({
      name: actTemplateData.name,
      bdDocument: actTemplateData,
      document: actDataToDocument,
      documentModel: actUpdated?.html,
      template: actTemplateData?.['_id'],
    });
  };

  const createActCertificatePreview = () => {
    const certificateUpdated = updateDocumentWithValues(
      actCertificateTemplateData?.editorModel,
      tags({
        society: actualSociety,
        board: {
          ...board,
          actTemplateHtml: previewAct?.documentModel || '',
        },
      })
    );
    const certificateDataToDocument = (
      <DocumentsGenerator
        invitationModel={certificateUpdated?.html}
        hasConsolidationCalendar={false}
        consolidationCalendar={{}}
      />
    );

    setPreviewActCertificate({
      name: actCertificateTemplateData.name,
      bdDocument: actCertificateTemplateData,
      document: certificateDataToDocument,
      documentModel: certificateUpdated?.html,
      template: actCertificateTemplateData?.['_id'],
    });
  };

  const generateTemplatesPreview = async () => {
    try {
      await createActPreview();
      await createActCertificatePreview();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectTabs = async (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (societyDocuments.length) {
      setActTemplateData(
        societyDocuments.find((doc) => doc['_id'] === actTemplate)
      );
      setActCertificateTemplateData(
        societyDocuments.find((doc) => doc['_id'] === actCertificateTemplate)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyDocuments]);

  useEffect(() => {
    if (actualSociety && actTemplateData && actCertificateTemplateData) {
      generateTemplatesPreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actTemplateData]);

  useEffect(() => {
    if (selectedTab === tabs.ACT) {
      store.dispatch(setPreview({ ...previewAct }));
    }

    if (selectedTab === tabs.CERTIFICATE) {
      store.dispatch(setPreview({ ...previewActCertificate }));
    }
  }, [selectedTab, previewAct, previewActCertificate]);

  return (
    <>
      <div className="nk-block pt-4">
        <div className="card">
          <div className="card-aside-wrap">
            <div className="card-content">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li className="nav-item">
                  <p
                    className={`nav-link pt-0 ${
                      selectedTab === tabs.ACT ? 'active' : ''
                    }`}
                    onClick={() => handleSelectTabs(tabs.ACT)}
                  >
                    <em className="icon ni ni-file-text" />
                    <span>{t('Act')}</span>
                  </p>
                </li>
                <li className="nav-item">
                  <p
                    className={`nav-link pt-0 ${
                      selectedTab === tabs.CERTIFICATE ? 'active' : ''
                    }`}
                    onClick={() => handleSelectTabs(tabs.CERTIFICATE)}
                  >
                    <em className="icon ni ni-file-text-fill" />
                    <span>{t('Certification')}</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <br />

      {actTemplateData && preview && selectedTab === tabs.ACT && (
        <div className="nk-block">
          <BoardActPreview board={board} />
        </div>
      )}

      {actTemplateData && preview && selectedTab === tabs.CERTIFICATE && (
        <div className="nk-block">
          <BoardActCertificatePreview board={board} />
        </div>
      )}
    </>
  );
};

export default BoardAct;
