/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { useTranslate } from 'hooks/useTranslate';
import { ChangeEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { BoardDelegationRole, Participant, Society } from 'types';

import Dialog from 'modules/_shared/components/Dialog';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getBoards, updateBoard } from 'modules/boards/redux/boardActions';

import AssistentsModalHeader from './AssistentsModalHeader';
import AssistentsModalTable from './AssistentsModalTable';

import {
  getPartnersOnAssistsChange,
  getPartnersOnInitialLoad,
  getPartnersOnRepresentationChange,
  getPartnersOnRepresentativeChange,
  mapParticipantsForSaving,
} from './utils';

type AssistentsModalProps = {
  society: Society;
  boardId: string;
};

type Option = {
  value: string;
  label: string;
  disabled: boolean;
};

const AssistentsModal = ({ society, boardId }: AssistentsModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const actualBoard = useSelector((state: State) =>
    state.boards.find((board) => board['_id'] === boardId)
  );

  const [assistsAll, setAssistsAll] = useState<boolean | null>(null);
  const [assistants, setAssistants] = useState<Participant[]>([]);
  const [hasAssistants, setHasAssistants] = useState<boolean>(false);
  const [totalAssistants, setTotalAssistants] = useState<number>(0);
  const [totalPercentND, setTotalPercentND] = useState<number>(0);

  const [participants, setParticipants] = useState<Participant[]>([]);
  const [representatives, setRepresentatives] = useState<Participant[]>([]);

  const [searchQuery, setSearchQuery] = useState('');

  const filterPartners = (query: string) => {
    if (!query || query.length < 3) return participants;

    return participants.filter((participant: Participant) =>
      participant.name.toLowerCase().includes(query.toLowerCase())
    );
  };

  const getCurrentValues = async () => {
    dispatch(getBoards(society['_id']));
  };

  const handleChangeAssistsAll = (event: ChangeEvent<HTMLInputElement>) => {
    const assists = event.target.value === 'true';
    setAssistsAll(assists);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleChangeRepresentative = (
    memberId: string,
    value: string,
    role?: BoardDelegationRole
  ) => {
    if (value !== '') {
      const updatedParticipants = getPartnersOnRepresentativeChange(
        memberId,
        value,
        participants,
        role
      );
      setParticipants(updatedParticipants);
    }
  };

  const handleChangeRepresentation = (memberId: string, value: boolean) => {
    const updatedParticipants = getPartnersOnRepresentationChange(
      memberId,
      value,
      participants
    );
    setParticipants(updatedParticipants);
  };

  const handlePartnerOptionChange = (
    memberId: string,
    value: string,
    role?: BoardDelegationRole
  ) => {
    handleChangeRepresentative(memberId, value, role);
  };

  const handleChangeAssists = (memberId: string, value: boolean) => {
    const updatedParticipants = getPartnersOnAssistsChange(
      memberId,
      value,
      participants
    );
    setParticipants(updatedParticipants);
  };

  const saveAssistants = async () => {
    Dialog({
      icon: 'ni-question',
      title: t('SetNoParticipantsAbsent'),
      buttonText: t('Yes,IWant'),
      cancelButtonText: t('Nope,Thanks'),
      onConfirm: () => {
        const participantsData = mapParticipantsForSaving(true, participants);
        dispatch(updateBoard(boardId, { participants: participantsData }));
        dispatch(setModal(null));
      },
      onCancel: () => {
        const participantsData = mapParticipantsForSaving(false, participants);
        dispatch(updateBoard(boardId, { participants: participantsData }));
        dispatch(setModal(null));
      },
    });
  };

  useEffect(() => {
    if (assistants?.length) {
      const participantsList = getPartnersOnInitialLoad(society, assistants);

      setRepresentatives(participantsList);
      setParticipants(participantsList);
    }
  }, [assistants]);

  useEffect(() => {
    if (assistsAll) {
      const updatedParticipants: Participant[] = [];
      participants.map((participant: Participant) =>
        updatedParticipants.push({
          ...participant,
          assists: true,
          isAbsent: false,
        })
      );
      setParticipants(updatedParticipants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistsAll]);

  useEffect(() => {
    let currentHasAssistants = false;
    let currentTotalAssistants = 0;
    let currentPercentND = 0;

    participants.forEach((participant) => {
      if (participant.assists) {
        currentHasAssistants = true;
        currentTotalAssistants += 1;
        currentPercentND += participant.percentage || 0;
      }
    });
    setHasAssistants(currentHasAssistants);
    setTotalPercentND(currentPercentND);
    setTotalAssistants(currentTotalAssistants);
  }, [participants]);

  useEffect(() => {
    if (society) {
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    if (actualBoard) {
      setAssistants(actualBoard?.participants || []);
    }
  }, [actualBoard]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('ControlOfMeetingAttendees')}</h5>
        <span className="badge-primary badge-sm rounded px-2 py-1 ml-2 fw-bold">
          {+totalPercentND.toFixed(3) || 0}% capital
        </span>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <div className="form-group" id="board-assistents-modal">
          <AssistentsModalHeader
            assistsAll={assistsAll}
            searchQuery={searchQuery}
            handleSearch={handleSearch}
            handleChangeAssistsAll={handleChangeAssistsAll}
          />

          <AssistentsModalTable
            boardId={boardId}
            societyId={society._id}
            participants={filterPartners(searchQuery)}
            representatives={representatives}
            handleChangeAssists={handleChangeAssists}
            handlePartnerOptionChange={handlePartnerOptionChange}
            handleChangeRepresentation={handleChangeRepresentation}
          />
        </div>

        <div className="d-flex flex-row justify-content-end align-items-center h-50px">
          <button
            type="button"
            className="btn btn-lg btn-outline-primary btn-dim"
            onClick={saveAssistants}
          >
            {t('Confirm')} {totalAssistants || ''}{' '}
            {totalAssistants === 1 ? t('Assistant') : t('Assistants')}
          </button>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <span className="sub-text">{t('OnlyAttendeesAreReflected')}</span>
      </Modal.Footer>
    </>
  );
};

export default AssistentsModal;
