import { useTranslate } from 'hooks/useTranslate';

import { BoardDelegationRole, Participant } from 'types';

import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import AssistentsModalRow from './AssistentsModalRow';

type AssistentsModalTableProps = {
  boardId: string;
  societyId: string;
  participants: Participant[];
  representatives: Participant[];
  handleChangeAssists: (memberId: string, checked: boolean) => void; // eslint-disable-line
  handleChangeRepresentation: (memberId: string, value: boolean) => void; // eslint-disable-line
  handlePartnerOptionChange: (
    memberId: string, // eslint-disable-line
    value: string, // eslint-disable-line
    role: BoardDelegationRole // eslint-disable-line
  ) => void;
};

const AssistentsModalTable = ({
  boardId,
  societyId,
  participants,
  representatives,
  handleChangeAssists,
  handleChangeRepresentation,
  handlePartnerOptionChange,
}: AssistentsModalTableProps) => {
  const { t } = useTranslate();
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="fw-first-name">
        {t('SelectTheMembersWhoHaveAttended')}:
      </label>
      <div className="form-control-wrap ">
        <div className="card card-bordered card-full">
          {participants?.length > 0 &&
            participants
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((partner, index) => (
                <>
                  <ul
                    key={partner?.cif || index}
                    className={
                      index + 1 > participants.length - 1
                        ? 'nk-activity'
                        : 'nk-activity border-bottom'
                    }
                  >
                    <AssistentsModalRow
                      index={index}
                      boardId={boardId}
                      societyId={societyId}
                      currentParticipant={partner}
                      participants={participants}
                      representatives={representatives}
                      partnerRepresentants={partnerRepresentants}
                      handleChangeAssists={handleChangeAssists}
                      handleChangeRepresentation={handleChangeRepresentation}
                      handlePartnerOptionChange={handlePartnerOptionChange}
                    />
                  </ul>
                </>
              ))}
        </div>
      </div>
    </div>
  );
};

export default AssistentsModalTable;
